export const GET_ORDERS = 'GET_ORDERS'
export const GET_ORDER = 'GET_ORDER'
export const GET_ORDER_DETAIL = 'GET_ORDER_DETAIL'
export const ORDER_DETAIL_SUCCESS = 'ORDER_DETAIL_SUCCESS'
export const ORDER_DETAIL_ERROR = 'ORDER_DETAIL_ERROR'
export const SUBMIT_ARTWORK_CHANGES = 'SUBMIT_ARTWORK_CHANGES'
export const APPROVE_ART_WORK = 'APPROVE_ART_WORK'
export const UPLOAD_ART_WORK = 'UPLOAD_ART_WORK'
export const UPLOAD_PRODUCT_ART_WORK = 'UPLOAD_PRODUCT_ART_WORK'
export const ORDER_ERROR = 'ORDER_ERROR'
export const ORDERS_SUCCESS = 'ORDERS_SUCCESS'
export const ORDERS_ERROR = 'ORDERS_ERROR'