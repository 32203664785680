<template>
    <!-- <v-app> -->
    <v-col cols="12" sm="4" md="4" lg="3" xl="2" class="px-lg-2">
        <v-card class="rounded-lg" height="100%" :to="'/customer/order-detail/' + order.details?.id">
            <div class="pa-2">
                <div class="text-left mb-2">
                    <v-chip color="orange lighten-5" v-if="getArtworkStatus(order)"
                        class="orange--text font-weight-extramedium" small>{{
                                getArtworkStatus(order)
                        }}
                    </v-chip>
                </div>
            </div>
            <div>
                <v-carousel hide-delimiters :show-arrows="true" height="auto">
                    <template v-slot:prev="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" min-width="4" height="24" color="blue-grey lighten-5"
                            class="pa-1 elevation-0 rounded-circle primary--text">
                            <v-icon class="text-body-1">mdi-chevron-left</v-icon>
                        </v-btn>
                    </template>
                    <v-carousel-item v-for="(image, imageIndex) in productimages" :key="imageIndex">
                        <v-card class="pa-0">
                            <div class="fill-height">
                                <!-- <v-img :src="image" contain aspect-ratio="1.5">
                                </v-img> -->
                                <NwImg :src="image" alt="Example Alt Text"
                                    style="min-height: 200px; max-height: 200px" />
                            </div>
                        </v-card>
                    </v-carousel-item>
                    <template v-slot:next="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" min-width="4" height="24" color="blue-grey lighten-5"
                            class="pa-1 elevation-0 rounded-circle primary--text">
                            <v-icon class="text-body-1">mdi-chevron-right</v-icon>
                        </v-btn>
                    </template>
                </v-carousel>
                <div class="text-left flex-column align-baseline pa-2">
                    <div class="text-body-2 font-weight-extramedium mb-2">Order request:
                        {{ order.number }}</div>
                    <div class="text-body-2 gray--text mb-1">{{ order.details?.number_of_boxes }} units • Swagbox</div>
                </div>

                <v-divider></v-divider>
                <!-- Sept 2, 2022 -->
                <div class="text-left flex-column align-baseline pa-2">
                    <div class="text-body-2 font-weight-extramedium mb-2">Requested on {{
                            getCreatedDate(order.created_at)
                    }}</div>
                    <div class="text-body-2 font-weight-extramedium mb-2">Total: <span class="font-weight-bold">{{
                            Number(order.details?.total_price).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })
                    }}</span></div>
                    <div class="text-left pb-1">
                        <v-chip color="indigo lighten-5" class="primary--text font-weight-extramedium" small>{{
                                getOrderStatus(order.status)
                        }}
                        </v-chip>
                    </div>
                </div>
            </div>
        </v-card>
    </v-col>
    <!-- </v-app> -->
</template>

<script>
import NwImg from "@/components/NwImg"
export default {
    name: 'ProductCard',
    props: {
        order: {
            type: Object,
            default: function () {
                return {}
            },
            required: true
        }
    },
    components: {
        NwImg
    },
    data() {
        return {
            OrdersTab: null,
            RequestSortSelected: 'Newest First',
            RequestSortBy: ['Newest First', 'Oldest First',],
            RequestStatusSelected: 'All',
            RequestStatus: ['All', 'Artwork not submitted', 'Proofs ready', 'Ready for Production',],
            // ProductSlider: 0,
            // productimages: [{
            //     src: require('@/assets/images/speedup-socks-and-stickers.png')
            // },
            // {
            //     src: require('@/assets/images/classics-pack.png')
            // },
            // {
            //     src: require('@/assets/images/cozy-and-calm.png')
            // },
            // {
            //     src: require('@/assets/images/happy-camper.png')
            // },
            // ],
        }
    },

    mounted() { },
    computed: {
        productimages() {
            let images = [];
            let productIds = [];
            this.order.variants.forEach(variant => {
                if (variant.images && variant.images.length > 0) {
                    if (!productIds.includes(variant.product_id)) {
                        variant.images.forEach(image => {
                            images.push(image);
                        });
                        productIds.push(variant.product_id);
                    }
                }
            });
            return images;
        }
    },
    methods: {
        getCreatedDate(created_at) {
            let date = new Date(created_at)
            return date.toLocaleString('default', { month: 'short' }) + " " + date.getDate() + ", " + date.getFullYear()
        },

        getOrderStatus(status) {
            if (status == 'UNFULFILLED') {
                return 'Unpaid';
            } else {
                return 'Paid';
            }
        },

        getArtworkStatus(order) {
            if (order.details.primary_logo == 'null' && order.details.secondary_logo == 'null') {
                return 'Artwork not submitted';
            } else {
                return false
            }
        }
    },
    created() { }
}
</script>

<style scoped>
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child,
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row) {
    border-bottom: 0px;
}
</style>
