<template>
  <v-app>
    <v-container fluid class="hero">
      <v-row class="align-center px-4">
        <v-col cols="12" sm="10" md="8" lg="6" class="text-left">
          <div class="py-8 py-sm-8">
            <!-- <v-btn color="rgba(27, 94, 32, 0.16)" dense class="text-none mb-2 green--text rounded-xl elevation-0 font-weight-bold">Looking to start your Print on Demand Business? <span class="font-weight-extrabold ml-2 mr-1">Try SwagifyPOD</span>😊</v-btn> -->
            <h1 class="text--primary font-weight-black text-h4 text-sm-h2 text-md-h2 text-lg-h1">Design and send custom swag anywhere,<br />anytime.</h1>
            <p class="my-4">Say goodbye to boring gifts and say hello, to a fully featured swag gifting platform for your employees, friends, and family.</p>
            <router-link :to="{name: 'ProductOnboarding',}" tag="v-btn" class="font-weight-bold primary text-none rounded-sm py-6">
              Shop swagbox
            </router-link>
            <br/>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  export default {
    name: 'Home-Page',
    components: {},
    mounted() {},
    computed: {},
    data() {
      return {

      }
    },
    methods: {}
  }
</script>

<style scoped>
  .hero {
    background: url('@/assets/images/hero-bnr.jpg');
    background-size: cover;
    /* height: 80vh; */
  }

  .hero .row {
    height: 100%;
  }
</style>
