<template>
  <v-app>
    <!-- How would you like your swagbox to be shipped? -->
    <div class="text-h5 text--primary text-left font-weight-bold mb-3">How would you like your swagbox to be shipped?
    </div>
    <v-sheet cols="12" class="mb-8">
      <v-tabs v-model="shippingOpt" height="auto" active-class="active-card" fixed-tabs slider-size="2" width="100%"
        class="shiptabs">
        <v-tab class="pa-0 ma-1 ma-lg-2">
          <v-card width="100%" height="100%" class="text-left pa-2 pa-sm-4 elevation-2">
            <div class="text-body-1 text-lg-h6 font-weight-bold mb-2 text-none">Order now, Ship to one address</div>
            <div class="text-body-2 text-lg-body-1 text-none">Your bulk order will all be sent to the address that you
              provide as soon as it’s ready!</div>
          </v-card>
        </v-tab>
        <v-tab class="pa-0 ma-1 ma-lg-1" disabled>
          <v-card width="100%" height="100%" class="text-left pa-2 pa-sm-4 elevation-2">
            <!-- Coming soon -->
            <v-chip color="primary" dense class="light--text font-weight-bold caption text-none py-0 px-3 mb-1" style="height: 22px;">Coming soon</v-chip>
            <!-- Coming soon -->
            <div class="text-body-1 text-lg-h6 font-weight-bold mb-2 text-none">Keep inventory, Ship to multiple
              addresses</div>
            <div class="text-body-2 text-lg-body-1 text-none">Send your swagboxes to our warehouse and, and then ship to
              individual addresses as needed!</div>
          </v-card>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="shippingOpt">

        <!-- Order now, Ship to one address TAB -->
        <v-tab-item :transition="false">
          <div class="text-left my-3">
            <div class="text-h6 font-weight-bold primary--text mx-sm-2" id="shipping_detail">Shipping details</div>
            <validation-observer ref="observer">
              <v-form>
                <v-container>
                  <v-row>
                    <!-- <v-col cols="12" md="6">
                    <v-subheader class="px-0 text--primary font-weight-bold">Country</v-subheader>
                    <v-select hide-details dense outlined>

                    </v-select>
                  </v-col> -->
                    <v-col cols="12" md="6">
                      <v-subheader class="px-0 text--primary font-weight-bold">Street address *</v-subheader>
                      <validation-provider v-slot="{ errors }" rules="required" name="Street address">
                        <v-text-field placeholder="Street address" outlined dense required :error-messages="errors"
                          :error="errors.length !== 0" :hide-details="errors.length <= 0" @change="updateShipping"
                          v-model="shipping_address.address_one">
                        </v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-subheader class="px-0 text--primary font-weight-bold">Street address 2</v-subheader>
                      <v-text-field placeholder="Street address" hide-details outlined dense required
                        @change="updateShipping" v-model="shipping_address.address_two">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-subheader class="px-0 text--primary font-weight-bold">State *</v-subheader>
                      <validation-provider v-slot="{ errors }" rules="required" name="State">
                        <v-autocomplete outlined dense :items="$store.state.us_states" v-model="shipping_address.state"
                          @change="updateShipping" item-value="name" :error-messages="errors"
                          :error="errors.length !== 0" :hide-details="errors.length <= 0" item-text="name">
                        </v-autocomplete>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-subheader class="px-0 text--primary font-weight-bold">City *</v-subheader>
                      <validation-provider v-slot="{ errors }" rules="required" name="City">
                        <v-text-field placeholder="Albany" outlined dense required :error-messages="errors"
                          :error="errors.length !== 0" :hide-details="errors.length <= 0"
                          v-model="shipping_address.city" @change="updateShipping">
                        </v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-subheader class="px-0 text--primary font-weight-bold">Zip postal code *</v-subheader>
                      <validation-provider v-slot="{ errors }" rules="required" name="Zip postal code">
                        <v-text-field placeholder="12084" outlined dense required :error-messages="errors"
                          :error="errors.length !== 0" :hide-details="errors.length <= 0"
                          v-model="shipping_address.postal_code" @change="updateShipping">
                        </v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-subheader class="px-0 text--primary font-weight-bold">Phone number *</v-subheader>
                      <!-- <vue-phone-number-input v-model="shipping_address.phone_number" @update="updatePhone"
                      @change="updateShipping"
                      :default-country-code="customerInfo.phone_data?.countryCode ? customerInfo.phone_data.countryCode : 'US'"
                      no-example :translations="{phoneNumberLabel: null}">
                    </vue-phone-number-input> -->
                      <validation-provider v-slot="{ errors }" rules="required|limit_phone:16" name="Phone Number">
                        <v-text-field placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'"
                          :error-messages="errors" :error="errors.length !== 0" :hide-details="errors.length <= 0"
                          v-model="shipping_address.phone_number" outlined dense required>
                        </v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </validation-observer>
          </div>
        </v-tab-item>

        <!-- Keep inventory, Ship to multiple addresses TAB -->
        <v-tab-item :transition="false">
          <div class="text-left my-3 dashed-border pa-2 pa-md-3 ma-lg-2">
            <div class="pa-1 mb-2">
              <div class="text-body-1 text-lg-h6 font-weight-bold mb-2 text-none primary--text">There’s some additional
                cost to hold your inventory in our warehouse</div>
              <div class="text-body-1 primary--text font-weight-extramedium">Read <v-btn text
                  class="text-none pa-0 text-body-1 primary--text font-weight-bold" height="auto">terms & conditions
                </v-btn> before commiting </div>
            </div>
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="4" md="4" lg="4" class="pa-1">
                  <div class="green--text font-weight-bold text-body-1 text--darken-2 mb-2">Keeping Inventory</div>
                  <div class="text-body-2">Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="4" class="pa-1">
                  <div class="green--text font-weight-bold text-body-1 text--darken-2 mb-2">Shipping</div>
                  <div class="text-body-2">Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                </v-col>
                <v-col cols="12" sm="4" md="4" lg="4" class="pa-1">
                  <div class="green--text font-weight-bold text-body-1 text--darken-2 mb-2">Packaging</div>
                  <div class="text-body-2">Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                </v-col>
              </v-row>
            </v-container>
            <!-- Divider -->
            <v-divider class="my-3"></v-divider>

            <!-- Cost -->
            <v-container>
              <v-row class="justify-end">
                <v-col cols="12" lg="5" class="pa-1">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td class="font-weight-extramedium text-left px-1">Storage cost of 1st box</td>
                          <td class="text-right font-weight-extrabold px-1">$5.00</td>
                        </tr>
                        <tr>
                          <td class="font-weight-extramedium text-left px-1">Each additional box needed</td>
                          <td class="text-right font-weight-extrabold px-1">$4.50</td>
                        </tr>
                        <tr>
                          <td class="font-weight-extramedium text-left px-1">Additional storage cost after 6 months</td>
                          <td class="text-right font-weight-extrabold px-1">$1.50</td>
                        </tr>
                        <tr>
                          <td class="font-weight-extramedium text-left px-1">Small Item Fee</td>
                          <td class="text-right font-weight-extrabold px-1">$0.25</td>
                        </tr>
                        <tr>
                          <td class="font-weight-extramedium text-left px-1">Medium Item Fee</td>
                          <td class="text-right font-weight-extrabold px-1">$1.00</td>
                        </tr>
                        <tr>
                          <td class="font-weight-extramedium text-left px-1">Large Item Fee</td>
                          <td class="text-right font-weight-extrabold px-1">$3.00</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
  </v-app>
</template>

<script>
export default {
  name: 'ContactDetails',
  components: {},
  mounted() { },
  computed: {},
  data() {
    return {
      customerInfo: {
        first_name: null,
        last_name: null,
        email: null,
        company_name: null,
        phone_number: null,
        phone_data: {},
      },
      shipping_address: {
        address_one: null,
        address_two: null,
        city: null,
        postal_code: null,
        state: 'New York',
        phone_number: null,
      },
      editAddress: false,
      shippingOpt: null,
    }
  },
  watch: {
    shippingOpt: {
      handler(newValue, oldValue) {
        this.$root.$emit('togglePaylaterButton', newValue)
      },
    }
  },
  methods: {
    updatePhone(e) {
      this.shipping_address.phone_number = e.nationalNumber
      this.$store.commit('updateShippingAddress', this.shipping_address)
    },

    validateForm() {
      if (!this.shippingOpt) {
        this.$refs.observer.validate().then(success => {
          if (!success) {
            this.$vuetify.goTo('#shipping_detail', { offset: 140 })
            return
          }
          this.$root.$emit('shhippingFormValidated');
        });

      } else {
        this.$root.$emit('shhippingFormValidated');
      }

    },

    // updateCustomerPhone(e) {
    //   this.customerInfo.phone_data = e
    //   this.customerInfo.phone_number = e.nationalNumber
    // },

    updateShipping() {
      this.shipping_address.phone_number = this.customerInfo.phone_number
      this.$store.commit('updateShippingAddress', this.shipping_address)
    },
    openEditForm(edit) {
      if (!edit) {
        this.customerInfo.first_name = this.$store.getters.getCustomerInfo.customer_info.first_name
        this.customerInfo.last_name = this.$store.getters.getCustomerInfo.customer_info.last_name
        this.customerInfo.email = this.$store.getters.getCustomerInfo.customer_info.email
        this.customerInfo.company_name = this.$store.getters.getCustomerInfo.customer_info.company_name
        this.customerInfo.phone_number = this.$store.getters.getCustomerInfo.customer_info.phone_number
        // this.customerInfo.phone_data = this.$store.getters.getCustomerInfo.customer_info.phone_data.formatInternational ? this.$store.getters.getCustomerInfo.customer_info.phone_data : JSON.parse(this.$store.getters.getCustomerInfo.customer_info.phone_data)
        this.phone_number = this.$store.getters.getCustomerInfo.customer_info.phone_number
      }

      this.editAddress = !edit
    },

    getPhoneNumber() {
      if (this.$store.getters.getCustomerInfo.customer_info.phone_data?.formatInternational) {
        return this.$store.getters.getCustomerInfo.customer_info.phone_data?.formatInternational
      } else {
        return JSON.parse(this.$store.getters.getCustomerInfo.customer_info.phone_data).formatInternational
      }
      // console.log('PH', JSON.parse(this.$store.getters.getCustomerInfo.customer_info.phone_data))
      // return this.$store.getters.getCustomerInfo.customer_info.phone_number
    },

    saveAddress() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }
        // if (!this.customerInfo.phone_data || !this.customerInfo.phone_data?.isValid) {
        //   return
        // }

        this.$store.getters.getCustomerInfo.customer_info.first_name = this.customerInfo.first_name
        this.$store.getters.getCustomerInfo.customer_info.last_name = this.customerInfo.last_name
        this.$store.getters.getCustomerInfo.customer_info.email = this.customerInfo.email
        this.$store.getters.getCustomerInfo.customer_info.company_name = this.customerInfo.company_name
        this.$store.getters.getCustomerInfo.customer_info.phone_number = this.customerInfo.phone_number
        // this.$store.getters.getCustomerInfo.customer_info.phone_data = JSON.stringify(this.customerInfo.phone_data)
        this.$store.getters.getCustomerInfo.customer_info.phone_number = this.customerInfo.phone_number
        this.editAddress = !this.editAddress
      });
    }
  },

  created() {
    console.log('this.$store.state.shipping_address', this.$store.state.shipping_address)
    this.shipping_address = this.$store.state.shipping_address
    this.$root.$on('validateShhippingForm', this.validateForm);
  }
}
</script>

<style scoped>
.v-subheader {
  height: 30px;
}

.v-text-field__details {
  margin-bottom: 0 !important;
  min-height: 0px !important;
}

.v-input__control .v-messages {
  min-height: 0px !important;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child,
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row) {
  border-bottom: 0px;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: none !important;
}
</style>
