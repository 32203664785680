<template>
  <v-app id="footer">
    <v-footer class="py-8">
      <v-row class="px-4">
        <v-col cols="12" class="text-lg-left">
          <div>
            <v-img :src="require('@/assets/images/swagbox-logo.png')" alt="Design tool" width="180px" class="fill-height"></v-img>
          </div>
          <p class="font-weight-medium mt-4 mb-2 ps-4">© {{ new Date().getFullYear() }} Swagboxes. All rights reserved. </p>
          <v-btn href="https://print-on-demand.swagify.com/terms-of-use.html" target="_blank" color="primary" text rounded class="my-2 font-weight-medium text-sm-body-2 text-none">
            Terms of use
          </v-btn>
          <v-btn href="https://print-on-demand.swagify.com/privacy-policy.html" target="_blank" color="primary" text rounded class="my-2 font-weight-medium text-sm-body-2 text-none">
            Privacy policy
          </v-btn>
          <v-btn href="https://print-on-demand.swagify.com/contact-us.html" target="_blank" color="primary" text rounded class="my-2 font-weight-medium text-sm-body-2 text-none">
            Contact
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
  export default {
    name: "Footer-bar",
    data: () => ({

    }),
  };
</script>

<style>
  #footer .v-application--wrap {
    min-height: auto;
  }
</style>
