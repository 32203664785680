<template>
  <v-app>
    <Navbar v-if="!$route.meta.hideNavbar" />

    <v-main id="mainclass">
      <router-view />
      <v-snackbar v-model="alertStatus" :color="getAlertColor ? getAlertColor : 'green darken-4'" min-height="60" right
        top :timeout="3000">
        {{ getAlertText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="alertStatus = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <!-- Loader -->
      <div class="loader-full" v-if="loading">
        <v-progress-circular :size="65" :width="3" color="primary" indeterminate>
          <v-img :src="require('@/assets/images/swagbox-icon.png')" contain alt="Swagbox Logo" height="36">
          </v-img>
        </v-progress-circular>
      </div>
      <!-- Loader -->

    </v-main>

    <Footer v-if="!$route.meta.hideFooter" />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  },

  data() {
    return {}
  },

  computed: {
    alertStatus: {
      get: function () {
        return this.$store.getters.getAlertStatus;
      },
      set: function () {
        this.$store.commit("hideAlert");
      },
    },

    loading() {
      return this.$store.getters.getPageLoadingStatus;
    },
    ...mapGetters(["getAlertText", "getAlertSuccess", "getAlertColor", "getBackdropOverlay"]),
  },

  created() {
  }
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.w-100 {
  width: 100%;
}

.v-snack__wrapper {
  margin-top: 5% !important;
}

.header-top .v-application--wrap {
  min-height: auto;
}

.v-text-field__details {
  padding: 2px 0px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
