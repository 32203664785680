import Vue from "vue";
import Vuex from "vuex";
import presetBoxes from "./modules/presetBoxes";
import stripe from "./modules/stripe";
import products from "./modules/products";
import categories from "./modules/categories";
import auth from "./modules/auth";
import orders from "./modules/orders";
import createPersistedState from "vuex-persistedstate";
// import SecureLS from "secure-ls";

// const secureLS = new SecureLS({ isCompression: false });
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cart: {},
    // test: [],
    customerInfo: {},
    alert: false,
    alertText: "",
    alertSuccess: false,
    alertColor: null,
    backdropOverlay: false,
    filterApplied: false,
    number_of_logos: 1,
    pageLoading: false,
    selectedColors: [],
    selectedSizes: [],
    selectedBrand: [],
    quantityLimits: {
      from: 0,
      to: 99
    },
    logo_price: 0.00,
    // testCart: [],
    testnum: null,
    shipping_address: {
      address_one: null,
      address_two: null,
      city: null,
      postal_code: null,
      state: 'New York',
      phone_number: null,
    },
    us_states: [
      {
        name: "Alabama",
        abbreviation: "AL"
      },
      {
        name: "Alaska",
        abbreviation: "AK"
      },
      {
        name: "American Samoa",
        abbreviation: "AS"
      },
      {
        name: "Arizona",
        abbreviation: "AZ"
      },
      {
        name: "Arkansas",
        abbreviation: "AR"
      },
      {
        name: "California",
        abbreviation: "CA"
      },
      {
        name: "Colorado",
        abbreviation: "CO"
      },
      {
        name: "Connecticut",
        abbreviation: "CT"
      },
      {
        name: "Delaware",
        abbreviation: "DE"
      },
      {
        name: "District Of Columbia",
        abbreviation: "DC"
      },
      {
        name: "Federated States Of Micronesia",
        abbreviation: "FM"
      },
      {
        name: "Florida",
        abbreviation: "FL"
      },
      {
        name: "Georgia",
        abbreviation: "GA"
      },
      {
        name: "Guam",
        abbreviation: "GU"
      },
      {
        name: "Hawaii",
        abbreviation: "HI"
      },
      {
        name: "Idaho",
        abbreviation: "ID"
      },
      {
        name: "Illinois",
        abbreviation: "IL"
      },
      {
        name: "Indiana",
        abbreviation: "IN"
      },
      {
        name: "Iowa",
        abbreviation: "IA"
      },
      {
        name: "Kansas",
        abbreviation: "KS"
      },
      {
        name: "Kentucky",
        abbreviation: "KY"
      },
      {
        name: "Louisiana",
        abbreviation: "LA"
      },
      {
        name: "Maine",
        abbreviation: "ME"
      },
      {
        name: "Marshall Islands",
        abbreviation: "MH"
      },
      {
        name: "Maryland",
        abbreviation: "MD"
      },
      {
        name: "Massachusetts",
        abbreviation: "MA"
      },
      {
        name: "Michigan",
        abbreviation: "MI"
      },
      {
        name: "Minnesota",
        abbreviation: "MN"
      },
      {
        name: "Mississippi",
        abbreviation: "MS"
      },
      {
        name: "Missouri",
        abbreviation: "MO"
      },
      {
        name: "Montana",
        abbreviation: "MT"
      },
      {
        name: "Nebraska",
        abbreviation: "NE"
      },
      {
        name: "Nevada",
        abbreviation: "NV"
      },
      {
        name: "New Hampshire",
        abbreviation: "NH"
      },
      {
        name: "New Jersey",
        abbreviation: "NJ"
      },
      {
        name: "New Mexico",
        abbreviation: "NM"
      },
      {
        name: "New York",
        abbreviation: "NY"
      },
      {
        name: "North Carolina",
        abbreviation: "NC"
      },
      {
        name: "North Dakota",
        abbreviation: "ND"
      },
      {
        name: "Northern Mariana Islands",
        abbreviation: "MP"
      },
      {
        name: "Ohio",
        abbreviation: "OH"
      },
      {
        name: "Oklahoma",
        abbreviation: "OK"
      },
      {
        name: "Oregon",
        abbreviation: "OR"
      },
      {
        name: "Palau",
        abbreviation: "PW"
      },
      {
        name: "Pennsylvania",
        abbreviation: "PA"
      },
      {
        name: "Puerto Rico",
        abbreviation: "PR"
      },
      {
        name: "Rhode Island",
        abbreviation: "RI"
      },
      {
        name: "South Carolina",
        abbreviation: "SC"
      },
      {
        name: "South Dakota",
        abbreviation: "SD"
      },
      {
        name: "Tennessee",
        abbreviation: "TN"
      },
      {
        name: "Texas",
        abbreviation: "TX"
      },
      {
        name: "Utah",
        abbreviation: "UT"
      },
      {
        name: "Vermont",
        abbreviation: "VT"
      },
      {
        name: "Virgin Islands",
        abbreviation: "VI"
      },
      {
        name: "Virginia",
        abbreviation: "VA"
      },
      {
        name: "Washington",
        abbreviation: "WA"
      },
      {
        name: "West Virginia",
        abbreviation: "WV"
      },
      {
        name: "Wisconsin",
        abbreviation: "WI"
      },
      {
        name: "Wyoming",
        abbreviation: "WY"
      }
    ]
  },
  mutations: {
    addToCart(state, payload) {
      // .push(...payload)
      state.cart = payload;

      const sorter = (a, b) => {
        if (a.sizes.length > b.sizes.length) {
          return -1;
        } else {
          return 1;
        }
      }

      state.cart.box_products.sort(sorter)
    },
    updateShippingAddress(state, payload) {
      state.shipping_address = payload
    },
    updateFilters(state, payload) {
      state.selectedColors = payload.colors
      state.selectedSizes = payload.size
      state.selectedBrand = payload.brand
    },
    pushToCart(state, payload) {
      if (!state.cart?.box_products) {
        let initializeCart = {
          box_products: [payload],
          min_quantity: 50,
          quantity: 50,
        }
        state.cart = initializeCart;
        state.quantityLimits = {
          from: 0,
          to: 99
        }
      } else {
        state.cart.box_products.push(payload)
      }

      const sorter = (a, b) => {
        if (a.sizes.length > b.sizes.length) {
          return -1;
        } else {
          return 1;
        }
      }

      state.cart.box_products.sort(sorter)
    },
    resetCartProducts(state, products) {
      state.cart.box_products = products
    },
    togglePageLoading(state, payload) {
      state.pageLoading = payload
    },
    reserShippingAddress(state) {
      state.shipping_address = {
        address_one: null,
        address_two: null,
        city: null,
        postal_code: null,
        state: 'New York',
        phone_number: null,
      }
    },
    updateCartProd(state, product) {
      if (state.cart?.box_products > 0) {
        let itemIndex = state.cart?.box_products.findIndex(prod => prod.id == product.id)
        state.cart.box_products[itemIndex] = product
      }
    },
    updateBoxQuantity(state, quantity) {
      state.cart.quantity = quantity
      if (quantity > 0 && quantity < 99) {
        state.quantityLimits = {
          from: 0,
          to: 99
        }
      } else if (quantity > 99 && quantity < 149) {
        state.quantityLimits = {
          from: 99,
          to: 149
        }
      } else if (quantity > 149 && quantity < 249) {
        state.quantityLimits = {
          from: 149,
          to: 249
        }
      } else if (quantity > 249 && quantity < 499) {
        state.quantityLimits = {
          from: 249,
          to: 499
        }
      } else if (quantity > 499 && quantity < 999) {
        state.quantityLimits = {
          from: 499,
          to: 999
        }
      } else if (quantity > 999 && quantity < 99999999999) {
        state.quantityLimits = {
          from: 999,
          to: 99999999999
        }
      }
    },
    emptyCart(state) {
      state.cart = [];
    },
    removeItemFromCart(state, payload) {
      delete state.cart.box_products.splice(payload, 1)
      if (state.cart.box_products.length == 0) {
        // state.cart = [];
        let initializeCart = {
          box_products: [],
          min_quantity: 50,
          quantity: 50,
        }
        state.cart = initializeCart;
        state.quantityLimits = {
          from: 0,
          to: 99
        }
      }
    },
    updateFilter(state, payload) {
      state.filterApplied = payload
    },
    storeCustomerInfo(state, payload) {
      state.customerInfo = payload;
    },
    removeCustomerInfo(state) {
      state.customerInfo = {};
    },
    resetLogos(state) {
      state.number_of_logos = 1
      state.logo_price = 0.00
    },
    updateLogoColors(state, payload) {
      state.number_of_logos = payload
      // if (state.number_of_logos == 1) {
      //   state.logo_price = 0.00
      // } else {
      //   state.logo_price = 1.68 * Number(payload)
      // }
    },
    showAlert(state, { text, successStatus, alertColor }) {
      state.alert = true;
      state.alertText = text;
      state.alertSuccess = successStatus;
      state.alertColor = alertColor;
    },
    hideAlert(state) {
      state.alert = false;
    },
  },
  actions: {
  },
  getters: {
    getShippingAddress(state) {
      return state.shipping_address
    },
    filterApplied(state) {
      return state.filterApplied
    },
    getCart(state) {
      return state.cart
    },
    getSelectedColors(state) {
      return state.selectedColors
    },
    getSelectedSizes(state) {
      return state.selectedSizes
    },
    getSelectedBrand(state) {
      return state.selectedBrand
    },
    getCustomerInfo(state) {
      return state.customerInfo
    },
    logoPrice(state) {
      return state.logo_price
    },
    numberOfLogos(state) {
      return state.number_of_logos
    },
    getQuantityLimits(state) {
      return state.quantityLimits
    },
    getPageLoadingStatus(state) {
      return state.pageLoading
    },
    getAlertText: (state) => state.alertText,
    getAlertSuccess: (state) => state.alertSuccess,
    getAlertColor: (state) => state.alertColor,
    getBackdropOverlay: (state) => state.backdropOverlay,
    getAlertStatus: (state) => state.alert,
  },
  modules: {
    presetBoxes,
    stripe,
    products,
    categories,
    orders,
    auth
  },
  // plugins: [createPersistedState({
  //   storage: {
  //     getItem: (key) => secureLS.get(key),
  //     setItem: (key, value) => secureLS.set(key, value),
  //     removeItem: (key) => secureLS.remove(key),
  //   },
  // })],
  plugins: [createPersistedState()]
})
