<template>
  <v-app>
    <!-- navbar -->
    <!-- <ProductNav class="d-none" @filterProduct="updateProductList"  v-if="!loading"/> -->
    <v-divider></v-divider>
    <!-- Products list ad side cart START-->
    <v-container fluid>
      <v-row>
        <!-- Side left Navbar START -->
        <v-col cols="12" md="3" lg="2" class="relative px-0 pt-0 mt-6" v-if="loading">
          <v-skeleton-loader v-for="count in catLoaderCount" :key="count" v-bind="attrs" type="list-item-avatar">
          </v-skeleton-loader>

        </v-col>
        <v-col cols="12" md="3" lg="2" class="relative px-0 pt-0" v-if="!loading">
          <v-navigation-drawer v-model="productcatmobile" class="cart-sidebar" fixed left width="250px"
            overlay-color="#f1f1f1" :absolute="$vuetify.breakpoint.mdAndUp" :permanent="$vuetify.breakpoint.mdAndUp">
            <v-btn @click="productcatmobile = false" color="elevation-0 darken-5 mt-8" fab absolute top right
              height="35" width="35" class="d-md-none">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-left">Categories</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-list rounded dense class="catsidenav">
              <v-list-item-group color="primary" v-model="selectedcat">
                <v-list-item v-for="item in $store.getters.categories" :key="item.id" :class="isSectionActive(item)"
                  @click="gotoCats('sub_cat', item)">
                  <v-list-item-icon class="mr-4">
                    <v-img :src="getItemIcon(item)" width="32px"></v-img>
                  </v-list-item-icon>
                  <v-list-item-content class="text-left">
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>
        </v-col>
        <!-- Side left Navbar END -->

        <!-- Main Products List START -->
        <v-col cols="12" md="6" lg="8">
          <v-container fluid v-if="loading" class="mt-1">
            <v-row>
              <v-col cols="6" md="4" lg="4" class="text-center">
                <v-skeleton-loader type="image, card-heading, image, card-heading"></v-skeleton-loader>
              </v-col>
              <v-col cols="6" md="4" lg="4" class="text-center">
                <v-skeleton-loader type="image, card-heading, image, card-heading"></v-skeleton-loader>
              </v-col>
              <v-col cols="6" md="4" lg="4" class="text-center">
                <v-skeleton-loader type="image, card-heading, image, card-heading"></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-container>
          <!-- Products list -->
          <ProductsList v-if="!loading" :catalogs="catalogs" />
        </v-col>
        <!-- Main Products List END -->

        <!-- Side cart START-->
        <v-col cols="12" md="3" lg="2" class="relative px-0 pt-0" v-if="CartItem.length && !loading">
          <v-navigation-drawer v-model="cartitems" class="cart-sidebar" fixed right
            :absolute="$vuetify.breakpoint.mdAndUp" :permanent="$vuetify.breakpoint.mdAndUp" width="300"
            overlay-color="#f1f1f1">
            <v-btn @click="cartitems = false" color="elevation-0 darken-5 mt-8" class="d-lg-none" fab absolute top right
              height="35" width="35">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-left">Your cart</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense>
              <v-list-item link v-for="(item, itemIndex) in CartItem" :key="itemIndex">
                <div class="d-flex justify-space-between my-2" @click="openDetail(item)">
                  <div>
                    <v-img :src="$helper.functions.getProdImage(item, 'test')" max-width="100%" height="auto" width="48"
                      contain>
                    </v-img>
                  </div>
                  <div class="text-left ml-2 text-caption">
                    <div class="font-weight-extramedium">{{ item.title }}</div>
                    <div class="text--primary font-weight-bold">Qty per Box: {{ getItemQuantity(item) }} • {{
                        Number(getItemPrice(item)).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })
                    }} <small>(Est)</small></div>
                    <div class="d-inline-block text-truncate" style="max-width: 100px;">
                      <span class="text--primary">Color:
                        <v-avatar :color="$helper.functions.getColorCode(item.selected_color)" size="8">
                        </v-avatar>
                        <span class="text-capitalize"> {{ $helper.functions.getColorName(item.selected_color) }}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <span class="remove-btn mr-2 d-flex justify-end">
                  <v-tooltip bottom content-class="text-body-2">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon color="priamry" height="24" width="24" v-bind="attrs" v-on="on"
                        @click="openDetail(item)">
                        <v-icon class="text-body-1">mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>

                  <v-tooltip bottom content-class="text-body-2">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon color="gray" @click="removeIndex(itemIndex)" height="24" width="24" v-bind="attrs"
                        v-on="on">
                        <v-icon class="text-body-1">mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </template>
                    <span class="text-body-2">Remove</span>
                  </v-tooltip>
                </span>
              </v-list-item>
            </v-list>

            <template v-slot:append>
              <div class="pa-2 pl-lg-3 text-left">
                <div class="d-flex justify-space-between align-center mb-2">
                  <div class="text-body-2 font-weight-extramedium">Quantity <span class="font-weight-extrabold">(Min {{
                      getMinQuantity()
                  }})</span></div>
                  <div class="text--primary text-body-2 font-weight-bold relative" style="width:80px;">
                    <div class="printed-qty-val">{{ $store.getters.getCart?.quantity }}</div>
                    <!-- <v-select :items="minqty" v-model="$store.getters.getCart.quantity" hide-details dense
                      class="cart-qty mt-0">
                    </v-select> -->

                    <!-- New Quantity and Price Breakdown List START-->
                    <v-select v-model="selectedqty" :items="qtyprices" item-text="value" @change="quantityUpdated"
                      ref="select" item-value="uqty" hide-details dense class="pa-0 my-0 relative qty-selct text-right"
                      :closeOnSelect="true" :menu-props="{ top: true, offsetx: true, contentClass: 'pricebreak-list' }"
                      hide>
                      <template v-slot:prepend-item>
                        <div class="text-left px-2">
                          <div class="text-body-2 font-weight-bold">Select quantity</div>
                          <div class="text-caption">The more you order, the more you save</div>
                        </div>
                        <v-divider class="mt-2"></v-divider>
                      </template>

                      <template v-slot:item="{ item }">
                        <div class="d-flex justify-space-between text-body-2" style="width:100%;">
                          <div class="font-weight-bold">{{ item.uqty }}</div>
                          <div class="font-weight-extramedium">${{ item.value }}</div>
                        </div>
                      </template>

                      <template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-btn-toggle class="px-2">
                          <v-text-field dense outlined hide-details v-model="custom_quantity"
                            placeholder="Enter Quantity" @keyup="submitQuantity"></v-text-field>
                          <v-btn text dense type="submit"
                            class="text-caption font-weight-bold text-none px-1 text-field-btn"
                            :disabled="custom_quantity < 50" height="auto" @click="updateCustomQuantity"
                            min-width="auto">Enter</v-btn>
                        </v-btn-toggle>
                      </template>
                    </v-select>
                    <!-- New Quantity and Price Breakdown List END-->

                  </div>
                </div>
                <div class="d-flex justify-space-between mb-2">
                  <div class="text-body-2 font-weight-extramedium">Cost per swagbox <small
                      class="green--text font-weight-extrabold text--lighten-2 text-body-2">(Est)</small></div>
                  <div class="text--primary text-body-2 font-weight-bold">{{
                      costPerSwagbox
                  }}</div>
                </div>
                <div class="d-flex justify-space-between mb-2">
                  <div class="text-body-2 font-weight-extramedium">Subtotal <small
                      class="green--text font-weight-extrabold text--lighten-2 text-body-2">(Est)</small></div>
                  <div class="text--primary text-body-2 font-weight-bold">{{ subTotal }}
                  </div>
                </div>
                <!-- <v-btn block color="primary" class="text-none py-6" @click="goToCartPage">
                  Checkout
                </v-btn> -->
                <v-btn block color="primary" :disabled="!CartItem.length"
                  class="font-weight-extramedium text-none rounded-sm py-6"
                  @click.stop="SelectQuantities = !SelectQuantities">
                  Next: Select sizes
                </v-btn>
              </div>
            </template>
          </v-navigation-drawer>
        </v-col>
        <!-- Side cart END-->
      </v-row>
    </v-container>
    <!-- Products list ad side cart end-->

    <!-- product filters -->
    <ProductsFilters @filterProduct="updateProductList" v-if="!loading" />

    <!-- Select Quantity Modal START -->
    <v-dialog v-model="SelectQuantities" transition="dialog-bottom-transition" fullscreen bottom scrollable>
      <v-btn @click="SelectQuantities = false" color="elevation-0 mt-10" fab absolute top right height="40" width="40">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
      <SelectQuantity v-if="SelectQuantities && !loading" />
    </v-dialog>
    <!-- Select Quantity Modal START -->

    <!-- Bottom nav only for mobile START-->
    <v-bottom-navigation :value="value" color="primary" fixed grow class="d-md-none">
      <v-btn @click.stop="catmobile">
        <span>Categories</span>
        <v-icon>mdi-view-grid-outline</v-icon>
      </v-btn>

      <v-btn @click.stop="cartitems = !cartitems">
        <span>Cart Items</span>
        <v-icon>mdi-cart-variant</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <!-- Bottom nav only for mobile END-->

    <!-- Product Detail view START -->
    <v-dialog v-model="openProdModal" max-width="1000px" v-if="Object.keys(productSelected).length != 0"
      overlay-color="#f1f1f1" content-class="rounded-xl relative" scrollable>
      <v-btn @click="openProdModal = false" color="elevation-0 mt-10" fab absolute top right height="40" width="40">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>

      <ProductDetail :productSelected="productSelected" />
    </v-dialog>
    <!-- Product Detail view END -->

    <!-- Scroll to top STRAT -->
    <!-- <v-btn class="primary darken-5 mt-10 mt-sm-16 ml-sm-16" v-scroll="onScroll" v-show="fab" fab @click="toTop" fixed
        center bottom>
      <v-icon>
        mdi-chevron-up
      </v-icon>
    </v-btn> -->
    <!-- Scroll to top END -->
  </v-app>
</template>

<script>
// import ProductNav from "./components/ProductNav"
import ProductsList from "./components/ProductsList"
import ProductsFilters from "./components/ProductsFilters"
import SelectQuantity from "@/components/SelectQuantity"
import ProductDetail from "@/components/ProductDetail"

export default {
  name: 'ProductCatalog',
  components: {
    // ProductNav,
    ProductsList,
    ProductsFilters,
    SelectQuantity,
    ProductDetail
  },

  data() {
    return {
      catalogs: [],
      cartitems: null,
      catLoaderCount: 10,
      productcatmobile: false,
      activeCat: null,
      offset: 100,
      selected: '50',
      selectedProduct: {},
      minqty: [50, 100, 150, 200, 300],
      value: 1,
      loading: true,
      SelectQuantities: false,
      openProdModal: false,
      custom_quantity: null,
      fab: false,
      // qtyprices: [{ uqty: '50', value: '2.00' }, { uqty: '100', value: '1.40' }, { uqty: '150', value: '1.40' }, { uqty: '250', value: '1.00' }, { uqty: '500', value: '0.82' }, { uqty: '1000', value: '0.76' }],
      selectedqty: this.$store.getters.getCart?.quantity ? this.$store.getters.getCart.quantity : '50',
      selectedcat: 0,
      SidenavItem: [
        { catname: 'Packaging', icon: 'mdi-package-variant-closed' },
        { catname: 'Water bottles', icon: 'mdi-bottle-soda-classic-outline' },
        { catname: 'Travel tumblers', icon: 'mdi-glass-pint-outline' },
        { catname: 'Coffee mugs', icon: 'mdi-coffee-outline' },
        { catname: 'Tops', icon: 'mdi-tshirt-crew-outline' },
        { catname: 'Sweatshirts', icon: 'mdi-tshirt-v-outline' },
        { catname: 'Outerwear', icon: 'mdi-account-tie' },
        { catname: 'Swag Accessories', icon: 'mdi-redhat' },
        { catname: 'Notebooks & pens', icon: 'mdi-notebook-outline' },
        { catname: 'backpacks', icon: 'mdi-bag-personal-outline' },
        { catname: 'Totes & duffles', icon: 'mdi-bag-checked' },
        { catname: 'Tech items', icon: 'mdi-usb-flash-drive-outline' },
        { catname: 'Bonus items', icon: 'mdi-gift-open-outline' },
      ]
    }
  },
  watch: {
    openProdModal(newValue, oldValue) {
      if (!newValue) {
        let prodIncluded = this.$store.state.cart?.box_products.find(prod => {
          return prod.id == this.selectedProduct.id
        });
        if (!prodIncluded) {
          this.$helper.functions.resetSpecificProduct(this.selectedProduct)
        }
      }
      if (newValue) {
        this.selectedProduct.number_of_logos = this.$store.getters.numberOfLogos
      }
      // console.log('newValue', newValue)
    },
    SelectQuantities(newValue, oldValue) {
      if (newValue == true) {
        let boxProducts = this.$store.state.cart?.box_products
        let boxQuantity = this.$store.getters.getCart?.quantity
        let variant = {}

        boxProducts.forEach(product => {
          let prodFinalPrice = 0
          let sizes = product.sizes
          let sizeQuantity = 0
          if (sizes.length > 0) {
            sizes.forEach(object => {
              sizeQuantity += Number(object.quantity);
            });

            // if (sizes.length == 1) {
            //   variant = product.variants.find(variant => {
            //     return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
            //   })
            //   if (variant) {
            //     let volumePrice = this.$helper.functions.getVolumePrice(variant, boxQuantity, this.$store.getters.getQuantityLimits)
            //     sizes[0].quantity = Number(boxQuantity) * Number(product.quantity)
            //     sizes[0].price = (sizes[0].quantity * volumePrice.price).toFixed(2)
            //     sizes[0].sku = variant.sku
            //   }
            //   prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
            //   product.final_price = prodFinalPrice.toFixed(2)

            //   let logoFinalPrice = (Number(this.$helper.functions.getProductLogoPrice(product, boxQuantity)) * Number(product.quantity)) * Number(boxQuantity)

            //   product.final_price = (Number(logoFinalPrice) + Number(product.final_price))
            //   console.log('product.final_price product.final_price', product.final_price)
            // }

            if (sizeQuantity == 0) {
              // let qua = sizes.length - 1
              // let sameValue = (boxQuantity / qua)
              // sameValue = Math.trunc(sameValue)

              let qua = null
              let sameValue = 0
              if (Number.isInteger(boxQuantity / sizes.length)) {
                qua = null
                sameValue = boxQuantity / sizes.length
                sameValue = Math.trunc(sameValue)
              } else {
                qua = sizes.length - 1
                sameValue = (boxQuantity / qua)
                sameValue = Math.trunc(sameValue)
              }

              if (sizes.length == 1) {
                variant = product.variants.find(variant => {
                  return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
                })
                if (variant) {
                  let volumePrice = this.$helper.functions.getVolumePrice(product, variant, boxQuantity, this.$store.getters.getQuantityLimits)
                  sizes[0].quantity = Number(boxQuantity) * Number(product.quantity)
                  sizes[0].price = (sizes[0].quantity * volumePrice.price).toFixed(2)
                  sizes[0].sku = variant.sku
                }

                prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
              } else {
                sizes.forEach((size, key) => {
                  if (key == qua) {
                    variant = product.variants.find(variant => {
                      return variant.color?.value === product.selected_color.value && variant.size === size.size
                    })
                    if (variant) {
                      let volumePrice = this.$helper.functions.getVolumePrice(product, variant, boxQuantity, this.$store.getters.getQuantityLimits)
                      size.quantity = (boxQuantity - (qua * sameValue)) * Number(product.quantity)
                      size.price = (size.quantity * volumePrice.price).toFixed(2)
                      size.sku = variant.sku
                    }
                    prodFinalPrice = (Number(prodFinalPrice) + Number(size.price))
                  } else {
                    variant = product.variants.find(variant => {
                      return variant.color?.value === product.selected_color.value && variant.size === size.size
                    })
                    if (variant) {
                      let volumePrice = this.$helper.functions.getVolumePrice(product, variant, boxQuantity, this.$store.getters.getQuantityLimits)
                      size.quantity = Number(sameValue) * Number(product.quantity)
                      size.price = (size.quantity * volumePrice.price).toFixed(2)
                      size.sku = variant.sku
                    }
                    prodFinalPrice = (Number(prodFinalPrice) + Number(size.price))
                  }
                });
              }
              product.final_price = prodFinalPrice.toFixed(2)
              let logoFinalPrice = (Number(this.$helper.functions.getProductLogoPrice(product, boxQuantity)) * Number(product.quantity)) * Number(boxQuantity)

              product.final_price = (Number(logoFinalPrice) + Number(product.final_price))
              // console.log('product.final_price', product.final_price)



              // let logoFinalPrice = (Number(this.$helper.functions.getProductLogoPrice(product, boxQuantity)) * Number(product.quantity)) * Number(boxQuantity)

              // product.final_price = (Number(logoFinalPrice) + Number(product.final_price))

              // console.log('product.final_price index', product.final_price)
              product.price_distributed = true
            }
          } else {
            let prodPrice = this.$helper.functions.getItemPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
            variant = product.variants.find(variant => {
              return variant.color?.value === product.selected_color.value
            })

            // let volumePrice = this.$helper.functions.getVolumePrice(variant, this.$store.state.cart?.quantity, this.$store.getters.getQuantityLimits)
            let varPrice = (Number(product.quantity) * Number(prodPrice)) * Number(boxQuantity)
            // console.log('VAR', varPrice)
            variant.quantity = Number(boxQuantity) * Number(product.quantity)
            variant.price = varPrice.toFixed(2)
            product.final_price = varPrice.toFixed(2)
          }
        });
      }
    },
  },
  computed: {
    productSelected() {
      return this.selectedProduct
    },

    qtyprices() {
      if (this.$store.state.cart?.box_products && this.$store.state.cart?.box_products.length) {
        let products = this.$store.state.cart?.box_products
        let boxQuantity = this.$store.getters.getCart?.quantity
        let priceOne = 0
        let priceTwo = 0
        let priceThree = 0
        let priceFour = 0
        let priceFive = 0
        let priceSix = 0
        products.forEach(prod => {
          if (prod.sizes.length) {
            let product = JSON.stringify(prod)
            product = JSON.parse(product)
            let finalPriceOne = this.$helper.functions.getSizedProdPrice(product, 50, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
            let finalPriceTwo = this.$helper.functions.getSizedProdPrice(product, 100, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
            let finalPriceThree = this.$helper.functions.getSizedProdPrice(product, 150, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
            let finalPriceFour = this.$helper.functions.getSizedProdPrice(product, 250, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
            let finalPriceFive = this.$helper.functions.getSizedProdPrice(product, 500, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
            let finalPriceSix = this.$helper.functions.getSizedProdPrice(product, 1000, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
            // console.log('finalPriceOne', finalPriceOne)
            // console.log('finalPriceTwo', finalPriceTwo)
            // console.log('finalPriceThree', finalPriceThree)
            // console.log('finalPriceFour', finalPriceFour)
            // console.log('finalPriceFive', finalPriceFive)
            // console.log('finalPriceSix', finalPriceSix)
            priceOne += finalPriceOne
            priceTwo += finalPriceTwo
            priceThree += finalPriceThree
            priceFour += finalPriceFour
            priceFive += finalPriceFive
            priceSix += finalPriceSix


          } else {
            // let variant = prod.variants.find(variant => {
            //   return variant.color?.value === prod.selected_color.value
            // })

            // priceOne += (variant.volume_pricing.find(pricing => {
            //   return pricing.quantity >= 50
            // }).price + Number(this.logoPrice)) * Number(prod.quantity)

            // priceTwo += (variant.volume_pricing.find(pricing => {
            //   return pricing.quantity >= 100
            // }).price + Number(this.logoPrice)) * Number(prod.quantity)

            // priceThree += (variant.volume_pricing.find(pricing => {
            //   return pricing.quantity >= 150
            // }).price + Number(this.logoPrice)) * Number(prod.quantity)

            // priceFour += (variant.volume_pricing.find(pricing => {
            //   return pricing.quantity >= 250
            // }).price + Number(this.logoPrice)) * Number(prod.quantity)

            // priceFive += (variant.volume_pricing.find(pricing => {
            //   return pricing.quantity >= 500
            // }).price + Number(this.logoPrice)) * Number(prod.quantity)

            // priceSix += (variant.volume_pricing.find(pricing => {
            //   return pricing.quantity >= 1000
            // }).price + Number(this.logoPrice)) * Number(prod.quantity)
            priceOne += this.$helper.functions.getItemPrice(prod, 50, this.numberOfLogos)
            priceTwo += this.$helper.functions.getItemPrice(prod, 100, this.numberOfLogos)
            priceThree += this.$helper.functions.getItemPrice(prod, 150, this.numberOfLogos)
            priceFour += this.$helper.functions.getItemPrice(prod, 250, this.numberOfLogos)
            priceFive += this.$helper.functions.getItemPrice(prod, 500, this.numberOfLogos)
            priceSix += this.$helper.functions.getItemPrice(prod, 1000, this.numberOfLogos)

          }

        })

        return [
          { uqty: '50', value: priceOne.toFixed(2) },
          { uqty: '100', value: priceTwo.toFixed(2) },
          { uqty: '150', value: priceThree.toFixed(2) },
          { uqty: '250', value: priceFour.toFixed(2) },
          { uqty: '500', value: priceFive.toFixed(2) },
          { uqty: '1000', value: priceSix.toFixed(2) }];
      } else {
        return []
      }
    },

    logoPrice() {
      return this.$store.getters.logoPrice
    },

    numberOfLogos() {
      return this.$store.getters.numberOfLogos
    },

    CartItem() {
      return this.$store.state.cart?.box_products ? this.$store.state.cart?.box_products : []
    },

    quantityLimits() {
      return this.$store.getters.getQuantityLimits
    },

    costPerSwagbox() {
      let products = this.$store.state.cart?.box_products
      let boxQuantity = this.$store.getters.getCart?.quantity
      let totalPrice = 0
      if (products && products.length > 0) {
        products.forEach(prod => {
          // if (prod.price == prod.final_price) {
          if (prod.sizes.length) {
            let currentProd = JSON.stringify(prod)
            currentProd = JSON.parse(currentProd)
            let price = this.$helper.functions.getSizedProdPrice(currentProd, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos).toFixed(2)
            totalPrice = Number(totalPrice) + Number(price)
          } else {
            let prodPrice = this.$helper.functions.getItemPrice(prod, this.$store.getters.getCart?.quantity, this.numberOfLogos)
            // let price = (Number(prod.quantity) * Number(prodPrice)) + (Number(this.logoPrice) * Number(prod.quantity))
            totalPrice = Number(totalPrice) + Number(prodPrice)
          }
        })

        // let totalPrice = products.reduce((total, prod) => (Number(prod.quantity) * Number(prod.price)) + (Number(this.logoPrice) * Number(prod.quantity)) + Number(total), 0);
        return totalPrice.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      } else {
        let totalPrice = 0
        return totalPrice.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
      }
      // return this.$helper.functions.costPerSwagbox(this.$store.state.cart?.box_products, this.$store.getters.getCart?.quantity, this.logoPrice)
    },

    subTotal() {
      let products = this.$store.state.cart?.box_products
      let boxQuantity = this.$store.getters.getCart?.quantity
      let totalPrice = 0
      if (products && products.length > 0) {
        products.forEach(prod => {
          // if (prod.price == prod.final_price) {
          if (prod.sizes.length) {
            let currentProd = JSON.stringify(prod)
            currentProd = JSON.parse(currentProd)
            let price = this.$helper.functions.getSizedProdPrice(currentProd, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos).toFixed(2)
            totalPrice = (Number(this.$store.getters.getCart?.quantity) * Number(price)) + Number(totalPrice)
          } else {
            let prodPrice = this.$helper.functions.getItemPrice(prod, this.$store.getters.getCart?.quantity, this.numberOfLogos)
            // let volumePrice = prod.variants.find(variant => {
            //   return variant.color?.value === prod.selected_color.value
            // }).volume_pricing.find(price => {
            //   // return price.quantity >= this.$store.state.cart?.quantity
            //   return price.quantity >= this.quantityLimits.from && price.quantity <= this.quantityLimits.to
            // })

            // let price = (Number(prod.quantity) * Number(prodPrice)) + (Number(this.logoPrice) * Number(prod.quantity))
            let quantityPrice = Number(prodPrice) * Number(boxQuantity)
            totalPrice = Number(totalPrice) + Number(quantityPrice)
          }

          // } else {
          //   let price = Number(prod.final_price)
          //   totalPrice = Number(totalPrice) + Number(price)
          // }
        })

        // let totalPrice = products.reduce((total, prod) => (Number(prod.quantity) * Number(prod.price)) + (Number(this.logoPrice) * Number(prod.quantity)) + Number(total), 0);
        return totalPrice.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      } else {
        let totalPrice = 0
        return totalPrice.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
      }

      // if (this.$store.state.cart?.box_products) {
      //   let productsPrice = this.$helper.functions.getBoxPrice(this.$store.state.cart?.box_products, this.logoPrice)
      //   return productsPrice.toLocaleString('en-US', {
      //     style: 'currency',
      //     currency: 'USD',
      //   })
      // } else {
      //   let price = 0
      //   return price.toLocaleString('en-US', {
      //     style: 'currency',
      //     currency: 'USD',
      //   })
      // }
    },
  },
  // For mobile product nav
  methods: {
    gotoCats(type, item) {
      this.$vuetify.goTo('#cat' + item.id, { offset: 25 })
      // if (type == 'top') {
      //   if (this.$store.getters.filterApplied) {
      //     this.scrollTo = 'top'
      //     this.$emit('filterProduct', { scrollTo: 'top' })
      //   } else {
      //     this.$vuetify.goTo('#mensclothing')
      //   }

      // } else {
      //   if (this.$store.getters.filterApplied) {
      //     this.scrollTo = category
      //     this.$emit('filterProduct', { scrollTo: category })
      //   } else {
      //     this.$vuetify.goTo('#cat' + category.id, { offset: 25 })
      //   }

      // }
    },

    isSectionActive(item) {
      return this.activeCat == item.id ? 'v-list-item--active' : ''
    },

    updateScroll() {
      let scrollPosition = window.scrollY
      this.selectedcat = null
      // $store.getters.products
      this.catalogs.forEach((category, key) => {
        if (document.getElementById('cat' + category.id)) {
          if (
            scrollPosition >=
            document.getElementById('cat' + category.id).offsetTop - this.offset
          ) {
            this.activeCat = category.id
          }
        }
      });
      // console.log('scrollPosition', scrollPosition)
      // this.on = 'customerPart'
      // if (
      //   scrollPosition >=
      //   this.$refs.transportationPart?.offsetTop - this.offset
      // ) {
      //   this.on = 'transportationPart'
      // } else if (
      //   scrollPosition >=
      //   this.$refs.memoPart?.offsetTop - this.offset
      // ) {
      //   this.on = 'memoPart'
      // } else if (
      //   scrollPosition >=
      //   this.$refs.dateTimePart?.offsetTop - this.offset
      // ) {
      //   this.on = 'dateTimePart'
      // } else if (
      //   scrollPosition >=
      //   this.$refs.customerPart?.offsetTop - this.offset
      // ) {
      //   this.on = 'customerPart'
      // }
    },

    getItemIcon(item) {
      let icon = ''
      switch (item.title) {
        case 'Packaging':
          icon = require('@/assets/images/icons/packaging.png');
          break;
        case 'Water Bottles':
          icon = require('@/assets/images/icons/water-bottles.png');
          break;
        case 'Travel Tumblers':
          icon = require('@/assets/images/icons/tumblers.png');
          break;
        case 'Coffee Mugs':
          icon = require('@/assets/images/icons/coffee-mugs.png');
          break;
        case 'Tops':
          icon = require('@/assets/images/icons/tops.png');
          break;
        case 'Sweatshirts':
          icon = require('@/assets/images/icons/sweatshirts.png');
          break;
        case 'Outerwear':
          icon = require('@/assets/images/icons/outerwear.png');
          break;
        case 'Swag Accessories':
          icon = require('@/assets/images/icons/accessories.png');
          break;
        case 'Notebooks & Pens':
          icon = require('@/assets/images/icons/notebooks-pens.png');
          break;
        case 'Backpacks':
          icon = require('@/assets/images/icons/backpacks.png');
          break;
        case 'Totes & Duffles':
          icon = require('@/assets/images/icons/tottes-duffles.png');
          break;
        case 'Bonus Items':
          icon = require('@/assets/images/icons/bonus-items.png');
          break;
        case 'Tech Items':
          icon = require('@/assets/images/icons/tech-items.png');
          break;
        case 'Bonus items':
          icon = require('@/assets/images/icons/bonus-items.png');
          break;
      }
      return icon
    },

    submitQuantity(e) {
      e.preventDefault()
      if (e.keyCode === 13) {
        if (this.custom_quantity > 49) {
          this.updateCustomQuantity()
        }
      }
    },

    updateCustomQuantity() {
      this.$refs.select.isMenuActive = false;
      this.selectedqty = this.custom_quantity
      this.quantityUpdated()
    },

    quantityUpdated() {
      setTimeout(() => {
        this.$store.commit('updateBoxQuantity', this.selectedqty)
        let boxProducts = this.$store.state.cart?.box_products
        boxProducts.forEach(product => {
          if (product.sizes.length) {
            let pricee = this.$helper.functions.getSizedProdPrice(product, this.$store.getters.getCart?.quantity, 0.00)
          }
        })
      }, 50)
    },

    openDetail(product) {
      this.$root.$emit('cartProdDetail', product)
    },

    catmobile() {
      this.productcatmobile = !this.productcatmobile;
    },

    getItemQuantity(product) {
      return product.quantity ? product.quantity : 1
    },

    getSizedProdPrice(product) {
      let price = 0
      let sizes = product.sizes
      let sizeQuantity = 0
      let prodFinalPrice = 0
      let boxQuantity = this.$store.getters.getCart?.quantity
      let variant = {}
      sizes.forEach(object => {
        sizeQuantity += Number(object.quantity);
      });

      if (sizes.length == 1) {
        variant = product.variants.find(variant => {
          return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
        })
        if (variant) {
          let volumePrice = variant.volume_pricing.find(price => {
            return price.quantity >= boxQuantity
          })
          sizes[0].quantity = Number(boxQuantity) * Number(product.quantity)
          sizes[0].price = (sizes[0].quantity * volumePrice.price).toFixed(2)
          sizes[0].sku = variant.sku
        }
        prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
        product.final_price = prodFinalPrice.toFixed(2)
      }

      // || !this.cartItemIds.includes(product.id)
      // if (sizeQuantity == 0) {
      let qua = sizes.length - 1
      let sameValue = (boxQuantity / qua)
      sameValue = Math.trunc(sameValue)
      if (sizes.length == 1) {
        variant = product.variants.find(variant => {
          return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
        })
        if (variant) {
          let volumePrice = variant.volume_pricing.find(price => {
            return price.quantity >= boxQuantity
          })
          sizes[0].quantity = Number(boxQuantity) * Number(product.quantity)
          sizes[0].price = (sizes[0].quantity * volumePrice.price).toFixed(2)
          sizes[0].sku = variant.sku
        }

        prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
      } else {
        sizes.forEach((size, key) => {
          if (key == qua) {
            variant = product.variants.find(variant => {
              return variant.color?.value === product.selected_color.value && variant.size === size.size
            })
            if (variant) {
              let volumePrice = variant.volume_pricing.find(price => {
                return price.quantity >= boxQuantity
              })
              size.quantity = (boxQuantity - (qua * sameValue)) * Number(product.quantity)
              size.price = (size.quantity * volumePrice.price).toFixed(2)
              size.sku = variant.sku
            }
            prodFinalPrice = (Number(prodFinalPrice) + Number(size.price))
          } else {
            variant = product.variants.find(variant => {
              return variant.color?.value === product.selected_color.value && variant.size === size.size
            })
            if (variant) {
              let volumePrice = variant.volume_pricing.find(price => {
                return price.quantity >= boxQuantity
              })
              size.quantity = Number(sameValue) * Number(product.quantity)
              size.price = (size.quantity * volumePrice.price).toFixed(2)
              size.sku = variant.sku
            }
            prodFinalPrice = (Number(prodFinalPrice) + Number(size.price))
          }
        });
      }
      product.final_price = prodFinalPrice.toFixed(2)
      // }

      let logoFinalPrice = Number(this.logoPrice) * Number(product.quantity)
      // console.log('Final Prod', product)

      price = ((Number(logoFinalPrice) + Number(product.final_price)) / Number(boxQuantity))
      return price
    },

    getItemPrice(item) {
      // if (item.price == item.final_price) {
      let price = 0
      if (item.sizes.length) {
        let prod = JSON.stringify(item)
        prod = JSON.parse(prod)
        price = this.$helper.functions.getSizedProdPrice(prod, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
        // price = (Number(price) / Number(item.quantity)).toFixed(2)
        price = Number(price).toFixed(2)
      } else {
        let prodPrice = this.$helper.functions.getItemPrice(item, this.$store.getters.getCart?.quantity, this.numberOfLogos)
        // let volumePrice = item.variants.find(variant => {
        //   return variant.color?.value === item.selected_color.value
        // }).volume_pricing.find(price => {
        //   return price.quantity >= this.quantityLimits.from && price.quantity <= this.quantityLimits.to
        //   // return price.quantity >= this.$store.state.cart?.quantity
        // })
        // price = (Number(prodPrice) / Number(item.quantity)).toFixed(2)
        price = Number(prodPrice).toFixed(2)
      }

      return price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
      // } else {
      //   return this.$helper.functions.getProductAveragePrice(item, this.$store.getters.getCart.quantity, this.logoPrice)
      // }

      // let price = item.final_price / this.$store.getters.getCart.quantity
      // return price.toLocaleString('en-US', {
      // style: 'currency',
      // currency: 'USD',
      // })
    },

    getTotalPrice() {
      let productsPrice = this.$helper.functions.getBoxPrice(this.$store.getters.getCart.box_products, this.$store.getters.getQuantityLimits)
      let minQuantity = this.$store.getters.getCart.quantity
      return (productsPrice * minQuantity).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    getMinQuantity() {
      return this.$store.getters.getCart.min_quantity ? this.$store.getters.getCart.min_quantity : 50
    },

    goToCartPage() {
      this.$router.push({ name: 'Cart' })
    },

    removeIndex(itemIndex) {
      this.$store.commit('removeItemFromCart', itemIndex)
    },

    isEmpty(object) {
      for (let property in object) {
        if (object[property].length != 0) {
          return false;
        }
      }
      return true;
    },

    updateProductList(data) {
      if (data) {
        this.$store.commit('updateFilter', !this.isEmpty(data))
      }
      // this.$store.commit('togglePageLoading', true)
      this.loading = true
      this.$store.dispatch('GET_PRODUCT_LIST', data).then(
        response => {
          if (response.status == 'success') {
            this.$root.$emit('scroll')
            this.catalogs = response.products

            // if(data?.scrollTo) {
            //   this.$vuetify.goTo('#cat'+data?.scrollTo.id, { offset: 25 })
            // }
            // this.$store.commit('togglePageLoading', false)
            this.loading = false
          }
        },
        error => {
          // this.$store.commit('togglePageLoading', false)
          this.loading = false
          throw error
        }
      )
    },

    // Scroll to top
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop() {
      this.$vuetify.goTo(0)
    }

  },

  created() {
    window.addEventListener('scroll', this.updateScroll)
    if (this.$store.getters.getCart?.min_quantity) {
      this.minqty.push(this.$store.getters.getCart?.min_quantity)
      this.minqty = this.minqty.sort(function (a, b) {
        if (a === Infinity)
          return 1;
        else if (isNaN(a))
          return -1;
        else
          return a - b;
      })
    }

    this.$store.dispatch('GET_CATEGORIES_LIST').then(
      response => {
        if (response.status == 'success') {
          // this.loading = false
        }
      },
      error => {
        this.loading = false
        throw error
      }
    )

    if (!this.$store.getters.getCart.quantity) {
      this.$store.getters.getCart.quantity = 50
    }

    this.$root.$on('distribution_invalid', (e) => {
      this.SelectQuantities = true
    })

    this.$root.$on('closeSkuModal', (e) => {
      this.SelectQuantities = false
    })

    if (this.$route.query.distribution == 'false') {
      this.SelectQuantities = true
      this.loading = false
    } else {
      let filters = {}
      if (this.$store.getters.getSelectedColors.length > 0 || this.$store.getters.getSelectedSizes.length > 0 || this.$store.getters.getSelectedBrand.length > 0) {
        filters = {
          colors: this.$store.getters.getSelectedColors,
          size: this.$store.getters.getSelectedSizes,
          // range: this.range,
          apply_filter: true,
          brand: this.$store.getters.getSelectedBrand,
        }
      }
      this.updateProductList(filters)
    }

    this.$root.$on('openProdDetail', (product) => {
      this.selectedProduct = product
      this.openProdModal = true
    })
  }
}
</script>

<style scoped>
.max-ftr-h {
  max-height: 140px;
  overflow-y: auto;
}

.v-avatar {
  border: 1px solid #d6d6d6 !important;
}

@media (min-width: 960px) {
  .cart-sidebar {
    height: calc(100vh - 64px) !important;
    position: sticky;
    top: 64px !important;
    align-self: start;
  }

  .remove-btn {
    position: absolute;
    right: 2px;
    bottom: 10px;
    z-index: 5;
  }

}
</style>
