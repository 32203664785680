<template>
  <v-app>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="d-flex justify-center align-center overflow-auto">
      <v-container>
        <v-row class="justify-center my-10">
          <v-col cols="12" sm="8" md="6" lg="8" xl="6">
            <div class="text-center" >
              <v-img :src="require('@/assets/images/thumb-up.png')" v-if="!linkExpired" contain width="250px" class="img-fluid mx-auto">
              </v-img>
              <v-img :src="require('@/assets/images/link-expired.png')" v-if="linkExpired" contain width="250px" class="img-fluid mx-auto">
              </v-img>
            </div>
            <template v-if="loading && !linkExpired">
              <h1 class="font-weight-black text--primary text-lg-h3">Please wait, we are verifying your account...</h1>
            </template>
            <template v-if="!loading && !linkExpired">
              <h1 class="font-weight-black text--primary text-lg-h3">Thank you! Your account has been verified</h1>
              <div class="text-body-1 my-5 text--darken-5 gray--text">You can now start ordering your swag.</div>
            </template>
            <template v-if="linkExpired">
              <h1 class="font-weight-black text--primary text-lg-h3">Uh-oh, Your activation link has expired</h1>
            </template>

            <div class="my-6" v-if="!loading && !linkExpired">
              <v-btn class="font-weight-bold primary text-none rounded-sm py-6 mx-2" :to="{name: 'LoginPage'}">Login
                Here</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

  </v-app>
</template>

<script>
export default {
  name: 'EmailVerified',
  data() {
    return {
      loading: true,
      linkExpired: false
    }
  },
  methods: {
    verifyAccount(e) {
      this.$store
        .dispatch('VERIFY_ACCOUNT', { email_verification_token: this.$route.params.token })
        .then(
          response => {
            // if (response.status == "success") {
            //   this.$store.commit("showAlert", {
            //     text: response.message,
            //     successStatus: true,
            //   });
            //   this.$router.push({ name: 'LoginPage' })
            // }
            this.loading = false
          },
          error => {
            this.loading = false
            this.linkExpired = true
            // this.$store.commit("showAlert", {
            //   text: error.data.message,
            //   successStatus: false,
            //   alertColor: 'red darken-4'
            // });
            throw error
          }
        )
        .catch(error => {
          this.loading = false
          if (error.data.errors)
            this.$refs.observer.setErrors(error.data.errors)
        })
    }
  },
  created() {
    this.verifyAccount();
  }
}
</script>

<style scoped>

</style>
