<template>
  <v-app>
    <v-container fluid class="mb-8 relative">
      <v-row class="align-center px-4 justify-center">
        <v-col cols="12" lg="5" class="text-center">
          <div class="pt-10 pb-6">
            <h1 class="text--primary font-weight-black text-h4 text-sm-h2 text-md-h2 text-lg-h3">Preset Boxes</h1>
            <p class="my-4">We've carefully selected and assembled these custom boxes to best suit your needs. </p>
          </div>
        </v-col>
      </v-row>

      <v-row class="px-sm-4">
        <!-- Preset Boxes -->
        <v-col cols="6" sm="4" md="3" lg="3" v-for="item in allBoxes" :key="item.id" :to="item.id">
          <v-card @click.stop="showPackDetail(item)" class="text-center pa-4 rounded-lg" height="100%">
            <div class="mb-3">
              <v-img :src="item.image?.image" class="fill-height"></v-img>
            </div>
            <div class="text-left">
              <v-card-title class="text-body-1 text-md-h6 text--primary mb-2 font-weight-bold pa-0">{{ item.name }}
              </v-card-title>
              <div class="text-body-2 font-weight-extramedium text--primary mb-2">Min. Qty: 50<span
                  class="font-weight-bold">{{ item.min_quantity }}</span></div>
              <div class="text-body-2 font-weight-extramedium text--primary">Starts from USD <span
                  class="font-weight-bold">{{ getBoxPrice(item.box_products) }}</span></div>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-btn :to="{ name: 'ProductOnboarding', }" color="primary darken-5 mt-10 mt-sm-16 ml-sm-6" fab absolute top left
        width="46px" height="46px">
        <v-icon>
          mdi-chevron-left
        </v-icon>
      </v-btn>
    </v-container>

    <!-- Preset Detail view START -->
    <v-dialog og v-model="drawer" max-width="1000px" v-if="drawer" overlay-color="#f1f1f1" content-class="rounded-xl"
      scrollable>
      <v-card>
        <v-card-title class="px-2">
          <v-toolbar class="elevation-0 pa-0 transparent" height="auto">
            <!-- <v-toolbar-title class="font-weight-bold text--primary text-h6">{{ box.name }}</v-toolbar-title> -->
            <v-spacer></v-spacer>
            <v-btn @click="drawer = false" color="elevation-0 mt-8" fab absolute top right height="40" width="40">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="pa-2 pt-0">
          <v-container>
            <v-row>
              <v-col cols="12" lg="6">
                <div>
                  <!-- <v-card flat tile> -->
                  <v-window v-model="onboarding" class="mb-3 elevation-1 rounded-lg" show-arrows>
                    <!-- Arrows -->
                    <template v-slot:prev="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" min-width="10" height="40"
                        class="pa-2 elevation-0 rounded-circle">
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:next="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" min-width="10" height="40"
                        class="pa-2 elevation-0 rounded-circle">
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </template>
                    <!-- main Images -->
                    <!-- v-if="box?.products_images?.length" -->
                    <v-window-item key="dsdsd">
                      <v-card class="pa-0">
                        <div class="fill-height">
                          <v-img :src="box.image.image" contain max-width="100%" height="400px" class="mx-auto"></v-img>
                        </div>
                      </v-card>
                    </v-window-item>
                    <v-window-item v-for="(image, index) in box.products_images" :key="index">
                      <v-card class="pa-0">
                        <div class="fill-height">
                          <v-img :src="getImage(image)" contain max-width="100%" height="400px" class="mx-auto"></v-img>
                        </div>
                      </v-card>
                    </v-window-item>
                  </v-window>

                  <!-- Slider -->
                  <div class="d-flex justify-space-between align-center pa-0" multiple>
                    <v-btn text @click="prev" class="pa-0" min-width="10px">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-item-group v-model="onboarding" class="text-center d-flex flex-row overflow-auto cst-slider py-2"
                      active-class="active">
                      <v-item key="index2" v-slot="{ active, toggle }">
                        <v-btn :input-value="active" @click="toggle" text class="img-fluid pa-0 mr-1" height="58px"
                          width="58px">
                          <v-img :src="box.image.image" contain width="58px"></v-img>
                        </v-btn>
                      </v-item>
                      <v-item v-for="(image, index) in box.products_images" :key="index" v-slot="{ active, toggle }">
                        <v-btn :input-value="active" @click="toggle" text class="img-fluid pa-0 mr-1" height="58px"
                          width="58px">
                          <v-img :src="getImage(image)" contain width="58px"></v-img>
                        </v-btn>
                      </v-item>
                    </v-item-group>
                    <v-btn text @click="next" class="pa-0" min-width="10px">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" lg="6" class="text-left">
                <div class="my-2">
                  <h1 class="text-left text--primary text-h6 font-weight-bold">{{ box.name }}</h1>
                </div>
                <p>{{ box.description }}</p>
                <div>
                  <v-list-item-subtitle class="text-primary text-subtitle-1 font-weight-extrabold text--primary">Box
                    includes:</v-list-item-subtitle>
                  <v-list color="transparent">
                    <v-list-item>
                      <v-list-item-content class="d-flex">
                        <v-list-item-title class="text-subtitle-2 text--primary font-weight-medium"
                          v-for="(product, prodoctIndex) in box.box_products" :key="prodoctIndex">
                          <v-icon color="primary">mdi-circle-medium</v-icon>
                          {{ product.title }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="text-end d-flex justify-end pa-3">
          <div class="me-3">
            <div class="font-weight-black text--primary">{{ getBoxPrice(box.box_products) }}</div>
            <div class="text--primary font-weight-medium text-body-2">Per box</div>
          </div>
          <!-- <router-link to="/" tag="v-btn" class="font-weight-bold primary text-none rounded-sm py-6">
              Start with this box
            </router-link> -->
          <v-btn class="font-weight-bold primary text-none rounded-sm py-6 px-3" @click="addToCart(box)">Start with this
            box
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Preset Detail view END -->
  </v-app>
</template>

<script>
export default {
  name: 'PresetBoxesList',
  props: {
    allBoxes: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
  },

  components: {},

  mounted() { },

  computed: {},

  data() {
    return {
      drawer: null,
      box: {},
      onboarding: 0,
    }
  },

  methods: {
    showPackDetail(box) {
      this.box = box
      this.drawer = !this.drawer
    },

    getImage(imageJson) {
      // let imageContent = JSON.parse(imageJson)
      let image = imageJson?.[0]?.data?.link ? imageJson?.[0]?.data?.link : ''
      if(image) {
        return this.$helper.functions.getPresetBoxProdImage(image)
      }      
      return ''
    },

    addToCart(box) {
      box.box_products.forEach(object => {
        object.selected_color = object.colors[0] ? object.colors[0] : null
        let variants = object.variants
        let variant = variants.find(obj => {
          return obj.color?.value == object.selected_color.value
        })
        let images = []
        variant.images.forEach((object, key) => {
          let img_name = this.$helper.functions.getImage(object)
          images.push(img_name)
        })
        if (images.length > 0) {
          object.images = images;
        } else {
          object.images = [];
        }
      })
      let cartItem = {
        box_products: box.box_products,
        box_id: box.id,
        box_image: box.image.image,
        box_description: box.description,
        min_quantity: 50, //box.min_quantity,
        quantity: 50, //box.min_quantity,
        box_name: box.name,
      }
      this.$store.commit('addToCart', cartItem)
      this.$router.push({ name: 'ProductCatalog' })
    },

    getBoxPrice(products) {
      return this.$helper.functions.getPresetBoxPrice(products).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    },

    // for Slider
    next() {
      this.onboarding = this.onboarding + 1 === this.length ?
        0 :
        this.onboarding + 1
    },
    prev() {
      this.onboarding = this.onboarding - 1 < 0 ?
        this.length - 1 :
        this.onboarding - 1
    },
  }
}
</script>

<style scoped>
.cst-slider button {
  opacity: 0.4;
  border: 1px solid #dedede;
}

.cst-slider .active {
  opacity: 1;
}
</style>
