<template>
  <v-app>
    <!-- mobile nav START-->
    <v-navigation-drawer v-model="dashnav" class="navbar d-lg-none" app fixed temporary left width="100%">
      <v-btn @click="dashnav = false" color="primary darken-5 mt-10" fab absolute top right height="40" width="40">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>

      <v-list-item>
        <v-list-item-content class="text-left">
          <v-list-item-title class="font-weight-bold text--primary text-h6">Boxes By Swagify</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list dense class="mt-4">
        <v-list-item>
          <v-list-item-content>
            <v-tabs v-model="DAshTab" class="dash-tabs" vertical>
              <v-tab class="px-1 mx-sm-4" to="/customer/dashboard"><span
                  class="font-weight-bold text-none text-body-1">Dashboard</span></v-tab>
              <v-tab class="px-1 mx-sm-4" to="/customer/quotes"><span
                  class="font-weight-bold text-none text-body-1">Quotes</span></v-tab>
              <v-tab class="px-1 mx-sm-4" to="/customer/orders"><span
                  class="font-weight-bold text-none text-body-1">Orders</span></v-tab>
              <v-tab class="px-1 mx-sm-4" to="/customer/shipment"><span
                  class="font-weight-bold text-none text-body-1">Shipment</span></v-tab>
              <v-tab class="px-1 mx-sm-4" to="/customer/inventory"><span
                  class="font-weight-bold text-none text-body-1">Inventory</span></v-tab>
            </v-tabs>

            <div class="text-center d-none">
              <v-btn :to="{ name: 'ProductOnboarding', }"
                class="font-weight-bold primary text-none rounded-sm py-6 my-2" width="100%">
                Shop swagbox
              </v-btn>
              <v-btn href="https://print-on-demand.swagify.com/contact-us.html" target="_blank"
                class="font-weight-bold text-none rounded-sm py-6 my-2" color="primary" outlined width="100%">
                Contact us
              </v-btn>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!-- mobile nav END-->

    <!-- Top system Bar START -->
    <v-system-bar height="40" color="#f0f2f5" class="justify-space-between px-0 d-none d-md-inline-flex">
      <div class="system-bar-nav">
        <v-btn color="primary" class="rounded-0 caption font-weight-bold w-100" height="40px">SWAGBOXES</v-btn>
      </div>
      <div class="system-bar-nav">
        <v-btn href="http://print-on-demand.swagify.com/" target="_blank" text
          class="rounded-0 caption font-weight-bold w-100" height="40px">PRINT ON DEMAND</v-btn>
      </div>
      <div class="system-bar-nav">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text class="rounded-0 caption font-weight-bold w-100" height="40px" v-bind="attrs" v-on="on">
              PROMOTIONAL PRODUCTS</v-btn>
          </template>
          <span>Coming Soon</span>
        </v-tooltip>
      </div>
      <div class="system-bar-nav">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text class="rounded-0 caption font-weight-bold w-100" height="40px" v-bind="attrs" v-on="on">
              FUNDRAISE</v-btn>
          </template>
          <span>Coming Soon</span>
        </v-tooltip>
      </div>
      <div class="system-bar-nav">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text class="rounded-0 caption font-weight-bold w-100" height="40px" v-bind="attrs" v-on="on">
              MARKETPLACE</v-btn>
          </template>
          <span>Coming Soon</span>
        </v-tooltip>
      </div>
      <div class="system-bar-nav">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text class="rounded-0 caption font-weight-bold w-100" height="40px" v-bind="attrs" v-on="on">BLANX
            </v-btn>
          </template>
          <span>Coming Soon</span>
        </v-tooltip>
      </div>
    </v-system-bar>
    <!-- Top system Bar END -->

    <v-app-bar class="navbar" elevate-on-scroll>
      <div class="d-md-none" offset-y absolute>
        <v-app-bar-nav-icon @click="dashnav = !dashnav" width="auto"></v-app-bar-nav-icon>
      </div>

      <router-link :to="{ name: 'Home', }">
        <v-img :src="require('@/assets/images/swagbox-logo.png')" contain max-width="100%" height="auto" width="140">
        </v-img>
      </router-link>

      <v-spacer></v-spacer>

      <!-- Try POD START -->
      <v-tabs v-model="DAshTab" class="dash-tabs d-none d-md-block" centered style="width:auto;">
        <v-tab class="px-1 mx-sm-4" to="/customer/dashboard"><span
            class="font-weight-bold text-none text-body-1">Dashboard</span></v-tab>
        <v-tab class="px-1 mx-sm-4" to="/customer/quotes"><span
            class="font-weight-bold text-none text-body-1">Quotes</span></v-tab>
        <v-tab class="px-1 mx-sm-4" to="/customer/orders"><span
            class="font-weight-bold text-none text-body-1">Orders</span></v-tab>
        <v-tab class="px-1 mx-sm-4" to="/customer/shipment"><span
            class="font-weight-bold text-none text-body-1">Shipment</span></v-tab>
        <!-- <v-tab class="px-1 mx-sm-4" to="/customer/inventory"><span
            class="font-weight-bold text-none text-body-1">Inventory</span></v-tab> -->
      </v-tabs>
      <!-- Try POD END -->

      <v-spacer></v-spacer>

      <!-- <v-badge color="green" :content="$store.state.cart?.box_products?.length"  overlap bordered offset-y="24" offset-x="20"> -->
      <v-btn icon @click="goToCartPage" v-if="!$store.state.cart?.box_products?.length">
        <v-icon class="text--primary">mdi-shopping-outline</v-icon>
      </v-btn>
      <!-- </v-badge> -->

      <!-- Dropdown User Menu -->
      <div>
        <v-menu bottom rounded offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon x-large v-on="on" class="rounded-0" width="60px" color="secondary">
              <v-avatar color="primary" size="32">
                <!-- <span class="white--text text-h5">{{ user.initials }}</span> -->
                <span>
                  <v-icon class="white--text text-h6">mdi-account</v-icon>
                </span>
              </v-avatar>
              <v-icon class="text-h5" style="width:20px;">mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center pa-2">
              <div class="mx-auto text-center" v-if="!$store.getters.isLoggedIn">
                <v-btn text width="100%" class="text-none font-weight-bold" :to="{ name: 'SignUpPage', }">Sign up
                </v-btn>
                <v-divider class="my-2"></v-divider>
                <v-btn text width="100%" class="text-none font-weight-bold" :to="{ name: 'LoginPage', }">Login</v-btn>
              </div>
              <div class="mx-auto text-center" v-if="$store.getters.isLoggedIn">
                <v-btn text width="100%" class="text-none font-weight-bold" @click="logout">Logout</v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </div>
    </v-app-bar>

    <v-container fluid class="pt-1">
      <router-view>
      </router-view>
    </v-container>
  </v-app>
</template>

<script>
// import CustomerOrders from "@/components/Dashboard/CustomerOrders"
// import CustomerQuotes from "@/components/Dashboard/CustomerQuotes"

export default {
  name: 'DashBoard',
  components: {
    // CustomerOrders,
    // CustomerQuotes
  },
  mounted() { },
  computed: {},
  data() {
    return {
      DAshTab: null,
      dashnav: false,
    }
  },
  methods: {
    goToCartPage() {
      if (!this.$helper.functions.allValidated(this.$store.state.cart?.box_products, this.$store.getters.getCart?.quantity)) {
        if (this.$route.name != 'ProductCatalog') {
          this.$router.push({ name: 'ProductCatalog' })
        } else {
          this.$root.$emit('distribution_invalid', false)
        }

      } else {
        this.$router.push({ name: 'Cart' })
      }
    },

    logout() {
      this.$store.commit("togglePageLoading", true);
      this.$store.dispatch('LOGOUT').then(
        response => {
          this.$store.commit("togglePageLoading", false);
          this.$router.push({ name: "Home" }).catch(() => { });
        },
        error => {
          this.$store.commit("togglePageLoading", false);
          this.$store.commit("showAlert", {
            text: error.data.message,
            successStatus: false,
            alertColor: 'red darken-4'
          });
          // if (error.data.redirect) {
          //   this.$router.push({ name: 'Home' })
          // }
          throw error
        }
      )
    },
  }
}
</script>

<style scoped>
.dash-tabs .v-tabs-slider-wrapper{
  bottom: 12px;
}

</style>
