<template>
  <v-app>
    <v-container fluid class="wave2-bg">
      <v-row class="justify-center mb-6 mt-10 px-4">
        <v-col cols="12" sm="12" md="10" lg="8">
          <h4 class="text-h4 text-md-h3 font-weight-black text--primary">Customizing gifts for your peers has never been this easy.</h4>
        </v-col>
      </v-row>

      <v-row class="justify-center mb-6 px-4">
        <!-- The boring tradition -->
        <v-col cols="12" sm="6" md="5" lg="4">
          <v-card class="pa-4 rounded-lg" height="100%">
            <h6 class="text-h6 text--primary mb-2 font-weight-bold">The Boring Traditional Way</h6>
            <div>
              <p class="mb-1">Spending hours to find the perfect swag.</p>
              <p class="mb-1">No place to store the swagboxes.</p>
              <p class="mb-1">The workload of putting together boxes.</p>
            </div>
            <div>
              <v-img :src="require('@/assets/images/boring-gift.png')" alt="Boring Box" class="fill-height"></v-img>
            </div>
          </v-card>
        </v-col>

        <!-- The new ‘Swagboxes’ experience  -->
        <v-col cols="12" sm="6" md="5" lg="4">
          <v-card class="pa-4 rounded-lg" height="100%">
            <h6 class="text-h6 text--primary mb-2 font-weight-bold">The new ‘Swagboxes’ experience </h6>
            <p>The obvious choice and we're not just saying that. Swagboxes allow you to create memorable gifts and an everlasting impact. Who wouldn't want to be a part of that?</p>
            <div>
              <v-img :src="require('@/assets/images/swagbox-gift.png')" alt="Swag box" class="fill-height"></v-img>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" class="mt-4">
          <router-link :to="{name: 'ProductOnboarding',}" tag="v-btn" class="font-weight-bold primary text-none rounded-sm py-6">
              Shop swagbox
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  export default {
    name: 'GiftingYourPeers',
    components: {},
    mounted() {},
    computed: {},
    data() {
      return {

      }
    },
    methods: {}
  }
</script>

<style scoped>
  .wave2-bg {
    background: url('@/assets/images/wave2-bg.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
</style>
