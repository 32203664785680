<template>
  <v-app>
    <v-container fluid>
      <v-row class="px-4 align-center justify-md-space-between justify-center">
        <v-col cols="12" sm="12" md="6" lg="5" class="text-left">
          <h5 class="text-h4 text-lg-h3 text-md-h4 font-weight-black text--primary">For all the rookies, What's a Swagbox?</h5>
          <p class="text-lg-h4 text-md-h5 text--primary font-weight-extramedium my-4">An assortment of your favorite items bundled together in a box with your message.</p>
          <router-link :to="{name: 'ProductOnboarding',}" tag="v-btn" class="font-weight-bold primary text-none rounded-sm py-6">
            Shop swagbox
          </router-link>
        </v-col>

        <v-col cols="12" sm="8" md="6" lg="5">
          <v-img :src="require('@/assets/images/swagboxes.png')" alt="Swag boxes" class="fill-height"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  export default {
    name: 'WhatSwagbox',
    components: {},
    mounted() {},
    computed: {},
    data() {
      return {

      }
    },
    methods: {}
  }
</script>

<style scoped>

</style>
