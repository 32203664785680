<template>
    <v-app>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <div class="d-flex justify-center align-center primary overflow-auto" style="min-height:100vh;">
            <v-container>
                <v-row class="justify-center my-10">
                    <v-col cols="12" sm="8" md="6" lg="5">
                        <v-card elevation="3" rounded="lg">
                            <div class="py-6">
                                <v-btn :to="{ name: 'Home', }" class="transparent" text>
                                    <v-img :src="require('@/assets/images/swagbox-logo.png')" max-width="100%"
                                        height="auto" width="200">
                                    </v-img>
                                </v-btn>
                            </div>
                            <v-divider></v-divider>
                            <div class="py-4 px-4">
                                <div class="text-body-2 text--primary font-weight-black text-uppercase">Verifying
                                    Account, Please wait....</div>

                            </div>
                            <!-- <v-divider></v-divider>
                            <div class="pa-2 text-body-1 font-weight-extramedium">
                                Already have an account?<v-btn text class="text--primary font-weight-extrabold pa-2"
                                    :to="{ name: 'LoginPage', }">Log in</v-btn>
                            </div> -->
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>

    </v-app>
</template>
  
  <script>
export default {
    name: 'SignUpPage',
    data() {
        return {
            loading: true
        }
    },
    created() {
        this.verifyAccount();
    },
    methods: {
        verifyAccount(e) {
            this.$store
                .dispatch('VERIFY_ACCOUNT', { email_verification_token: this.$route.params.token })
                .then(
                    response => {
                        if (response.status == "success") {
                            this.$store.commit("showAlert", {
                                text: response.message,
                                successStatus: true,
                            });
                            this.$router.push({ name: 'LoginPage' })
                        }
                        this.loading = false
                    },
                    error => {
                        this.loading = false
                        this.$store.commit("showAlert", {
                            text: error.data.message,
                            successStatus: false,
                            alertColor: 'red darken-4'
                        });
                        throw error
                    }
                )
                .catch(error => {
                    this.loading = false
                    if (error.data.errors)
                        this.$refs.observer.setErrors(error.data.errors)
                })
        }
    }
}
</script>
  
  <style scoped>
  .theme--light.v-btn--active:before,
  .theme--light.v-btn--active:hover:before {
      opacity: 0;
  }
  </style>
  