<template>
  <v-app>
    <div class="d-flex justify-center align-center primary overflow-auto" style="min-height:100vh;">
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-container>
        <v-row class="justify-center my-10">
          <v-col cols="12" sm="8" md="6" lg="5" xl="4">
            <v-card elevation="3" rounded="lg">
              <div class="py-6">
                <v-btn :to="{ name: 'Home', }" class="transparent" text>
                  <v-img :src="require('@/assets/images/swagbox-logo.png')" max-width="100%" height="auto" width="200">
                  </v-img>
                </v-btn>
              </div>
              <v-divider></v-divider>
              <div class="py-4 px-4">
                <div class="text-body-2 text--primary font-weight-black text-uppercase">Set a new Password</div>
                <validation-observer ref="observer">
                  <v-form @submit="resetPassword">
                    <v-container>
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-subheader class="px-0 text--primary font-weight-bold">New Password</v-subheader>
                          <validation-provider v-slot="{ errors }" rules="required|min:8|max:12" name="Password">
                            <v-text-field type="password" placeholder="Password" v-model="user.password" outlined dense
                              required :error-messages="errors" :error="errors.length !== 0"
                              :hide-details="errors.length <= 0">
                            </v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" class="py-1">
                          <v-subheader class="px-0 text--primary font-weight-bold">Confirm Password</v-subheader>
                          <validation-provider v-slot="{ errors }" rules="required|confirmed:Password|min:8|max:12"
                            name="Confirm Password">
                            <v-text-field type="password" placeholder="Confirm Password"
                              v-model="user.password_confirmation" outlined dense required :error-messages="errors"
                              :error="errors.length !== 0" :hide-details="errors.length <= 0">
                            </v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" class="py-3">
                          <vue-recaptcha :sitekey="reCaptchaSiteKey" @verify="verifyMethod">
                          </vue-recaptcha>
                        </v-col>
                        <v-col cols="12" class="py-1 my-3">
                          <v-btn class="font-weight-bold text-none rounded-sm py-6" type="password" color="primary"
                            width="100%">Confirm</v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </validation-observer>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

  </v-app>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
export default {
  name: 'SetPassword',
  components: {
    VueRecaptcha
  },
  mounted() { },
  computed: {},
  data() {
    return {
      user: {
        password: null,
        password_confirmation: null,
        email_verification_token: this.$route.params.token,
        captchaToken: null
      },
      reCaptchaSiteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
      loading: false,
      captchaVerified: false
    }
  },
  methods: {
    verifyMethod(token) {
      this.user.captchaToken = token
      this.captchaVerified = true
    },

    resetPassword(e) {
      e.preventDefault();
      if (!this.captchaVerified) {
        alert('Please confirm captcha to proceed');
        return
      }

      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }
        this.loading = true
        this.$store
          .dispatch('RESET_PASSWORD', this.user)
          .then(
            response => {
              if (response.status == "success") {
                this.$router.push({ name: 'NewPasswordSet' })
              }
              this.loading = false
            },
            error => {
              this.loading = false
              this.$store.commit("showAlert", {
                text: error.data.message,
                successStatus: false,
                alertColor: 'red darken-4'
              });
              throw error
            }
          )
          .catch(error => {
            this.loading = false
            if (error.data.errors)
              this.$refs.observer.setErrors(error.data.errors)
          })
      });
    }
  }
}
</script>

<style scoped>
.theme--light.v-btn--active:before,
.theme--light.v-btn--active:hover:before {
  opacity: 0;
}
</style>
