<template>
  <v-app>
    <div class="text-h5 text--primary text-left font-weight-bold mb-3">Your Cart ({{ CartItem?.length }} items)</div>
    <v-simple-table class="my-3 elevation-4" dense>
      <template v-slot:default>
        <tbody v-if="CartItem?.length">
          <tr v-for="(item, itemIndex) in CartItem" :key="itemIndex">
            <td class="py-3 px-2 px-sm-4" style="max-width: 250px; min-width:90px;">
              <div class="d-flex flex-wrap flex-sm-nowrap">
                <div class="">
                  <v-img :src="$helper.functions.getProdImage(item)" class="fill-height" contain width="64"></v-img>
                </div>
                <div class="ms-2 text-left">
                  <div class="font-weight-extramedium text-body-2 mb-1">{{ item.title }}</div>
                  <!-- <div class="mb-1">Color: {{ item.color }}</div> -->
                  <div class="font-weight-bold mb-1">Qty per Box: {{ item.quantity }}</div>
                </div>
              </div>
            </td>
            <td class="py-3 px-2 px-sm-4">
              <div>Item price</div>
            </td>
            <td class="py-3 px-2 px-sm-4">
              <div class="text-no-wrap primary--text text-caption font-weight-bold">{{
              getItemPrice(item) }} x {{
                $store.getters.getCart?.quantity }}</div>
            </td>
            <td class="py-3 px-2 px-sm-4">
              <div class="d-flex justify-end align-center mb-1">

                <div class="text-right text--primary font-weight-bold">{{ productPrice(item) }}</div>
              </div>
              <!-- <div class="d-flex justify-space-between align-center">
                <div>Setup fee</div>
                <div class="text-right text--primary font-weight-bold">
                  <v-icon class="mr-2 mb-1" dense>mdi-alert-circle-outline</v-icon>${{ item.Setupfee }}
                </div>
              </div> -->
              <div class="text-right">
                <v-btn text color="green" v-if="item.sizes.length"
                  class="font-weight-extramedium text-none pa-0 text--darken-2 mr-1 text-caption"
                  @click.stop="openProdPriceBreakdown(item)">View breakdown</v-btn>
                <v-btn text
                  class="text-left primary--text transparent text-none pa-0 font-weight-extramedium mr-1 text-caption"
                  @click.stop="SelectQuantities = !SelectQuantities">Edit</v-btn>
                <v-btn text
                  class="text-right red--text transparent text-none pa-0 text--darken-3 font-weight-extramedium text-caption"
                  @click="removeIndex(itemIndex)">
                  Remove
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- Select Quantity Modal START -->
    <v-dialog v-model="SelectQuantities" transition="dialog-bottom-transition" fullscreen bottom scrollable>
      <v-btn @click="closeQuantityDialogue" color="elevation-0 mt-10" fab absolute top right height="40" width="40">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
      <SelectQuantity />
    </v-dialog>
    <!-- Select Quantity Modal START -->

    <!-- Price breakdown Sidebar START-->
    <v-dialog v-model="PriceBreak" overlay-color="#f1f1f1" max-width="1000px" content-class="rounded-xl relative"
      scrollable>
      <v-btn @click="PriceBreak = false" color="elevation-0 darken-5 mt-10" fab absolute top right height="40"
        width="40">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>

      <!-- Price breakdown component -->
      <template v-if="selectedProd && PriceBreak">
        <PriceBreakdown :selectedProd="selectedProd" />
      </template>
    </v-dialog>
    <!-- Price breakdown Sidebar END-->

    <!-- Product Detail view START -->
    <v-dialog v-model="openProdModal" max-width="1000px" v-if="Object.keys(productSelected).length != 0"
      overlay-color="#f1f1f1" content-class="rounded-xl relative" scrollable>
      <v-btn @click="openProdModal = false" color="elevation-0 mt-10" fab absolute top right height="40" width="40">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>

      <ProductDetail :productSelected="productSelected" />
    </v-dialog>
    <!-- Product Detail view END -->
  </v-app>
</template>

<script>
import SelectQuantity from "@/components/SelectQuantity"
import PriceBreakdown from "@/components/PriceBreakdown"
import ProductDetail from "@/components/ProductDetail"

export default {
  name: 'CartItems',
  components: {
    SelectQuantity,
    PriceBreakdown,
    ProductDetail
  },
  mounted() { },
  computed: {
    productSelected() {
      return this.selectedProduct
    },

    CartItem() {
      if (this.$store.state.cart?.box_products) {
        // return cloneDeep(this.$store.state.cart?.box_products)
        return this.$store.state.cart?.box_products
      } else {
        return []
      }
    },

    logoPrice() {
      return this.$store.getters.logoPrice
    },

    allValidated() {
      return this.$helper.functions.allValidated(this.$store.state.cart?.box_products, this.$store.getters.getCart?.quantity)
    },

    quantityLimits() {
      return this.$store.getters.getQuantityLimits
    },
    numberOfLogos() {
      return this.$store.getters.numberOfLogos
    },
  },
  data() {
    return {
      SelectQuantities: false,
      PriceBreak: false,
      selectedProd: {},
      openProdModal: false,
      selectedProduct: {},
    }
  },
  methods: {
    productPrice(product) {

      let price = null
      if (product.sizes.length) {
        let prod = JSON.stringify(product)
        prod = JSON.parse(prod)
        price = this.$helper.functions.getSizedProdPrice(prod, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
        price = Number(price.toFixed(2)) * Number(this.$store.state.cart?.quantity)
        let logoPrice = this.$helper.functions.getProductLogoPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
        let logoFinalPrice = (Number(logoPrice) * Number(product.quantity)) * Number(this.$store.getters.getCart?.quantity)
        console.log('logoPrice', logoPrice)
        console.log('logoFinalPrice', logoFinalPrice)
        product.imprint_color_price = logoFinalPrice
        product.logo_price = logoPrice
      } else {
        let prodPrice = this.$helper.functions.getItemPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
        // let volumePrice = product.variants.find(variant => {
        //   return variant.color?.value === product.selected_color.value
        // }).volume_pricing.find(price => {
        //   return price.quantity >= this.quantityLimits.from && price.quantity <= this.quantityLimits.to
        //   // return price.quantity >= this.$store.state.cart?.quantity
        // })
        // price = ((Number(product.quantity) * Number(prodPrice)) + (Number(this.logoPrice) * Number(product.quantity))) * Number(this.$store.state.cart?.quantity)
        price = Number(prodPrice) * Number(this.$store.state.cart?.quantity)
      }
      return price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    getItemPrice(item) {
      let price = 0
      if (item.sizes.length) {
        let prod = JSON.stringify(item)
        prod = JSON.parse(prod)
        price = this.$helper.functions.getSizedProdPrice(prod, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos).toFixed(2)
      } else {
        let prodPrice = this.$helper.functions.getItemPrice(item, this.$store.getters.getCart?.quantity, this.numberOfLogos)
        // let volumePrice = item.variants.find(variant => {
        //   return variant.color?.value === item.selected_color.value
        // }).volume_pricing.find(price => {
        //   return price.quantity >= this.quantityLimits.from && price.quantity <= this.quantityLimits.to
        //   // return price.quantity >= this.$store.state.cart?.quantity
        // })
        price = Number(prodPrice) + Number(this.logoPrice)
      }
      return price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    closeQuantityDialogue() {
      if (this.allValidated) {
        this.$root.$emit('modalClosed', true)
        this.SelectQuantities = false
      }
    },

    openProdPriceBreakdown(item) {
      this.selectedProd = item
      this.PriceBreak = !this.PriceBreak
    },

    // getImage(image) {
    //   // let imageContent = JSON.parse(image)
    //   return image?.[0]?.data?.link ? image?.[0]?.data?.link : ''
    // },

    removeIndex(itemIndex) {
      // this.$helper.functions.resetItem(this.$store.getters.products, this.$store.state.cart?.box_products[itemIndex])
      this.$store.commit('removeItemFromCart', itemIndex)
      if (!this.$store.state.cart?.box_products || this.$store.state.cart?.box_products?.length == 0) {
        this.$router.push({ name: 'CartEmpty' })
      }
    }
  },

  created() {
    // this.CartItem = this.$store.state.cart.box_products
    this.$root.$on('closeModal', () => {
      this.SelectQuantities = false
    })
    if (!this.allValidated) {
      this.SelectQuantities = true
    }

    this.$root.$on('openProdDetailCart', (product) => {
      this.selectedProduct = product
      this.openProdModal = true
    })
  }
}
</script>

<style scoped>
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child,
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row) {
  border-bottom: 0px;
}
</style>
