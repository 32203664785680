<template>
  <!-- <div > -->
  <v-card>
    <v-card-title class="text-h6 font-weight-bold">
      Price breakdown
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" lg="6">
            <div>
              <v-img :src="$helper.functions.getProdImage(selectedProd)" contain height="300px"
                class="img-fluid mx-auto rounded-lg elevation-1"></v-img>
            </div>
          </v-col>
          <v-col cols="12" lg="6">
            <div class="text-body-1 text-lg-h6 font-weight-bold text-left mb-3">Unisex heavy cotton tee</div>
            <div class="text-body-2 font-weight-extramedium text-left my-3">Remaining in cart: {{
            $helper.functions.getRemainingCartQuantity(selectedProd, selectedProd.quantity,
            $store.getters.getCart?.quantity) }}
            </div>

            <v-divider></v-divider>
            <!-- Price breakdown table -->
            <div>
              <v-simple-table class="my-3 elevation-0 transparent" light dense hide-overlay>
                <template v-slot:default>
                  <tbody class="text-left text-caption font-weight-extramedium">
                    <tr v-for="(size, sizeIndex) in selectedProd.sizes" :key="sizeIndex">
                      <template v-if="sizeExist(selectedProd, size)">
                        <td class="text-uppercase">{{ size.size }}</td>
                        <td>{{ size.quantity }} x {{ $helper.functions.getSizeUnitPrice(size, selectedProd,
                        $store.getters.getCart.quantity) }}</td>
                        <td>{{ getFullSizePrice(size) }}</td>
                      </template>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <!-- Sidebar footer price -->
    <v-divider></v-divider>
    <v-card-actions class="pa-3 text-end d-flex justify-end align-center">
      <!-- <v-divider></v-divider> -->
      <div class="text-right">
        <div class="text-body-1 font-weight-black primary--text">{{ getProdFinalPrice() }}</div>
        <small class="text-caption font-weight-extramedium primary--text">For {{ $store.getters.getCart.quantity }}
          boxes</small>
      </div>
    </v-card-actions>

  </v-card>
  <!-- </div> -->
</template>
<script>
export default {
  name: 'PriceBreakdown',
  props: {
    selectedProd: {
      type: Object,
      default: function () {
        return {}
      },
      required: true
    }
  },
  components: {},
  mounted() { },
  computed: {
    numberOfLogos() {
      return this.$store.getters.numberOfLogos
    },
  },
  data() {
    return {}
  },
  methods: {
    getFullSizePrice(size) {
      let price = 0
      let variant = this.selectedProd.variants.find(variant => {
        return variant.color?.value === this.selectedProd.selected_color.value && variant.size === size.size
      })

      let productQuantities = variant.volume_pricing.map(volume => volume.quantity)
      let updatedBoxQuantity = Number(this.$store.getters.getCart.quantity) * Number(this.selectedProd.quantity)
      let expectedQuantity = productQuantities.reverse().find(e => e <= updatedBoxQuantity);

      let volumePrice = variant.volume_pricing.find(price => {
        return price.quantity == expectedQuantity
      })

      // let volumePrice = variant.volume_pricing.find(price => {
      //   return price.quantity >= this.$store.getters.getCart.quantity
      // })
      // let sizeUnitPrice = this.$helper.functions.getSizeUnitPrice(size, this.selectedProd)
      price = (size.quantity * volumePrice.price)
      return price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    getProdFinalPrice() {
      let product = this.selectedProd
      let price = null
      if (product.sizes.length) {
        let prod = JSON.stringify(product)
        prod = JSON.parse(prod)
        price = this.$helper.functions.getSizedProdPrice(prod, this.$store.getters.getCart?.quantity, 0.00, this.$store.getters.getQuantityLimits)
        price = Number(price.toFixed(2)) * Number(this.$store.state.cart?.quantity)
      } else {
        let prodPrice = this.$helper.functions.getItemPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
        let productLogoPrice = this.$helper.functions.getProductLogoPrice(product, this.$store.getters.getCart?.quantity)
        product.imprint_color_price = productLogoPrice
        // console.log('prodPrice', prodPrice)
        price = Number(prodPrice) * Number(this.$store.state.cart?.quantity)
        // price = Number(prodPrice)
      }
      return price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    // getProdFinalPrice() {
    //   let price = this.selectedProd.final_price
    //   console.log(this.selectedProd.final_price)
    //   price = Number(price) + (Number(this.$store.getters.logoPrice) * this.selectedProd.quantity)
    //   // * this.$store.getters.getCart.quantity
    //   return price.toLocaleString('en-US', {
    //     style: 'currency',
    //     currency: 'USD',
    //   })
    // },

    sizeExist(product, size) {
      let variant = {}
      // product.sizes.forEach(object => {
      variant = product.variants.find(variant => {
        return variant.color?.value === product.selected_color.value && variant.size === size.size
      })
      if (variant) {
        return true
      } else {
        return false
      }
      // });
    },
  },
}
</script>

<style scoped>
.fixed-nav-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child,
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row) {
  border-bottom: 0px;
}
</style>
