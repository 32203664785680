import axios from "axios";

import {
    GET_ORDERS,
    GET_ORDER,
    GET_ORDER_DETAIL,
    ORDERS_SUCCESS,
    ORDERS_ERROR,
    ORDER_DETAIL_SUCCESS,
    ORDER_DETAIL_ERROR,
    ORDER_ERROR,
    SUBMIT_ARTWORK_CHANGES,
    APPROVE_ART_WORK,
    UPLOAD_ART_WORK,
    UPLOAD_PRODUCT_ART_WORK
} from "@/store/actions/orders";

function initialState() {
    return {
        order: {},
        orders: []
    };
}

const state = initialState()


const getters = {
    order: (state) => state.order,
    orders: (state) => state.orders,
};

const actions = {
    [GET_ORDERS]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.post('/orders', params)
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.orders) {
                            // let orders = response.data.orders
                            // commit("ORDERS_SUCCESS", { orders });
                            resolve(response.data);
                        } else {
                            commit("ORDERS_ERROR");
                        }
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    [GET_ORDER]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.get('/order/' + params.order_id)
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.order) {
                            let order = response.data.order
                            commit("ORDER_DETAIL_SUCCESS", { order });
                            resolve(response.data);
                        } else {
                            commit("ORDER_DETAIL_ERROR");
                        }
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    [GET_ORDER_DETAIL]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.get('/get-order/' + params.order_id)
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.order) {
                            // let order = response.data.order
                            // commit("ORDER_DETAIL_SUCCESS", { order });
                            resolve(response.data);
                        } else {
                            commit("ORDER_DETAIL_ERROR");
                        }
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    [SUBMIT_ARTWORK_CHANGES]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.post('/submit-art-work', params)
                .then((response) => {
                    resolve(response.data);
                    if (response.status == 200) {
                        if (response.data.order) {
                            let order = response.data.order
                            commit("ORDER_DETAIL_SUCCESS", { order });
                            resolve(response.data);
                        } else {
                            commit("ORDER_DETAIL_ERROR");
                        }
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    [APPROVE_ART_WORK]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.post('/approve-art-work', params)
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.order) {
                            let order = response.data.order
                            commit("ORDER_DETAIL_SUCCESS", { order });
                            resolve(response.data);
                        } else {
                            commit("ORDER_DETAIL_ERROR");
                        }
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    [UPLOAD_ART_WORK]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.post('/upload-art-work', params)
                .then((response) => {
                    if (response.status == 200) {
                        resolve(response.data)
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    [UPLOAD_PRODUCT_ART_WORK]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.post('/upload-product-art-work', params)
                .then((response) => {
                    if (response.status == 200) {
                        resolve(response.data)
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
}

const mutations = {
    [ORDERS_SUCCESS]: (state, params) => {
        state.orders = params.orders;
    },

    [ORDER_DETAIL_SUCCESS]: (state, params) => {
        state.order = params.order;
    },

    [ORDER_DETAIL_ERROR]: (state) => {
        state.order = {};
    },

    [ORDER_ERROR]: () => {
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};