<template>
  <v-app>
    <v-container fluid class="mt-8">
      <v-row class="px-lg-4">
        <v-col cols="12" sm="12" md="8" lg="8">
          <ShippingDetails />

          <!-- Cart items -->

          <CartItems />
        </v-col>

        <v-col cols="12" sm="12" md="4" lg="4">
          <!-- Order summery -->
          <OrderSummary />

        </v-col>
      </v-row>
      <!-- <PayNow/> -->
    </v-container>
  </v-app>
</template>

<script>
import CartItems from "@/components/CartItems"
import ShippingDetails from "./components/ShippingDetails"
import OrderSummary from "./components/OrderSummary"

export default {
  name: 'PaymentOptions',
  components: {
    CartItems,
    ShippingDetails,
    OrderSummary,
  },
  mounted() { },
  computed: {},
  data() {
    return {

    }
  },
  methods: {},

  created() {
    // console.log(this.$store.state.customerInfo)
    if (!this.$store.state.cart?.box_products || this.$store.state.cart?.box_products?.length == 0) {
      this.$router.push({ name: 'CartEmpty' })
    } else if (!this.$store.state.customerInfo?.customer_info) {
      this.$store.commit("showAlert", {
        text: 'Please Fill your detail first...',
        successStatus: false,
        alertColor: 'red darken-4'
      });
      this.$router.push({ name: 'Cart' })
    }
  }
}
</script>

<style scoped>
</style>
