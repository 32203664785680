<template>
  <v-app class="mt-10">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <validation-observer ref="observer" :key="formKey">
      <v-form ref="form" :key="formKey">
        <v-container>
          <div class="text-h5 text--primary text-left font-weight-bold mb-3">Upload the logo or artwork you'd like to
            put on your swagbox</div>
          <v-row class="elevation-4 pa-4 rounded mx-1 mt-3">
            <p class="text-left text--primary">We recommend uploading vector or pdf file to get the best print quality
              possible. SVGs, PDFs, EPSs and high quality PNGs with 300DPI would work great.</p>
            <v-col cols="12" sm="6">

              <div class="uploadinput">
                <label for="PrimaryLogo">
                  <img :src="primaryLogoImage" class="img-fluid" v-if="primaryLogoImage">
                  <embed v-if="pdfSrc" type="video/webm" :src="pdfSrc" width="100%"
                    style="max-height: 19rem; min-height: 19rem" />
                </label>
                <input type="file" id="PrimaryLogo" class="d-none" @change="previewImage($event, 'primary')">
                <validation-provider v-slot="{ errors }" rules="required" name="Primary Logo">
                  <v-text-field placeholder="Adrian" v-model="primaryLogoValidation" :error-messages="errors"
                    :error="errors.length !== 0" :hide-details="errors.length <= 0" class="d-none" outlined dense
                    required>
                  </v-text-field>
                  <div class="v-text-field__details" v-if="errors.length > 0 || primaryLogoError">
                    <div class="v-messages theme--light error--text" role="alert">
                      <div class="v-messages__wrapper">
                        <div class="v-messages__message" v-if="errors[0]">{{ errors[0] }}</div>
                        <div class="v-messages__message" v-if="primaryLogoError">{{ primaryLogoError }}</div>
                      </div>
                    </div>
                  </div>
                </validation-provider>
              </div>

            </v-col>
            <v-col cols="12" sm="6">
              <div class="uploadinput">
                <label for="secondaryLogo">
                  <img :src="secondaryLogoImage" class="img-fluid" v-if="secondaryLogoImage">
                  <embed v-if="secondaryPdfSrc" type="video/webm" :src="secondaryPdfSrc" width="100%"
                    style="max-height: 19rem; min-height: 19rem" />
                </label>
                <input type="file" id="secondaryLogo" class="d-none" @change="previewImage($event, 'secondary')">
                <validation-provider v-slot="{ errors }" rules="required" name="Secondary Logo">
                  <v-text-field placeholder="Adrian" v-model="secondaryLogoValidation" :error-messages="errors"
                    :error="errors.length !== 0" :hide-details="errors.length <= 0" class="d-none" outlined dense
                    required>
                  </v-text-field>
                  <div class="v-text-field__details" v-if="errors.length > 0 || secondaryLogoError">
                    <div class="v-messages theme--light error--text" role="alert">
                      <div class="v-messages__wrapper">
                        <div class="v-messages__message" v-if="errors[0]">{{ errors[0] }}</div>
                        <div class="v-messages__message" v-if="secondaryLogoError">{{ secondaryLogoError }}</div>
                      </div>
                    </div>
                  </div>
                </validation-provider>
              </div>
            </v-col>
          </v-row>


          <div class="text-h5 text--primary text-left font-weight-bold mb-3 mt-10 mt-lg-16" id="contact-detail">Contact
            Details</div>
          <v-row>
            <v-col cols="12" md="6">
              <v-subheader class="px-0 text--primary font-weight-bold">First Name *</v-subheader>
              <validation-provider v-slot="{ errors }" rules="required" name="First Name">
                <v-text-field placeholder="Adrian" v-model="customerInfo.first_name" :error-messages="errors"
                  :error="errors.length !== 0" :hide-details="errors.length <= 0" outlined dense required>
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="6">
              <v-subheader class="px-0 text--primary font-weight-bold">Last Name *</v-subheader>
              <validation-provider v-slot="{ errors }" rules="required" name="Last Name">
                <v-text-field placeholder="Baker" v-model="customerInfo.last_name" :error-messages="errors"
                  :error="errors.length !== 0" :hide-details="errors.length <= 0" outlined dense required>
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="6">
              <v-subheader class="px-0 text--primary font-weight-bold">Email Address *</v-subheader>
              <validation-provider v-slot="{ errors }" rules="required|email" name="Email Address">
                <v-text-field placeholder="adrian@example.com" v-model="customerInfo.email" :error-messages="errors"
                  :error="errors.length !== 0" :hide-details="errors.length <= 0" outlined dense required>
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="6">
              <v-subheader class="px-0 text--primary font-weight-bold">Company Name *</v-subheader>
              <validation-provider v-slot="{ errors }" rules="required" name="Company Name">
                <v-text-field placeholder="Adrian’s company" v-model="customerInfo.company_name" :error-messages="errors"
                  :error="errors.length !== 0" :hide-details="errors.length <= 0" outlined dense required></v-text-field>
              </validation-provider>
            </v-col>

            <!-- <v-col cols="12" md="6"> -->
            <!-- <v-subheader class="px-0 text--primary font-weight-bold">Phone Number *</v-subheader> -->
            <!-- <validation-provider v-slot="{ errors }" rules="required|validate_phone_number" name="Phone Number"> -->
            <!-- <v-text-field type="text" v-model="phone_number" :error-messages="errors" :error="errors.length !== 0"
                  :hide-details="errors.length <= 0" outlined dense required @input="formatPhoneNumber">
                  <template slot="prepend-inner">
                    <div class="d-flex align-center">
                      <v-img :src="require('@/assets/images/usa-flag.png')" width="24px"></v-img><span
                        class="ml-2 font-weight-extramedium">+1</span>
                    </div>
                  </template>
                </v-text-field> -->
            <!-- <vue-phone-number-input v-model="phone_number"
                :default-country-code="customerInfo.phone_data?.countryCode ? customerInfo.phone_data.countryCode : 'US'"
                no-example @update="updatePhone" :translations="{phoneNumberLabel: null}"> -->
            <!-- <template slot="prepend-inner">
                    <div class="d-flex align-center">
                      <v-img :src="require('@/assets/images/usa-flag.png')" width="24px"></v-img><span
                        class="ml-2 font-weight-extramedium">+1</span>
                    </div>
                  </template> -->
            <!-- </vue-phone-number-input>
              <div class="v-text-field__details" v-if="customerInfo.phone_data && !customerInfo.phone_data?.isValid">
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">Invalid Phone Number</div>
                  </div>
                </div>
              </div> -->
            <!-- </validation-provider> -->
            <!-- <vue-phone-number-input v-model="phone_number" rules="required|validate_phone_number"/> -->
            <!-- </v-col> -->

            <v-col cols="12" md="6">
              <v-subheader class="px-0 text--primary font-weight-bold">Phone Number *</v-subheader>
              <validation-provider v-slot="{ errors }" rules="required|limit_phone:16" name="Phone Number">
                <v-text-field placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'"
                  v-model="customerInfo.phone_number" :error-messages="errors" :error="errors.length !== 0"
                  :hide-details="errors.length <= 0" outlined dense required>
                </v-text-field>
              </validation-provider>
            </v-col>

            <!-- <v-col cols="12" md="6">
              <v-subheader class="px-0 text--primary font-weight-bold">Address Line 1 *</v-subheader>
              <validation-provider v-slot="{ errors }" rules="required" name="Address Line 1">
                <v-text-field placeholder="Addess 1" v-model="customerInfo.address_one" :error-messages="errors"
                  :error="errors.length !== 0" :hide-details="errors.length <= 0" outlined dense required>
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="12">
              <v-subheader class="px-0 text--primary font-weight-bold">Address Line 2</v-subheader>
              <v-text-field placeholder="Addess 2" hide-details v-model="customerInfo.address_two" outlined dense
                required>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-subheader class="px-0 text--primary font-weight-bold">City *</v-subheader>
              <validation-provider v-slot="{ errors }" rules="required" name="City">
                <v-text-field placeholder="City" v-model="customerInfo.city" :error-messages="errors"
                  :error="errors.length !== 0" :hide-details="errors.length <= 0" outlined dense required>
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="4">
              <v-subheader class="px-0 text--primary font-weight-bold">State *</v-subheader>
              <validation-provider v-slot="{ errors }" rules="required" name="State">
                <v-autocomplete v-model="customerInfo.state" item-value="name" item-text="name" :items="states" outlined
                  dense :error-messages="errors" :error="errors.length !== 0" :hide-details="errors.length <= 0">
                </v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="4">
              <v-subheader class="px-0 text--primary font-weight-bold">Zip code *</v-subheader>
              <validation-provider v-slot="{ errors }" rules="required" name="Postal Code">
                <v-text-field placeholder="Postal Code" v-model="customerInfo.postal_code" :error-messages="errors"
                  :error="errors.length !== 0" :hide-details="errors.length <= 0" outlined dense required>
                </v-text-field>
              </validation-provider>
            </v-col> -->

          </v-row>
        </v-container>

        <v-container class="mt-5">
          <ShippingDetails />
        </v-container>
      </v-form>
    </validation-observer>
  </v-app>
</template>

<script>
import ShippingDetails from "./ShippingDetails"
export default {
  name: 'ContactDetails',
  components: {
    ShippingDetails
  },
  mounted() { },
  computed: {
    logoPrice() {
      return this.$store.getters.logoPrice
    },

    numberOfLogos() {
      return this.$store.getters.numberOfLogos
    },
  },
  data() {
    return {
      customerInfo: {
        first_name: null,
        last_name: null,
        email: null,
        company_name: null,
        phone_number: null,
        phone_data: {},
        phone: null,
        address_one: null,
        address_two: null,
        city: null,
        state: null,
        postal_code: null,
        primary_logo: null,
        secondary_logo: null,
      },
      // phone_number: null,
      logo: null,
      formKey: (Math.random() + 1).toString(36).substring(7),
      primaryLogoImage: require('@/assets/images/primary-logo.png'),
      primaryLogoValidation: '123',
      secondaryLogoImage: require('@/assets/images/secondary-logo.png'),
      secondaryLogoValidation: '123',
      primaryLogoError: null,
      secondaryLogoError: null,
      loading: false,
      pdfSrc: null,
      secondaryPdfSrc: null,
      states: []
    }
  },

  methods: {
    // formatPhoneNumber() {
    //   var x = this.phone_number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    //   this.phone_number = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    //   this.customerInfo.phone = this.phone_number
    // },
    // updatePhone(e) {
    //   this.customerInfo.phone_data = e
    //   this.customerInfo.phone_number = e.nationalNumber
    // },
    validateForm() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          this.$vuetify.goTo('#contact-detail')
          return
        }
        // if (!this.customerInfo.phone_data || !this.customerInfo.phone_data?.isValid) {
        //   return
        // }
        // this.customerInfo.phone_data = JSON.stringify(this.customerInfo.phone_data)
        this.loading = true
        let formData = new FormData();
        Object.keys(this.customerInfo).forEach(key => {
          formData.append(key, this.customerInfo[key])
        })
        
        // formData.append('total_price', productsPrice)
        this.$store
          .dispatch('ADD_CUSTOMER', formData)
          .then(
            response => {
              console.log(response)
              this.loading = false
              let data = {
                isFormValid: this.$refs.form.validate(),
                formData: response.customer
              }
              this.$root.$emit('isFormValid', data);
            },
            error => {
              this.loading = false
              this.$store.commit("showAlert", {
                text: error.data.message,
                successStatus: false,
                alertColor: 'red darken-4'
              });
              throw error
            }
          )
          .catch(error => {
            this.loading = false
            if (error.data.errors)
              this.$refs.observer.setErrors(error.data.errors)
          })
      });
    },

    previewImage(event, imageType) {
      let fileType = event.target.files[0]['type'];
      let allowedTypes = ['application/pdf', 'application/postscript', 'image/svg+xml', 'image/png', 'image/jpeg']

      if (allowedTypes.includes(fileType)) {
        if (imageType == 'primary') {
          this.customerInfo.primary_logo = event.target.files[0]
          this.primaryLogoError = null
          this.primaryLogoValidation = 'imageAdded'
          if (fileType == 'application/pdf') {
            this.primaryLogoImage = require('@/assets/images/pdf-preview.png')
            // this.primaryLogoImage = null
            // this.pdfSrc = URL.createObjectURL(event.target.files[0]) + '#toolbar=0&navpanes=0&scrollbar=0'
            return
          } else if (fileType == 'application/postscript') {
            this.primaryLogoImage = require('@/assets/images/eps-preview.png')
            return
          } else if (fileType == 'image/svg+xml') {
            this.primaryLogoImage = URL.createObjectURL(event.target.files[0])
            return
          } else {
            this.primaryLogoImage = URL.createObjectURL(event.target.files[0])
            // const reader = new FileReader();

            // reader.readAsDataURL(event.target.files[0]);
            // reader.onload = (e) => {
            //   const image = new Image();
            //   image.src = e.target.result;
            //   image.onload = (e) => {
            //     const height = e.target.height;
            //     const width = e.target.width;
            //     // this.height = height
            //     // this.width = width
            //     console.log(this.height)
            //     if ((width >= 2000 || height >= 2000)) {
            //       this.customerInfo.primary_logo = event.target.files[0]
            //       this.primaryLogoError = null
            //       this.primaryLogoValidation = 'imageAdded'
            //       this.primaryLogoImage = URL.createObjectURL(event.target.files[0])
            //     } else {
            //       this.$store.commit("showAlert", {
            //         text: 'Image is low resolution, please upload high resolution image',
            //         successStatus: false,
            //         alertColor: 'red darken-4'
            //       });
            //     }
            //   };
            // };

          }

        } else if (imageType == 'secondary') {
          this.customerInfo.secondary_logo = event.target.files[0]
          this.secondaryLogoError = null
          this.secondaryLogoValidation = 'imageAdded'
          if (fileType == 'application/pdf') {
            this.secondaryLogoImage = require('@/assets/images/pdf-preview.png')
            // this.secondaryLogoImage = null
            // this.secondaryPdfSrc = URL.createObjectURL(event.target.files[0]) + '#toolbar=0&navpanes=0&scrollbar=0'
            return
          } else if (fileType == 'application/postscript') {
            this.secondaryLogoImage = require('@/assets/images/eps-preview.png')
            return
          } else if (fileType == 'image/svg+xml') {
            this.secondaryLogoImage = require('@/assets/images/pdf-preview.png')
            return
          } else {
            this.secondaryLogoImage = URL.createObjectURL(event.target.files[0])
            // const reader = new FileReader();

            // reader.readAsDataURL(event.target.files[0]);
            // reader.onload = (e) => {
            //   const image = new Image();
            //   image.src = e.target.result;
            //   image.onload = (e) => {
            //     const height = e.target.height;
            //     const width = e.target.width;
            //     // this.height = height
            //     // this.width = width
            //     console.log(this.height)
            //     if ((width >= 2000 || height >= 2000)) {
            //       this.customerInfo.secondary_logo = event.target.files[0]
            //       this.secondaryLogoError = null
            //       this.secondaryLogoValidation = 'imageAdded'
            //       this.secondaryLogoImage = URL.createObjectURL(event.target.files[0])
            //     } else {
            //       this.$store.commit("showAlert", {
            //         text: 'Image is low resolution, please upload high resolution image',
            //         successStatus: false,
            //         alertColor: 'red darken-4'
            //       });
            //     }
            //   };
            // };

          }
        }
      } else {
        if (imageType == 'primary') {
          this.primaryLogoError = 'Invalid File type'
        } else {
          this.secondaryLogoError = 'Invalid File type'
        }
      }
    },

    updateCustomer() {
      this.customerInfo = this.$store.state.customerInfo?.customer_info
      // this.phone_number = this.$store.state.customerInfo?.customer_info.phone_number
      this.secondaryLogoValidation = 'imageAdded'
      this.primaryLogoValidation = 'imageAdded'
      let primaryLogoType = null
      let secodaryLogoType = null
      if (this.$store.state.customerInfo.customer_info.secondary_logo != 'null') {
        if (this.$store.state.customerInfo.customer_info.secondary_logo.includes('.eps')) {
          this.secondaryLogoImage = require('@/assets/images/eps-preview.png')
        } else if (this.$store.state.customerInfo.customer_info.secondary_logo.includes('.pdf')) {
          this.secondaryLogoImage = require('@/assets/images/pdf-preview.png')
        } else if (this.$store.state.customerInfo.customer_info.secondary_logo.includes('.svg')) {
          this.secondaryLogoImage = require('@/assets/images/pdf-preview.png')
        } else {
          this.secondaryLogoImage = this.$store.state.customerInfo.customer_info.secondary_logo
        }
      }

      if (this.$store.state.customerInfo.customer_info.primary_logo != 'null') {
        if (this.$store.state.customerInfo.customer_info.primary_logo.includes('.eps')) {
          this.primaryLogoImage = require('@/assets/images/eps-preview.png')
        } else if (this.$store.state.customerInfo.customer_info.primary_logo.includes('.pdf')) {
          this.primaryLogoImage = require('@/assets/images/pdf-preview.png')
        } else if (this.$store.state.customerInfo.customer_info.primary_logo.includes('.svg')) {
          this.primaryLogoImage = require('@/assets/images/pdf-preview.png')
        } else {
          this.primaryLogoImage = this.$store.state.customerInfo.customer_info.primary_logo
        }
      }
      // this.secondaryLogoImage = this.$store.state.customerInfo.customer_info.secondary_logo
      // this.primaryLogoImage = this.$store.state.customerInfo.customer_info.primary_logo
    }
  },

  created() {
    this.formKey = (Math.random() + 1).toString(36).substring(7)
    this.$root.$on('validateForm', this.validateForm);
    if (this.$store.state.customerInfo?.customer_info) {
      this.updateCustomer()
    }
    this.states = this.$store.state.us_states
  }

}
</script>

<style scoped>
.v-subheader {
  height: 30px;
}

.input-tel__label {
  display: none !important;
}

.v-text-field__details {
  margin-bottom: 0 !important;
  min-height: 0px !important;
}

.v-input__control .v-messages {
  min-height: 0px !important;
}

.v-text-field--outlined>>>fieldset {
  border-color: rgba(7, 26, 82, 1) !important;
  border-radius: 2px !important;
}
</style>
