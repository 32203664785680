<template>
    <v-app>
        <v-tabs v-model="shipment" background-color="transparent" active-class="active-card">
            <v-tab class="text-body-1 text-none font-weight-bold px-0 mx-2" to="/customer/shipment">Shipment</v-tab>
            <v-tab class="text-body-1 text-none font-weight-bold px-0 mx-2" to="/customer/contacts">Contacts</v-tab>
            <v-tab class="text-body-1 text-none font-weight-bold px-0 mx-2" to="/customer/contact-groups">Contact Groups</v-tab>
        </v-tabs>
    </v-app>
</template>

<script>
    export default {
        name: 'ShipmentTabs',
        components: {},
        data() {
            return {
                shipment: null,
            }
        },

        mounted() {},
        computed: {},
        methods: {},
        created() {}
    }
</script>

<style scoped>
    .v-card.v-card--link:hover {
        transform: scale(1.008) !important;
        border-bottom: 1px solid #d1d1d1;
    }

    .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child,
    .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row) {
        border-bottom: 0px;
    }
    .v-tabs .v-item-group .v-slide-group__prev, .v-tabs .v-item-group .v-slide-group__next{
        flex: 0;
        min-width: 0;
    }
</style>
