import axios from "axios";

import {
    GET_CATEGORIES_LIST,
    GET_CATEGORIES_LIST_SUCCESS,
    GET_CATEGORIES_LIST_ERROR
} from "@/store/actions/categories";

function initialState() {
    return {
        categories: [],
        brands: [],
        colors: [],
        sizes: []
    };
}

const state = initialState()


const getters = {
    categories: (state) => state.categories,
    brands: (state) => state.brands,
    colors: (state) => state.colors,
    sizes: (state) => state.sizes,
};

const actions = {
    [GET_CATEGORIES_LIST]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios.get('/categories')
                .then((response) => {
                    if (response.status == 200) {
                        let categories = response.data.categories
                        let brands = response.data.brands
                        let colors = response.data.colors
                        let sizes = response.data.sizes
                        // console.log("RES", colors)
                        commit("GET_CATEGORIES_LIST_SUCCESS", { categories, brands, colors, sizes });
                        resolve(response.data);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
}

const mutations = {
    [GET_CATEGORIES_LIST_SUCCESS]: (state, params) => {
        state.categories = params.categories;
        state.brands = params.brands;
        state.colors = params.colors;
        state.sizes = params.sizes;
    },

    [GET_CATEGORIES_LIST_ERROR]: () => {
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};