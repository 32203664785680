<template>
  <v-app>
    <v-container fluid class="wave1-bg">
      <v-row class="justify-center mb-6 mt-10">
        <v-col cols="12" sm="12" md="10" lg="8">
          <h3 class="text-h4 text-md-h3 font-weight-black text--primary">Create custom swag and share it with the world.</h3>
        </v-col>
      </v-row>
      <v-row class="px-4">
        <!-- Step one: Select swag START-->
        <v-col cols="12" sm="12" md="4" lg="4">
          <v-card class="text-left pa-4 rounded-lg" height="100%">
            <div class="mb-3">
              <v-img :src="require('@/assets/images/svg/hat.svg')" alt="Select swag" width="48px" class="fill-height"></v-img>
            </div>
            <h6 class="text-h6 text--primary mb-2"><span class="font-weight-bold">Step one:</span> Select swag</h6>
            <p class="ma-0">With our endless list of ever-evolving swag, you now have the freedom to choose how you want your voice to be heard. Select the Swag that best represents your message.</p>
          </v-card>
        </v-col>
        <!-- Step one: Select swag END-->

        <!-- Step two: Cart em’ up & checkout START -->
        <v-col cols="12" sm="12" md="4" lg="4">
          <v-card class="text-left pa-4 rounded-lg" height="100%">
            <div class="mb-3">
              <v-img :src="require('@/assets/images/svg/tshirt.svg')" alt="Tshirt on cart" width="48px" class="fill-height"></v-img>
            </div>
            <h6 class="text-h6 text--primary mb-2"><span class="font-weight-bold">Step two:</span> Cart 'em up & checkout </h6>
            <p class="ma-0">Add your chosen products to the cart, and be on your way to the beginning of building your legacy.</p>
          </v-card>
        </v-col>
        <!-- Step two: Cart em’ up & checkout END -->

        <!-- Finally: Approve the proofs sent on your email START -->
        <v-col cols="12" sm="12" md="4" lg="4">
          <v-card class="text-left pa-4 rounded-lg" height="100%">
            <div class="mb-3">
              <v-img :src="require('@/assets/images/svg/mail.svg')" alt="mail" width="48px" class="fill-height"></v-img>
            </div>
            <h6 class="text-h6 text--primary mb-2"><span class="font-weight-bold">Finally:</span> Approve the proofs sent on your email</h6>
            <p class="ma-0">After you finalize your swag and artwork, your legendary box will be on its way to being built so we can get it to you as soon as possible.</p>
          </v-card>
        </v-col>
        <!-- Finally: Approve the proofs sent on your email END -->
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  export default {
    name: 'CreateYourSwag',
    components: {},
    mounted() {},
    computed: {},
    data() {
      return {

      }
    },
    methods: {}
  }
</script>

<style scoped>
  .wave1-bg {
    background: url('@/assets/images/wave1-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 560px;
  }
</style>
