<template>
  <v-app>
    <div class="d-flex justify-center align-center primary overflow-auto" style="min-height:100vh;">
      <v-container>
        <v-row class="justify-center my-10">
          <v-col cols="12" sm="8" md="6" lg="5" xl="4">
            <v-card elevation="3" rounded="lg">
              <div class="py-6">
                <v-btn :to="{name: 'Home',}" class="transparent" text>
                  <v-img :src="require('@/assets/images/swagbox-logo.png')" max-width="100%" height="auto" width="200"></v-img>
                </v-btn>
              </div>
              <v-divider></v-divider>
              <div class="py-4 px-4">
                <!-- <v-icon class="green--text text-h3 my-4">mdi-check-circle-outline</v-icon> -->
                <div>
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" class="checkmark mx-auto mt-6 mb-8" style="width:50px;">
                    <circle class="path circle" fill="none" stroke="#1b5e20" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                    <polyline class="path check" fill="none" stroke="#1b5e20" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                  </svg>
                </div>
                <div class="text-h6 text--primary font-weight-black text-none">Your new password have been set.</div>
                <v-form>
                  <v-container>
                    <v-row>
                      <v-col cols="12" class="py-1 my-3">
                        <v-btn class="font-weight-bold text-none rounded-sm py-6" color="primary" width="100%" :to="{name: 'LoginPage'}">Take me to login</v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

  </v-app>
</template>

<script>
  export default {
    name: 'NewPasswordSet',
    components: {},
    mounted() {},
    computed: {},
    data() {
      return {}
    },
    methods: {}
  }
</script>

<style scoped>
  .theme--light.v-btn--active:before,
  .theme--light.v-btn--active:hover:before {
    opacity: 0;
  }
</style>
