import axios from "axios";

import {
    GET_PRESET_LIST,
    PRESET_BOX_SUCCESS,
    PRESET_BOX_ERROR
} from "@/store/actions/presetBoxes";

function initialState() {
    return {
        presetBoxes: []
    };
}

const state = initialState()


const getters = {
    presetBoxes: (state) => state.presetBoxes,
};

const actions = {
    [GET_PRESET_LIST]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios.get('/preset-boxes')
                .then((response) => {
                    if (response.status == 200) {
                        let presetBoxes = response.data.boxes
                        commit("PRESET_BOX_SUCCESS", { presetBoxes });
                        resolve(response.data);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
}

const mutations = {
    [PRESET_BOX_SUCCESS]: (state, params) => {
        state.presetBoxes = params.presetBoxes;
    },

    [PRESET_BOX_ERROR]: () => {
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};