<template>
    <v-app>
        <div class="text-h5 text--primary text-left font-weight-bold mb-3">Your Cart ({{ order.variants.length }} items)
        </div>
        <v-simple-table class="my-3 elevation-4" dense>
            <template v-slot:default>
                <tbody v-if="order">
                    <tr v-for="(item, itemIndex) in order.variants" :key="itemIndex">
                        <td class="py-3 px-2 px-sm-4" style="max-width: 400px;">
                            <div class="d-flex">
                                <div>
                                    <v-img :src="item['images'][0]" class="fill-height" contain width="74"></v-img>
                                </div>
                                <div class="ms-2 text-left">
                                    <div class="font-weight-extramedium text-body-2 mb-1">{{ item.sku }}</div>
                                    <div class="mb-1">Color: {{ item.selected_color.label }}</div>
                                    <div class="font-weight-bold mb-1">Qty per Box: {{ item.product_quantity }}</div>
                                    <div class="font-weight-bold mb-1">Total Qty: {{ item.quantity }}</div>
                                </div>
                            </div>
                        </td>
                        <td class="py-3 px-2 px-sm-4">
                            <div>Item price</div>
                        </td>
                        <td class="py-3 px-2 px-sm-4">
                            <div class="text-no-wrap primary--text text-caption font-weight-bold">{{
                            getVariantPrice(item) }} x {{
                                item.quantity }}</div>
                        </td>
                        <td class="py-3 px-2 px-sm-4">
                            <div class="d-flex justify-end align-center mb-1">

                                <div class="text-right text--primary font-weight-bold">{{ getItemPrice(item) }}</div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

    </v-app>
</template>
  
<script>

export default {
    name: 'PaymentRequestCart',
    components: {
    },
    props: {
        order: {
            type: Object,
            default: function () {
                return {}
            },
            required: true
        }
    },
    mounted() { },
    computed: {

    },
    data() {
        return {
            // SelectQuantities: false,
            // PriceBreak: false,
            // selectedProd: {},
        }
    },
    methods: {
        getItemPrice(item) {
            return (item.quantity * item.price).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })
        },

        getVariantPrice(item) {
            return item.price.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })
        }
    },

    created() {

    }
}
</script>
  
<style scoped>
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child,
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row) {
    border-bottom: 0px;
}
</style>
  