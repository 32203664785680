<template>
  <v-card class="sticky-top elevation-0 fixed" fixed>
    <div class="text-h5 text--primary text-left font-weight-bold mb-3">Order summary</div>
    <v-container>
      <v-row>
        <v-col cols="6">
          <div class="text-left">Number of swagboxes</div>
        </v-col>
        <v-col cols="6">
          <div class="text-right text--primary font-weight-bold">
            <!-- <validation-observer ref="observer">
              <v-form ref="form">
                <validation-provider v-slot="{ errors }" rules="required" name="Number of swagboxes">
                  <v-text-field placeholder="" type="number" v-model="$store.getters.getCart.quantity"
                    :error-messages="errors" :error="errors.length !== 0" :hide-details="errors.length <= 0" outlined
                    dense required>
                  </v-text-field>
                </validation-provider>
              </v-form>
            </validation-observer> -->
            {{ order.details.number_of_boxes }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <div class="text-left">Cost per swagbox</div>
        </v-col>
        <v-col cols="6">
          <div class="text-right text--primary font-weight-bold">
            {{ getBoxPrice(order) }}</div>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="6">
          <div class="text-left">Setup fee</div>
        </v-col>
        <v-col cols="6">
          <div class="text-right text--primary font-weight-bold">$36.00</div>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="6">
          <div class="text-left">Total</div>
        </v-col>
        <v-col cols="6">
          <div class="text-right text--primary font-weight-black">{{ getTotalPrice(order.details.total_price) }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn class="font-weight-bold primary text-none rounded-sm py-6" width="100%" @click="proceedToPayment">
            Pay Now</v-btn>
          <!-- <p class="my-4 text--primary">You can choose to <span class="font-weight-bold">pay later</span> at next step
            if you don’t want to pay yet.</p> -->
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'OrderSummary',

  components: {},

  props: {
    order: {
      type: Object,
      default: function () {
        return {}
      },
      required: true
    }
  },

  mounted() { },

  computed: {

  },

  data() {
    return {}
  },

  methods: {
    getBoxPrice(order) {
      return (order.details.total_price / order.details.number_of_boxes).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    getTotalPrice(price) {
      console.log(price)
      return Number(price).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    proceedToPayment() {
      this.$root.$emit('validatePaymentForm');
    },
  },

  created() {

  }
}
</script>

<style scoped>

</style>
