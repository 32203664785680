<template>
  <v-app>
    <v-container fluid class="relative">
      <v-btn @click.stop="filters = !filters" class="primary darken-5 mb-12 mb-lg-4 mt-10 mt-sm-16 ml-sm-4 floating-btn"
        fab fixed left bottom>
        <v-icon>
          mdi-tune
        </v-icon>
      </v-btn>
      <!-- filters START-->
      <v-navigation-drawer v-model="filters" fixed temporary width="360px" overlay-color="#f1f1f1">
        <v-btn @click="filters = false" color="elevation-0 darken-5 mt-10" fab absolute top right height="40"
          width="40">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>

        <v-list-item>
          <v-list-item-content class="text-left">
            <v-list-item-title class="font-weight-bold text--primary text-h6">Filters</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Clear Filters Button START -->
        <div class="text-left"
          v-if="$store.getters.getSelectedBrand.length || $store.getters.getSelectedColors.length || $store.getters.getSelectedSizes.length">
          <v-chip class="mx-2 caption font-weight-bold" close @click:close="clearFilter">Clear filters</v-chip>
        </div>
        <!-- Clear Filters Button END -->

        <v-list dense>
          <v-list-item>
            <v-container>
              <v-row>
                <!-- select color -->
                <v-col cols="12" class="mt-3 pa-1">
                  <div class="d-flex justify-space-between align-center">
                    <div class="text--primary font-weight-extrabold">Colors</div>
                    <div class="selectbrand">
                      <input type='checkbox' @click='colorscheckAll()' v-model='colorsCheckAll' id="colorscheck"
                        class="d-none">
                      <label for="colorscheck">
                        <span class="text--primary inactive text-caption font-weight-bold">Select all</span>
                        <span class="red--text active text-caption font-weight-bold">Deselect all</span>
                      </label>
                    </div>
                  </div>

                  <!-- Colors list -->

                  <div class="d-flex justify-start flex-wrap mt-2 max-ftr-h rounded-md">
                    <!-- Checkboxes list -->
                    <div v-for="lang in colorfilter" :key="lang.title" class="filter-colors">
                      <v-checkbox type='checkbox' v-bind:value='lang' v-model='colors'
                        class="mt-1 d-inline-block d-none" hide-details off-icon="mdi-none">
                        <template v-slot:label>
                          <div class="colors-list" v-bind:style="{ 'background-color': lang }">
                          </div>
                        </template>
                      </v-checkbox>
                    </div>
                  </div>
                </v-col>

                <!-- Sizes -->
                <v-col cols="12" class="mt-4 pa-1">
                  <div class="d-flex justify-space-between align-center">
                    <div class="text--primary font-weight-extrabold">Sizes</div>
                    <div class="selectbrand">
                      <input type='checkbox' @click='sizescheckAll()' v-model='issizescheckAll' id="sizescheck"
                        class="d-none">
                      <label for="sizescheck">
                        <span class="text--primary inactive text-caption font-weight-bold">Select all</span>
                        <span class="red--text active text-caption font-weight-bold">Deselect all</span>
                      </label>
                    </div>
                  </div>

                  <!-- Sizes list -->
                  <div class="d-flex justify-start flex-wrap mt-2 max-ftr-h rounded-md">
                    <!-- Checkboxes list -->
                    <div v-for="item in sizefilter" :key="item.title" class="sizes-chk">
                      <v-checkbox type='checkbox' v-bind:value='item' v-model='size' class="mt-1 d-inline-block"
                        hide-details off-icon="mdi-none">
                        <template v-slot:label>
                          <div class="colors-list text-uppercase text-overline font-weight-bold">
                            {{ item }}
                          </div>
                        </template>
                      </v-checkbox>
                    </div>
                  </div>
                </v-col>

                <!-- Price Range -->
                <v-col cols="12" class="mt-4 d-none">
                  <div>
                    <v-subheader class="px-0 text--primary font-weight-extrabold text-body-1 mb-4">Price range
                    </v-subheader>
                    <v-range-slider v-model="range" :max="max" :min="min" class="align-center" hide-details dense>
                      <template v-slot:prepend>
                        <div class="d-flex align-center">
                          <span class="font-weight-bold">$</span>
                          <v-text-field :value="range[0]" class="ma-0 pa-0 cart-qty" hide-details single-line
                            type="number" style="width: 20px" @change="$set(range, 0, $event)" dense></v-text-field>
                        </div>
                      </template>
                      <template v-slot:append>
                        <div class="d-flex align-center">
                          <span class="font-weight-bold">$</span>
                          <v-text-field :value="range[1]" class="ma-0 pa-0 cart-qty  " hide-details single-line
                            type="number" style="width: 20px" @change="$set(range, 1, $event)" dense></v-text-field>
                        </div>
                      </template>
                    </v-range-slider>
                  </div>
                </v-col>

                <!-- Brands -->
                <v-col cols="12" class="mt-4 pa-1">
                  <!-- Check All -->
                  <div class="d-flex justify-space-between align-center">
                    <div class="text--primary font-weight-extrabold">Brands</div>
                    <div class="selectbrand">
                      <input type='checkbox' @click='checkAll()' v-model='isCheckAll' id="brandscheck" class="d-none">
                      <label for="brandscheck">
                        <span class="text--primary inactive text-caption font-weight-bold">Select all</span>
                        <span class="red--text active text-caption font-weight-bold">Deselect all</span>
                      </label>
                    </div>
                  </div>
                  <!-- Checkboxes list -->
                  <div v-for="lang in brandfilter" :key="lang.title">
                    <v-checkbox type='checkbox' v-bind:value='lang.id' v-model='brand' class="mt-1" hide-details>
                      <template v-slot:label>
                        <div class="text--primary font-weight-extramedium text-body-2">
                          {{ lang.title }}
                        </div>
                      </template>
                    </v-checkbox>
                  </div>
                </v-col>
              </v-row>
              <!-- <v-btn @click="applyFilter">Apply</v-btn> -->
            </v-container>
          </v-list-item>
        </v-list>

        <template v-slot:append>
          <v-divider></v-divider>
          <div class="pa-2">
            <v-btn @click="applyFilter" class="text-none py-6 rounded-sm" color="primary" block>Apply</v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'ProductsFilters',

  data() {
    return {
      filters: null,
      filtersapplies: true,
      // price range
      min: 0,
      max: 90,
      range: [10, 70],
      // brands
      isCheckAll: false,
      // brandfilter: ["American Apparel", "Asics", "Bella + Canvas", "Being Atheletic", "Calvin Klien", "Carrier"],
      brand: this.$store.getters.getSelectedBrand,

      // colors
      colorsCheckAll: false,
      // colorfilter: ["#000000", "#FFFFFF", "#9C071A", "#263147", "#274C97", "#9A978E", "#FFB095", "#CDE5FA", "#343235", "#66838D", "#BF6B92", "#B26C9B"],
      colors: this.$store.getters.getSelectedColors,

      // sizes
      issizescheckAll: false,
      // sizefilter: ["xs", "sm", "md", "lg", "xl", "2xl", "3xl", "4xl", "5xl",],
      size: this.$store.getters.getSelectedSizes,

    };
  },
  computed: {
    colorfilter() {
      if (this.$store.getters.colors) {
        return this.$store.getters.colors
      } else {
        return []
      }
    },

    brandfilter() {
      if (this.$store.getters.brands) {
        return this.$store.getters.brands
      } else {
        return []
      }
    },

    sizefilter() {
      if (this.$store.getters.sizes) {
        return this.$store.getters.sizes
      } else {
        return []
      }
    }
  },
  methods: {
    applyFilter() {
      let filters = {
        colors: this.colors,
        size: this.size,
        // range: this.range,
        apply_filter: true,
        brand: this.brand,
      }
      this.$store.commit('updateFilters', filters)
      this.$emit('filterProduct', filters)
    },

    clearFilter() {
      this.brand = [];
      this.colors = [];
      this.size = [];
      let filters = {
        colors: this.colors,
        size: this.size,
        // range: this.range,
        apply_filter: true,
        brand: this.brand,
      }
      this.$store.commit('updateFilters', filters)
      this.$emit('filterProduct', filters)
    },
    // For brands filter
    checkAll: function () {

      this.isCheckAll = !this.isCheckAll;
      this.brand = [];
      if (this.isCheckAll) { // Check all
        for (var key in this.brandfilter) {
          this.brand.push(this.brandfilter[key].id);
        }
      }
    },

    // Colors filter
    colorscheckAll: function () {

      this.colorsCheckAll = !this.colorsCheckAll;
      this.colors = [];
      if (this.colorsCheckAll) { // Check all
        for (var key in this.colorfilter) {
          this.colors.push(this.colorfilter[key]);
        }
      }
    },

    sizescheckAll: function () {

      this.issizescheckAll = !this.issizescheckAll;
      this.size = [];
      if (this.issizescheckAll) { // Check all
        for (var key in this.sizefilter) {
          this.size.push(this.sizefilter[key]);
        }
      }
    },
  },

  created() {
    this.$root.$on('clearFilter', () => {
      this.colors = []
      this.size = []
      this.brand = []
    })
  }
}
</script>

<style scoped>
.max-ftr-h {
  max-height: 140px;
  overflow-y: auto;
}

@media (min-width: 960px) {
  /* .floating-btn {
    bottom: 2%;
  } */
}

@media (max-width: 600px) {
  .floating-btn {
    bottom: 15%;
  }
}
</style>
