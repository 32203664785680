<template>
  <v-app>
    <v-container fluid class="mt-8">
      <v-row class="px-lg-4">
        <v-col cols="12" sm="12" md="8" lg="8">
          <!-- Cart items -->
          <CartItems />

          <!-- Uplad files -->
          <!-- <UploadDesignFile /> -->

          <!-- Contact info -->
          <ContactDetails />
        </v-col>

        <v-col cols="12" sm="12" md="4" lg="4">
          <!-- Order summery -->
          <OrderSummary />
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import CartItems from "@/components/CartItems"
// import UploadDesignFile from "./components/UploadDesignFile"
import ContactDetails from "./components/ContactDetails"
import OrderSummary from "./components/OrderSummary"

export default {
  name: 'Cart-page',
  components: {
    CartItems,
    // UploadDesignFile,
    ContactDetails,
    OrderSummary
  },
  mounted() { },
  computed: {},
  data() {
    return {

    }
  },
  methods: {},

  created() {
    if (!this.$store.state.cart?.box_products || this.$store.state.cart?.box_products?.length == 0) {
      this.$router.push({ name: 'CartEmpty' })
    }
  }
}
</script>

<style scoped>

</style>
