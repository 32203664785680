<template>
    <v-app>
        <v-container fluid class="px-0">
            <template v-if="loading">
                <Loader />
            </template>
            <template v-if="!loading">
                <v-row>
                    <v-col cols="12" sm="12" md="5" lg="4" class="text-left mt-4">
                        <!-- Title -->
                        <div class="d-flex">
                            <v-btn @click="$router.go(-1)" class="mx-2" fab x-small depressed>
                                <v-icon dark>mdi-chevron-left</v-icon>
                            </v-btn>
                            <div class="d-flex align-center mb-1 flex-wrap">
                                <h1 class="text-h6 font-weight-bold primary--text d-inline-block mr-1">The Silo Bottle - 16.9 oz. x 70</h1>
                                <v-chip class="ma-1 primary--text font-weight-extramedium blue-grey lighten-5 pa-2" small>Bulk item</v-chip>
                                <v-chip class="ma-1 green--text text--lighten-3 font-weight-extramedium light-green lighten-5 pa-2" small>Shipped</v-chip>
                            </div>
                        </div>

                        <div class="d-flex my-4">
                            <!-- Product images -->
                            <div class="px-4 text-center">
                                <v-carousel v-model="onboarding" hide-delimiters :show-arrows="true" height="auto">
                                    <!-- Arrows -->
                                    <template v-slot:prev="{ on, attrs }">
                                        <v-btn color="grey" v-bind="attrs" v-on="on" min-width="6px" max-height="6px" class="pa-1 rounded-circle">
                                            <v-icon small>mdi-chevron-left</v-icon>
                                        </v-btn>
                                    </template>

                                    <!-- main Images -->
                                    <v-carousel-item v-for="item in slider" :key="item.src">
                                        <div class="shippment-slider">
                                            <!-- <v-img mg :src="item.src" contain height="244px" width="244px"></v-img> -->
                                            <NwImg :src="item.src" alt="Example Alt Text" class="img-fluid" />
                                        </div>
                                    </v-carousel-item>

                                    <template v-slot:next="{ on, attrs }">
                                        <v-btn color="grey" v-bind="attrs" v-on="on" min-width="6px" max-height="6px" class="pa-1 rounded-circle">
                                            <v-icon small>mdi-chevron-right</v-icon>
                                        </v-btn>
                                    </template>
                                </v-carousel>

                                <!-- Cancel shipment btn -->
                                <v-btn outlined color="red" class="my-2 rounded-sm text-none font-weight-bold py-6">Cancel shipment</v-btn>
                            </div>

                            <!-- Information -->
                            <div class="ml-3">
                                <!-- Order No. -->
                                <div class="mb-3">
                                    <div class="caption grey--text font-weight-bold">Order No.</div>
                                    <div class="text-body-1 font-weight-extramedium">SWAG132168</div>
                                    <div class="primary--text text-body-2 font-weight-bold">Order Details
                                        <v-icon small class="primary--text">mdi-chevron-right</v-icon>
                                    </div>
                                </div>

                                <!-- Item -->
                                <div class="mb-3">
                                    <div class="caption grey--text font-weight-bold">Item</div>
                                    <div class="text-body-1 font-weight-extramedium">Custom Mailer box</div>
                                </div>

                                <!-- Color -->
                                <div class="mb-3">
                                    <div class="caption grey--text font-weight-bold">Color</div>
                                    <div class="text-body-1 font-weight-extramedium">Full color</div>
                                </div>

                                <!-- Units shipped -->
                                <div class="mb-3">
                                    <div class="caption grey--text font-weight-bold">Units shipped</div>
                                    <div class="text-body-1 font-weight-extramedium">10</div>
                                </div>

                                <!-- Shipment Date -->
                                <div class="mb-3">
                                    <div class="caption grey--text font-weight-bold">Shipment Date</div>
                                    <div class="text-body-1 font-weight-extramedium">Sep, 24, 2022</div>
                                </div>
                            </div>
                        </div>
                    </v-col>

                    <v-divider vertical />

                    <!-- SHOW When recepents list empty STRAT -->
                    <v-col v-if="true" cols="12" sm="12" md="7" lg="8" class="mt-4">
                        <div class="py-16">
                            <div class="py-md-16">
                                <img src="@/assets/images/svg/notes-card.svg" class="img-fluid">
                                <div class="primary--test font-weight-bold mb-3">Your items are stored in warehouse ready to shipped</div>
                                <v-btn color="primary" class="py-6 rounded-sm font-weight-bold text-none">Ship to contacts</v-btn>
                            </div>
                        </div>
                    </v-col>
                    <!-- SHOW When recepents list empty END -->

                    <!-- Show when recepnts availabe START -->
                    <v-col v-else cols="12" sm="12" md="7" lg="8" class="mt-4">
                        <div class="text-h6 font-weight-bold primary--text mb-6 text-left">Recipients</div>

                        <!-- Karen M. -->
                        <v-card elevation="2" class="mb-10 rounded-lg">
                            <v-row class="mx-0 justify-space-between">
                                <!-- Recipients details -->
                                <v-col cols="12" sm="4" md="4" class="text-left">
                                    <div class="d-flex align-center align-sm-start">
                                        <!-- Recipients image -->
                                        <v-avatar color="primary" size="48">
                                            <span class="white--text text-body-1 font-weight-bold text-uppercase">KM</span>
                                        </v-avatar>
                                        <!-- Recipients name, email, and preferences -->
                                        <div class="ml-2 text-body-2 d-flex flex-sm-column flex-wrap">
                                            <div class="black--text mb-1 mr-4 mr-sm-0">Karen M.</div>
                                            <div class="grey--text text--darken-1 mb-1 mr-4 mr-sm-0">karen@alphalete.com</div>
                                            <div class="grey--text text--darken-1 mb-1 mr-4 mr-sm-0">Shirt size: SM</div>
                                        </div>
                                    </div>
                                </v-col>

                                <!-- Recipients Shipping Address -->
                                <v-col cols="6" sm="4" md="4" class="text-left pl-sm-16">
                                    <div class="pl-lg-10">
                                        <div class="caption font-weight-bold grey--text text--darken-1 mb-1">Shipping Address</div>
                                        <div class="text-body-2 font-weight-extramedium">
                                            <div>89 Williams St.</div>
                                            <div>Birmingham, AL 35209</div>
                                            <div>United States</div>
                                            <div>(253) 644-2182</div>
                                        </div>
                                    </div>
                                </v-col>

                                <!-- Shipment/Tracking No -->
                                <v-col cols="6" sm="4" md="4" class="text-left text-sm-right">
                                    <div>
                                        <div class="caption font-weight-bold grey--text text--darken-1 mb-1">Shipment/Tracking No,</div>
                                        <div class="text-body-1 font-weight-extramedium">RA989499275US</div>
                                        <div class="caption font-weight-bold grey--text text--darken-1 mb-1">USPS</div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>

                        <!-- Frank K. -->
                        <v-card elevation="2" class="mb-10 rounded-lg">
                            <v-row class="mx-0 justify-space-between">
                                <!-- Recipients details -->
                                <v-col cols="12" sm="4" md="4" class="text-left">
                                    <div class="d-flex align-center align-sm-start">
                                        <!-- Recipients image -->
                                        <v-avatar color="primary" size="48">
                                            <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="Frank K.">
                                        </v-avatar>
                                        <!-- Recipients name, email, and preferences -->
                                        <div class="ml-2 text-body-2 d-flex flex-sm-column flex-wrap">
                                            <div class="black--text mb-1 mr-4 mr-sm-0">Frank K.</div>
                                            <div class="grey--text text--darken-1 mb-1 mr-4 mr-sm-0">frank@alphalete.com</div>
                                            <div class="grey--text text--darken-1 mb-1 mr-4 mr-sm-0">Shirt size: LG</div>
                                        </div>
                                    </div>
                                </v-col>

                                <!-- Recipients Shipping Address -->
                                <v-col cols="6" sm="4" md="4" class="text-left pl-sm-16">
                                    <div class="pl-lg-10">
                                        <div class="caption font-weight-bold grey--text text--darken-1 mb-1">Shipping Address</div>
                                        <div class="text-body-2 font-weight-extramedium">
                                            <div>5331 Rexford Court,</div>
                                            <div>Montgomery AL 36116</div>
                                            <div>United States</div>
                                            <div>(234) 109-6666</div>
                                        </div>
                                    </div>
                                </v-col>

                                <!-- Shipment/Tracking No -->
                                <v-col cols="6" sm="4" md="4" class="text-left text-sm-right">
                                    <div>
                                        <div class="caption font-weight-bold grey--text text--darken-1 mb-1">Shipment/Tracking No,</div>
                                        <div class="text-body-1 font-weight-extramedium">RA588447995US</div>
                                        <div class="caption font-weight-bold grey--text text--darken-1 mb-1">USPS</div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>

                        <!-- Ria S. -->
                        <v-card elevation="2" class="mb-10 rounded-lg">
                            <v-row class="mx-0 justify-space-between">
                                <!-- Recipients details -->
                                <v-col cols="12" sm="4" md="4" class="text-left">
                                    <div class="d-flex align-center align-sm-start">
                                        <!-- Recipients image -->
                                        <v-avatar color="primary" size="48">
                                            <span class="white--text text-body-1 font-weight-bold text-uppercase">rs</span>
                                        </v-avatar>
                                        <!-- Recipients name, email, and preferences -->
                                        <div class="ml-2 text-body-2 d-flex flex-sm-column flex-wrap">
                                            <div class="black--text mb-1 mr-4 mr-sm-0">Ria S.</div>
                                            <div class="grey--text text--darken-1 mb-1 mr-4 mr-sm-0">karen@alphalete.com</div>
                                            <div class="grey--text text--darken-1 mb-1 mr-4 mr-sm-0">Shirt size: MD</div>
                                        </div>
                                    </div>
                                </v-col>

                                <!-- Recipients Shipping Address -->
                                <v-col cols="6" sm="4" md="4" class="text-left pl-sm-16">
                                    <div class="pl-lg-10">
                                        <div class="caption font-weight-bold grey--text text--darken-1 mb-1">Shipping Address</div>
                                        <div class="text-body-2 font-weight-extramedium">
                                            <div>89 Williams St.</div>
                                            <div>Birmingham, AL 35209</div>
                                            <div>United States</div>
                                            <div>(253) 644-2182</div>
                                        </div>
                                    </div>
                                </v-col>

                                <!-- Shipment/Tracking No -->
                                <v-col cols="6" sm="4" md="4" class="text-left text-sm-right">
                                    <div>
                                        <div class="caption font-weight-bold grey--text text--darken-1 mb-1">Shipment/Tracking No,</div>
                                        <div class="text-body-1 font-weight-extramedium">RA989499275US</div>
                                        <div class="caption font-weight-bold grey--text text--darken-1 mb-1">USPS</div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>

                        <!-- Amanda N. -->
                        <v-card elevation="2" class="mb-10 rounded-lg">
                            <v-row class="mx-0 justify-space-between">
                                <!-- Recipients details -->
                                <v-col cols="12" sm="4" md="4" class="text-left">
                                    <div class="d-flex align-center align-sm-start">
                                        <!-- Recipients image -->
                                        <v-avatar color="primary" size="48">
                                            <span class="white--text text-body-1 font-weight-bold text-uppercase">an</span>
                                        </v-avatar>
                                        <!-- Recipients name, email, and preferences -->
                                        <div class="ml-2 text-body-2 d-flex flex-sm-column flex-wrap">
                                            <div class="black--text mb-1 mr-4 mr-sm-0">Amanda N.</div>
                                            <div class="grey--text text--darken-1 mb-1 mr-4 mr-sm-0">amanda@alphalete.com</div>
                                            <div class="grey--text text--darken-1 mb-1 mr-4 mr-sm-0">Shirt size: SM</div>
                                        </div>
                                    </div>
                                </v-col>

                                <!-- Recipients Shipping Address -->
                                <v-col cols="6" sm="4" md="4" class="text-left pl-sm-16">
                                    <div class="pl-lg-10">
                                        <div class="caption font-weight-bold grey--text text--darken-1 mb-1">Shipping Address</div>
                                        <div class="text-body-2 font-weight-extramedium">
                                            <div>89 Williams St.</div>
                                            <div>Birmingham, AL 35209</div>
                                            <div>United States</div>
                                            <div>(253) 644-2182</div>
                                        </div>
                                    </div>
                                </v-col>

                                <!-- Shipment/Tracking No -->
                                <v-col cols="6" sm="4" md="4" class="text-left text-sm-right">
                                    <div>
                                        <div class="caption font-weight-bold grey--text text--darken-1 mb-1">Shipment/Tracking No,</div>
                                        <div class="text-body-1 font-weight-extramedium">RA681683025US</div>
                                        <div class="caption font-weight-bold grey--text text--darken-1 mb-1">USPS</div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>

                        <!-- Matt Dough -->
                        <v-card elevation="2" class="mb-10 rounded-lg">
                            <v-row class="mx-0 justify-space-between">
                                <!-- Recipients details -->
                                <v-col cols="12" sm="4" md="4" class="text-left">
                                    <div class="d-flex align-center align-sm-start">
                                        <!-- Recipients image -->
                                        <v-avatar color="primary" size="48">
                                            <img src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460" alt="Matt Dough">
                                        </v-avatar>
                                        <!-- Recipients name, email, and preferences -->
                                        <div class="ml-2 text-body-2 d-flex flex-sm-column flex-wrap">
                                            <div class="black--text mb-1 mr-4 mr-sm-0">Matt Dough</div>
                                            <div class="grey--text text--darken-1 mb-1 mr-4 mr-sm-0">mattdough@alphalete.com</div>
                                            <div class="grey--text text--darken-1 mb-1 mr-4 mr-sm-0">Shirt size: XL</div>
                                        </div>
                                    </div>
                                </v-col>

                                <!-- Recipients Shipping Address -->
                                <v-col cols="6" sm="4" md="4" class="text-left pl-sm-16">
                                    <div class="pl-lg-10">
                                        <div class="caption font-weight-bold grey--text text--darken-1 mb-1">Shipping Address</div>
                                        <div class="text-body-2 font-weight-extramedium">
                                            <div>89 Williams St.</div>
                                            <div>Birmingham, AL 35209</div>
                                            <div>United States</div>
                                            <div>(253) 644-2182</div>
                                        </div>
                                    </div>
                                </v-col>

                                <!-- Shipment/Tracking No -->
                                <v-col cols="6" sm="4" md="4" class="text-left text-sm-right">
                                    <div>
                                        <div class="caption font-weight-bold grey--text text--darken-1 mb-1">Shipment/Tracking No,</div>
                                        <div class="text-body-1 font-weight-extramedium">RA069858354US</div>
                                        <div class="caption font-weight-bold grey--text text--darken-1 mb-1">USPS</div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <!-- Show when recepnts availabe END -->
                </v-row>
            </template>
        </v-container>
    </v-app>
</template>

<script>
    import Loader from "@/components/Loader"
    import NwImg from "@/components/NwImg"
    export default {
        name: 'ShipmentDetails',
        components: {
            Loader,
            NwImg
        },
        data() {
            return {
                loading: false,
                onboarding: 0,
                slider: [{
                        src: require('@/assets/images/speedup-socks-and-stickers.png')
                    },
                    {
                        src: require('@/assets/images/classics-pack.png')
                    },
                    {
                        src: require('@/assets/images/cozy-and-calm.png')
                    },
                    {
                        src: require('@/assets/images/happy-camper.png')
                    },
                    {
                        src: require('@/assets/images/the-chief-swag-officer.png')
                    },
                    {
                        src: require('@/assets/images/the-outdoor-enthusiast.png')
                    },
                    {
                        src: require('@/assets/images/the-rebrand-plus.png')
                    },
                    {
                        src: require('@/assets/images/the-road-warrior.png')
                    },
                ]

            }
        },

        mounted() {},
        computed: {},
        methods: {},
        created() {}
    }
</script>

<style scoped>
    .v-card.v-card--link:hover {
        transform: scale(1.004) !important;
    }

    .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child,
    .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr>td:not(.v-data-table__mobile-row) {
        padding: 8px;
        vertical-align: top;
        font-size: 13px;
        height: auto;
        /* border-bottom: 1px solid rgba(0, 0, 0, .12); */
    }

    .shippment-slider {
        border: 1px solid #d1d1d1;
        overflow: hidden;
    }

    .shippment-slider,
    .shippment-slider img {
        width: 244px;
        height: 244px;
    }

    .shippment-slider img {
        object-fit: contain;
    }

    @media (max-width: 460px) {

        .shippment-slider,
        .shippment-slider img {
            width: 180px;
            height: 180px;
        }
    }
</style>
