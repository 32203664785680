<template>
    <v-app>
        <v-container fluid>
            <h1>Dashboard</h1>
        </v-container>
    </v-app>
</template>
  
<script>
export default {
    name: 'DashboardCustomer',
    components: {
    },
    data() {
        return {
        }
    },

    mounted() { },
    computed: {
    },
    methods: {
    },
    created() {
    }
}
</script>
  
<style scoped>
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child,
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row) {
    border-bottom: 0px;
}
</style>
  