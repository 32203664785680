<template>
  <v-app>
    <div class="d-flex justify-center align-center primary overflow-auto" style="min-height:100vh;">
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-container>
        <v-row class="justify-center my-10">
          <v-col cols="12" sm="8" md="6" lg="5" xl="4">
            <v-card elevation="3" rounded="lg">
              <div class="py-6">
                <v-btn :to="{ name: 'Home', }" class="transparent" text>
                  <v-img :src="require('@/assets/images/swagbox-logo.png')" max-width="100%" height="auto" width="200">
                  </v-img>
                </v-btn>
              </div>
              <v-divider></v-divider>
              <div class="py-4 px-4">
                <div class="text-body-1 text--primary font-weight-black text-uppercase">Forgot password?</div>
                <div class="text-body-2 text--primary font-weight-extrabold text-uppercase">No sweat! We got you.</div>
                <validation-observer ref="observer">
                  <v-form @submit="forgotPassword">
                    <v-container>
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-subheader class="px-0 text--primary font-weight-bold">Email</v-subheader>
                          <validation-provider v-slot="{ errors }" rules="required|email" name="Email">
                            <v-text-field placeholder="john@example.com" v-model="user.email" outlined dense required
                              :error-messages="errors" :error="errors.length !== 0" :hide-details="errors.length <= 0">
                            </v-text-field>
                          </validation-provider>
                          <div class="text-left my-2 text-body-2 mb-0">We'll email you a link to reset your password.</div>
                        </v-col>
                        <v-col cols="12" class="py-1">
                          <vue-recaptcha :sitekey="reCaptchaSiteKey" @verify="verifyMethod">
                          </vue-recaptcha>
                        </v-col>
                        <v-col cols="12" class="py-1">
                          <v-btn class="font-weight-bold text-none rounded-sm py-6" type="submit" color="primary"
                            width="100%">Send
                            reset link</v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </validation-observer>
              </div>
              <v-divider></v-divider>
              <div class="py-2 text-body-1 font-weight-extramedium">
                <v-btn text class="text--primary font-weight-extrabold pa-2" :to="{ name: 'LoginPage', }">Back to log in
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

  </v-app>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
export default {
  name: 'ForgotPassword',
  components: {
    VueRecaptcha
  },
  mounted() { },
  computed: {},
  data() {
    return {
      user: {
        email: null,
        captchaToken: null
      },
      reCaptchaSiteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
      loading: false,
      captchaVerified: false
    }
  },
  methods: {
    verifyMethod(token) {
      this.user.captchaToken = token
      this.captchaVerified = true
    },

    forgotPassword(e) {
      e.preventDefault()
      if (!this.captchaVerified) {
        alert('Please confirm captcha to proceed');
        return
      }

      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }
        this.loading = true
        this.$store
          .dispatch('FORGOT_PASSWORD', this.user)
          .then(
            response => {
              console.log(response)
              this.loading = false
              this.$store.commit("showAlert", {
                text: response.message,
                successStatus: true,
              });
              this.$router.push({ name: 'Home' })
            },
            error => {
              this.loading = false
              this.$store.commit("showAlert", {
                text: error.data.message,
                successStatus: false,
                alertColor: 'red darken-4'
              });
              throw error
            }
          )
          .catch(error => {
            this.loading = false
            if (error.data.errors)
              this.$refs.observer.setErrors(error.data.errors)
          })
      });
    }
  }
}
</script>

<style scoped>
.theme--light.v-btn--active:before,
.theme--light.v-btn--active:hover:before {
  opacity: 0;
}
</style>
