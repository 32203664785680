<template>
    <v-app>
        <v-divider></v-divider>

        <v-row class="my-1">
            <v-col cols="12" lg="3">
                <v-text-field placeholder="Search Orders" outlined dense hide-details required v-model="keyword"
                    v-on:keyup.enter="updateOrders" prepend-inner-icon="mdi-magnify">
                </v-text-field>
            </v-col>
            <v-col cols="12" lg="2">
                <v-select outlined dense hide-details :items="RequestSortBy" @change="updateOrders"
                    v-model="RequestSortSelected">
                    <template v-slot:prepend-inner>
                        <div class="text-no-wrap py-1">Sort by:</div>
                    </template>
                </v-select>
            </v-col>
            <v-col cols="12" lg="2" class="d-none">
                <v-select outlined dense hide-details :items="RequestStatus" @change="updateOrders"
                    v-model="RequestStatusSelected">
                    <template v-slot:prepend-inner>
                        <div class="text-no-wrap py-1">Status:</div>
                    </template>
                </v-select>
            </v-col>
        </v-row>
    </v-app>
</template>

<script>
export default {
    name: 'OrderFilters',
    data() {
        return {
            RequestSortSelected: 'Newest First',
            RequestSortBy: ['Newest First', 'Oldest First',],
            RequestStatusSelected: 'All',
            RequestStatus: ['All', 'Artwork not submitted', 'Proofs ready', 'Ready for Production',],
            keyword: null,
        }
    },
    mounted() { },
    computed: {},
    methods: {
        updateOrders() {
            let data = {
                sort_by: this.RequestSortSelected,
                status: this.RequestStatusSelected,
                keyword: this.keyword
            }
            this.$emit('updateFilters', data)
        }
    },
    created() { }
}
</script>

<style scoped>
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child,
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row) {
    border-bottom: 0px;
}
</style>
