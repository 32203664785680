import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

// const opts = {}

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  font: {
    family: "Nunito Sans",
  },
  theme: {
    themes: {
      light: {
        primary: "#071A52",
        secondary: "#424242",
        green: "#1b5e20",
      },
    },
  },
})
