<template>
  <v-app class="mt-6">
    <!-- <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay> -->
    <validation-observer ref="observer">
      <v-form ref="form">
        <v-container>
          <!-- Billing Details -->

          <v-row class="mb-3">
            <v-col cols="12">
              <div class="text-h5 text--primary text-left font-weight-bold">Billing Details</div>
            </v-col>
            <v-col cols="12" md="6">
              <v-subheader class="px-0 text--primary font-weight-bold text-body-2">Card Holder Name
              </v-subheader>
              <validation-provider v-slot="{ errors }" rules="required" name="Card Holder Name">
                <v-text-field placeholder="Brian Griffin" v-model="card.card_holder_name" :error-messages="errors"
                  :error="errors.length !== 0" :hide-details="errors.length <= 0" outlined dense required>
                </v-text-field>
              </validation-provider>
            </v-col>
            <!-- Card number * -->
            <v-col cols="12" md="6">
              <v-subheader class="px-0 text--primary font-weight-bold text-body-2">Card Number *</v-subheader>
              <validation-provider v-slot="{ errors }" rules="required|exact_length:19" name="Card Number">
                <v-text-field type="text" placeholder="16-digit number" v-mask="'#### #### #### ####'"
                  v-model="card.card_number" :error-messages="errors" :error="errors.length !== 0"
                  :hide-details="errors.length <= 0" outlined dense required></v-text-field>
              </validation-provider>
            </v-col>
            <!-- Expiry * -->
            <v-col cols="6">
              <v-subheader class="px-0 text--primary font-weight-bold text-body-2">Card Expiry Date *
              </v-subheader>
              <validation-provider v-slot="{ errors }" rules="required|date_format_month_year|future_month|future_year"
                name="Card Expiry Date">
                <v-text-field type="text" placeholder="MM / YYYY" v-model="card.card_expiry"
                  @input="formatExpiry(card.card_expiry)" :error-messages="errors" :error="errors.length !== 0"
                  :hide-details="errors.length <= 0" outlined dense required></v-text-field>
              </validation-provider>
            </v-col>
            <!-- CVV / CVC * -->
            <v-col cols="6">
              <v-subheader class="px-0 text--primary font-weight-bold text-body-2">CVV / CVC *</v-subheader>
              <validation-provider v-slot="{ errors }" rules="required|cvv_length" name="CVV / CVC">
                <v-text-field type="number" placeholder="CVV / CVC" v-model="card.card_cvv" :error-messages="errors"
                  :error="errors.length !== 0" :hide-details="errors.length <= 0" outlined dense required>
                </v-text-field>
              </validation-provider>
            </v-col>
          </v-row>

          <!-- Billing Address -->
          <v-row class="mb-4 mb-lg-10">
            <v-col cols="12">
              <div class="text-h5 text--primary text-left font-weight-bold">Billing Address</div>
            </v-col>

            <v-col cols="12" md="6">
              <v-subheader class="px-0 text--primary font-weight-bold text-body-2">Street address *</v-subheader>
              <validation-provider v-slot="{ errors }" rules="required" name="Street address">
                <v-text-field type="text" v-model="billing_address.address_one" :error-messages="errors"
                  :error="errors.length !== 0" :hide-details="errors.length <= 0" placeholder="Street address" outlined
                  dense required>
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="6">
              <v-subheader class="px-0 text--primary font-weight-bold">Street address 2</v-subheader>
              <v-text-field type="text" v-model="billing_address.address_two" placeholder="Street address" hide-details
                outlined dense required>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-subheader class="px-0 text--primary font-weight-bold">State *</v-subheader>
              <validation-provider v-slot="{ errors }" rules="required" name="State">
                <v-autocomplete outlined dense :items="$store.state.us_states" v-model="billing_address.state"
                  item-value="name" item-text="name" :error-messages="errors" :error="errors.length !== 0"
                  :hide-details="errors.length <= 0"></v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="6">
              <v-subheader class="px-0 text--primary font-weight-bold text-body-2">City *</v-subheader>
              <validation-provider v-slot="{ errors }" rules="required" name="City">
                <v-text-field type="text" v-model="billing_address.city" placeholder="Albany" outlined dense
                  :error-messages="errors" :error="errors.length !== 0" :hide-details="errors.length <= 0" required>
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="6">
              <v-subheader class="px-0 text--primary font-weight-bold text-body-2">Zip postal code *</v-subheader>
              <validation-provider v-slot="{ errors }" rules="required" name="Zip postal code">
                <v-text-field type="text" v-model="billing_address.postal_code" placeholder="12084"
                  :error-messages="errors" :error="errors.length !== 0" :hide-details="errors.length <= 0" outlined
                  dense required></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <v-subheader class="px-0 text--primary font-weight-bold">Phone number</v-subheader>
              <!-- <validation-provider v-slot="{ errors }" rules="required" name="Zip postal code">
                <vue-phone-number-input v-model="billing_address.phone_number" :default-country-code="'US'"
                  :error-messages="errors" :error="errors.length !== 0" :hide-details="errors.length <= 0" no-example
                  :translations="{phoneNumberLabel: null}">
                </vue-phone-number-input>
              </validation-provider> -->
              <validation-provider v-slot="{ errors }" rules="required|limit_phone:16" name="Phone Number">
                <v-text-field placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'"
                  v-model="billing_address.phone_number" :error-messages="errors" :error="errors.length !== 0"
                  :hide-details="errors.length <= 0" outlined dense required>
                </v-text-field>
              </validation-provider>
              <!-- @update="updatePhone" -->
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </validation-observer>
  </v-app>
</template>

<script>
export default {
  name: 'BillingDetails',
  components: {},
  props: {
    order: {
      type: Object,
      default: function () {
        return {}
      },
      required: true
    }
  },
  mounted() { },
  computed: {},
  data() {
    return {
      billing_address: {
        address_one: null,
        address_two: null,
        city: null,
        postal_code: null,
        state: null,
        phone_number: null,
      },
      card: {
        card_holder_name: null,
        card_number: null,
        card_expiry: null,
        card_cvv: null,
        phone_number: null,
        phone_data: {},
      },
    }
  },

  methods: {
    formatExpiry(cardExpiry) {
      if (cardExpiry.length == 3) {
        let thirdCharacter = this.card.card_expiry.substring(2, 3)
        if (thirdCharacter != '/') {
          let exp = this.card.card_expiry.substring(0, 2) + '/' + this.card.card_expiry.substring(2, this.card.card_expiry.length);
          this.card.card_expiry = exp
        }
      }
    },

    validatePaymentForm() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }


        this.$root.$emit('showLoading', { loaderStatus: true });
        let formData = new FormData();

        Object.keys(this.card).forEach(key => {
          formData.append(key, this.card[key])
        })

        formData.append('cart_price', this.order.details.total_price)
        formData.append('billing_address', JSON.stringify(this.billing_address))
        formData.append('order_id', this.order.id)
        this.$store
          .dispatch('CHARGE_PAYMENT', formData)
          .then(
            response => {
              console.log(response)
              this.$root.$emit('showLoading', { loaderStatus: false });
              this.$router.push({ name: 'ThankYou' })
            },
            error => {
              this.$root.$emit('showLoading', { loaderStatus: false });
              this.$store.commit("showAlert", {
                text: error.data.message,
                successStatus: false,
                alertColor: 'red darken-4'
              });
              throw error
            }
          )
          .catch(error => {
            this.$root.$emit('showLoading', { loaderStatus: false });
            if (error.data.errors)
              this.$refs.observer.setErrors(error.data.errors)
          })
      });
    }
  },

  created() {
    this.$root.$on('validatePaymentForm', this.validatePaymentForm);
    this.billing_address.address_one = this.order.details.billing_address?.address1 ? this.order.details.billing_address?.address1 : null
    this.billing_address.address_two = this.order.details.billing_address?.address2 ? this.order.details.billing_address?.address2 : null
    this.billing_address.city = this.order.details.billing_address?.city ? this.order.details.billing_address?.city : null
    this.billing_address.postal_code = this.order.details.billing_address?.zip ? this.order.details.billing_address?.zip : null
    this.billing_address.state = this.order.details.billing_address?.state ? this.order.details.billing_address?.state : 'New York'
    this.billing_address.phone_number = this.order.details.billing_address?.phone_number ? this.order.details.billing_address?.phone_number : null
  }
}
</script>

<style scoped>
.v-subheader {
  height: 30px;
}

.input-tel__label {
  display: none !important;
}

.v-text-field__details {
  margin-bottom: 0 !important;
  min-height: 0px !important;
}

.v-input__control .v-messages {
  min-height: 0px !important;
}

.v-text-field--outlined>>>fieldset {
  border-color: rgba(7, 26, 82, 1) !important;
  border-radius: 2px !important;
}
</style>
