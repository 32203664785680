<template>
  <v-app>
    <v-card class="elevation-4 mb-10 pa-4 d-none">
      <div class="d-flex justify-space-between align-center">
        <div class="font-weight-bold text-left">Contact Details: {{
          $store.getters.getCustomerInfo.customer_info.first_name
        }}
          {{ $store.getters.getCustomerInfo.customer_info.last_name }}, {{
            $store.getters.getCustomerInfo.customer_info.email
              + ', ' + $store.getters.getCustomerInfo.customer_info.company_name + ', ' +
              $store.getters.getCustomerInfo.customer_info.phone_number
          }}</div>
        <div>
          <v-btn class="font-weight-extrabold py-5 text-none" color="primary" v-if="!editAddress" outlined
            @click="openEditForm(editAddress)">Edit</v-btn>
          <v-btn class="font-weight-extrabold py-5 text-none" color="primary" v-if="editAddress" @click="saveAddress()">
            Save</v-btn>
        </div>
      </div>
      <template v-if="editAddress">
        <v-card class="elevation-4 mb-4 mt-4 pa-4">
          <validation-observer ref="observer">
            <v-form ref="form">
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-subheader class="px-0 text--primary font-weight-bold">First Name *</v-subheader>
                    <validation-provider v-slot="{ errors }" rules="required" name="First Name">
                      <v-text-field placeholder="Adrian" v-model="customerInfo.first_name" :error-messages="errors"
                        :error="errors.length !== 0" :hide-details="errors.length <= 0" outlined dense required>
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-subheader class="px-0 text--primary font-weight-bold">Last Name *</v-subheader>
                    <validation-provider v-slot="{ errors }" rules="required" name="Last Name">
                      <v-text-field placeholder="Baker" v-model="customerInfo.last_name" :error-messages="errors"
                        :error="errors.length !== 0" :hide-details="errors.length <= 0" outlined dense required>
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-subheader class="px-0 text--primary font-weight-bold">Email Address *</v-subheader>
                    <validation-provider v-slot="{ errors }" rules="required|email" name="Email Address">
                      <v-text-field placeholder="adrian@example.com" v-model="customerInfo.email"
                        :error-messages="errors" :error="errors.length !== 0" :hide-details="errors.length <= 0"
                        outlined dense required>
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-subheader class="px-0 text--primary font-weight-bold">Company Name *</v-subheader>
                    <validation-provider v-slot="{ errors }" rules="required" name="Company Name">
                      <v-text-field placeholder="Adrian’s company" v-model="customerInfo.company_name"
                        :error-messages="errors" :error="errors.length !== 0" :hide-details="errors.length <= 0"
                        outlined dense required></v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-subheader class="px-0 text--primary font-weight-bold">Phone Number *</v-subheader>
                    <!-- <validation-provider v-slot="{ errors }" rules="required|max:11" name="Phone Number">
                    <v-text-field placeholder="+1" type="number" v-model="customerInfo.phone_number" :error-messages="errors"
                      :error="errors.length !== 0" :hide-details="errors.length <= 0" outlined dense required>
                      <template slot="prepend-inner">
                        <div class="d-flex align-center">
                          <v-img :src="require('@/assets/images/usa-flag.png')" width="24px"></v-img><span class="ml-2 font-weight-extramedium">+1</span>
                        </div>
                      </template>
                    </v-text-field>
                  </validation-provider> -->

                    <!-- <validation-provider v-slot="{ errors }" rules="required|validate_phone_number" name="Phone Number"> -->
                    <!-- <v-text-field type="text" v-model="phone_number" :error-messages="errors" :error="errors.length !== 0"
                  :hide-details="errors.length <= 0" outlined dense required @input="formatPhoneNumber">
                  <template slot="prepend-inner">
                    <div class="d-flex align-center">
                      <v-img :src="require('@/assets/images/usa-flag.png')" width="24px"></v-img><span
                        class="ml-2 font-weight-extramedium">+1</span>
                    </div>
                  </template>
                </v-text-field> -->
                    <!-- <vue-phone-number-input v-model="phone_number" @update="updateCustomerPhone"
                      :default-country-code="customerInfo.phone_data?.countryCode ? customerInfo.phone_data.countryCode : 'US'"
                      no-example :translations="{phoneNumberLabel: null}"> -->
                    <!-- <template slot="prepend-inner">
                    <div class="d-flex align-center">
                      <v-img :src="require('@/assets/images/usa-flag.png')" width="24px"></v-img><span
                        class="ml-2 font-weight-extramedium">+1</span>
                    </div>
                  </template> -->
                    <!-- </vue-phone-number-input> -->
                    <!-- <div class="v-text-field__details"
                      v-if="customerInfo.phone_data && !customerInfo.phone_data?.isValid">
                      <div class="v-messages theme--light error--text" role="alert">
                        <div class="v-messages__wrapper">
                          <div class="v-messages__message">Invalid Phone Number</div>
                        </div>
                      </div>
                    </div> -->
                    <validation-provider v-slot="{ errors }" rules="required|limit_phone:16" name="Phone Number">
                      <v-text-field placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'"
                        v-model="customerInfo.phone_number" :error-messages="errors" :error="errors.length !== 0"
                        :hide-details="errors.length <= 0" outlined dense required>
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <!-- <v-col cols="12" md="6">
              <v-subheader class="px-0 text--primary font-weight-bold">Address Line 1 *</v-subheader>
              <validation-provider v-slot="{ errors }" rules="required" name="Address Line 1">
                <v-text-field placeholder="Addess 1" v-model="customerInfo.address_one" :error-messages="errors"
                  :error="errors.length !== 0" :hide-details="errors.length <= 0" outlined dense required>
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="12">
              <v-subheader class="px-0 text--primary font-weight-bold">Address Line 2</v-subheader>
              <v-text-field placeholder="Addess 2" hide-details v-model="customerInfo.address_two" outlined dense
                required>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-subheader class="px-0 text--primary font-weight-bold">City *</v-subheader>
              <validation-provider v-slot="{ errors }" rules="required" name="City">
                <v-text-field placeholder="City" v-model="customerInfo.city" :error-messages="errors"
                  :error="errors.length !== 0" :hide-details="errors.length <= 0" outlined dense required>
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="4">
              <v-subheader class="px-0 text--primary font-weight-bold">State *</v-subheader>
              <validation-provider v-slot="{ errors }" rules="required" name="State">
                <v-autocomplete v-model="customerInfo.state" item-value="name" item-text="name" :items="states" outlined
                  dense :error-messages="errors" :error="errors.length !== 0" :hide-details="errors.length <= 0">
                </v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="4">
              <v-subheader class="px-0 text--primary font-weight-bold">Zip code *</v-subheader>
              <validation-provider v-slot="{ errors }" rules="required" name="Postal Code">
                <v-text-field placeholder="Postal Code" v-model="customerInfo.postal_code" :error-messages="errors"
                  :error="errors.length !== 0" :hide-details="errors.length <= 0" outlined dense required>
                </v-text-field>
              </validation-provider>
            </v-col> -->
                </v-row>
              </v-container>
            </v-form>
          </validation-observer>
        </v-card>
      </template>
    </v-card>

    <v-card class="elevation-4 mb-10 pa-4">
      <div class="d-flex justify-space-between align-center">
        <div>

          <div class="font-weight-bold text-left">Contact details:</div>
          <div class="text-left mb-2">{{
            $store.getters.getCustomerInfo.customer_info.first_name + ', ' +
              $store.getters.getCustomerInfo.customer_info.email + ', ' +
              $store.getters.getCustomerInfo.customer_info.company_name + ', ' +
              $store.getters.getCustomerInfo.customer_info.phone_number
          }}</div>
          <div class="font-weight-bold text-left">Shipping details:</div>
          <div class="text-left">{{ shipping_address.address_one +', '+ shipping_address.state +', '+ shipping_address.city +', '+ shipping_address.postal_code }}</div>
        </div>
        <v-btn class="font-weight-extrabold py-5 text-none" color="primary" v-if="!editAddress" outlined
          :to="{path:'/cart'}">Edit</v-btn>
          <!-- @click="openEditForm(editAddress)"  -->
      </div>
    </v-card>


    <!-- How would you like your swagbox to be shipped? -->
    <div class="d-none">
      <div class="text-h5 text--primary text-left font-weight-bold mb-3">How would you like your swagbox to be shipped?
      </div>
      <v-sheet cols="12" class="mb-8">
        <v-tabs v-model="shippingOpt" height="auto" active-class="active-card" fixed-tabs slider-size="2" width="100%"
          class="shiptabs">
          <v-tab class="pa-0 ma-1 ma-lg-2">
            <v-card width="100%" height="100%" class="text-left pa-2 pa-sm-4 elevation-2">
              <div class="text-body-1 text-lg-h6 font-weight-bold mb-2 text-none">Order now, Ship to one address</div>
              <div class="text-body-2 text-lg-body-1 text-none">Your bulk order will all be sent to the address that you
                provide as soon as it’s ready!</div>
            </v-card>
          </v-tab>
          <v-tab class="pa-0 ma-1 ma-lg-1" disabled>
            <v-card width="100%" height="100%" class="text-left pa-2 pa-sm-4 elevation-2">
              <!-- Coming soon -->
              <v-chip color="primary" dense class="light--text font-weight-bold caption text-none py-0 px-3 mb-1"
                style="height: 22px;">Coming soon</v-chip>
              <!-- Coming soon -->
              <div class="text-body-1 text-lg-h6 font-weight-bold mb-2 text-none">Keep inventory, Ship to multiple
                addresses</div>
              <div class="text-body-2 text-lg-body-1 text-none">Send your swagboxes to our warehouse and, and then ship
                to
                individual addresses as needed!</div>
            </v-card>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="shippingOpt">

          <!-- Order now, Ship to one address TAB -->
          <v-tab-item :transition="false">
            <div class="text-left my-3">
              <div class="text-h6 font-weight-bold primary--text mx-sm-2" id="shipping_detail">Shipping details</div>
              <validation-observer ref="observer">
                <v-form>
                  <v-container>
                    <v-row>
                      <!-- <v-col cols="12" md="6">
                    <v-subheader class="px-0 text--primary font-weight-bold">Country</v-subheader>
                    <v-select hide-details dense outlined>

                    </v-select>
                  </v-col> -->
                      <v-col cols="12" md="6">
                        <v-subheader class="px-0 text--primary font-weight-bold">Street address *</v-subheader>
                        <validation-provider v-slot="{ errors }" rules="required" name="Street address">
                          <v-text-field placeholder="Street address" outlined dense required :error-messages="errors"
                            :error="errors.length !== 0" :hide-details="errors.length <= 0" @change="updateShipping"
                            v-model="shipping_address.address_one">
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-subheader class="px-0 text--primary font-weight-bold">Street address 2</v-subheader>
                        <v-text-field placeholder="Street address" hide-details outlined dense required
                          @change="updateShipping" v-model="shipping_address.address_two">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-subheader class="px-0 text--primary font-weight-bold">State *</v-subheader>
                        <validation-provider v-slot="{ errors }" rules="required" name="State">
                          <v-autocomplete outlined dense :items="$store.state.us_states"
                            v-model="shipping_address.state" @change="updateShipping" item-value="name"
                            :error-messages="errors" :error="errors.length !== 0" :hide-details="errors.length <= 0"
                            item-text="name">
                          </v-autocomplete>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-subheader class="px-0 text--primary font-weight-bold">City *</v-subheader>
                        <validation-provider v-slot="{ errors }" rules="required" name="City">
                          <v-text-field placeholder="Albany" outlined dense required :error-messages="errors"
                            :error="errors.length !== 0" :hide-details="errors.length <= 0"
                            v-model="shipping_address.city" @change="updateShipping">
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-subheader class="px-0 text--primary font-weight-bold">Zip postal code *</v-subheader>
                        <validation-provider v-slot="{ errors }" rules="required" name="Zip postal code">
                          <v-text-field placeholder="12084" outlined dense required :error-messages="errors"
                            :error="errors.length !== 0" :hide-details="errors.length <= 0"
                            v-model="shipping_address.postal_code" @change="updateShipping">
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-subheader class="px-0 text--primary font-weight-bold">Phone number *</v-subheader>
                        <!-- <vue-phone-number-input v-model="shipping_address.phone_number" @update="updatePhone"
                      @change="updateShipping"
                      :default-country-code="customerInfo.phone_data?.countryCode ? customerInfo.phone_data.countryCode : 'US'"
                      no-example :translations="{phoneNumberLabel: null}">
                    </vue-phone-number-input> -->
                        <validation-provider v-slot="{ errors }" rules="required|limit_phone:16" name="Phone Number">
                          <v-text-field placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'"
                            :error-messages="errors" :error="errors.length !== 0" :hide-details="errors.length <= 0"
                            v-model="shipping_address.phone_number" outlined dense required>
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </validation-observer>
            </div>
          </v-tab-item>

          <!-- Keep inventory, Ship to multiple addresses TAB -->
          <v-tab-item :transition="false">
            <div class="text-left my-3 dashed-border pa-2 pa-md-3 ma-lg-2">
              <div class="pa-1 mb-2">
                <div class="text-body-1 text-lg-h6 font-weight-bold mb-2 text-none primary--text">There’s some
                  additional
                  cost to hold your inventory in our warehouse</div>
                <div class="text-body-1 primary--text font-weight-extramedium">Read <v-btn text
                    class="text-none pa-0 text-body-1 primary--text font-weight-bold" height="auto">terms & conditions
                  </v-btn> before commiting </div>
              </div>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" sm="4" md="4" lg="4" class="pa-1">
                    <div class="green--text font-weight-bold text-body-1 text--darken-2 mb-2">Keeping Inventory</div>
                    <div class="text-body-2">Lorem Ipsum is simply dummy text of the printing and typesetting
                      industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4" class="pa-1">
                    <div class="green--text font-weight-bold text-body-1 text--darken-2 mb-2">Shipping</div>
                    <div class="text-body-2">Lorem Ipsum is simply dummy text of the printing and typesetting
                      industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4" class="pa-1">
                    <div class="green--text font-weight-bold text-body-1 text--darken-2 mb-2">Packaging</div>
                    <div class="text-body-2">Lorem Ipsum is simply dummy text of the printing and typesetting
                      industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                  </v-col>
                </v-row>
              </v-container>
              <!-- Divider -->
              <v-divider class="my-3"></v-divider>

              <!-- Cost -->
              <v-container>
                <v-row class="justify-end">
                  <v-col cols="12" lg="5" class="pa-1">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td class="font-weight-extramedium text-left px-1">Storage cost of 1st box</td>
                            <td class="text-right font-weight-extrabold px-1">$5.00</td>
                          </tr>
                          <tr>
                            <td class="font-weight-extramedium text-left px-1">Each additional box needed</td>
                            <td class="text-right font-weight-extrabold px-1">$4.50</td>
                          </tr>
                          <tr>
                            <td class="font-weight-extramedium text-left px-1">Additional storage cost after 6 months
                            </td>
                            <td class="text-right font-weight-extrabold px-1">$1.50</td>
                          </tr>
                          <tr>
                            <td class="font-weight-extramedium text-left px-1">Small Item Fee</td>
                            <td class="text-right font-weight-extrabold px-1">$0.25</td>
                          </tr>
                          <tr>
                            <td class="font-weight-extramedium text-left px-1">Medium Item Fee</td>
                            <td class="text-right font-weight-extrabold px-1">$1.00</td>
                          </tr>
                          <tr>
                            <td class="font-weight-extramedium text-left px-1">Large Item Fee</td>
                            <td class="text-right font-weight-extrabold px-1">$3.00</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-sheet>
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'ContactDetails',
  components: {},
  mounted() { },
  computed: {},
  data() {
    return {
      customerInfo: {
        first_name: null,
        last_name: null,
        email: null,
        company_name: null,
        phone_number: null,
        phone_data: {},
      },
      shipping_address: {
        address_one: null,
        address_two: null,
        city: null,
        postal_code: null,
        state: 'New York',
        phone_number: null,
      },
      editAddress: false,
      shippingOpt: null,
    }
  },
  watch: {
    shippingOpt: {
      handler(newValue, oldValue) {
        this.$root.$emit('togglePaylaterButton', newValue)
      },
    }
  },
  methods: {
    updatePhone(e) {
      this.shipping_address.phone_number = e.nationalNumber
      this.$store.commit('updateShippingAddress', this.shipping_address)
    },

    validateForm(e) {
      // console.log('shippingOpt', e)
      if (!this.shippingOpt) {
        this.$refs.observer.validate().then(success => {
          if (!success) {
            this.$vuetify.goTo('#shipping_detail', { offset: 140 })
            return
          }
          this.$root.$emit('shhippingFormValidated', e);
        });

      } else {
        this.$root.$emit('shhippingFormValidated', e);
      }

    },

    // updateCustomerPhone(e) {
    //   this.customerInfo.phone_data = e
    //   this.customerInfo.phone_number = e.nationalNumber
    // },

    updateShipping() {
      this.shipping_address.phone_number = this.customerInfo.phone_number
      this.$store.commit('updateShippingAddress', this.shipping_address)
    },
    openEditForm(edit) {
      if (!edit) {
        this.customerInfo.first_name = this.$store.getters.getCustomerInfo.customer_info.first_name
        this.customerInfo.last_name = this.$store.getters.getCustomerInfo.customer_info.last_name
        this.customerInfo.email = this.$store.getters.getCustomerInfo.customer_info.email
        this.customerInfo.company_name = this.$store.getters.getCustomerInfo.customer_info.company_name
        this.customerInfo.phone_number = this.$store.getters.getCustomerInfo.customer_info.phone_number
        // this.customerInfo.phone_data = this.$store.getters.getCustomerInfo.customer_info.phone_data.formatInternational ? this.$store.getters.getCustomerInfo.customer_info.phone_data : JSON.parse(this.$store.getters.getCustomerInfo.customer_info.phone_data)
        this.phone_number = this.$store.getters.getCustomerInfo.customer_info.phone_number
      }

      this.editAddress = !edit
    },

    getPhoneNumber() {
      if (this.$store.getters.getCustomerInfo.customer_info.phone_data?.formatInternational) {
        return this.$store.getters.getCustomerInfo.customer_info.phone_data?.formatInternational
      } else {
        return JSON.parse(this.$store.getters.getCustomerInfo.customer_info.phone_data).formatInternational
      }
      // console.log('PH', JSON.parse(this.$store.getters.getCustomerInfo.customer_info.phone_data))
      // return this.$store.getters.getCustomerInfo.customer_info.phone_number
    },

    saveAddress() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }
        // if (!this.customerInfo.phone_data || !this.customerInfo.phone_data?.isValid) {
        //   return
        // }

        this.$store.getters.getCustomerInfo.customer_info.first_name = this.customerInfo.first_name
        this.$store.getters.getCustomerInfo.customer_info.last_name = this.customerInfo.last_name
        this.$store.getters.getCustomerInfo.customer_info.email = this.customerInfo.email
        this.$store.getters.getCustomerInfo.customer_info.company_name = this.customerInfo.company_name
        this.$store.getters.getCustomerInfo.customer_info.phone_number = this.customerInfo.phone_number
        // this.$store.getters.getCustomerInfo.customer_info.phone_data = JSON.stringify(this.customerInfo.phone_data)
        this.$store.getters.getCustomerInfo.customer_info.phone_number = this.customerInfo.phone_number
        this.editAddress = !this.editAddress
      });
    }
  },

  created() {
    console.log('this.$store.state.shipping_address', this.$store.state.shipping_address)
    this.shipping_address = this.$store.state.shipping_address
    this.$root.$on('validateShhippingForm', this.validateForm);
  }
}
</script>

<style scoped>
.v-subheader {
  height: 30px;
}

.v-text-field__details {
  margin-bottom: 0 !important;
  min-height: 0px !important;
}

.v-input__control .v-messages {
  min-height: 0px !important;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child,
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row) {
  border-bottom: 0px;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: none !important;
}
</style>
