<template>
    <v-app>
        <v-container fluid v-if="order">
            <v-row class="px-lg-4" v-if="!isPaid">
                <v-col cols="12" sm="12" md="8" lg="8">
                    <!-- Cart items -->
                    <PaymentRequestCart :order="order" />

                    <!-- Billing info -->
                    <BillingDetails :order="order" />
                </v-col>

                <v-col cols="12" sm="12" md="4" lg="4">
                    <!-- Order summery -->
                    <OrderSummary :order="order" />
                </v-col>
            </v-row>
        </v-container>
        <v-container v-if="isPaid">
            <div class="d-flex justify-center align-center overflow-auto" style="min-height:70vh;">
                <div>
                    <div class="text-center">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" class="checkmark mx-auto mb-10">
                        <circle class="path circle" fill="none" stroke="#1b5e20" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                        <polyline class="path check" fill="none" stroke="#1b5e20" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                        </svg>
                    </div>
                    <h1 class="font-weight-black text--primary text-lg-h4">Don't sweat it. <br/>You've already paid for this order.</h1>
                </div>
            </div>
        </v-container>
        <!-- Loader -->
        <div class="loader-full" v-if="loading">
            <v-progress-circular :size="65" :width="3" color="primary" indeterminate>
                <v-img :src="require('@/assets/images/swagbox-icon.png')" contain alt="Swagbox Logo" height="36">
                </v-img>
            </v-progress-circular>
        </div>
        <!-- Loader -->
    </v-app>
</template>

<script>
import PaymentRequestCart from "@/components/PaymentRequestCart"
import BillingDetails from "./components/BillingDetails"
import OrderSummary from "./components/OrderSummary"

export default {
    name: 'RequestPayment',
    components: {
        PaymentRequestCart,
        BillingDetails,
        OrderSummary,
    },
    mounted() { },

    data() {
        return {
            loading: true,
            isPaid: false
        }
    },

    computed: {
        order() {
            return this.$store.getters.order
        }
    },

    methods: {
        showLoading(e) {
            this.loading = e.loaderStatus
        }
    },

    created() {
        this.$root.$on('showLoading', this.showLoading);
        this.$store.dispatch('GET_ORDER', { order_id: this.$route.params.order_id }).then(
            response => {
                console.log('response.order.details', response.order)
                if (response.order.status == 'FULFILLED') {
                    this.isPaid = true
                }

                this.loading = false
            },
            error => {
                if (error.data?.redirect == true) {
                    this.$store.commit("showAlert", {
                        text: error.data.message,
                        successStatus: false,
                        alertColor: 'red darken-4'
                    });
                    this.$router.push({ name: 'Home' })
                }
                this.loading = false
                throw error
            }
        )
    }
}
</script>

<style scoped>

</style>
