<template>
  <div class="" style="height:100%;">
    <v-card class="elevation-0 sticky-top">
      <div class="text-h5 text--primary text-left font-weight-bold mb-3">Order summary</div>
      <v-container>
        <v-row>
          <v-col cols="6">
            <div class="text-left">Number of swagboxes</div>
          </v-col>
          <v-col cols="6">
            <div class="text-right text--primary font-weight-bold">
              {{ $store.getters.getCart.quantity }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <div class="text-left">Cost per swagbox</div>
          </v-col>
          <v-col cols="6">
            <div class="text-right text--primary font-weight-bold">{{
              $helper.functions.costPerSwagbox($store.state.cart?.box_products, $store.getters.getCart.quantity,
                $store.getters.logoPrice, this.$store.getters.getQuantityLimits, numberOfLogos)
            }}</div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <div class="text-left">Sales Tax</div>
          </v-col>
          <v-col cols="6">
            <div class="text-right text--primary font-weight-bold">{{ salesTax.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }) }}</div>
          </v-col>
        </v-row>

        <v-row v-if="inventoryCharges">
          <v-col cols="6">
            <div class="text-left">Warehouse storage cost</div>
          </v-col>
          <v-col cols="6">
            <div class="text-right text--primary font-weight-bold">{{ inventoryCharges }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <div class="text-left">Total</div>
          </v-col>
          <v-col cols="6">
            <div class="text-right text--primary font-weight-black">{{ subTotal }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div>
              <v-btn @click.stop="paynow()" :disabled="!taxCalculated" class="font-weight-bold text-none rounded-sm py-6 my-2" width="100%"
                color="primary">Pay now</v-btn>
              <v-btn @click.stop="payLater()" :disabled="!taxCalculated" class="font-weight-bold text-none rounded-sm py-6 my-2" width="100%"
                color="primary" outlined>I’ll pay later</v-btn>
            </div>
            <div class="my-4 d-none">
              <v-subheader class="px-0 text--primary font-weight-bold text-body-1">Promo code</v-subheader>
              <div class="d-flex flex-row align-center">
                <v-text-field class="rounded-0" placeholder="Enter code" outlined clearable hide-details dense>
                </v-text-field>
                <v-btn class="font-weight-bold text-none rounded-sm ml-2 py-5" color="primary" outlined>Apply</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- Pay later SideBar START -->
    <v-dialog v-model="Paylater" overlay-color="#f1f1f1" max-width="800px" content-class="rounded-xl relative" scrollable>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-btn @click="Paylater = false" color="elevation-0 darken-5 mt-10" fab absolute top right height="40" width="40">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>

      <!-- <v-list-item>
        <v-list-item-content class="text-left">
          <v-list-item-title class="font-weight-bold text--primary text-h6">Pay later</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <!-- Payment Options START -->
      <v-card>
        <v-card-title class="text-h6 font-weight-bold">
          Pay later
        </v-card-title>

        <v-card-text>
          <!-- <v-list-item-content> -->
          <v-container>
            <v-row class="justify-center">
              <v-col cols="12" class="pa-2">
                <h5 class="font-weight-bold text--primary text-h5">Congratulations! You’re eligible to pay later for
                  your order</h5>
                <div>
                  <v-img :src="require('@/assets/images/boxes.png')" alt="Swag boxes" contain width="300"
                    class="img-fluid mx-auto"></v-img>
                </div>
                <div class="text-center">
                  <div class="text--primary font-weight-bold text-h6 mb-3">When do you have to pay?
                  </div>
                  <p class="text-body-2 text--primary">Once you approve the digital proofs we send you for the products
                    in your Swagbox, full payment must be made before your order goes into production.
                    <strong>Alternatively</strong> you can also pay for your Swagbox now.
                  </p>
                </div>

                <div class="text-center d-flex flex-column align-center">
                  <!-- <div>
                    <v-checkbox label="Don’t show this message again" hide-details class="text--primary my-2">
                    </v-checkbox>
                  </div> -->
                  <div class="mt-2">
                    <v-btn inline-block @click.stop="PayNow = !PayNow"
                      class="font-weight-bold text-none rounded-sm py-5 my-1 mx-2" color="primary" outlined>I’ll pay now
                    </v-btn>
                    <v-btn inline-block class="font-weight-bold text-none rounded-sm py-5 my-1 mx-2" color="primary"
                      @click="payLaterOrder()">Continue with paying later
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <!-- </v-list-item-content> -->
        </v-card-text>
      </v-card>
      <!-- Payment Options END -->
      <!-- Footer START -->
      <!-- <template v-slot:append>
        <div class="pa-2">
          <v-checkbox label="Don’t show this message again" hide-details class="text--primary mt-0"></v-checkbox>
          <v-btn @click.stop="PayNow = !PayNow" class="font-weight-bold text-none rounded-sm py-6 my-2" color="primary"
            outlined width="100%">I’ll pay now</v-btn>
          <v-btn class="font-weight-bold text-none rounded-sm py-6 my-2" color="primary" width="100%"
            @click="payLaterOrder()">Continue with paying later
          </v-btn>
        </div>
      </template> -->
      <!-- Footer END -->
    </v-dialog>
    <!-- Pay later SideBar END -->

    <!-- Pay Now SideBar START -->
    <v-dialog v-model="PayNow" v-if="PayNow" overlay-color="#f1f1f1" max-width="800px" content-class="rounded-xl relative"
      scrollable>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-btn @click="PayNow = false" color="elevation-0 darken-5 mt-10" fab absolute top right height="40" width="40">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>

      <!-- <v-list-item>
        <v-list-item-content class="text-left">
          <v-list-item-title class="font-weight-bold text--primary text-h6">Pay now</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <!-- Payment Options START -->
      <v-card>
        <!-- <v-card-title class="text-h6 font-weight-bold">
          Pay now
        </v-card-title> -->

        <v-card-text>
          <!-- <v-list-item-content> -->
          <h5 class="font-weight-black text--primary text-h5 mb-2 mt-16"><span class="font-weight-bold mr-2">Total:</span>
            {{
              subTotal
            }}</h5>
          <validation-observer ref="observer" v-slot="{}">
            <v-form ref="stripeform">
              <v-container>
                <!-- Card Details -->
                <v-row class="mb-3">
                  <!-- Cardholder name -->
                  <v-col cols="12" md="6">
                    <v-subheader class="px-0 text--primary font-weight-bold text-body-2">Card Holder Name
                    </v-subheader>
                    <validation-provider v-slot="{ errors }" rules="required" name="Card Holder Name">
                      <v-text-field placeholder="Adrian Baker" v-model="card.card_holder_name" :error-messages="errors"
                        :error="errors.length !== 0" :hide-details="errors.length <= 0" outlined dense required>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <!-- Card number * -->
                  <v-col cols="12" md="6">
                    <v-subheader class="px-0 text--primary font-weight-bold text-body-2">Card Number *</v-subheader>
                    <validation-provider v-slot="{ errors }" rules="required|exact_length:19" name="Card Number">
                      <v-text-field type="text" placeholder="16-digit number" v-mask="'#### #### #### ####'"
                        v-model="card.card_number" :error-messages="errors" :error="errors.length !== 0"
                        :hide-details="errors.length <= 0" outlined dense required></v-text-field>
                    </validation-provider>
                  </v-col>
                  <!-- Expiry * -->
                  <v-col cols="6">
                    <v-subheader class="px-0 text--primary font-weight-bold text-body-2">Card Expiry Date *
                    </v-subheader>
                    <validation-provider v-slot="{ errors }"
                      rules="required|date_format_month_year|future_month|future_year" name="Card Expiry Date">
                      <v-text-field type="text" placeholder="MM / YYYY" v-model="card.card_expiry"
                        @input="formatExpiry(card.card_expiry)" :error-messages="errors" :error="errors.length !== 0"
                        :hide-details="errors.length <= 0" outlined dense required></v-text-field>
                    </validation-provider>
                  </v-col>
                  <!-- CVV / CVC * -->
                  <v-col cols="6">
                    <v-subheader class="px-0 text--primary font-weight-bold text-body-2">CVV / CVC *</v-subheader>
                    <validation-provider v-slot="{ errors }" rules="required|cvv_length" name="CVV / CVC">
                      <v-text-field type="number" placeholder="CVV / CVC" v-model="card.card_cvv" :error-messages="errors"
                        :error="errors.length !== 0" :hide-details="errors.length <= 0" outlined dense
                        required></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>

                <!-- Billing Address -->
                <v-row class="mb-3">
                  <v-col cols="12 px-1 py-0">
                    <h6 class="font-weight-bold text--primary text-h6 text-left">Billing Address</h6>
                  </v-col>
                  <v-col cols="12 py-0">
                    <v-checkbox label="Same as shipping address" class="my-0" v-model="use_shipping_address"
                      @change="updateBillingAddress" hide-details></v-checkbox>
                  </v-col>
                  <!-- <v-col cols="12" md="6">
                    <v-subheader class="px-0 text--primary font-weight-bold text-body-2">Country</v-subheader>
                    <v-select outlined dense hide-details></v-select>
                  </v-col> -->
                  <v-col cols="12" md="6">
                    <v-subheader class="px-0 text--primary font-weight-bold text-body-2">Street address * </v-subheader>
                    <v-text-field type="text" placeholder="Street address" v-model="billing_address.address_one" outlined
                      dense hide-details required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-subheader class="px-0 text--primary font-weight-bold text-body-2">Street address 2
                    </v-subheader>
                    <v-text-field type="text" placeholder="Street address" v-model="billing_address.address_two" outlined
                      dense hide-details required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-subheader class="px-0 text--primary font-weight-bold text-body-2">State * </v-subheader>
                    <v-autocomplete outlined dense hide-details :items="$store.state.us_states"
                      v-model="billing_address.state" item-value="name" item-text="name"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-subheader class="px-0 text--primary font-weight-bold text-body-2">City * </v-subheader>
                    <v-text-field type="text" placeholder="Albany" v-model="billing_address.city" outlined dense
                      hide-details required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-subheader class="px-0 text--primary font-weight-bold text-body-2">Zip postal code *
                    </v-subheader>
                    <v-text-field type="text" placeholder="12084" v-model="billing_address.postal_code" outlined dense
                      hide-details required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-subheader class="px-0 text--primary font-weight-bold">Phone number</v-subheader>
                    <!-- <vue-phone-number-input v-model="billing_address.phone_number" @update="updatePhone"
                      :default-country-code="'US'" no-example :translations="{phoneNumberLabel: null}">
                    </vue-phone-number-input> -->
                    <!-- <validation-provider v-slot="{ errors }" rules="required|limit_phone:16" name="Phone Number"> -->
                    <!-- :error-messages="errors" :error="errors.length !== 0"  :hide-details="errors.length <= 0"-->
                    <v-text-field placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'"
                      v-model="billing_address.phone_number" outlined dense required>
                    </v-text-field>
                    <!-- </validation-provider> -->
                  </v-col>
                </v-row>

                <!-- Saved cards -->
                <v-row class="mt-6 d-none">
                  <h6 class="font-weight-bold text--primary text-h6 text-left">Saved cards</h6>
                  <!-- <v-col cols="12">
                    <v-radio-group class="mt-0">
                      <v-radio value="visa" on-icon="mdi-checkbox-intermediate" off-icon="mdi-checkbox-blank-outline">
                        <template v-slot:label>
                          <div class="d-flex justify-space-between align-center cardoptions">
                            <div class="text--primary">Visa ending in <strong>****4326</strong></div>
                            <div class="text--primary font-weight-bold">04/26</div>
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="mastercard" on-icon="mdi-checkbox-intermediate" off-icon="mdi-checkbox-blank-outline">
                        <template v-slot:label>
                          <div class="d-flex justify-space-between align-center cardoptions">
                            <div class="text--primary">Mastercard ending in <strong>****5478</strong></div>
                            <div class="text--primary font-weight-bold">03/24</div>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col> -->
                  <v-col cols="12" class="pt-0">
                    <div class="pt-1 d-flex align-center">
                      <div class="mb-1 text-body-1 font-weight-bold text-center">
                        <a href="login" color="primary"
                          class="text-none rounded-sm text-body-1 font-weight-extrabold pa-0" text min-width="0"
                          height="0">
                          Login</a>
                        <span class="ml-1">in to see your saved cards</span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </validation-observer>
          <!-- </v-list-item-content> -->
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions class="pa-3 text-end d-flex justify-end align-center">
          <div>
            <v-btn class="font-weight-bold text-none rounded-sm py-6" color="primary" width="100%" @click="checkout">
              Confirm payment
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
      <!-- Payment Options END -->
      <!-- Footer START -->
      <!-- <template v-slot:append>
        <div class="pa-2">
          <v-btn class="font-weight-bold text-none rounded-sm py-6" color="primary" width="100%" @click="checkout">
            Confirm payment</v-btn>
        </div>
      </template> -->
      <!-- Footer END -->
    </v-dialog>
    <!-- Pay Now SideBar END -->
  </div>
</template>

<script>
export default {
  name: 'OrderSummary',
  components: {},
  mounted() { },
  data() {
    return {
      PayNow: null,
      Paylater: null,
      payLaterDisabled: true,
      firstBoxInventoryCharges: 5.00,
      remainingBoxesInventoryCharges: 4.50,
      use_shipping_address: true,
      card: {
        card_holder_name: null,
        card_number: null,
        card_expiry: null,
        card_cvv: null,
        phone_number: null,
        phone_data: {},
      },
      billing_address: {
        address_one: null,
        address_two: null,
        city: null,
        postal_code: null,
        state: null,
        phone_number: null,
      },
      loading: false,
      showError: null,
      taxCalculated: false,
      salesTax: 0
      // cartPrice: null
    }
  },

  watch: {
    PayNow(newValue, oldValue) {
      if (newValue == true) {
        if (this.use_shipping_address) {
          this.billing_address.address_one = this.$store.getters.getShippingAddress.address_one
          this.billing_address.address_two = this.$store.getters.getShippingAddress.address_two
          this.billing_address.city = this.$store.getters.getShippingAddress.city
          this.billing_address.postal_code = this.$store.getters.getShippingAddress.postal_code
          this.billing_address.state = this.$store.getters.getShippingAddress.state
          this.billing_address.phone_number = this.$store.getters.getShippingAddress.phone_number
        }
      }
    }
  },

  computed: {
    logoPrice() {
      return this.$store.getters.logoPrice
    },

    numberOfLogos() {
      return this.$store.getters.numberOfLogos
    },

    subTotal() {
      if (this.$store.state.cart?.box_products) {
        let productsPrice = this.$helper.functions.getBoxPrice(this.$store.state.cart?.box_products, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
        if (!this.payLaterDisabled) {
          let remainingBoxesCharges = Number(this.remainingBoxesInventoryCharges) * (Number(this.$store.getters.getCart.quantity) - 1)
          productsPrice = Number(productsPrice) + Number(this.firstBoxInventoryCharges) + Number(remainingBoxesCharges)
        }
        productsPrice = productsPrice + this.salesTax
        return productsPrice.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
        // let minQuantity = this.$store.getters.getCart.quantity
        // return (productsPrice * minQuantity).toLocaleString('en-US', {
        //   style: 'currency',
        //   currency: 'USD',
        // })
      } else {
        let price = 0
        return price.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
      }
      // let minQuantity = this.$store.getters.getCart.quantity
      // return (productsPrice * minQuantity).toLocaleString('en-US', {
      //   style: 'currency',
      //   currency: 'USD',
      // })
    },

    cartPrice() {
      let productsPrice = this.$helper.functions.costPerSwagbox(this.$store.state.cart?.box_products).slice(1)
      let minQuantity = this.$store.getters.getCart.quantity
      return productsPrice
    },

    inventoryCharges() {
      if (!this.payLaterDisabled) {
        let remainingBoxesCharges = Number(this.remainingBoxesInventoryCharges) * (Number(this.$store.getters.getCart.quantity) - 1)
        let charges = Number(this.firstBoxInventoryCharges) + Number(remainingBoxesCharges)
        return charges.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
      }
      return false
    },

    chargePayment() {
      let productsPrice = this.$helper.functions.getBoxPrice(this.$store.state.cart?.box_products, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
      if (!this.payLaterDisabled) {
        let remainingBoxesCharges = Number(this.remainingBoxesInventoryCharges) * (Number(this.$store.getters.getCart.quantity) - 1)
        let charges = Number(this.firstBoxInventoryCharges) + Number(remainingBoxesCharges)
        productsPrice = Number(productsPrice) + Number(charges)
      }
      return productsPrice.toFixed(2)
    }
  },

  methods: {
    formatExpiry(cardExpiry) {
      if (cardExpiry.length == 3) {
        let thirdCharacter = this.card.card_expiry.substring(2, 3)
        if (thirdCharacter != '/') {
          let exp = this.card.card_expiry.substring(0, 2) + '/' + this.card.card_expiry.substring(2, this.card.card_expiry.length);
          this.card.card_expiry = exp
        }
      }
    },

    updateBillingAddress() {
      if (JSON.stringify(this.billing_address) === JSON.stringify(this.$store.state.shipping_address) && this.use_shipping_address == false) {
        this.billing_address = {
          address_one: null,
          address_two: null,
          city: null,
          postal_code: null,
          state: null,
          phone_number: null,
        };
      } else if (this.use_shipping_address == true) {
        this.billing_address.address_one = this.$store.getters.getShippingAddress.address_one
        this.billing_address.address_two = this.$store.getters.getShippingAddress.address_two
        this.billing_address.city = this.$store.getters.getShippingAddress.city
        this.billing_address.postal_code = this.$store.getters.getShippingAddress.postal_code
        this.billing_address.state = this.$store.getters.getShippingAddress.state
        this.billing_address.phone_number = this.$store.getters.getShippingAddress.phone_number
      }
    },
    updatePhone(e) {
      // this.customerInfo.phone_data = e
      // this.customerInfo.phone_number = e.nationalNumber
      // this.billing_address.phone_number = e.nationalNumber
      // this.$store.commit('updateShippingAddress', this.shipping_address)
    },
    payLater() {
      this.$root.$emit('validateShhippingForm', 'paylater');
      // this.Paylater = !this.Paylater
    },
    payLaterOrder() {
      this.loading = true
      let formData = new FormData();
      formData.append('products', JSON.stringify(this.$store.getters.getCart))
      formData.append('customer_info', JSON.stringify(this.$store.getters.getCustomerInfo))
      formData.append('cart_price', this.chargePayment)
      formData.append('number_of_logos', this.$store.getters.numberOfLogos)
      formData.append('logos_price', this.$store.getters.logoPrice)
      formData.append('billing_address', JSON.stringify(this.billing_address))
      formData.append('shipping_address', JSON.stringify(this.$store.state.shipping_address))
      formData.append('sales_tax', this.salesTax)

      this.$store
        .dispatch('PAYLATER_ORDER', formData)
        .then(
          response => {
            console.log(response)
            this.loading = false
            this.Paylater = false
            this.$store.commit('emptyCart')
            this.$store.commit('removeCustomerInfo')
            this.$store.commit('resetLogos')
            this.$store.commit('reserShippingAddress')
            this.$router.push({ name: 'OrderQuote' })
          },
          error => {
            this.loading = false
            this.$store.commit("showAlert", {
              text: error.data.message,
              successStatus: false,
              alertColor: 'red darken-4'
            });
            throw error
          }
        )
        .catch(error => {
          this.loading = false
          if (error.data.errors)
            this.$refs.observer.setErrors(error.data.errors)
        })
    },
    checkout() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }
        this.loading = true
        let formData = new FormData();

        Object.keys(this.card).forEach(key => {
          formData.append(key, this.card[key])
        })

        formData.append('products', JSON.stringify(this.$store.getters.getCart))
        formData.append('customer_info', JSON.stringify(this.$store.getters.getCustomerInfo))
        formData.append('cart_price', this.chargePayment)
        formData.append('number_of_logos', this.$store.getters.numberOfLogos)
        formData.append('logos_price', this.$store.getters.logoPrice)
        // formData.append('billing_address', JSON.stringify(this.billingAddress))
        formData.append('billing_address', JSON.stringify(this.billing_address))
        formData.append('shipping_address', JSON.stringify(this.$store.state.shipping_address))
        formData.append('sales_tax', this.salesTax)
        this.$store
          .dispatch('CHECKOUT', formData)
          .then(
            response => {
              console.log(response)
              this.loading = false
              this.PayNow = false
              Object.keys(this.card).forEach(key => {
                this.card[key] = null;
              });

              this.$store.commit('emptyCart')
              this.$store.commit('removeCustomerInfo')
              this.$store.commit('resetLogos')
              this.$store.commit('reserShippingAddress')
              this.$router.push({ name: 'ThankYou' })
            },
            error => {
              this.loading = false
              this.$store.commit("showAlert", {
                text: error.data.message,
                successStatus: false,
                alertColor: 'red darken-4'
              });
              throw error
            }
          )
          .catch(error => {
            this.loading = false
            if (error.data.errors)
              this.$refs.observer.setErrors(error.data.errors)
          })
      });
    },

    paynow() {
      if (!this.$store.getters.getCart.min_quantity) {
        this.quantityUpdated()
        // this.showError = 'Number of swagboxes requied'
        return
      }
      this.$root.$emit('validateShhippingForm', 'paynow');
      // this.PayNow = true
    },

    quantityUpdated() {
      // console.log(this.$store.state.cart)
      if (this.$store.getters.getCart.min_quantity) {
        this.showError = null
      } else {
        this.showError = null
        this.showError = 'Number of swagboxes requied'
      }
    },

    getCartPrice(products) {
      return this.$helper.functions.getBoxPrice(products).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    getTotalPrice() {
      let productsPrice = this.$helper.functions.getBoxPrice(this.$store.getters.getCart.box_products)
      let minQuantity = this.$store.getters.getCart.quantity
      return (productsPrice * minQuantity).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    calculateTax() {
      this.taxCalculated = false
      let state = this.$store.state.us_states.find(x => x.name === this.$store.state.shipping_address.state).abbreviation
      this.loading = true
      let productsPrice = this.$helper.functions.getBoxPrice(this.$store.state.cart?.box_products, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
      let lineItems = [];
      let lineItem = []
      lineItem['id'] = 1
      lineItem['quantity'] = 1
      lineItem['product_tax_code'] = 20010
      lineItem['unit_price'] = productsPrice
      lineItems.push(lineItem)
      let formData = new FormData();
      formData.append('to_zip', this.$store.state.shipping_address.postal_code)
      formData.append('to_state', state)
      formData.append('amount', productsPrice)
      formData.append('shipping', 0)
      formData.append('line_items', lineItems)
      this.$store
        .dispatch('CALCULATE_TAX', formData)
        .then(
          response => {
            console.log(response)
            if (response.status == 'success') {
              this.salesTax = response.tax
              this.taxCalculated = true
            }
            this.loading = false
          },
          error => {
            this.loading = false
            this.$store.commit("showAlert", {
              text: error.data.message,
              successStatus: false,
              alertColor: 'red darken-4'
            });
            this.$router.push({ name: 'Cart' })
            throw error
          }
        )
        .catch(error => {
          this.loading = false
          console.log(error)
          if (error.data.errors)
            this.$store.commit("showAlert", {
              text: error.data.message,
              successStatus: false,
              alertColor: 'red darken-4'
            });
            this.$router.push({ name: 'Cart' })
        })
    }
  },

  created() {
    this.calculateTax()
    this.$root.$on('togglePaylaterButton', (payload) => {
      if (payload == 0) {
        this.payLaterDisabled = true
      } else {
        this.payLaterDisabled = false
      }
    })
    this.$root.$on('shhippingFormValidated', (payload) => {
      if (payload == 'paynow') {
        this.PayNow = true
      } else {
        this.Paylater = !this.Paylater
      }

    })

    this.$root.$on('modalClosed', (payload) => {
      if (payload) {
        this.calculateTax()
      }

    })
  }
}
</script>

<style scoped>
.v-subheader {
  height: 30px;
}

.v-text-field--outlined>>>fieldset {
  border-color: rgba(7, 26, 82, 1) !important;
  border-radius: 2px !important;
}

.cardoptions {
  width: 100%;
}
</style>
