<template>
  <v-app>
    <v-container fluid class="primary my-10 spring-bg">
      <v-row class="px-4 justif-space-between align-center py-16">
        <v-col cols="12" md="8" lg="5" class="text-md-left">
          <h5 class="text-h3 white--text font-weight-black mb-4">Ready to get started?</h5>
          <p class="mb-0 white--text pe-16">Building a legacy takes time, sweat, and tears. With us, it's easy. Let's get your customized Swag Box ready to embrace.</p>
        </v-col>

        <v-col cols="12" md="4" lg="7" class="text-md-end">
          <router-link :to="{name: 'ProductOnboarding',}" tag="v-btn" class="font-weight-bold text--primary text-none rounded-sm py-6">
            Shop swagbox
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  export default {
    name: 'GetStarted',
    components: {},
    mounted() {},
    computed: {},
    data() {
      return {

      }
    },
    methods: {}
  }
</script>

<style scoped>
  .spring-bg {
    background: url('@/assets/images/spring-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
</style>
