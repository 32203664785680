import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import router from './router/index.js'
import VueRouter from 'vue-router';
import store from './store'
import helper from "./helper";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import '@/plugins/vee-validate'
import VueMask from 'v-mask'
Vue.use(VueMask);

require('@/assets/styles/main.scss')
Vue.component('vue-phone-number-input', VuePhoneNumberInput);                                                          

// Vue.config.productionTip = false
// Vue.use(vuetify)
Vue.use(VueRouter)
Vue.prototype.$helper = helper;
require('@/services/api')

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
