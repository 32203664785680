import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  //   required,
  email,
  //   max,
  //   min,
  //   size,
  //   max_value,
  //   min_value,
  confirmed,
  //   integer
  // date_format
} from 'vee-validate/dist/rules.umd'

// extend('required', (value) => {
//   if (value) {
//     return true;
//   }
//   return '{_field_} required'
// })

extend('required', {

  validate(value) {
    if (value) {
      return true;
    }
  },
  // This rule reports the `required` state of the field.
  computesRequired: true,
  message: '{_field_} required'
});

extend('exact_length_cvv', (value, max_count) => {
  if (value.length <= max_count) {
    return true;
  }
  return 'maximum ' + max_count + ' characters allowed for {_field_}'
})

extend('limit_phone', (value, min_count) => {
  if (value.length == min_count) {
    return true;
  }
  return 'Invalid Phone Number'
})

extend('validate_phone_number', (value) => {
  if (value.length >= 14) {
    return true;
  }
  return 'minimum 10 characters allowed for {_field_}'
})

extend('confirmed', {
  ...confirmed,
  message: 'Confirm password did not matched with Password'
})

extend('date_format_month_year', {
  message: `Invalid Date Format, required format i.e MM/YYYY`,
  validate: value => {
    return /((0[1-9]|1[0-12])\/[1-9]\d{3}$)/.test(value)
  }
})

// extend('future_month', {
//   message: `Invalid Date Format, required format i.e MM/YYYY`,
//   validate: value => {
//     return /((0[1-9]|1[0-12])\/[1-9]\d{3}$)/.test(value)
//   }
// })

extend('future_month', (value) => {
  let arr = value.split("/");
  if (new Date().getFullYear() == arr[1]) {
    if (new Date().getMonth() < arr[0]) {
      return true
    }
    return 'Invalid Month'
  }
  return true

})

extend('future_year', (value) => {
  let arr = value.split("/");
  if (new Date().getFullYear() <= arr[1]) {
    return true
  }
  return 'Invalid Year'
})

extend('max', (value, max_count) => {
  if (value.length <= max_count) {
    return true;
  }
  return 'maximum ' + max_count + ' characters allowed for {_field_}'
  // message: 'maximum '+ max +' characters allowed'
})

extend('min', (value, min_count) => {
  // if (value.length == min_count) {
  //   return true;
  // }
  if (value.length >= min_count) {
    return true;
  }
  return 'minimum ' + min_count + ' characters required for {_field_}'
  // message: 'maximum '+ max +' characters allowed'
})

// extend('min', {
//   ...min,
//   message: 'field_min_message'
// })

// extend('faq_max_value', {
//   ...max,
//   message: 'field_faq_max_value_message'
// })

// extend('size', {
//   ...size,
//   message: 'field_size_message'
// })

extend('email', {
  ...email,
  message: 'Invalid Email Format'
})

// extend('min_value', {
//   ...min_value,
//   message: 'field_min_value_message'
// })

// extend('max_value', {
//   ...max_value,
//   message: 'field_max_value_message'
// })


// extend('password_confirmed', {
//   ...confirmed,
//   message: 'field_password_confirmed_message'
// })

// extend('exact_length', {
//   message: '{_field_} should be exactly 16 digits',
//   validate: value => {
//     if (value.length == 16) {
//       return true
//     }
//   }
// })

extend('exact_length', (value, max_count) => {
  if (value.length == max_count) {
    return true;
  }
  return 'Invalid Card Number'
  // return 'maximum ' + max_count + ' characters allowed for {_field_}'
  // message: '{_field_} should be exactly 3 digits',
  // validate: value => {
  //   if (value.length == 3) {
  //     return true
  //   }
  // }
})

extend('cvv_length', (value, max_count) => {
  if (value.length == 3 || value.length == 4) {
    return true;
  }
  return 'Invalid CVV / CVC'
})

// extend('only_english_lang_allowed', {
//   message: `Only english charactor's are allowed`,
//   validate: value => {
//     // validation allowing only english language
//     var strongRegex = new RegExp(`[^\u0000-\u0080]+`)
//     return !strongRegex.test(value)
//   }
// })

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
