<template>
  <v-app>
    <div>Privacy Policy</div>
  </v-app>
</template>

<script>


  export default {
    name: 'PrivacyPolicy',
    components: {
    },
    mounted() {},
    computed: {},
    data() {
      return {

      }
    },
    methods: {}
  }
</script>

<style scoped>

</style>
