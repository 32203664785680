<template>
  <v-app>
    <v-container fluid class="mb-8 wavebg">
      <v-row class="align-center px-4 justify-center">
        <v-col cols="12" lg="5" class="text-center">
          <div class="pt-10 pb-6">
            <h1 class="text--primary font-weight-black text-h4 text-sm-h2 text-md-h2 text-lg-h3">Pick an option</h1>
            <p class="my-4">We've made it easy for you to get started in building your swag box, by providing different ways to begin.
Pick the customization option that best suits your needs.</p>
          </div>
        </v-col>
      </v-row>

      <v-row class="px-4 justify-center">
        <!-- Preset Boxes -->
        <v-col cols="12" sm="6" md="4" lg="4">
          <v-card :to="{ name: 'PresetBoxes', }" class="text-center pa-4 rounded-lg" height="100%">
            <h6 class="text-h6 text--primary mb-2 font-weight-bold">Preset Boxes</h6>
            <p class="ma-0">With our preset boxes, you can now skip the selection process of products, and check out what we have already carefully prepared for you.</p>
            <div class="mb-3">
              <v-img :src="require('@/assets/images/preset-boxes.png')" alt="Preset Boxes" class="fill-height"></v-img>
            </div>
          </v-card>
        </v-col>

        <!-- Do it yourself -->
        <v-col cols="12" sm="6" md="4" lg="4">
          <v-card :to="{ name: 'ProductCatalog', }" class="text-center pa-4 rounded-lg" height="100%">
            <h6 class="text-h6 text--primary mb-2 font-weight-bold">Do it yourself</h6>
            <p class="ma-0">Want to get creative and create your own customized box? Let's do it!</p>
            <div class="mb-3">
              <v-img :src="require('@/assets/images/do-it-yourself.png')" alt="Do it yourself" class="fill-height">
              </v-img>
            </div>
          </v-card>
        </v-col>

        <!-- Bulk swag. No box -->
        <v-col cols="12" sm="6" md="4" lg="4">
          <v-card :to="{ name: 'ProductCatalog', }" class="text-center pa-4 rounded-lg" height="100%">
            <h6 class="text-h6 text--primary mb-2 font-weight-bold">Bulk swag. No box</h6>
            <p class="ma-0">Not looking to bundle your items in a box at all? That's okay! Order single or multiple items in bulk.</p>
            <div class="mb-3">
              <v-img :src="require('@/assets/images/bulk-swag.png')" alt="Bulk swag" class="fill-height"></v-img>
            </div>
          </v-card>
        </v-col>

      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'PickOption',
  components: {},
  mounted() { },
  computed: {},
  data() {
    return {

    }
  },
  methods: {}
}
</script>

<style scoped>
.wavebg {
  background: url('@/assets/images/wave1-bg.png');
  background-size: contain;
  background-position-y: top;
  background-repeat: repeat-y;
  background-position-x: center;
}
</style>
