<template>
  <v-app>
    <v-container fluid class="primary mb-8 py-6">
      <v-row class="align-center px-4 justify-center">
        <v-col cols="12" sm="12" md="7" lg="5">
          <div>
            <h2 class="white--text text-h4 text-md-h4 text-lg-h3 text-left font-weight-black">A swag management platform that makes sourcing, storing, and shipping swag easy.</h2>
          </div>
        </v-col>
        <v-col cols="12" sm="10" md="5" lg="7">
          <v-img :src="require('@/assets/images/management-img.png')" alt="Swag Management" class="fill-height"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  export default {
    name: 'Swag-Management',
    components: {},
    mounted() {},
    computed: {},
    data() {
      return {

      }
    },
    methods: {}
  }
</script>

<style scoped>

</style>
