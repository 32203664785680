// import axios from "@/services/api";
import axios from "axios";
import {
  SIGN_UP,
  VERIFY_ACCOUNT,
  LOGIN,
  LOGOUT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_FORGOT,
  AUTH_RESET,
  AUTH_PROFILE,
  AUTH_PROFILE_SUCCESS,
  AUTH_PROFILE_ERROR
} from "@/store/actions/auth";
import router from "@/router/index.js";

function initialState() {
  return {
    token: "",
    status: "",
    user: null
  };
}

const state = {
  token: "",
  status: "",
  user: null
};

const getters = {
  token: state => state.token,
  isLoggedIn: state => state.token,
  authStatus: state => state.status,
  user: state => state.user
};

const actions = {
  [SIGN_UP]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios.post('/signup', payload)
        .then((response) => {
          if (response.status == 200) {
            resolve(response.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [VERIFY_ACCOUNT]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios.post('/verify-account', payload)
        .then((response) => {
          if (response.status == 200) {
            resolve(response.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [LOGIN]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios.post('/login', payload)
        .then((response) => {
          const token = response.data.access_token;
          const user = response.data.user;
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          commit("AUTH_SUCCESS", { token, user });
          if (response.status == 200) {
            resolve(response.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [LOGOUT]: ({ commit, dispatch }) => {
    return new Promise(resolve => {
      axios
        .post("/logout")
        .then(resp => {
          console.log("RESP", resp)
          if (resp.status >= 200 && resp.status <= 299) {
            commit("AUTH_LOGOUT");
            resolve(resp.data);
          }
        })
        .catch(err => {
          console.log(err);
        })
    });
  },
  [FORGOT_PASSWORD]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios.post('/forgot-password', payload)
        .then((response) => {
          if (response.status == 200) {
            resolve(response.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [RESET_PASSWORD]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios.post('/reset-password', payload)
        .then((response) => {
          if (response.status == 200) {
            resolve(response.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
  //   return new Promise((resolve, reject) => {
  //     commit("AUTH_REQUEST");
  //     dispatch("API_PROCESSING", true, { root: true });
  //     axios({
  //       url: "/user/login",
  //       data: user,
  //       method: "POST"
  //     })
  //       .then(resp => {
  //         if (resp.status === 200) {
  //           const token = resp.data.data.access_token;
  //           const user = resp.data.data.user;
  //           // if(user.role=="E" || user.role=="e")
  //           axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  //           commit("AUTH_SUCCESS", { token, user });
  //         }
  //         resolve(resp);
  //       })
  //       .catch(err => {
  //         commit("AUTH_ERROR");
  //         reject(err);
  //       })
  //       .finally(() => {
  //         dispatch("API_PROCESSING", false, { root: true });
  //       });
  //   });
  // },
  // [AUTH_LOGOUT]: ({ commit, dispatch }) => {
  //   dispatch("API_PROCESSING", true, { root: true });
  //   return new Promise(resolve => {
  //     axios
  //       .post("/user/logout")
  //       .then(resp => {
  //         if (resp.status >= 200 && resp.status <= 299) {
  //           commit("AUTH_LOGOUT");
  //         }
  //       })
  //       .catch(err => {
  //         console.log(err);
  //       })
  //       .finally(() => {
  //         dispatch("API_PROCESSING", false, { root: true });
  //       });
  //     resolve();
  //   });
  // },
  // [AUTH_FORGOT]: ({ commit, dispatch }, user) => {
  //   return new Promise((resolve, reject) => {
  //     // commit("AUTH_FORGOT");
  //     dispatch("API_PROCESSING", true, { root: true });
  //     axios({
  //       url: "/user/auth/password/email",
  //       data: user,
  //       method: "POST"
  //     })
  //       .then(resp => {
  //         // if (resp.status >= 200 && resp.status <= 299) {
  //         //   console.log(resp);
  //         // }
  //         resolve(resp);
  //       })
  //       .catch(err => {
  //         commit("AUTH_ERROR");
  //         reject(err);
  //       })
  //       .finally(() => {
  //         dispatch("API_PROCESSING", false, { root: true });
  //       });
  //   });
  // },
  // [AUTH_RESET]: ({ commit, dispatch }, user) => {
  //   return new Promise((resolve, reject) => {
  //     dispatch("API_PROCESSING", true, { root: true });
  //     axios({
  //       url: "/user/auth/password/reset",
  //       data: user,
  //       method: "POST"
  //     })
  //       .then(resp => {
  //         // if (resp.status >= 200 && resp.status <= 299) {
  //         //   console.log(resp);
  //         // }
  //         resolve(resp);
  //       })
  //       .catch(err => {
  //         commit("AUTH_ERROR");
  //         reject(err);
  //       })
  //       .finally(() => {
  //         dispatch("API_PROCESSING", false, { root: true });
  //       });
  //   });
  // },
  // [AUTH_PROFILE]: ({ commit, dispatch }) => {
  //   return new Promise((resolve, reject) => {
  //     dispatch("API_PROCESSING", true, { root: true });
  //     axios
  //       .get("/user/settings/profile")
  //       .then(resp => {
  //         commit("AUTH_PROFILE_SUCCESS", resp.data.data);

  //         resolve(resp);
  //       })
  //       .catch(error => {
  //         commit("AUTH_PROFILE_ERROR", error);

  //         reject(error);
  //       })
  //       .finally(() => {
  //         dispatch("API_PROCESSING", false, { root: true });
  //       });
  //   });
  // }
};

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, params) => {
    state.status = "success";
    state.token = params.token;
    state.user = params.user;
    // console.log('stata.token', state.token)
    // console.log('stata.user', state.user)
  },
  [AUTH_PROFILE_SUCCESS]: (state, params) => {
    state.status = "success";
    state.user = params.user;
  },
  [AUTH_PROFILE_ERROR]: state => {
    state.status = "error";
  },
  [AUTH_ERROR]: state => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: state => {
    state.token = "";
    state.user = null;

    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
    window.localStorage.clear();
    state.status = "";
    state.user = null;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
