<template>
    <v-app>
        <v-container fluid>
            <v-row>
                <v-col cols="12" class="pa-0">
                    <!-- <v-tabs v-model="OrdersTab" background-color="#F5F6F8" class="dash-tabs">
              <v-tab class="px-1 mx-sm-4"><span class="font-weight-bold text-none text-body-1">Requested</span></v-tab>
              <v-tab class="px-1 mx-sm-4"><span class="font-weight-bold text-none text-body-1">Completed</span></v-tab>
            </v-tabs> -->

                    <!-- <v-tabs-items v-model="OrdersTab"> -->
                    <!-- Requested Orders -->
                    <!-- <v-tab-item :transition="false"> -->
                    <!-- <div style="height:calc(100vh - 350px);" class="d-flex align-center justify-center text-h2">Requested</div> -->
                    <v-container fluid>
                        <v-row class="mb-1 align-center">
                            <v-col cols="6" class="text-left">
                                <h1 class="text-h5 font-weight-bold">Quotes</h1>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <v-btn :to="{ name: 'ProductOnboarding', }" class="font-weight-bold primary text-none rounded-sm py-6 mx-2">
                                    Shop swagbox
                                </v-btn>
                            </v-col>
                        </v-row>

                        <OrderFilters @updateFilters="updateFilters"/>

                        <!-- Requested Orders -->
                        <v-row class="my-1">
                            <template v-if="loading">
                                <Loader />
                            </template>
                            <!-- <template > -->
                            <template v-if="!loading">
                                <ProductCard v-for="(order, orderKey) in orders" :key="orderKey" :order="order" />
                            </template>
                            <!-- </template> -->
                        </v-row>
                    </v-container>
                    <!-- </v-tab-item> -->

                    <!-- Completed Orders -->
                    <!-- <v-tab-item :transition="false">
                <div style="height:calc(100vh - 350px);" class="d-flex align-center justify-center text-h2">Completed</div>
              </v-tab-item>
            </v-tabs-items> -->
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import ProductCard from "@/components/Dashboard/ProductCard"
import OrderFilters from "@/components/Dashboard/OrderFilters"
import Loader from "@/components/Loader"

export default {
    name: 'CustomerQuotes',
    components: {
        ProductCard,
        Loader,
        OrderFilters
    },
    data() {
        return {
            OrdersTab: null,
            RequestSortSelected: 'Newest First',
            RequestSortBy: ['Newest First', 'Oldest First',],
            RequestStatusSelected: 'All',
            RequestStatus: ['All', 'Artwork not submitted', 'Proofs ready', 'Ready for Production',],
            keyword: null,
            orders: [],
            // ProductSlider: 0,
            loading: false,
        }
    },

    mounted() { },
    computed: {
        // orders() {
        //     return this.$store.getters.orders
        // },
    },
    methods: {
        updateFilters(e) {
            this.loadOrderList(e)
        },

        loadOrderList(filter) {
            let data = {
                order_type: 'UNFULFILLED',
                sort_by: filter?.sort_by,
                status: filter?.status,
                keyword: filter?.keyword
            }
            this.loading = true
            this.$store.dispatch('GET_ORDERS', data).then(
                response => {
                    this.orders = response.orders
                    this.loading = false
                },
                error => {
                    this.loading = false
                    throw error
                }
            )
        }
    },
    created() {
        this.loadOrderList()
    }
}
</script>
<style scoped>
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child,
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row) {
    border-bottom: 0px;
}
</style>
