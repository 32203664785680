<template>
    <!-- <v-app> -->
    <v-card>
        <!-- <v-card-title class="px-2">

        </v-card-title> -->

        <v-card-text class="pt-8">
            <v-container>
                <!-- Images Slider -->
                <v-row>
                    <v-col cols="12" md="6" lg="6">
                        <div>
                            <!-- <v-card flat tile> -->
                            <v-window v-model="onboarding" class="elevation-1 rounded-lg" show-arrows>
                                <!-- Arrows -->
                                <template v-slot:prev="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" min-width="10" height="40"
                                        class="pa-2 elevation-0 rounded-circle">
                                        <v-icon>mdi-chevron-left</v-icon>
                                    </v-btn>
                                </template>
                                <template v-slot:next="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" min-width="10" height="40"
                                        class="pa-2 elevation-0 rounded-circle">
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </template>
                                <!-- main Images -->
                                <v-window-item v-for="(image, imageIndex) in images" :key="imageIndex">
                                    <v-card class="pa-0">
                                        <div class="slider-height">
                                            <!-- <v-img mg :src="image" contain max-width="100%" height="400px"
                                                class="mx-auto">
                                            </v-img> -->
                                            <NwImg :src="image" alt="Example Alt Text" :key="image"/>
                                        </div>
                                    </v-card>
                                </v-window-item>
                            </v-window>

                            <!-- Slider temporary hide-->
                            <!-- <div class="d-flex justify-space-between align-center pa-0 mt-3" multiple>
                                <v-btn text @click="prev" class="pa-0" min-width="10px">
                                    <v-icon>mdi-chevron-left</v-icon>
                                </v-btn>
                                <v-item-group v-model="onboarding"
                                    class="text-center d-flex flex-row overflow-auto cst-slider py-2"
                                    active-class="active">
                                    <v-item v-for="(image, imageIndex) in thumbImages" :key="imageIndex"
                                        v-slot="{ active, toggle }">
                                        <v-btn :input-value="active" @click="toggle" text class="img-fluid pa-0 mr-1"
                                            height="58px" width="58px">
                                            <v-img :src="image" contain width="58px"></v-img>
                                        </v-btn>
                                    </v-item>
                                </v-item-group>
                                <v-btn text @click="next" class="pa-0" min-width="10px">
                                    <v-icon>mdi-chevron-right</v-icon>
                                </v-btn>
                            </div> -->
                        </div>
                    </v-col>

                    <v-col cols="12" md="6" lg="6">
                        <div class="my-2">
                            <h1 class="text-left text--primary text-h6 font-weight-bold">{{ product.title }}
                            </h1>
                        </div>

                        <!-- Old color Dropdown -->
                        <!-- <div>
                    <v-subheader class="px-0 text--primary font-weight-bold text-body-1">Choose color </v-subheader>
                    <v-select v-model="product.selected_color" :items="product.colors" return-object
                      filled dense @change="colorChanged" clearable outlined hide-details>
                      <template v-slot:selection="{ item }">
                        <v-chip :color="item.color" class="color-dropdown"
                          v-bind:style="{ 'background-color': item.value }"></v-chip>
                        {{ item.label }}
                      </template>
                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs">
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-row no-gutters class="d-flex align-center font-weight-medium">
                                <div :color="item.value" class="color-dropdown mr-2"
                                  v-bind:style="{ 'background-color': item.value }"></div>
                                {{ item.label }}
                              </v-row>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                </div> -->
                        <!-- New Colors Slection radio START -->
                        <div>
                            <v-subheader class="px-0 text--primary font-weight-bold text-body-1">Choose color
                            </v-subheader>
                            <div class="d-flex justify-start flex-wrap max-ftr-h px-2">
                                <!-- radio list -->
                                <v-radio-group v-model="product.selected_color"
                                    v-for="(item, colorIndex) in product.colors" :key="colorIndex"
                                    class="product-colors">
                                    <v-radio :value='item' class="d-inline-block d-none" hide-details
                                        off-icon="mdi-none" @change="updateCart(product, item)">
                                        <template v-slot:label>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <div class="colors-list" v-on="on"
                                                        v-bind:style="{ 'background-color': item.value }">
                                                    </div>
                                                </template>
                                                <span class="text-capitalize">{{ $helper.functions.getColorName(item)
                                                }}</span>
                                            </v-tooltip>
                                            <!-- </div> -->
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                            </div>
                        </div>
                        <!-- New Colors Slection radio END -->

                        <!-- Quantity -->
                        <div class="d-flex flex-wrap flex-lg-nowrap">
                            <div>
                                <v-subheader class="px-0 text--primary font-weight-bold text-body-1">Qty per box
                                </v-subheader>
                                <div class="qty-plusmins mb-4" style="max-width: 50%;">
                                    <v-text-field v-model="product.quantity" type="number" append-outer-icon="mdi-plus"
                                        readonly @click:append-outer="increment" prepend-icon="mdi-minus"
                                        @click:prepend="decrement" outlined dense hide-details max-width="30%">
                                    </v-text-field>
                                </div>
                            </div>
                            <!-- v-if="product.imprint_colors.length > 0" -->
                            <div>
                                <v-subheader class="px-0 text--primary font-weight-bold text-body-1">Number of colors in
                                    Logo
                                </v-subheader>
                                <div class="qty-plusmins mb-4" style="max-width: 50%;">
                                    <v-text-field v-model="product.number_of_logos" type="number"
                                        append-outer-icon="mdi-plus" @click:append-outer="logoColorsincrement(product)"
                                        readonly prepend-icon="mdi-minus" @click:prepend="logoColorsdecrement(product)"
                                        outlined dense hide-details max-width="30%">
                                    </v-text-field>
                                </div>
                            </div>
                        </div>
                        <!-- End-Quantity -->

                        <!-- Information -->
                        <div cols="12">
                            <template v-if="product.description">
                                <v-subheader class="px-0 text--primary font-weight-bold text-body-1">Description
                                </v-subheader>
                                <p class="text-left mb-0 text-body-1 text--back" v-html="product.description"></p>
                            </template>

                            <template v-if="product.care_instructions">
                                <v-subheader class="px-0 text--primary font-weight-bold text-body-1">Care Instructions
                                </v-subheader>
                                <p class="text-left mb-0 text-body-1 text--back">{{ product.care_instructions }}</p>
                            </template>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-3 text-end d-flex justify-space-between align-center flex-wrap">
            <div class="text--primary font-weight-bold mb-2 per-boxprice">From {{ productSinglePrice(product) }} / Per box <small class="green--text font-weight-extrabold text--lighten-2 text-body-2">({{ product.quantity }} {{ product.quantity > 1 ? "Units" : "Unit" }})</small>
            </div>
            <div class="me-2 ml-sm-auto text-left text-sm-right">
                <div class="font-weight-black text--primary">{{ productPrice(product) }}</div>
                <div class="text--primary font-weight-medium text-body-2">{{ $store.getters.getCart.quantity }}
                    swagboxes
                </div>
            </div>
            <div class="d-flex justify-end">
                <v-btn class="font-weight-bold text-none rounded-sm py-6" color="primary"
                    :disabled="!product.selected_color" v-if="!cartItemIds.includes(product.id)"
                    @click="addToCart(product)">Add to box
                </v-btn>
                <v-btn class="font-weight-bold text-none rounded-sm py-6" color="red" outlined
                    v-if="cartItemIds.includes(product.id)" @click="removeIndex(product.id)">Remove
                </v-btn>
            </div>
        </v-card-actions>
    </v-card>
    <!-- </v-app> -->
</template>
<script>
import { cloneDeep } from 'lodash'
import NwImg from "@/components/NwImg"
export default {
    name: 'ProductDetail',
    props: {
        productSelected: {
            type: Object,
            default: function () {
                return {}
            },
            required: true
        }
    },
    components: {
        NwImg
    },
    data() {
        return {
            onboarding: 0,
            logo_colors: 1
            // images: []
        }
    },
    computed: {
        images() {
            if (!this.productSelected.selected_color) {
                let base_url = process.env.VUE_APP_S3_BASE;
                // console.log(base_url + 'thumbnails/' + this.productSelected.sku + '-thumbnail.png')
                return [base_url + 'medium/' + this.productSelected.sku + '-thumbnail.png']
            }

            let variants = this.productSelected.variants
            let variant = variants.find(obj => {
                return obj.color?.value == this.productSelected.selected_color.value
            })
            let images = []
            variant.images.forEach((object, key) => {
                let img_name = this.$helper.functions.getImage(object)
                images.push(img_name)
            })
            if (images.length > 0) {
                let prod = this.productSelected
                prod.images = images;
                this.$store.commit('updateCartProd', prod)
                return images
            } else {
                return []
            }
        },

        thumbImages() {
            if (!this.productSelected.selected_color) {
                let base_url = process.env.VUE_APP_S3_BASE;
                // console.log(base_url + 'thumbnails/' + this.productSelected.sku + '-thumbnail.png')
                return [base_url + 'thumbnails/' + this.productSelected.sku + '-thumbnail.png']
            }
            let variants = this.productSelected.variants
            let variant = variants.find(obj => {
                return obj.color?.value == this.productSelected.selected_color.value
            })
            let images = []
            variant.images.forEach((object, key) => {
                let img_name = this.$helper.functions.getImage(object, 'thumbnails')
                images.push(img_name)
            })
            if (images.length > 0) {
                return images
            } else {
                return []
            }
        },

        cartItemIds() {
            if (this.$store.state.cart?.box_products) {
                return this.$store.state.cart?.box_products.map(prod => prod.id)
            } else {
                return []
            }

        },

        product() {
            return this.productSelected
        },

        logoPrice() {
            return this.$store.getters.logoPrice
        },

        numberOfLogos: {
            get() {
                return this.$store.getters.numberOfLogos
            },
            set(newName) {
                // console.log(newName)
            }
        },

        quantityLimits() {
            return this.$store.getters.getQuantityLimits
        },

        volumePrice() {
            let volumePrice = this.productSelected.variants.find(variant => {
                return variant.color?.value === this.productSelected.selected_color.value
            }).volume_pricing.find(price => {
                return price.quantity >= this.quantityLimits.from && price.quantity <= this.quantityLimits.to
            })
            return volumePrice
        },
    },

    watch: {
        // whenever question changes, this function will run
        // productSelected(newQuestion, oldQuestion) {
        //     console.log('Watched')
        //     this.getImages(true)
        // }
    },

    methods: {
        basename(url) {
            if (url) {
                return url.split('/').pop();
            } else {
                return null
            }
        },

        productPrice(product) {
            if (!product.selected_color) {
                let productLogoPrice = this.$helper.functions.getProductLogoPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
                if (!productLogoPrice) {
                    productLogoPrice = 0
                }
                let productLogoFinalPrice = (Number(productLogoPrice) * Number(product.quantity))
                // let basePrice = (Number(product.price) * Number(product.quantity)) + Number(productLogoFinalPrice)

                // let productLogoPrice = (Number(this.$helper.functions.getProductLogoPrice(product, this.$store.getters.getCart?.quantity)) * Number(product.quantity))
                let lowestPrice = this.$helper.functions.getProdLowestPrice(product, this.$store.getters.getCart?.quantity)
                // let basePrice = (Number(product.price) * Number(product.quantity)) + Number(productLogoFinalPrice)
                let basePrice = (Number(lowestPrice) * Number(product.quantity)) + Number(productLogoFinalPrice)
                let bulkPrice = (Number(basePrice) * Number(this.$store.getters.getCart?.quantity))

                return bulkPrice.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })
            }
            let price = null
            if (product.sizes.length) {
                let prod = JSON.stringify(product)
                prod = JSON.parse(prod)
                price = this.$helper.functions.getSizedProdPrice(prod, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
                price = Number(price.toFixed(2)) * Number(this.$store.state.cart?.quantity)
            } else {
                let prodPrice = this.$helper.functions.getItemPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
                let productLogoPrice = this.$helper.functions.getProductLogoPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
                product.imprint_color_price = productLogoPrice
                // console.log('prodPrice', prodPrice)
                price = Number(prodPrice) * Number(this.$store.state.cart?.quantity)
                // price = Number(prodPrice)
            }
            return price.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })
        },

        getSizedProdPrice(product) {
            let price = 0
            let sizes = product.sizes
            let sizeQuantity = 0
            let prodFinalPrice = 0
            let boxQuantity = this.$store.getters.getCart?.quantity
            let variant = {}
            sizes.forEach(object => {
                sizeQuantity += Number(object.quantity);
            });

            if (sizes.length == 1) {
                variant = product.variants.find(variant => {
                    return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
                })
                if (variant) {
                    let volumePrice = variant.volume_pricing.find(price => {
                        return price.quantity >= boxQuantity
                    })
                    sizes[0].quantity = Number(boxQuantity) * Number(product.quantity)
                    sizes[0].price = (sizes[0].quantity * volumePrice.price).toFixed(2)
                    sizes[0].sku = variant.sku
                }
                prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
                product.final_price = prodFinalPrice.toFixed(2)
            }

            // || !this.cartItemIds.includes(product.id)
            // if (sizeQuantity == 0) {
            let qua = sizes.length - 1
            let sameValue = (boxQuantity / qua)
            sameValue = Math.trunc(sameValue)
            if (sizes.length == 1) {
                variant = product.variants.find(variant => {
                    return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
                })
                if (variant) {
                    let volumePrice = variant.volume_pricing.find(price => {
                        return price.quantity >= boxQuantity
                    })
                    sizes[0].quantity = Number(boxQuantity) * Number(product.quantity)
                    sizes[0].price = (sizes[0].quantity * volumePrice.price).toFixed(2)
                    sizes[0].sku = variant.sku
                }

                prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
            } else {
                sizes.forEach((size, key) => {
                    if (key == qua) {
                        variant = product.variants.find(variant => {
                            return variant.color?.value === product.selected_color.value && variant.size === size.size
                        })
                        if (variant) {
                            let volumePrice = variant.volume_pricing.find(price => {
                                return price.quantity >= boxQuantity
                            })
                            size.quantity = (boxQuantity - (qua * sameValue)) * Number(product.quantity)
                            size.price = (size.quantity * volumePrice.price).toFixed(2)
                            size.sku = variant.sku
                        }
                        prodFinalPrice = (Number(prodFinalPrice) + Number(size.price))
                    } else {
                        variant = product.variants.find(variant => {
                            return variant.color?.value === product.selected_color.value && variant.size === size.size
                        })
                        if (variant) {
                            let volumePrice = variant.volume_pricing.find(price => {
                                return price.quantity >= boxQuantity
                            })
                            size.quantity = Number(sameValue) * Number(product.quantity)
                            size.price = (size.quantity * volumePrice.price).toFixed(2)
                            size.sku = variant.sku
                        }
                        prodFinalPrice = (Number(prodFinalPrice) + Number(size.price))
                    }
                });
            }
            product.final_price = prodFinalPrice.toFixed(2)
            // }

            let logoFinalPrice = Number(this.logoPrice) * Number(product.quantity)
            // console.log('Final Prod', product)

            price = ((Number(logoFinalPrice) + Number(product.final_price)) / Number(boxQuantity))
            return price
        },

        productSinglePrice(product) {
            let price = null

            if (!product.selected_color) {
                let productLogoPrice = this.$helper.functions.getProductLogoPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
                if (!productLogoPrice) {
                    productLogoPrice = 0
                }
                let lowestPrice = this.$helper.functions.getProdLowestPrice(product, this.$store.getters.getCart?.quantity)
                // console.log(lowestPrice)
                let productLogoFinalPrice = (Number(productLogoPrice) * Number(product.quantity))
                // let basePrice = (Number(product.price) * Number(product.quantity)) + Number(productLogoFinalPrice)
                let basePrice = (Number(lowestPrice) * Number(product.quantity)) + Number(productLogoFinalPrice)

                return basePrice.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })
            }

            if (product.sizes.length) {
                let prod = JSON.stringify(product)
                prod = JSON.parse(prod)
                price = this.$helper.functions.getSizedProdPrice(prod, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos).toFixed(2)
                price = Number(price)
            } else {
                let prodPrice = this.$helper.functions.getItemPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
                let productLogoPrice = this.$helper.functions.getProductLogoPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
                product.imprint_color_price = productLogoPrice
                // console.log('productLogoPrice', productLogoPrice)
                // price = (Number(product.quantity) * Number(prodPrice)) + (Number(productLogoPrice) * Number(product.quantity))
                price = Number(prodPrice)
            }
            // if (product.price == product.final_price) {
            // price = (Number(product.quantity) * Number(this.volumePrice.price)) + (Number(this.logoPrice) * Number(product.quantity))
            // } else {
            //     price = product.final_price / this.$store.state.cart?.quantity
            //     price = Number(price) + Number(this.logoPrice)
            // }
            // console.log('PROD',product)
            return price.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })
        },

        updateCart(product, color) {
            if (this.cartItemIds.includes(product.id)) {
                product.selected_color = color
                let variant = {}
                if (product.sizes.length > 0) {
                    product.sizes.forEach((size, key) => {
                        variant = product.variants.find(variant => {
                            return variant.color?.value === product.selected_color.value && variant.size === size.size
                        })
                        if (variant) {
                            size.sku = variant.sku
                        }
                    });
                } else {
                    let boxProduct = this.$store.state.cart?.box_products.find(prod => {
                        return prod.id === product.id
                    })
                    // boxProducts.forEach(product => {
                    boxProduct.variants.forEach((variant, key) => {
                        delete variant["quantity"]
                        delete variant["price"]
                    });
                    // })

                    variant = product.variants.find(variant => {
                        return variant.color?.value === product.selected_color.value
                    })
                    let volumePrice = variant.volume_pricing.find(price => {
                        return price.quantity >= this.$store.state.cart?.quantity
                    })

                    let varPrice = (Number(volumePrice.price) + (Number(this.logoPrice) * Number(product.quantity))) * Number(this.$store.state.cart?.quantity)
                    variant.quantity = Number(this.$store.state.cart?.quantity) * Number(product.quantity)
                    variant.price = varPrice.toFixed(2)
                    product.final_price = varPrice.toFixed(2)
                }


                this.$store.commit('updateCartProd', product)
                // console.log(product)
            }
            // this.getImages()
        },

        addToCart(product) {
            if (!product.selected_color) {
                product.selected_color = product.colors[0] ? product.colors[0] : null
            }
            // product.final_price = (Number(product.quantity) * Number(product.price)) + (Number(this.logoPrice) * Number(product.quantity))
            this.$store.commit('pushToCart', product)
        },

        removeIndex(itemId) {
            let itemIndex = this.$store.state.cart?.box_products.findIndex(prod => prod.id == itemId)
            // this.$helper.functions.resetItem(this.$store.getters.products, this.$store.state.cart?.box_products[itemIndex])
            this.$store.commit('removeItemFromCart', itemIndex)
        },

        next() {
            this.onboarding = this.onboarding + 1 === this.length ?
                0 :
                this.onboarding + 1
        },

        prev() {
            this.onboarding = this.onboarding - 1 < 0 ?
                this.length - 1 :
                this.onboarding - 1
        },

        increment() {
            if (this.product.quantity < 10) {
                this.product.quantity = this.product.quantity + 1
                if (this.$store.state.cart?.box_products && this.$store.state.cart?.box_products.map(prod => prod.id).includes(this.product.id)) {
                    this.$helper.functions.distributeQuantity(this.product, {}, this.$store.getters.getCart?.quantity, this.numberOfLogos)
                }
                setTimeout(() => {
                    this.$store.commit('updateCartProd', this.product)
                }, 10)

            }
        },

        decrement() {
            // console.log(this.product.quantity)
            if (this.product.quantity != 1) {
                this.product.quantity = this.product.quantity - 1
                if (this.$store.state.cart?.box_products && this.$store.state.cart?.box_products.map(prod => prod.id).includes(this.product.id)) {
                    this.$helper.functions.distributeQuantity(this.product, {}, this.$store.getters.getCart?.quantity, this.numberOfLogos)
                }
                setTimeout(() => {
                    this.$store.commit('updateCartProd', this.product)
                }, 10)
            }
        },

        logoColorsincrement(product) {
            if (product.number_of_logos < 4) {
                product.number_of_logos = Number(product.number_of_logos) + 1
                if (this.$store.state.cart?.box_products && this.$store.state.cart?.box_products.map(prod => prod.id).includes(this.product.id)) {
                    this.$helper.functions.distributeQuantity(this.product, {}, this.$store.getters.getCart?.quantity, this.numberOfLogos)
                }
                this.$store.commit('updateLogoColors', product.number_of_logos)
            }
        },

        logoColorsdecrement(product) {
            if (product.number_of_logos != 1) {
                product.number_of_logos = Number(product.number_of_logos) - 1
                if (this.$store.state.cart?.box_products && this.$store.state.cart?.box_products.map(prod => prod.id).includes(this.product.id)) {
                    this.$helper.functions.distributeQuantity(this.product, {}, this.$store.getters.getCart?.quantity, this.numberOfLogos)
                }
                this.$store.commit('updateLogoColors', product.number_of_logos)
            }
        }

        // getImages(removeImages = false) {
        //     if(removeImages) {
        //         this.images = []
        //     }
        //     //
        //     let variants = this.productSelected.variants
        //     let variant = variants.find(obj => {
        //         return obj.color?.value == this.productSelected.selected_color.value
        //     })
        //     let images = []
        //     variant.images.forEach((object, key) => {
        //         images.push(object.link)
        //     })
        //     console.log('Called')
        //     let imageLoaded = 0;
        //     for (let i = 0; i < images.length; i++) {

        //         const img = new Image();
        //         img.src = images[i];

        //         img.onload = () => {
        //             imageLoaded++;
        //             console.log(images[i])
        //             if (imageLoaded === images.length) {
        //                 console.log("Done !");
        //                 this.images = images
        //                 //         // this.isLoading = false;
        //             }
        //         };
        //     }
        // }
    },

    created() {
        // this.getImages();
        // let variants = this.productSelected.variants
        // let variant = variants.find(obj => {
        //     return obj.color?.value == this.productSelected.selected_color.value
        // })
        // let images = []
        // variant.images.forEach((object, key) => {
        //     images.push(object.link)
        // })

        // let imageLoaded = 0;
        // for (let i = 0; i < images.length; i++) {

        //     const img = new Image();
        //     img.src = images[i];

        //     img.onload = () => {
        //         imageLoaded++;
        //         console.log(images[i])
        //         if (imageLoaded === images.length) {
        //             console.log("Done !");
        //             this.images = images
        //             //         // this.isLoading = false;
        //         }

        //         console.log(imageLoaded);
        //     };
        // }
        // console.log('kana')
        // if (images.length > 0) {
        //     return images
        // } else {
        //     return []
        // }
    }
}
</script>

<style scoped>

</style>
