<template>
    <v-app>
        <v-container fluid>
            <v-row class="align-center">
                <v-col cols="6" class="text-left">
                    <h1 class="text-h5 font-weight-bold">Shipment</h1>
                </v-col>
                <v-col cols="6" class="text-right">
                    <v-btn :to="{ name: 'AddContactGroup', }" class="font-weight-bold primary text-none rounded-sm py-6 mx-2">
                        Add contact group
                    </v-btn>
                </v-col>
            </v-row>

            <v-row class="mt-0 mb-10">
                <v-col cols="12 px-0">

                    <!-- Tab Bar -->
                    <ShipmentTabs />
                    <!-- Tab Bar -->

                    <!-- search and filters START -->
                    <v-row class="my-1">
                        <!-- Search contacts group-->
                        <v-col cols="12" lg="3">
                            <v-text-field placeholder="Search contact group" outlined dense hide-details required prepend-inner-icon="mdi-magnify">
                            </v-text-field>
                        </v-col>
                        <!-- Sort by: -->
                        <v-col cols="12" lg="2">
                            <v-select outlined dense hide-details>
                                <template v-slot:prepend-inner>
                                    <div class="text-no-wrap py-1">Sort by:</div>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                    <!-- search and filters END -->

                    <v-divider></v-divider>

                    <!-- When contact groups is empty START -->
                    <div v-if="false">
                        <v-row>
                            <v-col>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left" style="width: 45%;">Name</th>
                                                <th class="text-left">Contacts</th>
                                                <th class="text-left" style="width: 200px;">Category</th>
                                            </tr>
                                        </thead>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                        <v-row style="height: calc(100vh - 400px);" class="align-center">
                            <v-col cols="12" class="text-center">
                                <v-img :src="require('@/assets/images/svg/notes-card.svg')" contain width="48px" class="mx-auto"></v-img>
                                <div class="text-h6 font-weight-bold primary--text mb-1">No contact groups yet.</div>
                                <div class="grey--text font-weight-extramedium">You haven’t added contact groups yet.</div>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- When contact groups is empty START -->

                    <!-- Show When contacts groups listed  -->
                    <v-row v-else>
                        <v-col cols="12">
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left" style="width: 45%;">Name</th>
                                            <th class="text-left">Contacts</th>
                                            <th class="text-left" style="width: 200px;">Category</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <!-- Group name-->
                                            <td class="text-left">
                                                <div class="d-flex align-center flex-wrap">
                                                    <v-avatar color="primary" size="48" class="mr-3">
                                                        <span class="text-uppercase white--text font-weight-bold">NE</span>
                                                    </v-avatar>
                                                    <span>New employees</span>
                                                </div>
                                            </td>

                                            <!-- Contacts -->
                                            <td class="text-left">17</td>

                                            <!-- Category -->
                                            <td class="text-left">Employees</td>
                                        </tr>

                                        <tr>
                                            <!-- Group name-->
                                            <td class="text-left">
                                                <div class="d-flex align-center flex-wrap">
                                                    <v-avatar color="primary" size="48" class="mr-3">
                                                        <img src="https://cdn.shopify.com/shopifycloud/hatchful_web_two/bundles/4a14e7b2de7f6eaf5a6c98cb8c00b8de.png" alt="Frank K. Chill">
                                                    </v-avatar>
                                                    <span>Top 50 clients</span>
                                                </div>
                                            </td>

                                            <!-- Contacts -->
                                            <td class="text-left">13</td>

                                            <!-- Category -->
                                            <td class="text-left">Clients</td>
                                        </tr>

                                        <tr>
                                            <!--Group name-->
                                            <td class="text-left">
                                                <div class="d-flex align-center flex-wrap">
                                                    <v-avatar color="primary" size="48" class="mr-3">
                                                        <span class="text-uppercase white--text font-weight-bold">WF</span>
                                                    </v-avatar>
                                                    <span>Weekend F.C.</span>
                                                </div>
                                            </td>

                                            <!-- Contacts -->
                                            <td class="text-left">21</td>

                                            <!-- Category -->
                                            <td class="text-left">Team mates</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>

                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
    import ShipmentTabs from "@/components/Dashboard/ShipmentTabs"
    export default {
        name: 'ContactGroups',
        components: {
            ShipmentTabs,
        },
        data() {
            return {}
        },

        mounted() {},
        computed: {},
        methods: {},
        created() {}
    }
</script>

<style scoped>
    .v-card.v-card--link:hover {
        transform: scale(1.008) !important;
        border-bottom: 1px solid #d1d1d1;
    }

    .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr>td:last-child,
    .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
        border-bottom: 1px solid rgba(0, 0, 0, .12);
        padding-top: 8px;
        padding-bottom: 8px;
    }
</style>
