<template>
  <v-app>
    <v-container fluid v-if="loading" class="mt-1">
      <v-row>
        <v-col cols="3" lg="3" class="text-center">
          <v-skeleton-loader type="image, card-heading, image, card-heading"></v-skeleton-loader>
        </v-col>
        <v-col cols="3" lg="3" class="text-center">
          <v-skeleton-loader type="image, card-heading, image, card-heading"></v-skeleton-loader>
        </v-col>
        <v-col cols="3" lg="3" class="text-center">
          <v-skeleton-loader type="image, card-heading, image, card-heading"></v-skeleton-loader>
        </v-col>
        <v-col cols="3" lg="3" class="text-center">
          <v-skeleton-loader type="image, card-heading, image, card-heading"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>

    <!-- Pick an option-->
    <PresetBoxesList v-if="!loading && allBoxes.length" :allBoxes="allBoxes"/>

  </v-app>
</template>

<script>
import PresetBoxesList from "./components/PresetBoxesList"

export default {
  name: 'PresetBoxes',
  components: {
    PresetBoxesList,
  },

  mounted() { },

  computed: {
    allBoxes() {
      return this.$store.getters.presetBoxes
    }
  },

  data() {
    return {
      loading: true
    }
  },

  methods: {},

  created() {
    this.$store.dispatch('GET_PRESET_LIST').then(
      response => {
        if (response.status == 'success') {
            this.loading = false
        }
      },
      error => {
        this.loading = false
        throw error
      }
    )
  }
}
</script>

<style scoped>
</style>
