<template>
  <v-app>
    <div class="d-flex justify-center align-center overflow-auto" style="min-height: 80vh;">
      <!-- <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay> -->
      <v-container>
        <v-row class="justify-center my-10">
          <v-col cols="12" sm="8" md="6" lg="5" xl="4">
            <div>
              <div class="fill-height">
                <v-img :src="require('@/assets/images/emptycart.png')" class="img-fluid mx-auto" width="200px"></v-img>
              </div>
              <!-- <div class="mb-2">
                <v-icon class="text-h1 primary--text">mdi-cart-remove</v-icon>
              </div> -->
              <h1 class="mb-3 text--primary font-weight-bold">Your cart is empty.</h1>
              <div class="mb-4 font-weight-extramedium text--black">Looks like you have not added anything to your cart. Go ahead & explore top Swagsboxes.</div>
              <v-btn :to="{ name: 'ProductOnboarding', }" class="font-weight-bold primary text-none rounded-sm py-6 mx-2">Shop swagbox</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-app>
</template>

<script>
  export default {
    name: 'CartEmpty',
    components: {},
    mounted() {},
    computed: {},
    data() {
      return {}
    },
  }
</script>

<style scoped>
</style>
