<template>
  <v-app>
    <!-- Loader -->
    <!-- <div class="loader-full">
        <v-progress-circular :size="65" :width="3" color="primary" indeterminate>
          <v-img :src="require('@/assets/images/swagbox-icon.png')" contain alt="Swagbox Logo" height="36"></v-img>
        </v-progress-circular>
      </div> -->
    <!-- Loader -->
    <v-container fluid v-if="order">
      <v-row class="justify-center">
        <v-col cols="12" class="indigo lighten-5">
          <h1 class="text-body-1 font-weight-bold">Please upload an artwork so we can start processing your order :
            <span class="font-weight-black">{{ order.number }}</span>
          </h1>
        </v-col>
        <v-col cols="12" lg="6" class="text-center my-3" v-if="!alreadyUpdated">
          <div class="text-h5 text--primary font-weight-bold mb-3">Upload the logo or artwork you'd like to put on your
            swagbox</div>
          <p class="text--primary">We recommend uploading vector or pdf file to get the best print quality
            possible. SVGs, PDFs, EPSs and high quality PNGs with 300DPI would work great.</p>
        </v-col>
      </v-row>
    </v-container>
    <!-- <validation-observer ref="observer" :key="formKey"> -->
    <v-form ref="form" :key="formKey" @submit="submitArtWork" v-if="!alreadyUpdated">
      <v-container>
        <v-row class="justify-center">
          <!-- PrimaryLogo -->
          <v-col cols="12" sm="6" md="5" lg="4">
            <div class="uploadinput">
              <label for="PrimaryLogo">
                <img :src="primaryLogoImage" class="img-fluid" v-if="primaryLogoImage">
                <embed v-if="pdfSrc" type="video/webm" :src="pdfSrc" width="100%"
                  style="max-height: 19rem; min-height: 19rem" />
              </label>
              <input type="file" id="PrimaryLogo" class="d-none" @change="previewImage($event, 'primary')">
              <!-- <validation-provider v-slot="{ errors }" rules="required" name="Primary Logo"> -->
              <v-text-field placeholder="Adrian" v-model="primaryLogoValidation" class="d-none" outlined dense required>
              </v-text-field>
              <!-- :error-messages="errors"
                    :error="errors.length !== 0" :hide-details="errors.length <= 0" -->
              <div class="v-text-field__details" v-if="primaryLogoError">
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <!-- <div class="v-messages__message" v-if="errors[0]">{{ errors[0] }}</div> -->
                    <div class="v-messages__message" v-if="primaryLogoError">{{ primaryLogoError }}</div>
                  </div>
                </div>
              </div>
              <!-- </validation-provider> -->
            </div>
          </v-col>

          <!-- secondaryLogo -->
          <v-col cols="12" sm="6" md="5" lg="4">
            <div class="uploadinput">
              <label for="secondaryLogo">
                <img :src="secondaryLogoImage" class="img-fluid" v-if="secondaryLogoImage">
                <embed v-if="secondaryPdfSrc" type="video/webm" :src="secondaryPdfSrc" width="100%"
                  style="max-height: 19rem; min-height: 19rem" />
              </label>
              <input type="file" id="secondaryLogo" class="d-none" @change="previewImage($event, 'secondary')">
              <!-- <validation-provider v-slot="{ errors }" rules="required" name="Secondary Logo"> -->
              <v-text-field placeholder="Adrian" v-model="secondaryLogoValidation" class="d-none" outlined dense
                required>
              </v-text-field>
              <!-- :error-messages="errors"
                :error="errors.length !== 0" :hide-details="errors.length <= 0" -->
              <div class="v-text-field__details" v-if="secondaryLogoError">
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <!-- <div class="v-messages__message" v-if="errors[0]">{{ errors[0] }}</div> -->
                    <div class="v-messages__message" v-if="secondaryLogoError">{{ secondaryLogoError }}</div>
                  </div>
                </div>
              </div>
              <!-- </validation-provider> -->
            </div>
          </v-col>
        </v-row>
        <div cols="12" class="my-4">
          <v-btn color="primary" type="submit" class="text-none rounded-0 py-6 font-weight-bold" :disabled="notValid">
            Submit Artwork</v-btn>
        </div>
        <!-- {{ order.details.primary_logo ? order.details.primary_logo : '' }} -->
      </v-container>
    </v-form>
    <v-container v-if="alreadyUpdated">
      <div class="d-flex justify-center align-center overflow-auto" style="min-height:70vh;">
        <div>
          <div class="text-center">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" class="checkmark mx-auto mb-10">
              <circle class="path circle" fill="none" stroke="#1b5e20" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
              <polyline class="path check" fill="none" stroke="#1b5e20" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
            </svg>
          </div>
          <h1 class="font-weight-black text--primary text-lg-h4">Artwork already submitted</h1>
        </div>
      </div>
    </v-container>
    <!-- </validation-observer> -->
    <!-- Loader -->
    <div class="loader-full" v-if="loading">
      <v-progress-circular :size="65" :width="3" color="primary" indeterminate>
        <v-img :src="require('@/assets/images/swagbox-icon.png')" contain alt="Swagbox Logo" height="36"></v-img>
      </v-progress-circular>
    </div>
    <!-- Loader -->
  </v-app>
</template>

<script>
export default {
  name: 'UploadArtwork',
  components: {},
  mounted() { },

  data() {
    return {
      logo: null,
      formKey: (Math.random() + 1).toString(36).substring(7),
      primaryLogoImage: require('@/assets/images/primary-logo.png'),
      primaryLogoValidation: null,
      secondaryLogoImage: require('@/assets/images/secondary-logo.png'),
      secondaryLogoValidation: null,
      primaryLogoError: null,
      secondaryLogoError: null,
      primary_logo: null,
      secondary_logo: null,
      loading: true,
      pdfSrc: null,
      secondaryPdfSrc: null,
      states: [],
      notValid: true,
      alreadyUpdated: false
    }
  },

  computed: {
    order() {
      return this.$store.getters.order
    }
  },

  methods: {
    previewImage(event, imageType) {
      let fileType = event.target.files[0]['type'];
      let allowedTypes = ['application/pdf', 'application/postscript', 'image/svg+xml', 'image/png', 'image/jpeg']

      if (allowedTypes.includes(fileType)) {
        if (imageType == 'primary') {
          this.primary_logo = event.target.files[0]
          this.notValid = false
          this.primaryLogoError = null
          this.primaryLogoValidation = 'imageAdded'
          if (fileType == 'application/pdf') {
            this.primaryLogoImage = require('@/assets/images/pdf-preview.png')
            // this.primaryLogoImage = null
            // this.pdfSrc = URL.createObjectURL(event.target.files[0]) + '#toolbar=0&navpanes=0&scrollbar=0'
            return
          } else if (fileType == 'application/postscript') {
            this.primaryLogoImage = require('@/assets/images/eps-preview.png')
            return
          } else if (fileType == 'image/svg+xml') {
            this.primaryLogoImage = URL.createObjectURL(event.target.files[0])
            return
          } else {
            this.primaryLogoImage = URL.createObjectURL(event.target.files[0])
            // const reader = new FileReader();

            // reader.readAsDataURL(event.target.files[0]);
            // reader.onload = (e) => {
            //   const image = new Image();
            //   image.src = e.target.result;
            //   image.onload = (e) => {
            //     const height = e.target.height;
            //     const width = e.target.width;
            //     // this.height = height
            //     // this.width = width
            //     console.log(this.height)
            //     if ((width >= 2000 || height >= 2000)) {
            //       this.customerInfo.primary_logo = event.target.files[0]
            //       this.primaryLogoError = null
            //       this.primaryLogoValidation = 'imageAdded'
            //       this.primaryLogoImage = URL.createObjectURL(event.target.files[0])
            //     } else {
            //       this.$store.commit("showAlert", {
            //         text: 'Image is low resolution, please upload high resolution image',
            //         successStatus: false,
            //         alertColor: 'red darken-4'
            //       });
            //     }
            //   };
            // };

          }

        } else if (imageType == 'secondary') {
          this.secondary_logo = event.target.files[0]
          this.notValid = false
          this.secondaryLogoError = null
          this.secondaryLogoValidation = 'imageAdded'
          if (fileType == 'application/pdf') {
            this.secondaryLogoImage = require('@/assets/images/pdf-preview.png')
            // this.secondaryLogoImage = null
            // this.secondaryPdfSrc = URL.createObjectURL(event.target.files[0]) + '#toolbar=0&navpanes=0&scrollbar=0'
            return
          } else if (fileType == 'application/postscript') {
            this.secondaryLogoImage = require('@/assets/images/eps-preview.png')
            return
          } else if (fileType == 'image/svg+xml') {
            this.secondaryLogoImage = require('@/assets/images/pdf-preview.png')
            return
          } else {
            this.secondaryLogoImage = URL.createObjectURL(event.target.files[0])
            // const reader = new FileReader();

            // reader.readAsDataURL(event.target.files[0]);
            // reader.onload = (e) => {
            //   const image = new Image();
            //   image.src = e.target.result;
            //   image.onload = (e) => {
            //     const height = e.target.height;
            //     const width = e.target.width;
            //     // this.height = height
            //     // this.width = width
            //     console.log(this.height)
            //     if ((width >= 2000 || height >= 2000)) {
            //       this.customerInfo.secondary_logo = event.target.files[0]
            //       this.secondaryLogoError = null
            //       this.secondaryLogoValidation = 'imageAdded'
            //       this.secondaryLogoImage = URL.createObjectURL(event.target.files[0])
            //     } else {
            //       this.$store.commit("showAlert", {
            //         text: 'Image is low resolution, please upload high resolution image',
            //         successStatus: false,
            //         alertColor: 'red darken-4'
            //       });
            //     }
            //   };
            // };

          }
        }
      } else {
        if (imageType == 'primary') {
          this.primaryLogoError = 'Invalid File type'
        } else {
          this.secondaryLogoError = 'Invalid File type'
        }
      }
    },

    submitArtWork(e) {
      e.preventDefault()
      // this.$refs.observer.validate().then(success => {
      //   if (!success) {
      //     console.log('Failed')
      //     return
      //   }
      this.loading = true
      let formData = new FormData();
      formData.append('primary_logo', this.primary_logo)
      formData.append('secondary_logo', this.secondary_logo)
      formData.append('order_id', this.order.id)
      this.$store
        .dispatch('UPLOAD_ART_WORK', formData)
        .then(
          response => {
            console.log(response)
            this.loading = false
            this.$store.commit("showAlert", {
              text: response.message,
              successStatus: true,
            });
            this.$router.push({ name: 'Home' })
          },
          error => {
            this.loading = false
            this.$store.commit("showAlert", {
              text: error.data.message,
              successStatus: false,
              alertColor: 'red darken-4'
            });
            throw error
          }
        )
        .catch(error => {
          this.loading = false
          if (error.data.errors)
            this.$refs.observer.setErrors(error.data.errors)
        })
      // })
    }
  },

  created() {
    this.$store.dispatch('GET_ORDER', { order_id: this.$route.params.order_id }).then(
      response => {
        if (response.order.details.primary_logo != 'null' || response.order.details.secondary_logo != 'null') {
          this.alreadyUpdated = true
        }
        this.loading = false
      },
      error => {
        // this.loading = false
        throw error
      }
    )
  }
}
</script>

<style scoped>
.action-buttons {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.active .v-sheet--outlined {
  border: 1px solid #071A52;
}

@media (max-width: 600px) {
  .action-buttons {
    position: relative;
  }
}
</style>
