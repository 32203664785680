<template>
    <v-app>
        <v-container fluid>
            <v-row class="align-center">
                <v-col cols="6" class="text-left">
                    <h1 class="text-h5 font-weight-bold">Shipment</h1>
                </v-col>
                <v-col cols="6" class="text-right">
                    <v-btn :to="{ name: 'ProductOnboarding', }" class="font-weight-bold primary text-none rounded-sm py-6 mx-2">
                        Create shipment
                    </v-btn>
                </v-col>
            </v-row>

            <v-row class="mt-0 mb-10">
                <v-col cols="12 px-0">

                    <!-- Tab Bar -->
                    <ShipmentTabs />
                    <!-- Tab Bar -->

                    <v-row class="my-1">
                        <!-- Search shipments -->
                        <v-col cols="12" lg="3">
                            <v-text-field placeholder="Search shipments" outlined dense hide-details required prepend-inner-icon="mdi-magnify">
                            </v-text-field>
                        </v-col>
                        <!-- Sort by: -->
                        <v-col cols="12" lg="2">
                            <v-select outlined dense hide-details>
                                <template v-slot:prepend-inner>
                                    <div class="text-no-wrap py-1">Sort by:</div>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>

                    <!-- Holidays Pack 10  x 10 -->
                    <v-card elevation="2" class="rounded-lg py-2 my-2 mx-1 mb-4 detailcard" :to="{ name: 'ShipmentDetails', }">
                        <v-row class="w-100 mx-0 align-center">
                            <v-col cols="6" class="text-left">
                                <div class="d-flex align-center">
                                    <div>
                                        <v-img src="https://swagify-storage-bucket.s3.us-west-1.amazonaws.com/products/thumbnails/ecmb-thumbnail.png" contain width="60px" height="60px" class="img-fluid"></v-img>
                                    </div>
                                    <div class="ml-2">
                                        <div class="text-body-1 font-weight-extramedium">Holidays Pack 10 x 10</div>
                                        <div class="text-body-2 grey--text ">Shipped to one address
                                            <v-chip small class="font-weight-extramedium ml-2 primary--text blue-grey lighten-4">Swagbox</v-chip>
                                            <v-chip small class="font-weight-extramedium ml-2 grey lighten-3">Order no. SWAG21432</v-chip>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="6" class="text-lg-right">
                                <div class="d-flex align-center justify-sm-end">
                                    <span class="green--text text-body-2 text--lighten-3 font-weight-extramedium">Shipped on Sep, 24, 2022</span>
                                    <div class="d-inline-block ml-2">
                                        <v-img :src="require('@/assets/images/svg/scheduled-icon.svg')" contain height="36px" width="36px"></v-img>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>

                    <!-- The Silo Bottle - 16.9 oz.  x 70 -->
                    <v-card elevation="2" class="rounded-lg py-2 my-2 mx-1 mb-4 detailcard" :to="{ name: 'ShipmentDetails', }">
                        <v-row class="w-100 mx-0 align-center">
                            <v-col cols="6" class="text-left">
                                <div class="d-flex align-center">
                                    <div>
                                        <v-img src="https://swagify-storage-bucket.s3.us-west-1.amazonaws.com/products/thumbnails/tsb169-matte-navy-front.png" contain width="60px" height="60px" class="img-fluid"></v-img>
                                    </div>
                                    <div class="ml-2">
                                        <div class="text-body-1 font-weight-extramedium">The Silo Bottle - 16.9 oz. x 70</div>
                                        <div class="text-body-2 grey--text ">Shipped to multiple addresses
                                            <v-chip small class="font-weight-extramedium ml-2 primary--text blue-grey lighten-4">Bulk item</v-chip>
                                            <v-chip small class="font-weight-extramedium ml-2 grey lighten-3">Order no. SWAG13221</v-chip>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="6" class="text-lg-right">
                                <div class="d-flex align-center justify-sm-end">
                                    <span class="primary--text text-body-2 font-weight-extramedium">Scheduled for Sep, 29, 2022</span>
                                    <div class="d-inline-block ml-2">
                                        <v-img :src="require('@/assets/images/svg/shipped-icon.svg')" contain height="36px" width="36px"></v-img>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>

                    <!-- Fellow Carter Everywhere Mug 16 oz  x 12 -->
                    <v-card elevation="2" class="rounded-lg py-2 my-2 mx-1 mb-4 detailcard" :to="{ name: 'ShipmentDetails', }">
                        <v-row class="w-100 mx-0 align-center">
                            <v-col cols="6" class="text-left">
                                <div class="d-flex align-center">
                                    <div>
                                        <v-img src="https://swagify-storage-bucket.s3.us-west-1.amazonaws.com/products/thumbnails/fcm16-matte-white-front.png" contain width="60px" height="60px" class="img-fluid"></v-img>
                                    </div>
                                    <div class="ml-2">
                                        <div class="text-body-1 font-weight-extramedium">Fellow Carter Everywhere Mug 16 oz x 12</div>
                                        <div class="text-body-2 grey--text ">Shipped to multiple addresses
                                            <v-chip small class="font-weight-extramedium ml-2 primary--text blue-grey lighten-4">Bulk item</v-chip>
                                            <v-chip small class="font-weight-extramedium ml-2 grey lighten-3">Order no. SWAG21432111</v-chip>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="6" class="text-lg-right">
                                <div class="d-flex align-center justify-sm-end">
                                    <span class="red--text text--darken-4 text-body-2 font-weight-extramedium">Canceled</span>
                                    <div class="d-inline-block ml-2">
                                        <v-img :src="require('@/assets/images/svg/canceled-icon.svg')" contain height="36px" width="36px"></v-img>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>

                    <!-- New Employees Pack   x 10 -->
                    <v-card elevation="2" class="rounded-lg py-2 my-2 mx-1 mb-4 detailcard" :to="{ name: 'ShipmentDetails', }">
                        <v-row class="w-100 mx-0 align-center">
                            <v-col cols="6" class="text-left">
                                <div class="d-flex align-center">
                                    <div>
                                        <v-img src="https://swagify-storage-bucket.s3.us-west-1.amazonaws.com/products/thumbnails/icm-thumbnail.png" contain width="60px" height="60px" class="img-fluid"></v-img>
                                    </div>
                                    <div class="ml-2">
                                        <div class="text-body-1 font-weight-extramedium">New Employees Pack   x 10</div>
                                        <div class="text-body-2 grey--text ">Shipped to one address
                                            <v-chip small class="font-weight-extramedium ml-2 primary--text blue-grey lighten-4">Swagbox</v-chip>
                                            <v-chip small class="font-weight-extramedium ml-2 grey lighten-3">Order no. SWAG21443</v-chip>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="6" class="text-lg-right">
                                <div class="d-flex align-center justify-sm-end">
                                    <span class="orange--text text--darken-4 text-body-2 font-weight-extramedium">Stored in warehouse</span>
                                    <div class="d-inline-block ml-2">
                                        <v-img :src="require('@/assets/images/svg/stored-icon.svg')" contain height="36px" width="36px"></v-img>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog v-model="viewproof" persistent fullscreen transition="dialog-bottom-transition"
            overlay-color="#f1f1f1" content-class="rounded-lg relative">
            <v-btn @click="viewproof = false" color="elevation-0 mt-10" fab absolute top right height="40" width="40">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-card>

            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
    import ShipmentTabs from "@/components/Dashboard/ShipmentTabs"
    export default {
        name: 'CustomerShipment',
        components: {
            ShipmentTabs,
        },
        data() {
            return {
                shipment: null,
            }
        },

        mounted() {},
        computed: {},
        methods: {},
        created() {}
    }
</script>

<style scoped>
    .v-card.v-card--link:hover {
        transform: scale(1.008) !important;
        border-bottom: 1px solid #d1d1d1;
    }

    .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child,
    .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row) {
        border-bottom: 0px;
    }
</style>
