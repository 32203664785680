<template>
  <v-app>
    <v-container fluid v-if="order">
      <!-- <v-progress-circular :size="65" :width="3" color="primary" indeterminate>
        <v-img :src="require('@/assets/images/swagbox-icon.png')" contain alt="Swagbox Logo" height="36"></v-img>
      </v-progress-circular> -->
      <v-row>
        <v-col cols="12" class="indigo lighten-5">
          <h1 class="text-body-1 font-weight-bold">Approve your proofs to finalize this order:
            <span class="font-weight-black">{{ order.number }}</span>
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="5">
          <v-card v-model="onboarding" class="mb-3 elevation-0 rounded-0 overflow-hidden" max-height="400">
            <!-- main Images -->
            <div class="fill-height">
              <v-img mg :src="selectedArtWork.image" contain aspect-ratio="1.5" v-if="selectedProof == 'Image'"></v-img>

              <!-- PDf preview START-->
              <v-img :src="require('@/assets/images/icons/proof.png')" contain aspect-ratio="1.7" v-else
                class="ma-4"></v-img>
              <!-- PDf preview END-->
            </div>
          </v-card>
          <div v-if="selectedProof != 'Image'">
            <v-btn color="primary" class="text-none font-weight-medium rounded-xl px-4 py-2 mr-2"
              @click="downloadProof(selectedArtWork)">Download</v-btn>
            <v-btn outlined class="text-none font-weight-medium rounded-xl px-4 py-2" @click="downloadProof()">Download
              all</v-btn>
          </div>
        </v-col>

        <v-divider vertical class="d-none d-md-block"></v-divider>

        <v-col cols="12" lg="7" class="relative pt-0">
          <div class="max-ftr-h overflow-auto" style="max-height: 500px;">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th style="max-width:180px;">Item</th>
                    <th>Status</th>
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(variant, variantIndex) in order.variants">
                    <template v-for="(art_work, artIndex) in variant.art_works">
                      <tr dense v-if="art_work.status != 'replaced'"
                        :class="selectedArtWork.id == art_work.id ? 'active' : ''" :key="art_work.image">
                        <td class="pa-1" :class="artIndex" :key="variantIndex" @click="updateSelectedArtWork(art_work)">
                          <div class="d-flex align-center">
                            <v-sheet height="50px" min-width="50px" outlined rounded="lg">
                              <v-img :src="getArtWorkImage(art_work.image)" contain aspect-ratio="1" rounded-lg
                                rounded="lg"></v-img>

                              <!-- PDf preview START-->
                              <!-- <v-img :src="require('@/assets/images/icons/pdf.png')" contain aspect-ratio="1"
                                rounded="lg" class="ma-1"></v-img> -->
                              <!-- PDf preview END-->
                            </v-sheet>
                            <div class="ml-2 font-weight-extramedium">{{ variant.name }}</div>
                          </div>
                        </td>
                        <td class="text-left pa-2" style="max-width:100px;">
                          <v-chip small color="orange lighten-5"
                            class="orange--text text--darken-4 font-weight-extramedium"
                            v-if="art_work.status == 'Pending'">Need Approval</v-chip>
                          <v-chip small color="green lighten-5"
                            class="green--text text--darken-4 font-weight-extramedium"
                            v-if="art_work.status == 'Approved'">Approved
                          </v-chip>
                          <v-chip small color="indigo lighten-5" class="primary--text font-weight-extramedium"
                            v-if="art_work.status == 'Changes_Requested'">Requested</v-chip>
                        </td>
                        <td class="text-right pa-2">
                          <div v-if="selectedArtWork.id == art_work.id">
                            <v-btn small text color="primary" class="text-none font-weight-medium mx-2 px-0"
                              @click.stop="openRequestPopup(variant.name)" v-if="art_work.status == 'Pending'">Request
                              changes
                            </v-btn>
                            <v-btn small color="primary" class="text-none font-weight-medium rounded-xl px-2"
                              v-if="art_work.status == 'Pending'" @click="approveArtWork">Approve
                              Proof
                            </v-btn>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- Request changes Dialog form START-->
    <v-dialog v-model="RequestChanges" max-width="600px" overlay-color="#f1f1f1" content-class="rounded-xl relative"
      scrollable>
      <v-btn @click="RequestChanges = false" color="elevation-0 mt-10" fab absolute top right height="40" width="40">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
      <v-card>
        <v-card-title class="text-h6 primary--text">
          Request changes for <span class="font-weight-bold ml-1">{{ variantName }}</span>
        </v-card-title>
        <validation-observer ref="observer">
          <v-form ref="form">

            <v-card-text class="my-6">
              <validation-provider v-slot="{ errors }" rules="required" name="Request Changes">
                <v-textarea dense outlined v-model="requestMessage" :error-messages="errors"
                  :error="errors.length !== 0" :hide-details="errors.length <= 0" class="ma-0 pa-0" rows="3"
                  placeholder="Type your message here..">
                </v-textarea>
              </validation-provider>
            </v-card-text>

          </v-form>
        </validation-observer>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" large class="text-none font-weight-bold rounded-0 ma-1 px-2 px-lg-3"
            @click="submitReview">
            Submit Request
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Request changes Dialog form END-->
    <!-- Loader -->
    <div class="loader-full" v-if="loading">
      <v-progress-circular :size="65" :width="3" color="primary" indeterminate>
        <v-img :src="require('@/assets/images/swagbox-icon.png')" contain alt="Swagbox Logo" height="36"></v-img>
      </v-progress-circular>
    </div>
    <!-- Loader -->
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  name: 'ProofAction',
  components: {},
  mounted() { },
  data() {
    return {
      RequestChanges: null,
      onboarding: 0,
      variantName: null,
      selectedArtWork: {},
      loading: true,
      requestMessage: null,
      slider: [{
        src: require('@/assets/images/speedup-socks-and-stickers.png')
      },
      {
        src: require('@/assets/images/classics-pack.png')
      },
      {
        src: require('@/assets/images/cozy-and-calm.png')
      },
      {
        src: require('@/assets/images/happy-camper.png')
      },
      {
        src: require('@/assets/images/the-chief-swag-officer.png')
      },
      {
        src: require('@/assets/images/the-outdoor-enthusiast.png')
      },
      {
        src: require('@/assets/images/the-rebrand-plus.png')
      },
      {
        src: require('@/assets/images/the-road-warrior.png')
      },
      ],
      selectedProof: 'Image'
    }
  },

  computed: {
    order() {
      return this.$store.getters.order
    }
  },

  methods: {
    setSelectedArtwork() {
      this.$store.getters.order.variants.forEach((variant, key) => {
        if (variant.art_works?.length > 0) {
          variant.art_works.forEach((artWork, key) => {
            if (Object.keys(this.selectedArtWork).length == 0) {
              if (artWork.status != 'replaced') {
                if (this.isImage(artWork.image)) {
                  this.selectedProof = 'Image'
                } else {
                  this.selectedProof = 'Not Image'
                }
                this.selectedArtWork = artWork
              }
            }
          })
        }

      })
    },

    async downloadPDF(pdfLink) {
        try {
          const response = await fetch(pdfLink);
          if (!response.ok) {
            throw new Error(`Failed to fetch PDF: ${response.status} ${response.statusText}`);
          }
          const blob = await response.blob();
          const date = new Date();
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `proof-${date.toISOString().slice(0, 10)}-${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}-${date.getMilliseconds()}.` + pdfLink.split(".").pop();
          link.click();
        } catch (error) {
          console.error(error);
        }
    },

    async downloadProof(artWork = false) {
      if (artWork) {
        if (artWork.status != 'replaced' && !this.isImage(artWork.image)) {
          this.downloadPDF(artWork.image)
        }
      } else {
        if (this.order.variants?.length) {
          this.order.variants.forEach(function (variant) {
            if (variant.art_works && variant.art_works.length) {
              variant.art_works.forEach(function (artWork) {
                console.log(artWork.status)
                if (artWork.status != 'replaced' && !this.isImage(artWork.image)) {
                  this.downloadPDF(artWork.image)
                }
              }.bind(this))
            }
          }.bind(this))
        }
      }
    },

    getArtWorkImage(image) {
      if (this.isImage(image)) {
        return image
      } else {
        return require('@/assets/images/icons/pdf.png')
      }
    },

    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    },

    updateSelectedArtWork(artWork) {
      if (this.isImage(artWork.image)) {
        this.selectedProof = 'Image'
      } else {
        this.selectedProof = 'Not Image'
      }
      this.selectedArtWork = artWork
    },

    openRequestPopup(variantName) {
      this.requestMessage = null
      this.variantName = variantName
      this.RequestChanges = !this.RequestChanges
    },

    submitReview() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }
        this.loading = true
        // let payload = {
        //   art_work: this.selectedArtWork,
        //   order_id: this.order.id,
        //   request_message: this.requestMessage
        // }

        let formData = new FormData();
        formData.append('art_work', JSON.stringify(this.selectedArtWork))
        formData.append('order_id', this.order.id)
        formData.append('request_message', this.requestMessage)

        this.$store.dispatch('SUBMIT_ARTWORK_CHANGES', formData).then(
          response => {
            this.setSelectedArtwork()
            this.RequestChanges = false
            this.$store.commit("showAlert", {
              text: response.message,
              successStatus: true,
            });
            this.loading = false
          },
          error => {
            this.loading = false
            this.$store.commit("showAlert", {
              text: error.data.message,
              successStatus: false,
              alertColor: 'red darken-4'
            });
            // if (error.data.redirect) {
            //   this.$router.push({ name: 'Home' })
            // }
            throw error
          }
        )
      });
    },

    approveArtWork() {
      this.loading = true
      let payload = {
        art_work: this.selectedArtWork,
        order_id: this.order.id
      }
      this.$store.dispatch('APPROVE_ART_WORK', payload).then(
        response => {
          this.setSelectedArtwork()
          this.$store.commit("showAlert", {
            text: response.message,
            successStatus: true,
          });
          // this.$router.push({ name: 'Home' })
          this.loading = false
        },
        error => {
          this.loading = false
          this.$store.commit("showAlert", {
            text: error.data.message,
            successStatus: false,
            alertColor: 'red darken-4'
          });
          // if (error.data.redirect) {
          //   this.$router.push({ name: 'Home' })
          // }
          throw error
        }
      )
    }
  },

  created() {
    this.$store.dispatch('GET_ORDER', { order_id: this.$route.params.order_id }).then(
      response => {
        this.setSelectedArtwork()
        console.log(response)
        this.loading = false
      },
      error => {
        this.loading = false
        this.$store.commit("showAlert", {
          text: error.data.message,
          successStatus: false,
          alertColor: 'red darken-4'
        });
        if (error.data.redirect) {
          this.$router.push({ name: 'Home' })
        }
        throw error
      }
    )
  }
}
</script>

<style scoped>
.action-buttons {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.active .v-sheet--outlined {
  border: 1px solid #071A52;
}

.v-image .v-image__image {
  border-radius: 20px !important;
}

@media (max-width: 600px) {
  .action-buttons {
    position: relative;
  }
}
</style>
