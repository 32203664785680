<template>
    <!-- <v-app> -->
    <div class="loader-full">
        <v-progress-circular :size="65" :width="3" color="primary" indeterminate>
            <v-img :src="require('@/assets/images/swagbox-icon.png')" contain alt="Swagbox Logo" height="36">
            </v-img>
        </v-progress-circular>
    </div>
    <!-- </v-app> -->
</template>
  
<script>
export default {
    name: 'PageLoader',
    components: {},
    data() {
        return {
        }
    },
    mounted() { },
    computed: {},
    methods: {},
    created() { }
}
</script>

  