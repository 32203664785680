<template>
  <v-card class="sticky-top elevation-0 fixed" fixed>
    <div class="text-h5 text--primary text-left font-weight-bold mb-3">Order summary</div>
    <v-container>
      <v-row>
        <v-col cols="6">
          <div class="text-left">Number of swagboxes</div>
        </v-col>
        <v-col cols="6">
          <div class="text-right text--primary font-weight-bold">
            <!-- <validation-observer ref="observer">
              <v-form ref="form">
                <validation-provider v-slot="{ errors }" rules="required" name="Number of swagboxes">
                  <v-text-field placeholder="" type="number" v-model="$store.getters.getCart.quantity"
                    :error-messages="errors" :error="errors.length !== 0" :hide-details="errors.length <= 0" outlined
                    dense required>
                  </v-text-field>
                </validation-provider>
              </v-form>
            </validation-observer> -->
            {{ $store.getters.getCart.quantity }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <div class="text-left">Cost per swagbox</div>
        </v-col>
        <v-col cols="6">
          <div class="text-right text--primary font-weight-bold">{{
          $helper.functions.costPerSwagbox($store.state.cart?.box_products, this.$store.getters.getCart?.quantity,
          this.$store.getters.logoPrice, this.$store.getters.getQuantityLimits, numberOfLogos)
          }}</div>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="6">
          <div class="text-left">Setup fee</div>
        </v-col>
        <v-col cols="6">
          <div class="text-right text--primary font-weight-bold">$36.00</div>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="6">
          <div class="text-left">Total</div>
        </v-col>
        <v-col cols="6">
          <div class="text-right text--primary font-weight-black">{{ subTotal }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn class="font-weight-bold primary text-none rounded-sm py-6" width="100%"
            :disabled="$store.getters.getCart?.box_products?.length ? false : true" @click="proceedToPayment">
            Proceed to Payment</v-btn>
          <p class="my-4 text--primary">You can choose to <span class="font-weight-bold">pay later</span> at next step
            if you don’t want to pay yet.</p>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'OrderSummary',

  components: {},

  mounted() { },

  computed: {
    logoPrice() {
      return this.$store.getters.logoPrice
    },

    numberOfLogos() {
      return this.$store.getters.numberOfLogos
    },

    subTotal() {
      if (this.$store.state.cart?.box_products) {
        let productsPrice = this.$helper.functions.getBoxPrice(this.$store.state.cart?.box_products, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
        return productsPrice.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
        // let minQuantity = this.$store.getters.getCart.quantity
        // return (productsPrice * minQuantity).toLocaleString('en-US', {
        //   style: 'currency',
        //   currency: 'USD',
        // })
      } else {
        let price = 0
        return price.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
      }
      // let minQuantity = this.$store.getters.getCart.quantity
      // return (productsPrice * minQuantity).toLocaleString('en-US', {
      //   style: 'currency',
      //   currency: 'USD',
      // })
    }
  },

  data() {
    return {}
  },

  methods: {
    proceedToPayment() {
      // this.$refs.observer.validate().then(success => {
      //   if (!success) {
      //     return
      //   }
      this.$root.$emit('validateForm');
      // });
    },

    askForPayment(event) {
      console.log(event)
    },

    getCartPrice(products) {
      return this.$helper.functions.getBoxPrice(products).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    getTotalPrice() {
      let productsPrice = this.$helper.functions.getBoxPrice(this.$store.getters.getCart.box_products)
      let minQuantity = this.$store.getters.getCart.quantity
      return (productsPrice * minQuantity).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    }
  },

  created() {
    this.$root.$on('isFormValid', function (event) {
      if (event.isFormValid) {
        this.$store.commit('storeCustomerInfo', event.formData)
        this.$router.push({ name: 'PaymentOptions' })
      }
    });
  }
}
</script>

<style scoped>

</style>
