<template>
    <v-app>
        <v-container fluid class="px-0">
            <template v-if="loading">
                <Loader />
            </template>
            <template v-if="!loading">
                <v-row>
                    <v-col cols="12">
                        <!-- Title -->
                        <div class="d-flex mt-4">
                            <v-btn @click="$router.go(-1)" class="mx-2" fab x-small depressed>
                                <v-icon dark>mdi-chevron-left</v-icon>
                            </v-btn>
                            <div class="d-flex align-center mb-1 flex-wrap">
                                <h1 class="text-h6 font-weight-bold primary--text d-inline-block mr-1">Add contact</h1>
                            </div>
                        </div>
                    </v-col>
                </v-row>

                <v-form>
                    <v-row>
                        <!-- Upload photo -->
                        <v-col cols="12" sm="4" md="3" lg="2" class="text-sm-left">
                            <v-file-input v-model="image" id="profilepic" class="d-none" />
                            <label for="profilepic" class="contactpic relative mx-auto mx-sm-0 ml-lg-3">
                                <div class="placeholder">
                                    <img src="@/assets/images/svg/user.svg" class="img-fluid">
                                    <div class="text-body-2 font-weight-extramedium primary--text mt-2">Upload photo</div>
                                </div>
                                <v-img :src="url" contain class="img-fluid"></v-img>
                            </label>
                        </v-col>

                        <!-- Form -->
                        <v-col cols="12" sm="8" md="9" lg="10">
                            <v-row>
                                <!-- First name -->
                                <v-col cols="12" sm="6">
                                    <v-subheader class="px-0 text--primary font-weight-bold">First name *</v-subheader>
                                    <validation-provider rules="required" name="First Name">
                                        <v-text-field placeholder="Adian" hide-details outlined dense required></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <!-- Last name -->
                                <v-col cols="12" sm="6">
                                    <v-subheader class="px-0 text--primary font-weight-bold">Last name *</v-subheader>
                                    <validation-provider rules="required" name="Last name">
                                        <v-text-field placeholder="Baker" hide-details outlined dense required></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <!-- Company name -->
                                <v-col cols="12" sm="6">
                                    <v-subheader class="px-0 text--primary font-weight-bold">Company name *</v-subheader>
                                    <validation-provider rules="required" name="Company name">
                                        <v-text-field placeholder="Adrian’s T-Shirts" hide-details outlined dense required></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <!-- Title or Position -->
                                <v-col cols="12" sm="6">
                                    <v-subheader class="px-0 text--primary font-weight-bold">Title or Position</v-subheader>
                                    <validation-provider name="Title or Position">
                                        <v-text-field placeholder="Executive" hide-details outlined dense></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <!-- Email address -->
                                <v-col cols="12" sm="6">
                                    <v-subheader class="px-0 text--primary font-weight-bold">Email address *</v-subheader>
                                    <validation-provider rules="required" name="Email address">
                                        <v-text-field placeholder="adrian@example.com" hide-details outlined dense required></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <!-- Shirt size -->
                                <v-col cols="12" sm="6">
                                    <v-subheader class="px-0 text--primary font-weight-bold">Shirt size *</v-subheader>
                                    <validation-provider rules="required" name="Shirt size">
                                        <v-select v-model="shirtsize" :items="sizelist" item-text="sizes" hide-details outlined dense required></v-select>
                                    </validation-provider>
                                </v-col>

                                <!-- Address 1 -->
                                <v-col cols="12" sm="6">
                                    <v-subheader class="px-0 text--primary font-weight-bold">Address 1 *</v-subheader>
                                    <validation-provider rules="required" name="Address 1">
                                        <v-text-field placeholder="apartment , suite , floor etc" hide-details outlined dense required></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <!-- Address 2 -->
                                <v-col cols="12" sm="6">
                                    <v-subheader class="px-0 text--primary font-weight-bold">Address 2</v-subheader>
                                    <validation-provider name="Address 2">
                                        <v-text-field placeholder="Optional" hide-details outlined dense></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <!-- City -->
                                <v-col cols="12" sm="6">
                                    <v-subheader class="px-0 text--primary font-weight-bold">City *</v-subheader>
                                    <validation-provider rules="required" name="City">
                                        <v-text-field placeholder="New york" hide-details outlined dense required></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <!-- State -->
                                <v-col cols="12" sm="6">
                                    <v-subheader class="px-0 text--primary font-weight-bold">State *</v-subheader>
                                    <validation-provider rules="required" name="State">
                                        <v-text-field placeholder="New york" hide-details outlined dense required></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <!-- Zip code -->
                                <v-col cols="12" sm="6">
                                    <v-subheader class="px-0 text--primary font-weight-bold">Zip code *</v-subheader>
                                    <validation-provider rules="required" name="Zip code">
                                        <v-text-field placeholder="zipcode" hide-details outlined dense required></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <!-- Phone no. -->
                                <v-col cols="12" sm="6">
                                    <v-subheader class="px-0 text--primary font-weight-bold">Phone no. *</v-subheader>
                                    <validation-provider rules="required" name="Phone no">
                                        <v-text-field placeholder="+1(###)-###-####" hide-details outlined dense required></v-text-field>
                                    </validation-provider>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="12" class="mt-10 text-sm-right">
                            <v-divider></v-divider>
                            <div class="mt-4">
                                <v-btn depressed class="text-none rounded-sm mx-1 py-6 font-weight-bold grey--text">Delete contact</v-btn>
                                <v-btn color="primary" class="text-none rounded-sm mx-1 py-6 font-weight-bold">Save contact</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-form>
            </template>
        </v-container>
    </v-app>
</template>

<script>
    import Loader from "@/components/Loader"
    // import NwImg from "@/components/NwImg"
    export default {
        name: 'AddNewContact',
        components: {
            Loader,
            // NwImg
        },
        data() {
            return {
                loading: false,
                image: null,
                shirtsize: {
                    sizes: 'LG'
                },
                sizelist: [{
                    sizes: '3XL'
                }, {
                    sizes: '2XL'
                }, {
                    sizes: 'XL'
                }, {
                    sizes: 'LG'
                }, {
                    sizes: 'MD'
                }, {
                    sizes: 'SM'
                }, {
                    sizes: 'XS'
                }]
            }
        },

        mounted() {},

        computed: {
            url() {
                if (!this.image) return;
                return URL.createObjectURL(this.image);
            }
        },
        methods: {},

        created() {}
    }
</script>

<style scoped>
    .v-subheader {
        height: auto;
        margin-bottom: 10px;
    }
</style>
