<template>
  <!-- <v-app> -->
  <v-card height="100%">
    <!-- Header title -->
    <v-card-title class="px-2 text-center my-lg-3 mt-6">
      <v-toolbar class="elevation-0 pa-0 transparent text-center" height="auto">
        <div style="width:100%">
          <v-toolbar-title class="text--primary text-subtitle-1 text-md-h5 font-weight-black text-wrap">I would like to
            order
            <div class="input-sizer">
              <input type="type" onInput="this.parentNode.dataset.value = this.value" size="1" @input="updateQuantity"
                v-model="quantity" @change="resetQuantity" class="green--text text--lighten-2">
            </div>
            boxes
            <small class="text-body-2 font-weight-extramedium d-block d-sm-inline-block">(min qty. {{
            $store.getters.getCart.min_quantity }})</small>
          </v-toolbar-title>
        </div>
      </v-toolbar>
    </v-card-title>

    <!-- Item Grid -->
    <v-card-text>
      <v-sheet color="blue-grey" class="lighten-5 py-3 text-lg-body-1 font-weight-extramedium">Please select the sizes
        and distribute quantities for products in your cart.</v-sheet>
      <v-container fluid>
        <v-row>
          <!-- Plain mailer box -->
          <!-- <v-col cols="12" sm="6" md="4" lg="3">
            <v-card class="text-center pa-2 pa-md-4 rounded-lg product-card relative" height="100%">
              <div class="mb-3 pointer">
                <v-img :src="require('@/assets/images/plain-mailer-box.png')" cover height="200px"
                  class="img-fluid mx-auto rounded-lg">
                </v-img>
              </div>
              <div class="text-left">
                <v-card-title class="text-body-1 mb-2 font-weight-bold pa-0">
                  Plain mailer box
                </v-card-title>
                <div class="text-body-2 font-weight-extramedium mb-2">
                  Color: <span class="font-weight-bold">General</span>
                </div>
                <div class="gray--text text-body-2 font-weight-extramedium mb-2">
                  In cart: 50 (Doesn’t need distribution)
                </div>
                <div class="text-body-2 font-weight-extramedium mb-2">
                  Qty in each box: <span class="font-weight-bold green--text text--darken-1">1 </span>(Max 10 in each
                  box)
                </div>
              </div>
              <v-card-actions class="pa-0 my-6 transparent">
                <v-sheet class="d-flex justify-space-between align-center transparent action-btns">
                  <div>
                    <div class="primary--text font-weight-extramedium">Item price USD 3.00 <span
                        class="font-weight-extrabold">6.00</span></div>
                  </div>
                  <div>
                    <v-btn text color="priamry" class="text-caption font-weight-extramedium mx-1 text-none pa-0"
                      min-width="auto" height="auto"
                      @click.stop="openProductDetail(item, cartItemIds.includes(item.id))">Edit</v-btn>
                    <v-btn text color="red"
                      class="text-caption font-weight-extramedium mx-1 text-none pa-0 text--darken-3" min-width="auto"
                      height="auto">Remove</v-btn>
                  </div>
                </v-sheet>
              </v-card-actions>
            </v-card>
          </v-col> -->

          <!-- Unisex heavy cotton tee -->
          <v-col cols="12" sm="6" md="4" lg="3" v-for="(item, itemIndex) in cartItems" :key="itemIndex" class="mb-3">
            <v-card class="text-center pa-2 pa-md-4 rounded-lg product-card relative"
              :class="$helper.functions.getRemainingCartQuantity(item, item.quantity, $store.getters.getCart?.quantity) != 0 ? 'invalid' : ''"
              height="100%">
              <div class="mb-3 pointer" @click.stop="openProductDetail(item)">
                <v-img :src="$helper.functions.getProdImage(item)" contain height="200px"
                  class="img-fluid mx-auto rounded-lg">
                </v-img>
              </div>
              <div class="text-left">
                <v-card-title class="text-body-1 mb-2 font-weight-bold pa-0">
                  {{ item.title }}
                </v-card-title>
                <div class="text-body-2 font-weight-extramedium mb-2">
                  Color: <v-avatar :color="$helper.functions.getColorCode(item.selected_color)" size="10">
                  </v-avatar><span class="font-weight-bold"> {{ $helper.functions.getColorName(item.selected_color)
                  }}</span>
                </div>
                <div class="text-body-2 font-weight-extramedium mb-2" v-if="item.sizes.length > 1">
                  Remaining in cart: <span class="font-weight-bold">{{
                  getRemainingCartQuantity(item) }}</span>
                </div>
                <div class="gray--text text-body-2 font-weight-extramedium mb-2" v-if="item.sizes.length == 1">
                  In cart: {{ $store.getters.getCart?.quantity }} (Doesn’t need distribution)
                </div>
                <div class="text-body-2 font-weight-extramedium mb-2">
                  Qty in each box: <span class="font-weight-bold green--text text--darken-1">{{ item.quantity }}
                  </span>(Max 10 in each box)
                  <!-- <v-menu :close-on-content-click="false" :max-width="250" open-on-hover offset-x
                    v-if="item.quantity > 1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="text-body-1 orange--text text--darken-1 pointer" v-bind="attrs" v-on="on">
                        mdi-shield-alert-outline</v-icon>
                    </template>
                    <v-card>
                      <div class="text-caption pa-2 text-left orange--text text--darken-1">
                        Hey, You’re adding {{ item.quantity }} units in each box, You only have {{
                        $store.getters.getCart?.quantity }} boxes in your cart,
                        You should either
                        increase the number of boxes or you might have to restock more boxes later.
                      </div>
                    </v-card>
                  </v-menu> -->
                </div>

                <!-- QTy inputs -->
                <div class="d-flex justify-start my-3 flex-wrap" v-if="item.sizes.length > 1">
                  <div v-for="(size, sizeIndex) in item.sizes" :key="sizeIndex">
                    <div class="qty-inputs text-center mr-1 mb-1" v-if="sizeExist(item, size)">
                      <input type="number" placeholder="0" v-model="size.quantity" @change="updateprodPrice(size, item)"
                        @input="validateSizeQuantity(size, item.sizes, item.quantity)">
                      <label class="text-caption font-weight-extrabold d-block text-uppercase">{{ size.size }}</label>
                    </div>
                    <!-- <template v-if="size.size == 'md'">{{ item.final_price }}</template> -->
                  </div>
                </div>
              </div>
              <!-- Action and detail buttons -->
              <v-card-actions class="pa-0 my-9 my-sm-6 transparent">
                <v-sheet class="d-flex justify-space-between align-center transparent action-btns">
                  <div class="text-left">
                    <div class="primary--text font-weight-extramedium">From USD <span class="font-weight-extrabold">{{
                    getItemPrice(item) }}</span></div>
                    <v-btn text color="green" class="text-caption font-weight-extramedium text-none pa-0 text--darken-2"
                      v-if="item.sizes.length" min-width="auto" height="auto"
                      @click.stop="openProdPriceBreakdown(item)">View breakdown</v-btn>
                  </div>
                  <div>
                    <v-btn text color="priamry" class="text-caption font-weight-extramedium mx-1 text-none pa-0"
                      min-width="auto" height="auto" @click.stop="openProductDetail(item)">Edit</v-btn>
                    <v-btn text color="red"
                      class="text-caption font-weight-extramedium mx-1 text-none pa-0 text--darken-3" min-width="auto"
                      height="auto" @click="removeIndex(itemIndex)">Remove</v-btn>
                  </div>
                </v-sheet>
              </v-card-actions>
            </v-card>

            <!-- Error message -->
            <v-sheet
              class="text-left red--text text--darken-2 transparent font-weight-extramedium mt-1 text-caption d-block"
              v-if="$helper.functions.getRemainingCartQuantity(item, item.quantity, $store.getters.getCart?.quantity) != 0">
              Please distribute the remaining units in your cart to continue checking out.
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-divider></v-divider>

    <!-- Footer Actions -->
    <v-card-actions class="pa-2 pa-md-4 text-end d-flex justify-end">
      <div class="d-flex justify-end">
        <div class="me-2 me-md-6 d-flex flex-column justify-space-between">
          <div class="text--black font-weight-extramedium caption text-sm-body-2">Cost per swagbox</div>
          <div class="text--black font-weight-extramedium caption text-sm-body-2">Subtotal ({{ $store.getters.getCart.quantity }}
            boxes)</div>
        </div>
        <div class="me-2 me-md-6 d-flex flex-column justify-space-between">
          <div class="text--primary font-weight-bold text-body-2">{{ costPerSwagbox }}</div>
          <div class="text--primary font-weight-bold text-body-2">{{ subTotal }}</div>
        </div>
        <v-btn class="font-weight-extramedium text-none rounded-sm py-6 px-lg-6" color="primary"
          v-if="$route.name == 'ProductCatalog'" @click="goToCartPage" :disabled="!allValidated">
          Next: Checkout</v-btn>
        <v-btn class="font-weight-extramedium text-none rounded-sm py-6 px-lg-6"
          v-if="$route.name == 'Cart' || $route.name == 'PaymentOptions'" color="primary" @click="closeModal"
          :disabled="!allValidated">
          Save Changes</v-btn>
      </div>
    </v-card-actions>

    <!-- Price breakdown Sidebar START-->
    <v-dialog v-model="PriceBreak" overlay-color="#f1f1f1" max-width="1000px" content-class="rounded-xl relative"
      scrollable>
      <v-btn @click="PriceBreak = false" color="elevation-0 darken-5 mt-10" fab absolute top right height="40"
        width="40">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>

      <!-- Price breakdown component -->
      <template v-if="selectedProd && PriceBreak">
        <PriceBreakdown :selectedProd="selectedProd" />
      </template>

    </v-dialog>
    <!-- Price breakdown Sidebar END-->

    <!-- Product Detail view START -->
    <!-- <v-dialog v-model="productdetail" max-width="1000px" v-if="Object.keys(productSelected).length != 0"
      overlay-color="#f1f1f1" content-class="rounded-xl relative" scrollable>
      <v-btn @click="productdetail = false" color="elevation-0 mt-10" fab absolute top right height="40" width="40">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn> -->
    <!-- <v-toolbar class="elevation-0 pa-0 transparent" height="auto">
        <v-spacer></v-spacer>
        <v-btn @click="productdetail = false" color="elevation-0 mt-8" fab absolute top right height="40" width="40">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar> -->

    <!-- <ProductDetail :productSelected="productSelected" v-if="productdetail" /> -->
    <!-- </v-dialog> -->
    <!-- Product Detail view END -->
  </v-card>
  <!-- </v-app> -->
</template>
<script>
import { cloneDeep } from 'lodash'
import PriceBreakdown from "@/components/PriceBreakdown"
// import ProductDetail from "@/components/ProductDetail"

export default {
  name: 'SelectQuantity',
  components: {
    PriceBreakdown,
    // ProductDetail
  },
  data() {
    return {
      productdetail: false,
      selectedProduct: {},
      SelectQuantities: true,
      PopoverWarning: false,
      PriceBreak: false,
      quantity: this.$store.getters.getCart?.quantity,
      lastMinQuantity: null,
      selectedProd: {},
    }
  },
  mounted() { },
  watch: {
    quantity(newValue, oldValue) {
      if (newValue < this.$store.getters.getCart.min_quantity && oldValue >= this.$store.getters.getCart.min_quantity) {
        this.lastMinQuantity = oldValue
      }
    }
  },
  computed: {
    logoPrice() {
      return this.$store.getters.logoPrice
    },

    allValidated() {
      return this.$helper.functions.allValidated(this.$store.state.cart?.box_products, this.$store.getters.getCart?.quantity)
    },

    productSelected() {
      return this.selectedProduct
    },

    numberOfLogos() {
      return this.$store.getters.numberOfLogos
    },

    cartItems() {
      if (this.$store.state.cart?.box_products) {
        return this.$store.state.cart?.box_products
        // return cloneDeep(this.$store.state.cart?.box_products)
      } else {
        return []
      }
    },

    costPerSwagbox() {
      return this.$helper.functions.costPerSwagbox(this.$store.state.cart?.box_products, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
    },

    subTotal() {
      if (this.$store.state.cart?.box_products) {
        let productsPrice = this.$helper.functions.getBoxPrice(this.$store.state.cart?.box_products, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos)
        return productsPrice.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
        // let minQuantity = this.$store.getters.getCart.quantity
        // return (productsPrice * minQuantity).toLocaleString('en-US', {
        //   style: 'currency',
        //   currency: 'USD',
        // })
      } else {
        let price = 0
        return price.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
      }

    }
  },
  methods: {
    getRemainingCartQuantity(item) {
      let remainingQuantity = this.$helper.functions.getRemainingCartQuantity(item, item.quantity,
        this.$store.getters.getCart?.quantity)
      if (remainingQuantity < 0) {
        let prod = this.$store.state.cart?.box_products.find(prod => {
          return prod.id == item.id
        })
        delete prod.price_distributed;
        this.$helper.functions.getSizedProdPrice(prod, this.$store.getters.getCart?.quantity, 0.00, this.$store.getters.getQuantityLimits)
        let updatedProd = this.$store.state.cart?.box_products.find(prod => {
          return prod.id == item.id
        })
        updatedProd.price_distributed = true;
      }

      // console.log('remainingQuantity', remainingQuantity)
      // if (remainingQuantity < 0) {
      //   console.log('LES')
      //   // this.$helper.functions.distributeQuantity(item, this.$store.getters.getQuantityLimits, this.$store.getters.getCart?.quantity)
      //   let product = item
      //   let quantityLimits = this.$store.getters.getQuantityLimits
      //   let boxQuantity = this.$store.getters.getCart?.quantity
      //   let sizes = product.sizes
      //   let sizeQuantity = 0
      //   let variant = {}
      //   let prodFinalPrice = 0
      //   if (sizes.length > 0) {
      //     sizes.forEach(object => {
      //       sizeQuantity += Number(object.quantity);
      //     });

      //     if (sizes.length == 1) {
      //       variant = product.variants.find(variant => {
      //         return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
      //       })
      //       if (variant) {
      //         let volumePrice = this.$helper.functions.getVolumePrice(variant, boxQuantity, quantityLimits)
      //         sizes[0].quantity = Number(boxQuantity) * Number(product.quantity)
      //         sizes[0].price = (sizes[0].quantity * volumePrice.price).toFixed(2)
      //         sizes[0].sku = variant.sku
      //       }
      //       prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
      //       product.final_price = prodFinalPrice.toFixed(2)
      //     }

      //     // if (sizeQuantity == 0) {
      //       let qua = sizes.length - 1
      //       let sameValue = (boxQuantity / qua)
      //       sameValue = Math.trunc(sameValue)
      //       if (sizes.length == 1) {
      //         variant = product.variants.find(variant => {
      //           return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
      //         })
      //         if (variant) {
      //           let volumePrice = this.$helper.functions.getVolumePrice(variant, boxQuantity, quantityLimits)
      //           sizes[0].quantity = Number(boxQuantity) * Number(product.quantity)
      //           sizes[0].price = (sizes[0].quantity * volumePrice.price).toFixed(2)
      //           sizes[0].sku = variant.sku
      //         }

      //         prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
      //       } else {
      //         sizes.forEach((size, key) => {
      //           if (key == qua) {
      //             variant = product.variants.find(variant => {
      //               return variant.color?.value === product.selected_color.value && variant.size === size.size
      //             })
      //             if (variant) {
      //               let volumePrice = this.$helper.functions.getVolumePrice(variant, boxQuantity, quantityLimits)
      //               size.quantity = (boxQuantity - (qua * sameValue)) * Number(product.quantity)
      //               size.price = (size.quantity * volumePrice.price).toFixed(2)
      //               size.sku = variant.sku
      //             }
      //             prodFinalPrice = (Number(prodFinalPrice) + Number(size.price))
      //           } else {
      //             variant = product.variants.find(variant => {
      //               return variant.color?.value === product.selected_color.value && variant.size === size.size
      //             })
      //             if (variant) {
      //               let volumePrice = this.$helper.functions.getVolumePrice(variant, boxQuantity, quantityLimits)
      //               size.quantity = Number(sameValue) * Number(product.quantity)
      //               size.price = (size.quantity * volumePrice.price).toFixed(2)
      //               size.sku = variant.sku
      //             }
      //             prodFinalPrice = (Number(prodFinalPrice) + Number(size.price))
      //           }
      //         });
      //       }
      //       product.final_price = prodFinalPrice.toFixed(2)
      //       product.price_distributed = true
      //     // }
      //   }
      // }
      return remainingQuantity
    },

    getItemPrice(product) {
      // let pricee = this.$helper.functions.getItemPrice(item, this.$store.getters.getCart?.quantity, this.numberOfLogos)
      // return pricee.toFixed(2).toLocaleString('en-US', {
      //   style: 'currency',
      //   currency: 'USD',
      // })
      let price = null
      if (product.sizes.length) {
        let prod = JSON.stringify(product)
        prod = JSON.parse(prod)
        price = this.$helper.functions.getSizedProdPrice(prod, this.$store.getters.getCart?.quantity, this.logoPrice, this.$store.getters.getQuantityLimits, this.numberOfLogos).toFixed(2)
      } else {
        let prodPrice = this.$helper.functions.getItemPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
        let productLogoPrice = this.$helper.functions.getProductLogoPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
        product.imprint_color_price = productLogoPrice
        // console.log('productLogoPrice', productLogoPrice)
        // price = (Number(product.quantity) * Number(prodPrice)) + (Number(productLogoPrice) * Number(product.quantity))
        price = Number(prodPrice)
      }
      return price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    sizeExist(product, size) {
      let variant = {}
      // product.sizes.forEach(object => {
      variant = product.variants.find(variant => {
        return variant.color?.value === product.selected_color.value && variant.size === size.size
      })
      if (variant) {
        return true
      } else {
        return false
      }
      // });
    },

    closeModal() {
      this.$root.$emit('closeModal')
    },

    validateSizeQuantity(size, sizes, itemQuantity) {
      let boxQuantity = Number(this.$store.getters.getCart?.quantity) * Number(itemQuantity)
      let sizeQuantity = 0
      sizes.forEach(object => {
        sizeQuantity += Number(object.quantity);
      });
      if (sizeQuantity > boxQuantity) {
        this.$store.commit("showAlert", {
          text: "You only have " + boxQuantity + " units available to distribute. Either increase the number of boxes or redistribute them to fit the number.",
          successStatus: false,
          alertColor: 'red darken-4'
        });
        let overQuantity = Number(sizeQuantity) - Number(boxQuantity)
        size.quantity = size.quantity - overQuantity
      }
    },

    removeIndex(itemIndex) {
      // let boxProduct = this.$store.state.cart?.box_products[itemIndex]
      // let categoryId = boxProduct.pivot.category_id

      // this.$helper.functions.resetItem(this.$store.getters.products, this.$store.state.cart?.box_products[itemIndex])
      // console.log('boxProduct', boxProduct)
      // let product = this.$store.getters.products.find(obj => {
      //   return obj.id == categoryId
      // }).products.find(prod => {
      //   return prod.id == boxProduct.id
      // })
      // console.log('product', product)
      // product.selected_color = null
      // // product.price_distributed = false
      // delete product.price_distributed;
      // product.quantity = 1
      // product.final_price = product.price
      // console.log('product after', product)
      this.$store.commit('removeItemFromCart', itemIndex)

      if (this.$store.state.cart?.box_products.length < 1) {
        this.$root.$emit('closeSkuModal')
      }
    },

    openProductDetail(product) {
      // let prod = this.$store.state.cart?.box_products.find(boxProduct => {
      //   return boxProduct.id === product.id
      // })
      // this.selectedProduct = prod
      // this.productdetail = true
      if (this.$route.name == 'ProductCatalog') {
        this.$root.$emit('openProdDetail', this.$store.state.cart?.box_products.find(prod => prod.id == product.id))
      } else {
        this.$root.$emit('openProdDetailCart', this.$store.state.cart?.box_products.find(prod => prod.id == product.id))
      }

    },

    // getRemainingCartQuantity(item) {
    //   console.log('ds')
    //   let sizes = item.sizes
    //   let quantity = this.$store.getters.getCart?.quantity
    //   let values = this.$helper.functions.getRemainingCartQuantity(sizes, quantity)
    //   return '123'
    // },

    resetQuantity() {
      if (this.quantity < this.$store.getters.getCart.min_quantity) {
        this.quantity = this.lastMinQuantity
      }
      // let boxProducts = this.$store.state.cart?.box_products
      // boxProducts.forEach(product => {
      //   if(product.sizes.length) {
      //     let pricee = this.$helper.functions.getSizedProdPrice(product, this.$store.getters.getCart?.quantity, 0.00)
      //   }
      // })

    },

    goToCartPage() {
      this.$router.push({ name: 'Cart' })
    },

    updateQuantity(e) {
      if (this.quantity >= this.$store.getters.getCart?.min_quantity) {
        this.$store.commit('updateBoxQuantity', this.quantity)

        let boxProducts = this.$store.state.cart?.box_products
        let boxQuantity = this.$store.getters.getCart?.quantity
        let variant = {}

        boxProducts.forEach(product => {
          // console.log('PROD')
          // this.$helper.functions.getItemPrice(product, this.$store.getters.getCart?.quantity)
          if (product.sizes.length > 0) {
            let pricee = this.$helper.functions.getSizedProdPrice(product, this.$store.getters.getCart?.quantity, 0.00)
          } else {
            // let boxProduct = this.$store.state.cart?.box_products.find(prod => {
            //   return prod.id === product.id
            // })
            // boxProducts.forEach(product => {
            // product.variants.forEach((variant, key) => {
            //   delete variant["quantity"]
            //   delete variant["price"]
            // });
            // })

            // let prodPrice = this.$helper.functions.getItemPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
            // let productLogoPrice = this.$helper.functions.getProductLogoPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
            // product.imprint_color_price = productLogoPrice

            let variant = product.variants.find(variant => {
              return variant.color?.value === product.selected_color.value
            })
            let productQuantities = variant.volume_pricing.map(volume => volume.quantity)
            let updatedBoxQuantity = Number(boxQuantity) * Number(product.quantity)
            let expectedQuantity = productQuantities.reverse().find(e => e <= updatedBoxQuantity);

            let volumePrice = variant.volume_pricing.find(price => {
              return price.quantity == expectedQuantity
            })

            let productLogoPrice = this.$helper.functions.getProductLogoPrice(product, this.$store.getters.getCart?.quantity, this.numberOfLogos)
            // console.log('volumePrice', volumePrice)
            // console.log('productLogoPrice', productLogoPrice)

            variant.quantity = Number(this.$store.state.cart?.quantity) * Number(product.quantity)
            // console.log('variant.quantity', variant.quantity)
            // variant.price = (Number(product.quantity) * Number(volumePrice.price) * Number(this.$store.state.cart?.quantity)) + (Number(productLogoPrice) * Number(product.quantity))
            variant.price = (Number(volumePrice.price) + Number(productLogoPrice)) * Number(product.quantity) * Number(variant.quantity)

            // IF NEEDS TO REVERTED
            // variant = product.variants.find(variant => {
            //   return variant.color?.value === product.selected_color.value
            // })
            // let volumePrice = variant.volume_pricing.find(price => {
            //   return price.quantity >= this.$store.state.cart?.quantity
            // })

            // let varPrice = (Number(volumePrice.price) + (Number(this.logoPrice) * Number(product.quantity))) * Number(this.$store.state.cart?.quantity)
            // variant.quantity = Number(this.$store.state.cart?.quantity) * Number(product.quantity)
            // variant.price = varPrice.toFixed(2)
            // product.final_price = varPrice.toFixed(2)
          }

          // let prodFinalPrice = 0
          // let sizes = product.sizes
          // let sizeQuantity = 0
          // if (sizes.length > 0) {
          //   sizes.forEach(object => {
          //     sizeQuantity += Number(object.quantity);
          //   });
          //   let qua = sizes.length - 1
          //   let sameValue = (boxQuantity / qua).toFixed(0)
          //   if (sizes.length == 1) {
          //     variant = product.variants.find(variant => {
          //       return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
          //     })
          //     sizes[0].quantity = boxQuantity
          //     sizes[0].price = (sizes[0].quantity * variant.price).toFixed(2)
          //     sizes[0].sku = variant.sku
          //     prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
          //     product.final_price = prodFinalPrice.toFixed(2)
          //     this.$store.commit('updateCartProd', product)
          //   }
          // } else {
          //   let prodPrice = this.$helper.functions.getItemPrice(product, this.$store.getters.getCart?.quantity)
          //   variant = product.variants.find(variant => {
          //     return variant.color?.value === product.selected_color.value
          //   })

          //   // let volumePrice = this.$helper.functions.getVolumePrice(variant, this.$store.state.cart?.quantity, this.$store.getters.getQuantityLimits)
          //   let varPrice = (Number(product.quantity) * Number(prodPrice)) * Number(boxQuantity)
          //   // console.log('VAR', varPrice)
          //   variant.quantity = Number(boxQuantity) * Number(product.quantity)
          //   variant.price = varPrice.toFixed(2)
          //   product.final_price = varPrice.toFixed(2)
          // }
        });
      } else {
        return e.preventDefault()
      }
    },

    openProdPriceBreakdown(item) {
      this.selectedProd = item
      this.PriceBreak = !this.PriceBreak
    },

    updateprodPrice(size, item) {
      let variants = item.variants
      var variant = variants.find(variant => {
        return variant.color?.value === item.selected_color.value && variant.size === size.size
      })
      let volumePrice = this.$helper.functions.getVolumePrice(item, variant, this.$store.getters.getCart?.quantity, this.$store.getters.getQuantityLimits)
      let tot = volumePrice.price * size.quantity
      size.price = tot.toFixed(2)
      size.sku = variant.sku
      let product = this.$helper.functions.updateProductPrice(item, this.$store.getters.getCart?.quantity)
      this.$store.commit('resetCartProducts', this.cartItems)
    }
  },
}
</script>

<style scoped>
.v-card.product-card:hover {
  transform: scale(1.01);
  background-color: #fdfdfd;
}

.input-sizer {
  display: inline-grid;
  vertical-align: top;
  align-items: center;
  position: relative;
}

.input-sizer.stacked {
  align-items: stretch;
}

.input-sizer.stacked::after,
.input-sizer.stacked input,
.input-sizer.stacked textarea {
  grid-area: 2/1;
}

.input-sizer::after,
.input-sizer input {
  width: auto;
  min-width: 1.5em;
  grid-area: 1/2;
  border-bottom: 1px solid rgba(7, 26, 82, 0.2)
}

.input-sizer input:focus-visible,
input:focus-visible {
  outline: none;
}

.input-sizer::after {
  content: attr(data-value) " ";
  visibility: hidden;
  white-space: pre-wrap;
}

.qty-inputs input {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(77, 77, 77, 0.7);
  text-align: center;
  max-width: 38px;
  height: 38px;
  border-radius: 2px;
  font-size: 14px;
}

.action-btns {
  position: absolute;
  width: calc(100% - 30px);
  bottom: 0;
  margin: 10px auto;
}
</style>
