<template>
  <v-app>
    <v-container fluid class="blue-grey lighten-5 py-16 mb-6 arc-bg">
      <v-row class="justify-center px-4">
        <v-col cols="12" sm="12" md="10" lg="8">
          <!-- Coming soon -->
          <v-chip color="blue" class="blue lighten-4 primary--text font-weight-bold mb-4">Coming soon</v-chip>
          <!-- Coming soon -->
          <h4 class="text-h4 text-lg-h3 font-weight-black text--primary">There are a lot more reasons to build your swagbox with us</h4>
        </v-col>
      </v-row>

      <v-row class="px-4">
        <!-- Intuitive dashboard START-->
        <v-col cols="12" sm="6" md="4" lg="4">
          <v-card class="text-left pa-4 rounded-lg" height="100%">
            <div class="mb-3">
              <v-img :src="require('@/assets/images/svg/graph.svg')" alt="Graph" width="48px" class="fill-height"></v-img>
            </div>
            <h6 class="text-h6 text--primary mb-2 font-weight-bold">Intuitive dashboard</h6>
            <p class="ma-0">All-in-one platform to simplify all of your swag needs and manage your orders.</p>
          </v-card>
        </v-col>
        <!-- Intuitive dashboard END-->

        <!-- Ship to Multiple Addresses anytime you want START -->
        <v-col cols="12" sm="6" md="4" lg="4">
          <v-card class="text-left pa-4 rounded-lg" height="100%">
            <div class="mb-3">
              <v-img :src="require('@/assets/images/svg/delivery.svg')" alt="Delivery" width="48px" class="fill-height"></v-img>
            </div>
            <h6 class="text-h6 text--primary mb-2 font-weight-bold">Ship to Multiple Addresses anytime you want</h6>
            <p class="ma-0">There's something great about sharing a personalized and custom-packaged gift to anyone in the world.</p>
          </v-card>
        </v-col>
        <!-- Ship to Multiple Addresses anytime you want END -->

        <!-- Inventory & Warehousing START -->
        <v-col cols="12" sm="6" md="4" lg="4">
          <v-card class="text-left pa-4 rounded-lg" height="100%">
            <div class="mb-3">
              <v-img :src="require('@/assets/images/svg/package.svg')" alt="Package" width="48px" class="fill-height"></v-img>
            </div>
            <h6 class="text-h6 text--primary mb-2 font-weight-bold">Inventory & Warehousing</h6>
            <p class="ma-0">No sweat - We handle it all. From storing your boxes to assuring they get delivered to the correct address at the right time.</p>
          </v-card>
        </v-col>
        <!-- Inventory & Warehousing END -->

        <!-- Redeem pages START-->
        <v-col cols="12" sm="6" md="4" lg="4">
          <v-card class="text-left pa-4 rounded-lg" height="100%">
            <div class="mb-3">
              <v-img :src="require('@/assets/images/svg/hot.svg')" alt="Redeem pages" width="48px" class="fill-height"></v-img>
            </div>
            <h6 class="text-h6 text--primary mb-2 font-weight-bold">Redeem pages</h6>
            <p class="ma-0">Redeem pages are custom-built web links to collect accurate information from recipients so that users can order & distribute their swag with confidence and ease.</p>
          </v-card>
        </v-col>
        <!-- Redeem pages END-->

        <!-- Online stores START -->
        <v-col cols="12" sm="6" md="4" lg="4">
          <v-card class="text-left pa-4 rounded-lg" height="100%">
            <div class="mb-3">
              <v-img :src="require('@/assets/images/svg/store.svg')" alt="Store" width="48px" class="fill-height"></v-img>
            </div>
            <h6 class="text-h6 text--primary mb-2 font-weight-bold">Online stores</h6>
            <p class="ma-0">Create an online store with your custom boxes, to share your ideas and your brand's message with the world.</p>
          </v-card>
        </v-col>
        <!-- Online stores END -->

        <!-- Design tool & self customization START -->
        <v-col cols="12" sm="6" md="4" lg="4">
          <v-card class="text-left pa-4 rounded-lg" height="100%">
            <div class="mb-3">
              <v-img :src="require('@/assets/images/svg/shirt.svg')" alt="Design tool" width="48px" class="fill-height"></v-img>
            </div>
            <h6 class="text-h6 text--primary mb-2 font-weight-bold">Design tool & self customization</h6>
            <p class="ma-0">With our ever-evolving design lab, you are empowered to stay up to date on every customization trend.</p>
          </v-card>
        </v-col>
        <!-- Design tool & self customization END -->
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  export default {
    name: 'BuildYourSwagbox',
    components: {},
    mounted() {},
    computed: {},
    data() {
      return {

      }
    },
    methods: {}
  }
</script>

<style scoped>
  .arc-bg {
    background: url('@/assets/images/arc-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
</style>
