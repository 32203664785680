export const helper = {
    functions: {
        getBoxPrice(boxProducts, quantity, logoPrice = 0.00, quantityLimits = {}, numberOfLogos = 0) {
            if (boxProducts && boxProducts.length > 0) {
                let products = boxProducts
                let boxQuantity = quantity
                let totalPrice = 0
                if (products && products.length > 0) {
                    products.forEach(prod => {
                        if (prod.sizes.length) {
                            let currentProd = JSON.stringify(prod)
                            currentProd = JSON.parse(currentProd)
                            let price = this.getSizedProdPrice(currentProd, quantity, logoPrice, quantityLimits, numberOfLogos).toFixed(2)
                            totalPrice = (Number(quantity) * Number(price)) + Number(totalPrice)
                        } else {
                            let prodPrice = this.getItemPrice(prod, quantity, numberOfLogos)
                            // let volumePrice = prod.variants.find(variant => {
                            //     return variant.color?.value === prod.selected_color.value
                            // }).volume_pricing.find(price => {
                            //     return price.quantity >= quantityLimits.from && price.quantity <= quantityLimits.to
                            //     // return price.quantity >= boxQuantity
                            // })

                            // let price = (Number(prod.quantity) * Number(prodPrice)) + (Number(logoPrice) * Number(prod.quantity))
                            let quantityPrice = Number(prodPrice) * Number(boxQuantity)
                            totalPrice = Number(totalPrice) + Number(quantityPrice)
                        }
                    })

                    // let totalPrice = products.reduce((total, prod) => (Number(prod.quantity) * Number(prod.price)) + (Number(this.logoPrice) * Number(prod.quantity)) + Number(total), 0);
                    return totalPrice;
                } else {
                    let totalPrice = 0
                    return totalPrice;
                }
            } else {
                return '0'
            }

        },

        getVolumePrice(product, variant, boxQuantity, quantityLimits) {
            let productQuantities = variant.volume_pricing.map(volume => volume.quantity)
            let updatedBoxQuantity = Number(boxQuantity) * Number(product.quantity)
            let expectedQuantity = productQuantities.reverse().find(e => e <= updatedBoxQuantity);

            let volumePrice = variant.volume_pricing.find(price => {
                return price.quantity == expectedQuantity
            })

            // let volumePrice = variant.volume_pricing.find(price => {
            //     return price.quantity >= quantityLimits.from && price.quantity <= quantityLimits.to
            //     // return price.quantity >= boxQuantity
            // })
            return volumePrice
        },

        distributeQuantity(product, quantityLimits, boxQuantity, numberOfLogos = 0) {
            // let boxProducts = this.$store.state.cart?.box_products
            // let boxQuantity = this.$store.getters.getCart?.quantity
            let variant = {}

            // boxProducts.forEach(product => {
            let prodFinalPrice = 0
            let sizes = product.sizes
            let sizeQuantity = 0
            if (sizes.length > 0) {
                sizes.forEach(object => {
                    sizeQuantity += Number(object.quantity);
                });

                // if (sizes.length == 1) {
                //     variant = product.variants.find(variant => {
                //         return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
                //     })
                //     if (variant) {
                //         let volumePrice = this.getVolumePrice(product, variant, boxQuantity, quantityLimits)
                //         sizes[0].quantity = Number(boxQuantity) * Number(product.quantity)
                //         sizes[0].price = (sizes[0].quantity * volumePrice.price).toFixed(2)
                //         sizes[0].sku = variant.sku
                //     }
                //     prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
                //     product.final_price = prodFinalPrice.toFixed(2)
                // }

                if (sizeQuantity == 0) {
                    // let qua = sizes.length - 1
                    // let sameValue = (boxQuantity / qua)
                    // sameValue = Math.trunc(sameValue)

                    let qua = null
                    let sameValue = 0
                    if (Number.isInteger(boxQuantity / sizes.length)) {
                        qua = null
                        sameValue = boxQuantity / sizes.length
                        sameValue = Math.trunc(sameValue)
                    } else {
                        qua = sizes.length - 1
                        sameValue = (boxQuantity / qua)
                        sameValue = Math.trunc(sameValue)
                    }


                    if (sizes.length == 1) {
                        variant = product.variants.find(variant => {
                            return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
                        })
                        if (variant) {
                            let volumePrice = this.getVolumePrice(product, variant, boxQuantity, quantityLimits)
                            sizes[0].quantity = Number(boxQuantity) * Number(product.quantity)
                            sizes[0].price = (sizes[0].quantity * volumePrice.price).toFixed(2)
                            sizes[0].sku = variant.sku
                        }

                        prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
                    } else {
                        sizes.forEach((size, key) => {
                            if (key == qua) {
                                variant = product.variants.find(variant => {
                                    return variant.color?.value === product.selected_color.value && variant.size === size.size
                                })
                                if (variant) {
                                    let volumePrice = this.getVolumePrice(product, variant, boxQuantity, quantityLimits)
                                    size.quantity = (boxQuantity - (qua * sameValue)) * Number(product.quantity)
                                    size.price = (size.quantity * volumePrice.price).toFixed(2)
                                    size.sku = variant.sku
                                }
                                prodFinalPrice = (Number(prodFinalPrice) + Number(size.price))
                            } else {
                                variant = product.variants.find(variant => {
                                    return variant.color?.value === product.selected_color.value && variant.size === size.size
                                })
                                if (variant) {
                                    let volumePrice = this.getVolumePrice(product, variant, boxQuantity, quantityLimits)
                                    size.quantity = Number(sameValue) * Number(product.quantity)
                                    size.price = (size.quantity * volumePrice.price).toFixed(2)
                                    size.sku = variant.sku
                                }
                                prodFinalPrice = (Number(prodFinalPrice) + Number(size.price))
                            }
                        });
                    }
                    product.final_price = prodFinalPrice.toFixed(2)
                    // product.price_distributed = true
                }
            } else {
                let prodPrice = this.getItemPrice(product, boxQuantity, numberOfLogos)
                variant = product.variants.find(variant => {
                    return variant.color?.value === product.selected_color.value
                })

                // let volumePrice = this.getVolumePrice(variant, this.$store.state.cart?.quantity, quantityLimits)
                let varPrice = (Number(product.quantity) * Number(prodPrice)) * Number(boxQuantity)
                variant.quantity = Number(boxQuantity) * Number(product.quantity)
                variant.price = varPrice.toFixed(2)
                product.final_price = varPrice.toFixed(2)
            }
            // });
        },

        getItemBasePrice(item, boxQuantity) {
            // return 10
            // if (!item.selected_color) {
            //     item.selected_color = item.colors[0] ? item.colors[0] : null
            // }
            // let variant = item.variants.find(variant => {
            //     return variant.color?.value === item.selected_color.value
            // })
            // let productQuantities = variant.volume_pricing.map(volume => volume.quantity)
            // let expectedQuantity = productQuantities.reverse().find(e => e <= boxQuantity);

            // let volume_price = variant.volume_pricing.find(price => {
            //     return price.quantity == expectedQuantity
            // })

            return Number(item.price)
        },

        getItemPrice(item, boxQuantity, numberOfLogos = 0) {
            if (!item.selected_color) {
                item.selected_color = item.colors[0] ? item.colors[0] : null
            }
            // console.log('getItemPrice', item.sizes.length)
            if (item.sizes.length > 0) {
                // return this.getSizedProdPrice(item, boxQuantity, 0, {})
                // let final_price = (Number(item.final_price) / Number(boxQuantity))


                let logoPrice = 0
                if (item.imprint_colors.length > 0) {
                    let logoVolumePrice = item.imprint_colors.find(prod => {
                        return prod.unit == numberOfLogos
                    })

                    let logQuantities = logoVolumePrice.volume_pricing.map(volume => volume.quantity)
                    let updatedBoxQuantity = Number(boxQuantity) * Number(item.quantity)
                    let expectedLogoQuantity = logQuantities.reverse().find(e => e <= updatedBoxQuantity);

                    let logoVolumePricing = logoVolumePrice.volume_pricing.find(price => {
                        return price.quantity == expectedLogoQuantity
                    })

                    logoPrice = Number(logoVolumePricing.price)
                    item.imprint_color_price = logoPrice
                }
                // Number(logoPrice) + 
                // console.log('item.final_price', item.final_price)
                let price = ((Number(item.final_price)) / Number(boxQuantity))
                return price
            } else {
                // console.log('dsds')
                let variant = item.variants.find(variant => {
                    return variant.color?.value === item.selected_color.value
                })
                let productQuantities = variant.volume_pricing.map(volume => volume.quantity)
                let updatedBoxQuantity = Number(boxQuantity) * Number(item.quantity)
                let expectedQuantity = productQuantities.reverse().find(e => e <= updatedBoxQuantity);

                let volume_price = variant.volume_pricing.find(price => {
                    return price.quantity == expectedQuantity
                })

                // Logo Price
                let logoPrice = 0
                if (item.imprint_colors.length > 0) {
                    let logoVolumePrice = item.imprint_colors.find(prod => {
                        return prod.unit == numberOfLogos
                    })

                    let logQuantities = logoVolumePrice.volume_pricing.map(volume => volume.quantity)
                    let UpdatedColorQuantity = Number(boxQuantity) * Number(item.quantity)
                    let expectedLogoQuantity = logQuantities.reverse().find(e => e <= UpdatedColorQuantity);

                    let logoVolumePricing = logoVolumePrice.volume_pricing.find(price => {
                        return price.quantity == expectedLogoQuantity
                    })

                    logoPrice = Number(logoVolumePricing.price)
                    item.imprint_color_price = logoPrice
                }

                return (Number(item.quantity) * Number(volume_price.price)) + (Number(logoPrice) * Number(item.quantity))
            }
            // return Number(volume_price.price)
        },

        getProductLogoPrice(item, boxQuantity, numberOfLogos = 0) {

            let logoVolumePrice = item.imprint_colors.find(prod => {
                return prod.unit == numberOfLogos
            })

            if (logoVolumePrice) {
                let productQuantities = logoVolumePrice.volume_pricing.map(volume => volume.quantity)
                let updatedBoxQuantity = Number(boxQuantity) * Number(item.quantity)
                // console.log('updatedBoxQuantity', updatedBoxQuantity)
                let expectedQuantity = productQuantities.reverse().find(e => e <= updatedBoxQuantity);
                let volume_price = logoVolumePrice.volume_pricing.find(price => {
                    return price.quantity == expectedQuantity
                })
                // console.log('volume_price', volume_price)
                return Number(volume_price.price)
            } else {
                return Number(0)
            }
        },

        getSizedProdPrice(product, boxQuantity, logoPrice, quantityLimits = {}, numberOfLogos) {

            let price = 0
            let sizes = product.sizes
            let sizeQuantity = 0
            let prodFinalPrice = 0
            let variant = {}
            sizes.forEach(object => {
                sizeQuantity += Number(object.quantity);
            });

            // if (sizes.length == 1) {
            //     variant = product.variants.find(variant => {
            //         return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
            //     })
            //     if (variant) {
            //         let volumePrice = variant.volume_pricing.find(price => {
            //             return price.quantity >= boxQuantity
            //         })
            //         sizes[0].quantity = product.price_distributed ? sizes[0].quantity : Number(boxQuantity) * Number(product.quantity)
            //         sizes[0].price = (sizes[0].quantity * volumePrice.price).toFixed(2)
            //         sizes[0].sku = variant.sku
            //     }
            //     prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
            //     product.final_price = prodFinalPrice.toFixed(2)
            // }

            // || !this.cartItemIds.includes(product.id)
            // if (sizeQuantity == 0) {
            let qua = null
            let sameValue = 0
            if (Number.isInteger(boxQuantity / sizes.length)) {
                qua = null
                sameValue = boxQuantity / sizes.length
                sameValue = Math.trunc(sameValue)
            } else {
                qua = sizes.length - 1
                sameValue = (boxQuantity / qua)
                sameValue = Math.trunc(sameValue)
            }

            if (sizes.length == 1) {
                variant = product.variants.find(variant => {
                    return variant.color?.value === product.selected_color.value && variant.size === sizes[0].size
                })

                if (variant) {
                    let productQuantities = variant.volume_pricing.map(volume => volume.quantity)
                    let updatedBoxQuantity = Number(boxQuantity) * Number(product.quantity)
                    let expectedQuantity = productQuantities.reverse().find(e => e <= updatedBoxQuantity);

                    let volumePrice = variant.volume_pricing.find(price => {
                        return price.quantity == expectedQuantity
                    })

                    // let volumePrice = variant.volume_pricing.find(price => {
                    //     return price.quantity >= quantityLimits.from && price.quantity <= quantityLimits.to
                    //     // return price.quantity >= boxQuantity
                    // })
                    // product.price_distributed ? sizes[0].quantity : 
                    sizes[0].quantity = Number(boxQuantity) * Number(product.quantity)
                    sizes[0].price = (sizes[0].quantity * volumePrice.price).toFixed(2)
                    sizes[0].sku = variant.sku
                }

                prodFinalPrice = (Number(prodFinalPrice) + Number(sizes[0].price))
            } else {

                sizes.forEach((size, key) => {
                    if (key == qua) {
                        variant = product.variants.find(variant => {
                            return variant.color?.value === product.selected_color.value && variant.size === size.size
                        })
                        // console.log('Variant Qua', variant)
                        if (variant) {
                            let productQuantities = variant.volume_pricing.map(volume => volume.quantity)
                            let updatedBoxQuantity = Number(boxQuantity) * Number(product.quantity)
                            let expectedQuantity = productQuantities.reverse().find(e => e <= updatedBoxQuantity);

                            let volumePrice = variant.volume_pricing.find(price => {
                                return price.quantity == expectedQuantity
                            })

                            // let volumePrice = variant.volume_pricing.find(price => {
                            //     return price.quantity >= quantityLimits.from && price.quantity <= quantityLimits.to
                            //     // return price.quantity >= boxQuantity
                            // })

                            // product.price_distributed ? size.quantity : 
                            size.quantity = product.price_distributed ? size.quantity : (boxQuantity - (qua * sameValue)) * Number(product.quantity)
                            size.price = (size.quantity * volumePrice.price).toFixed(2)
                            size.sku = variant.sku
                        }
                        prodFinalPrice = (Number(prodFinalPrice) + Number(size.price))
                    } else {
                        variant = product.variants.find(variant => {
                            return variant.color?.value === product.selected_color.value && variant.size === size.size
                        })
                        // console.log('Variant', variant)
                        if (variant) {
                            let productQuantities = variant.volume_pricing.map(volume => volume.quantity)
                            let updatedBoxQuantity = Number(boxQuantity) * Number(product.quantity)
                            let expectedQuantity = productQuantities.reverse().find(e => e <= updatedBoxQuantity);

                            let volumePrice = variant.volume_pricing.find(price => {
                                return price.quantity == expectedQuantity
                            })

                            // let volumePrice = variant.volume_pricing.find(price => {
                            //     return price.quantity >= quantityLimits.from && price.quantity <= quantityLimits.to
                            //     // return price.quantity >= boxQuantity
                            // })

                            // product.price_distributed ? size.quantity :
                            size.quantity = product.price_distributed ? size.quantity : Number(sameValue) * Number(product.quantity)
                            size.price = (size.quantity * volumePrice.price).toFixed(2)
                            size.sku = variant.sku
                        }
                        prodFinalPrice = (Number(prodFinalPrice) + Number(size.price))

                    }
                });
            }
            product.final_price = prodFinalPrice.toFixed(2)



            // }

            let logoFinalPrice = (Number(this.getProductLogoPrice(product, boxQuantity, numberOfLogos)) * Number(product.quantity)) * Number(boxQuantity)//Number(logoPrice) * Number(product.quantity)

            price = ((Number(logoFinalPrice) + Number(product.final_price)) / Number(boxQuantity))
            product.final_price = (Number(logoFinalPrice) + Number(product.final_price))

            return price
        },

        resetItem(allproducts, boxProduct) {
            if (boxProduct.pivot) {
                let categoryId = boxProduct.pivot.category_id
                let product = allproducts.find(obj => {
                    return obj.id == categoryId
                }).products.find(prod => {
                    return prod.id == boxProduct.id
                })
                product.selected_color = null
                // product.price_distributed = false
                delete product.price_distributed;
                product.quantity = 1
                product.final_price = product.price
            }
        },

        resetSpecificProduct(product) {

            product.selected_color = null
            product.quantity = 1
            product.final_price = product.price
            product.number_of_logos = 1
        },

        getPresetBoxPrice(boxProducts) {
            if (boxProducts && boxProducts.length > 0) {
                let products = boxProducts
                // let boxQuantity = quantity
                let totalPrice = 0
                if (products && products.length > 0) {
                    products.forEach(prod => {

                        // let volumePrice = prod.variants.find(variant => {
                        //     return variant.color?.value === prod.selected_color.value
                        // }).volume_pricing.find(price => {
                        //     return price.quantity >= 50
                        // })

                        // let price = (Number(prod.quantity) * Number(volumePrice.price))
                        totalPrice = Number(totalPrice) + Number(prod.price)
                    })

                    // let totalPrice = products.reduce((total, prod) => (Number(prod.quantity) * Number(prod.price)) + (Number(this.logoPrice) * Number(prod.quantity)) + Number(total), 0);
                    return totalPrice;
                } else {
                    let totalPrice = 0
                    return totalPrice;
                }
            } else {
                return '0'
            }

        },

        costPerSwagbox(boxProducts, quantity, logoPrice = 0.00, quantityLimits = {}, numberOfLogos = 0) {

            let products = boxProducts
            let boxQuantity = quantity
            let totalPrice = 0
            if (products && products.length > 0) {
                products.forEach(prod => {
                    if (prod.sizes.length) {
                        let currentProd = JSON.stringify(prod)
                        currentProd = JSON.parse(currentProd)
                        let price = this.getSizedProdPrice(currentProd, quantity, logoPrice, quantityLimits, numberOfLogos).toFixed(2)
                        totalPrice = Number(totalPrice) + Number(price)

                    } else {
                        let prodPrice = this.getItemPrice(prod, quantity, numberOfLogos)
                        // let volumePrice = prod.variants.find(variant => {
                        //     return variant.color?.value === prod.selected_color.value
                        // }).volume_pricing.find(price => {
                        //     return price.quantity >= quantityLimits.from && price.quantity <= quantityLimits.to
                        //     // return price.quantity >= boxQuantity
                        // })

                        // let price = (Number(prod.quantity) * Number(prodPrice)) + (Number(logoPrice) * Number(prod.quantity))
                        totalPrice = Number(totalPrice) + Number(prodPrice)
                    }

                })

                // let totalPrice = products.reduce((total, prod) => (Number(prod.quantity) * Number(prod.price)) + (Number(this.logoPrice) * Number(prod.quantity)) + Number(total), 0);
                return totalPrice.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                });
            } else {
                let totalPrice = 0
                return totalPrice.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })
            }
            // if (boxProducts && boxProducts.length > 0) {
            //     let totalPrice = boxProducts.reduce((total, obj) => Number(obj.final_price) + Number(total) + Number(logoPrice), 0);
            //     if (quantity) {
            //         totalPrice = totalPrice / quantity
            //     }
            //     return totalPrice.toLocaleString('en-US', {
            //         style: 'currency',
            //         currency: 'USD',
            //     })
            // } else {
            //     let totalPrice = 0
            //     return totalPrice.toLocaleString('en-US', {
            //         style: 'currency',
            //         currency: 'USD',
            //     })
            // }
        },

        getCartPrice() {

            if (this.$store.getters.getCart) {
                let totalPrice = this.$store.getters.getCart.reduce((total, obj) => obj.final_price + total, 0)
                return totalPrice
            } else {
                return '0'
            }
        },

        productPrice(product) {

            return product.final_price.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            });
        },

        getProductAveragePrice(product, quantity, logoPrice = 1.68) {

            let price = Number(product.final_price) / Number(quantity)
            price = price + logoPrice
            return price.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })
        },

        productBasePrice(product) {

            return product.price.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            });
        },

        getProductBulkPrice(product, quantity) {

            let totalPrice = product.price * quantity
            return totalPrice.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })
        },

        getProductBulkPricee(product, quantity, prodQuantity) {

            let totalPrice = (product.final_price * prodQuantity) * quantity
            return totalPrice.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })
        },

        getColorName(color) {
            let colorLabel = ''
            if (color) {
                colorLabel = color.label.replace('-', ' ')
            }
            return colorLabel
        },

        getColorCode(color) {
            let colorCode = ''
            if (color) {
                colorCode = color.value ? color.value : '#ffffff'
            }
            return colorCode.trim()
        },

        getSizeUnitPrice(size, selectedProd, boxQuantity) {

            let variants = selectedProd.variants
            var variant = variants.find(variant => {
                return variant.color?.value === selectedProd.selected_color.value && variant.size === size.size
            })

            let productQuantities = variant.volume_pricing.map(volume => volume.quantity)
            let updatedBoxQuantity = Number(boxQuantity) * Number(selectedProd.quantity)
            let expectedQuantity = productQuantities.reverse().find(e => e <= updatedBoxQuantity);

            let volumePrice = variant.volume_pricing.find(price => {
                return price.quantity == expectedQuantity
            })

            // let volumePrice = variant.volume_pricing.find(price => {
            //     return price.quantity >= boxQuantity
            // })
            if (volumePrice) {
                let price = volumePrice.price
                return price.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })
            }
            return 0

        },

        getRemainingCartQuantity(item, itemQuantity = 1, quantity) {

            let sizes = item.sizes
            if (sizes.length > 0) {
                let totalQuantity = sizes.reduce((total, obj) => Number(obj.quantity) + Number(total), 0)
                quantity = Number(quantity) * Number(itemQuantity)
                let remainingQuantity = (Number(quantity) - Number(totalQuantity))
                return remainingQuantity
            } else {
                // let variant = item.variants.find(variant => {
                //     return variant.color?.value === item.selected_color.value
                // })
                // quantity = Number(variant.quantity)
                // let remainingQuantity = (Number(quantity) - Number(totalQuantity))
                return 0

                // return 100
            }
        },

        getProdLowestPrice(product, boxQuantity) {
            let variants = product.variants
            let minimunPrice = []
            variants.forEach(variant => {
                let volumePricing = variant.volume_pricing
                let price = volumePricing.find(obj => {
                    return obj.quantity == boxQuantity
                })
                // volumePricing.forEach(price => {
                // let minimum = volumePricing.reduce((previous, current) => {
                //     return current.price > previous.price ? current : previous;
                // });
                minimunPrice.push(price.price);
                // })
            });
            if (minimunPrice.length > 0) {
                return Number(Math.min(...minimunPrice))
            } else {
                return Number(0)
            }
            // return minimunPrice
        },

        updateProductPrice(product, boxQuantity = 0) {

            let sizes = product.sizes
            let totalPrice = 0;

            sizes.forEach(object => {
                totalPrice += Number(object.price);
            });
            // let totalPrice = sizes.reduce((total, object) => {
            //     return Number(total) + Number(object.price);
            // }, 0)
            let logoFinalPrice = (Number(this.getProductLogoPrice(product, boxQuantity)) * Number(product.quantity)) * Number(boxQuantity)
            if (totalPrice == 0) {
                // product.final_price = product.price
                product.final_price = (Number(logoFinalPrice) + Number(product.price))
            } else {
                // product.final_price = totalPrice
                product.final_price = (Number(logoFinalPrice) + Number(totalPrice))
            }
            return product
        },

        allValidated(products, quantity) {

            let boxProducts = products
            let boxQuantity = quantity
            let validated = true
            if (boxProducts && boxProducts.length > 0) {
                boxProducts.forEach(product => {
                    let sizes = product.sizes
                    let sizeQuantity = 0
                    if (sizes.length > 0) {
                        sizes.forEach(object => {
                            sizeQuantity += Number(object.quantity);
                        });
                        let finalQuantity = Number(boxQuantity) * Number(product.quantity)
                        if (sizeQuantity != finalQuantity) {
                            validated = false
                        }
                    }
                });
                return validated
            } else {
                return true
            }

        },

        getProdImage(product) {
            let variants = product.variants
            let variant = variants.find(obj => {
                return obj.color?.value == product.selected_color.value
            })
            let image = null
            variant.images.forEach((object, key) => {
                if (object.location == 'front') {
                    // image = object.link
                    image = this.getImage(object, 'thumbnails')
                }
            })
            return image
        },

        basename(url) {
            // console.log('basename')
            if (url) {
                return url.split('/').pop();
            } else {
                return null
            }
        },

        getThumbImage(item, test = 'tt') {
            let base_url = process.env.VUE_APP_S3_BASE;
            // console.log(base_url + 'thumbnails/' + item.sku + '-thumbnail.png')
            return base_url + 'thumbnails/' + item.sku + '-thumbnail.png'
        },

        getImage(image, size = 'medium') {
            if (image) {
                let img_name = this.basename(`${image.link + ""}` || null)
                let base_url = process.env.VUE_APP_S3_BASE;
                if (size === 'large') {
                    return base_url + img_name;
                }
                return base_url + size + '/' + img_name;
            } else {
                return null
            }

        },

        getPresetBoxProdImage(image) {
            let img_name = this.basename(`${image + ""}` || null)
            let base_url = process.env.VUE_APP_S3_BASE;
            return base_url + 'medium' + '/' + img_name;
        },

        getNumberOfProducts(order) {
            let productIds = []
            order.variants.forEach(variant => {
                if (!productIds.includes(variant.product_id)) {
                    productIds.push(variant.product_id);
                }
            });
            return productIds.length
        }
    }
}

export default helper;