<template>
    <v-app>
        <v-container fluid>
            <v-row class="align-center">
                <v-col cols="6" class="text-left">
                    <h1 class="text-h5 font-weight-bold">Shipment</h1>
                </v-col>
                <v-col cols="6" class="text-right">
                    <v-btn @click.stop="addcontact = !addcontact" class="font-weight-bold primary text-none rounded-sm py-6 mx-2">
                        Add contact
                    </v-btn>
                </v-col>
            </v-row>

            <v-row class="mt-0 mb-10">
                <v-col cols="12 px-0">

                    <!-- Tab Bar -->
                    <ShipmentTabs />
                    <!-- Tab Bar -->

                    <!-- search and filters START -->
                    <v-row class="my-1">
                        <!-- Search contacts -->
                        <v-col cols="12" lg="3">
                            <v-text-field placeholder="Search contacts" outlined dense hide-details required prepend-inner-icon="mdi-magnify">
                            </v-text-field>
                        </v-col>
                        <!-- Sort by: -->
                        <v-col cols="12" lg="2">
                            <v-select outlined dense hide-details>
                                <template v-slot:prepend-inner>
                                    <div class="text-no-wrap py-1">Sort by:</div>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                    <!-- search and filters END -->

                    <v-divider></v-divider>

                    <!-- When Contacts list is empty START -->
                    <div v-if="false">
                        <v-row>
                            <v-col>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">Contact</th>
                                                <th class="text-left">Company</th>
                                                <th class="text-left">Title</th>
                                                <th class="text-left">Email</th>
                                                <th class="text-left" style="width: 200px;">Shirt Size</th>
                                            </tr>
                                        </thead>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                        <v-row style="height: calc(100vh - 400px);" class="align-center">
                            <v-col cols="12" class="text-center">
                                <v-img :src="require('@/assets/images/svg/contacts-card.svg')" contain width="48px" class="mx-auto"></v-img>
                                <div class="text-h6 font-weight-bold primary--text mb-1">No contacts yet.</div>
                                <div class="grey--text font-weight-extramedium">You haven’t added contacts yet.</div>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- When Contacts list is empty START -->

                    <!-- Show When contacts listed  -->
                    <v-row v-else>
                        <v-col cols="12">
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">Contact</th>
                                            <th class="text-left">Company</th>
                                            <th class="text-left">Title</th>
                                            <th class="text-left">Email</th>
                                            <th class="text-left" style="width: 200px;">Shirt Size</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <!-- Contact avatar & Name-->
                                            <td class="text-left">
                                                <div>
                                                    <v-avatar color="primary" size="48" class="mr-3">
                                                        <span class="text-uppercase white--text font-weight-bold">KM</span>
                                                    </v-avatar>
                                                    <span>Karen M.</span>
                                                </div>
                                            </td>

                                            <!-- Company -->
                                            <td class="text-left">Alphalete</td>

                                            <!-- Title -->
                                            <td class="text-left">Jr. Designer</td>

                                            <!-- Email -->
                                            <td class="text-left">
                                                <a href="mailto: karen@alphalete.com" class="text-decoration-none primary--text font-weight-extramedium">
                                                    karen@alphalete.com
                                                </a>
                                            </td>

                                            <!-- Shirt Size -->
                                            <td class="text-left">S</td>
                                        </tr>

                                        <tr>
                                            <!-- Contact avatar & Name-->
                                            <td class="text-left">
                                                <div>
                                                    <v-avatar color="primary" size="48" class="mr-3">
                                                        <img src="https://randomuser.me/api/portraits/men/81.jpg" alt="Frank K. Chill">
                                                    </v-avatar>
                                                    <span>Frank K. Chill</span>
                                                </div>
                                            </td>

                                            <!-- Company -->
                                            <td class="text-left">Alphalete</td>

                                            <!-- Title -->
                                            <td class="text-left">Co-ordinator</td>

                                            <!-- Email -->
                                            <td class="text-left">
                                                <a href="mailto: frank@alphalete.com" class="text-decoration-none primary--text font-weight-extramedium">
                                                    frank@alphalete.com
                                                </a>
                                            </td>

                                            <!-- Shirt Size -->
                                            <td class="text-left">LG</td>
                                        </tr>

                                        <tr>
                                            <!-- Contact avatar & Name-->
                                            <td class="text-left">
                                                <div>
                                                    <v-avatar color="primary" size="48" class="mr-3">
                                                        <span class="text-uppercase white--text font-weight-bold">CN</span>
                                                    </v-avatar>
                                                    <span>Cynthia D. Nero</span>
                                                </div>
                                            </td>

                                            <!-- Company -->
                                            <td class="text-left">Alphalete</td>

                                            <!-- Title -->
                                            <td class="text-left">Co-ordinator</td>

                                            <!-- Email -->
                                            <td class="text-left">
                                                <a href="mailto: cynthia@alphalete.com" class="text-decoration-none primary--text font-weight-extramedium">
                                                    cynthia@alphalete.com
                                                </a>
                                            </td>

                                            <!-- Shirt Size -->
                                            <td class="text-left">S</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>

                </v-col>
            </v-row>
        </v-container>

        <!-- Add contacts Dialog START -->
        <v-dialog v-model="addcontact" persistent max-width="800px" overlay-color="#f1f1f1" content-class="rounded-lg relative">
            <v-btn @click="addcontact = false" color="elevation-0 mt-10" fab absolute top right height="40" width="40">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-card height="540px">
                <v-row class="mx-0 align-center">
                    <!-- Upload CSV -->
                    <v-col cols="12" sm="6" md="6">
                        <div class="py-10 py-sm-16 px-lg-14">
                            <v-list text class="d-flex transparent">
                                <v-list-item class="flex-column" dense style="min-height: auto;">
                                    <input type="file" id="csvupload" class="d-none">
                                    <label for="csvupload" class="text-center csv-upload">
                                        <v-img :src="require('@/assets/images/svg/notes-card.svg')" contain width="48px" height="48px" class="mx-auto"></v-img>
                                        <span class="d-block text-h6 font-weight-bold primary--text mt-2">Upload CSV</span>
                                    </label>
                                </v-list-item>
                            </v-list>
                            <div class="grey--text text-body-2 font-weight-extramedium">
                                Add multiple contacts at one time. Download guidelines
                                <v-btn text color="green" class="text-none px-0 py-0 text-body-2 font-weight-bold text--lighten-3" min-width="auto" height="auto">here</v-btn>
                                to make sure your CSVs are formatted correctly.
                            </div>
                        </div>
                    </v-col>

                    <v-divider vertical style="height: 540px" class="d-none d-md-block"></v-divider>

                    <!-- Individual contact< -->
                    <v-col cols="12" sm="6" md="6">
                        <v-divider class="d-sm-none"></v-divider>
                        <div class="py-10 py-sm-16 px-lg-14">
                            <v-list text class="d-flex transparent">
                                <v-list-item :to="{ name: 'AddNewContact', }" class="flex-column" dense style="min-height: auto;">
                                    <v-img :src="require('@/assets/images/svg/contacts-card.svg')" contain width="48px" height="48px"></v-img>
                                    <span class="d-block text-h6 font-weight-bold primary--text mt-2">Individual contact</span>
                                </v-list-item>
                            </v-list>
                            <div class="grey--text text-body-2 font-weight-extramedium">Manually add a contact right from your account.</div>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <!-- Add contacts Dialog END -->
    </v-app>
</template>

<script>
    import ShipmentTabs from "@/components/Dashboard/ShipmentTabs"
    export default {
        name: 'ContactsList',
        components: {
            ShipmentTabs,
        },
        data() {
            return {
                addcontact: false,
            }
        },

        mounted() {},
        computed: {},
        methods: {},
        created() {}
    }
</script>

<style scoped>
    .v-card.v-card--link:hover {
        transform: scale(1.008) !important;
        border-bottom: 1px solid #d1d1d1;
    }

    .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr>td:last-child,
    .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
        border-bottom: 1px solid rgba(0, 0, 0, .12);
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .csv-upload:before {
        background-color: currentColor;
        bottom: 0;
        content: "";
        left: 0;
        opacity: 0;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        transition: .3s cubic-bezier(.25, .8, .5, 1);
    }

    .csv-upload:hover:before {
        opacity: .04;
    }
</style>
