<template>
  <v-app>
    <div class="d-flex justify-center align-center overflow-auto" style="min-height:90vh;">
      <v-container>
        <v-row class="justify-center my-4">
          <v-col cols="12" sm="8" md="6" lg="8" xl="6">
            <div class="text-center">
              <!-- <v-img :src="require('@/assets/images/thank-you.png')" contain height="auto"
                max-width="100%" width="120px" class="mx-auto"></v-img> -->
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" class="checkmark mx-auto mb-10">
                  <circle class="path circle" fill="none" stroke="#1b5e20" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                  <polyline class="path check" fill="none" stroke="#1b5e20" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                </svg>
            </div>
            <h1 class="font-weight-black text--primary text-lg-h3">Your order has been submitted successfully.</h1>
            <div class="text-body-1 my-5 text--darken-5 gray--text">We’ll send you a confirmation email shortly. Please
              be on the lookout to see what the next steps are!</div>
            <!-- <div class="text-h4 text--primary">Before you leave.</div> -->

            <!-- <div class="font-weight-extramedium px-lg-10">We’ve noticed that this is your first time using this email on our website. Please check your email to set a new account password to access your dashboard.</div> -->
            <!-- <div class="my-6"> -->
            <!-- <v-btn color="primary" class="font-weight-bold text-none rounded-sm py-6" :to="{name: 'LoginPage'}">Go to login</v-btn> -->
            <!-- </div> -->
          </v-col>
        </v-row>
      </v-container>
    </div>

  </v-app>
</template>

<script>
export default {
  name: 'ThankYou',
  components: {},
  mounted() { },
  computed: {},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.theme--light.v-btn--active:before,
.theme--light.v-btn--active:hover:before {
  opacity: 0;
}
</style>
