<template>
  <v-app>
    <div class="d-flex justify-center align-center overflow-auto">
      <v-container>
        <v-row class="justify-center my-10">
          <v-col cols="12" sm="8" md="6" lg="9" xl="6">
            <div class="text-center">
              <v-img :src="require('@/assets/images/hand-raise.png')" contain width="250px" class="img-fluid mx-auto"></v-img>
            </div>
            <h1 class="font-weight-black text--primary text-lg-h3 mt-3">Welcome aboard! Your account has been created successfully.</h1>
            <div class="text-body-1 my-5 text--darken-5 gray--text px-lg-16 mx-lg-10">We have sent a confirmation email to activate your account. Please follow to link to get started with building some sick swag!</div>
          </v-col>
        </v-row>
      </v-container>
    </div>

  </v-app>
</template>

<script>
  export default {
    name: 'AccountCreated',
    components: {},
    mounted() {},
    computed: {},
    data() {
      return {}
    },
    methods: {}
  }
</script>

<style scoped>
  .theme--light.v-btn--active:before,
  .theme--light.v-btn--active:hover:before {
    opacity: 0;
  }
</style>
