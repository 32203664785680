<template>
    <v-app>
        <v-container fluid class="px-0">
            <template v-if="loading">
                <Loader />
            </template>
            <template v-if="!loading">
                <v-row class="mb-1 align-center">
                    <v-col cols="12" sm="7" class="text-left">
                        <div class="d-flex">
                            <v-btn @click="$router.go(-1)" class="mx-2" fab x-small depressed>
                                <v-icon dark>mdi-chevron-left</v-icon>
                            </v-btn>
                            <div>
                                <div class="d-flex align-center mb-1">
                                    <h1 class="text-h6 font-weight-bold primary--text d-inline-block mr-2">Order no.
                                        {{ order.number }}</h1>

                                    <!-- Ready to ship -->
                                    <v-chip v-if="order.status == 'FULFILLEDd'"
                                        class="ma-1 indigo--text text--lighten-1 font-weight-extramedium indigo lighten-5"
                                        small>Ready to ship</v-chip>

                                    <!-- Paid -->
                                    <v-chip class="ma-1 primary--text font-weight-extramedium blue-grey lighten-5"
                                        v-if="order.status == 'FULFILLED'" small>Paid</v-chip>

                                    <!-- Artwork not submitted -->
                                    <v-chip
                                        class="ma-1 orange--text text--darken-4 font-weight-extramedium orange lighten-5"
                                        v-if="order.status == 'FULFILLEDd'" small>Artwork not submitted</v-chip>

                                    <!-- Unpaid -->
                                    <v-chip class="ma-1 red--text text--darken-4 font-weight-extramedium red lighten-5"
                                        v-if="order.status == 'UNFULFILLED'" small>Unpaid</v-chip>

                                    <!-- In stock     -->
                                    <div class="ma-1 text-body-2 green--text font-weight-bold text--lighten-3">In stock:
                                        100</div>

                                </div>
                                <div class="text-body-2 font-weight-medium mb-1">Total {{ getOrderPrice(order) }} / {{
                                        getCreatedDate(order.created_at)
                                }}
                                </div>
                                <div class="text-body-2 font-weight-medium mb-1">Order type: Swagbox</div>
                                <div class="text-body-2 font-weight-medium mb-1">{{ order.details?.number_of_boxes }}
                                    Swagboxes for {{ getNumberOfProducts(order) }} items</div>
                            </div>
                        </div>
                    </v-col>

                    <v-col cols="12" sm="5" class="text-right">
                        <v-btn :to="{ name: 'ProductOnboarding', }"
                            class="font-weight-bold  text-none rounded-sm py-6 mx-2" color="primary" outlined>
                            Restock swagbox
                        </v-btn>
                        <v-btn :to="{ name: 'ProductOnboarding', }"
                            class="font-weight-bold primary text-none rounded-sm py-6 mx-2">
                            Ship to contacts
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row>
                    <v-col cols="12">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left text-body-2 font-weight-extramedium">Item</th>
                                        <th class="text-left text-body-2 font-weight-extramedium">Quantity</th>
                                        <th class="text-left text-body-2 font-weight-extramedium">Size & Price Breakdown
                                        </th>
                                        <th class="text-left text-body-2 font-weight-extramedium">Subtotal</th>
                                        <th class="text-left text-body-2 font-weight-extramedium">Proof</th>
                                        <th class="text-left text-body-2 font-weight-extramedium" style="width:340px;">
                                            Options</th>
                                    </tr>
                                </thead>
                                <tbody v-for="(product, productIndex) in products" :key="productIndex">
                                    <!--  ----------------------- first product -----------------------  -->
                                    <tr>
                                        <td class="text-left">
                                            <div class="d-flex my-2">
                                                <div>
                                                    <v-img :src="product.images[0] ? product.images[0] : ''"
                                                        :lazy-src="product.images[0] ? product.images[0] : ''"
                                                        height="54px" width="54px" class="grey lighten-3 rounded-sm"
                                                        contain style="border:0.1px solid #F7F7F7;"></v-img>
                                                </div>
                                                <div class="ml-3">
                                                    <h6 class="text-body-2 font-weight-extramedium">{{ product.name }}
                                                    </h6>
                                                    <div class="caption">Color: {{ product.selected_color?.label }}
                                                    </div>
                                                    <div class="caption">Colors in logo: {{ product.number_of_logos }}
                                                        (+${{ product.imprint_color_price }})</div>
                                                    <div class="caption">Qty each box: {{ product.product_quantity }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        <td class="text-left">
                                            <div>{{ Number(order.details?.number_of_boxes * product.product_quantity) }}
                                            </div>
                                        </td>

                                        <td class="text-left" v-if="product.variants.length == 1">
                                            <div class="mb-1">One size ${{ Number(product.variants[0]?.price) }}/each
                                            </div>
                                        </td>

                                        <td class="text-left" v-if="product.variants.length > 1">
                                            <div class="mb-1" v-for="(variant, variantIndex) in product.variants"
                                                :key="variantIndex"><span>{{ variant.size.toUpperCase() }} X {{
                                                        variant.quantity
                                                }} ${{ Number(variant.price) }}/each</span>
                                            </div>
                                        </td>

                                        <td class="text-left">
                                            <div class="mb-1">{{ getProductPrice(product) }}</div>
                                        </td>

                                        <td class="text-left">
                                            <div class="my-2">
                                                <v-img :src="getProofImage(product)" :lazy-src="getProofImage(product)"
                                                    height="54px" width="54px" class="grey lighten-3 rounded-sm" contain
                                                    style="border:0.1px solid #F7F7F7;"></v-img>
                                            </div>
                                        </td>

                                        <td class="text-left">
                                            <v-btn @click.stop="viewArtWork(product)" text color="primary" small
                                                v-if="checkArtWork(product)"
                                                class="font-weight-bold text-none text-body-2 px-2">View artwork</v-btn>
                                            <v-btn @click.stop="viewProof(product)" text color="primary" small
                                                class="font-weight-bold text-none text-body-2 px-2"
                                                v-if="checkProof(product)">View proof</v-btn>
                                            <v-btn text color="green" small
                                                class="font-weight-bold text-none text-body-2 px-2 text--lighten-3">
                                                Restock item</v-btn>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3"></td>
                                        <td class="text-left">
                                            <div class="font-weight-bold">{{ getProductPrice(product) }}</div>
                                        </td>
                                        <td colspan="2"></td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row class="justify-space-between">
                    <!-- Billing Address -->
                    <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="text-left py-6 px-4">
                        <h6 class="text-h6 primary--text font-weight-bold mb-1">Billing Address</h6>
                        <div class="text-body-2 font-weight-extramedium">
                            <div>Street: {{ order.details.billing_address.address1 }}</div>
                            <div>{{ order.details?.customer?.email }}</div>
                            <div>{{ order.details.billing_address.state }}, {{ order.details.billing_address.city }}
                            </div>
                            <div>United States</div>
                            <div>{{ order.details?.customer?.phone }}</div>
                            <div>{{ order.details.billing_address.zip }}</div>
                        </div>
                    </v-col>

                    <!-- Shipping Address -->
                    <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="text-left py-6 px-4">
                        <h6 class="text-h6 primary--text font-weight-bold mb-1">Shipping Address</h6>
                        <div class="text-body-2 font-weight-extramedium">
                            <div>Multiple Addresses</div>
                            <v-btn text class="px-0 text-none font-weight-bold primary--text">Check shipment<v-icon>
                                    mdi-chevron-right</v-icon>
                            </v-btn>
                        </div>
                    </v-col>

                    <!-- Payment information -->
                    <v-col cols="12" sm="12" md="4" lg="3" xl="3" class="text-left text-md-right py-6 px-4">
                        <h6 class="text-h6 primary--text font-weight-bold mb-1">Payment information</h6>
                        <div class="text-body-2 font-weight-extramedium" v-if="order.status == 'FULFILLED'">
                            <div class="mb-2">Paid by Visa <span class="font-weight-bold primary--text">****{{
                                    order.transaction?.last_4
                            }}</span>
                            </div>
                            <div class="d-flex justify-space-between mb-2">
                                <div>Subtotal ({{ products.length }} items)</div>
                                <div>{{ getAllOrderBasePrice() }}</div>
                            </div>
                            <div class="d-flex justify-space-between mb-2">
                                <div>Cost of colors in logo</div>
                                <div>${{ Number(this.allLogoPrice) }}</div>
                            </div>
                            <div class="d-flex justify-space-between mb-2">
                                <div>Shipping </div>
                                <div>N/A</div>
                            </div>
                            <div class="d-flex justify-space-between mb-2">
                                <div>Setup fee</div>
                                <div>N/A</div>
                            </div>
                            <div class="d-flex justify-space-between mb-2 primary--text font-weight-black">
                                <div>TOTAL</div>
                                <div>{{ getOrderPrice(order) }}</div>
                            </div>
                        </div>
                        <div class="text-body-2 font-weight-extramedium" v-if="order.status == 'UNFULFILLED'">
                            <div class="mb-2">Unpaid</div>
                        </div>
                    </v-col>
                </v-row>
            </template>
        </v-container>

        <!-- View Artwork Dialog START -->
        <v-dialog v-model="viewartwork" persistent max-width="700px" overlay-color="#f1f1f1"
            content-class="rounded-lg relative">
            <v-btn @click="viewartwork = false" color="elevation-0 mt-10" fab absolute top right height="40" width="40">
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-card>
                <div class="py-8 artworkimg">
                    <img :src="artWorks" class="img-fluid">
                </div>

                <v-card-actions class="text-center pb-10" v-if="viewartwork">
                    <v-btn outlined color="primary" class="text-none font-weight-bold mx-auto" v-if="!proofApproved()"
                        @click="$refs.file.click()">Add custom artwork for
                        this item</v-btn>
                    <input type="file" ref="file" style="display: none" accept="image/*" @change="uploadArtWork" />
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- View Artwork Dialog END -->

        <!-- View Proof Dialog START -->
        <v-dialog v-model="viewproof" persistent fullscreen transition="dialog-bottom-transition"
            overlay-color="#f1f1f1" content-class="rounded-lg relative">
            <v-btn @click="viewproof = false" color="elevation-0 mt-10" fab absolute top right height="40" width="40">
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-card>
                <v-container fluid v-if="viewproof">
                    <v-row>
                        <v-col cols="12" md="6" class="text-left my-4">
                            <div class="d-inline-block">
                                <!-- title -->
                                <div class="text-h6 font-weight-bold mb-3">Unisex heavy cotton tee <v-chip
                                        class="text-uppercase white--text font-weight-bold" color="orange" x-small>{{
                                                proof.status
                                        }}
                                    </v-chip>
                                </div>

                                <!-- proof image -->
                                <div class="proofimage d-inline-block">
                                    <v-img :src="proof.image" class="img-fluid" width="480px"></v-img>
                                </div>
                                <!-- Action button -->
                                <div class="mx-auto text-center" v-if="proof.status == 'Pending'">
                                    <v-btn color="primary" class="text-none font-weight-bold rounded-sm ma-1"
                                        @click="approveArtWork">Approve
                                        proof</v-btn>
                                    <!-- <v-btn outlined color="primary" class="text-none font-weight-bold rounded-sm ma-1">
                                        Add custom artwork</v-btn> -->
                                </div>
                            </div>
                        </v-col>

                        <v-divider vertical style="height:100vh;"></v-divider>

                        <v-col cols="12" md="6" class="px-0 relative">
                            <div class="text-left mb-4 mt-2 px-4 primary--text font-weight-bold text-h6">Messages</div>

                            <v-divider></v-divider>

                            <!-- When Chat screen Empty START -->
                            <!-- <v-row v-if="true" class="fill-height pb-14 mx-0 align-center">
                                <v-col class="px-lg-16">
                                    <div class="px-lg-16">
                                        <div class="text-h6 primary--text font-weight-bold mb-3">
                                            You can type your requests here if you want to change anything in design.
                                        </div>
                                        <img src="@/assets/images/svg/chat.svg" class="img-fluid d-block mx-auto">
                                    </div>
                                </v-col>
                            </v-row> -->
                            <!-- When Chat screen Empty END -->

                            <!-- When Chat initiated START-->
                            <div class="fill-height chat-window pt-10">
                                <v-row class="mx-0 align-top mt-0" v-for="(message, messageIndex) in messages"
                                    :key="messageIndex">
                                    <v-col class="py-0" v-if="message.sended_by == 'Customer'">
                                        <!-- right side messgae -->
                                        <div class="d-flex flex-row align-center my-2 justify-end">
                                            <div>
                                                <img v-if="message.image" :src="message.image"
                                                    class="sent-image img-fluid d-block ml-auto">
                                                <v-alert
                                                    class="primary--text ml-16 mr-3 rounded-lg py-3 lighten-4 text-left chevron-right mb-3"
                                                    color="blue-grey">
                                                    {{ message.text }}
                                                </v-alert>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col class="py-0" v-if="message.sended_by == 'Admin'">
                                        <!-- right side messgae -->
                                        <div class="d-flex flex-row align-center my-2">
                                            <div>
                                                <img v-if="message.image" :src="message.image"
                                                    class="sent-image img-fluid d-block ml-auto">
                                                <v-alert outlined
                                                    class="primary--text mr-16 ml-3 rounded-lg py-3 text-left chevron-left mb-3"
                                                    color="blue-grey">
                                                    {{ message.text }}
                                                </v-alert>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                                <!-- <v-row class="py-0">
                                    <v-col class="py-0">
                                        <div class="d-flex flex-row align-center my-2">
                                            <div>
                                                <v-alert outlined
                                                    class="primary--text mr-16 ml-3 rounded-lg py-3 text-left chevron-left mb-3"
                                                    color="blue-grey">
                                                    Just Testing
                                                </v-alert>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row> -->
                                <!-- <v-row class="py-0">

                                    <v-col class="py-0">
                                        <div class="d-flex flex-row align-center my-2">
                                            <div>
                                                <v-alert outlined
                                                    class="primary--text mr-16 ml-3 rounded-lg py-3 text-left chevron-left mb-3">
                                                    Hey sure. We'll add a new proof soon
                                                </v-alert>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row> -->
                                <!-- <v-row class="mx-0 align-top mt-0">
                                    <v-col class="py-0">
                                        <div class="d-flex flex-row align-center my-2 justify-end flex-wrap">
                                            <div>
                                                <img src="@/assets/images/classics-pack.png"
                                                    class="sent-image img-fluid d-block ml-auto">
                                                <v-alert
                                                    class="primary--text ml-16 mr-3 rounded-lg py-3 lighten-4 text-left chevron-right mb-3"
                                                    color="blue-grey">
                                                    Hey I love this, Can you just make sure its a tad bit smaller on the
                                                    print..
                                                </v-alert>
                                            </div>
                                        </div>

                                        <div class="d-flex flex-row align-center my-2">
                                            <div>
                                                <img src="@/assets/images/classics-pack.png"
                                                    class="sent-image img-fluid d-block">
                                                <v-alert outlined
                                                    class="primary--text mr-16 ml-3 rounded-lg py-3 text-left chevron-left mb-3">
                                                    Hey sure. We'll add a new proof soon
                                                </v-alert>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row> -->
                            </div>
                            <!-- When Chat initiated END-->

                            <!-- Type message area START -->
                            <v-footer absolute class="transparent px-1">
                                <v-container class="ma-0 pa-0 white">
                                    <div class="text-body-2 grey--text font-eight-extramedium my-1">😊 That's the end of
                                        the conversation.</div>
                                    <v-divider></v-divider>
                                    <validation-observer ref="observer">
                                        <v-form ref="form" class="white">
                                            <v-row no-gutters class="">
                                                <v-col class="pl-3 pr-6 mt-2">
                                                    <div class="d-flex flex-row align-center">
                                                        <!-- message Input field -->
                                                        <validation-provider v-slot="{ errors }" rules="required"
                                                            name="Changes" class="w-100">
                                                            <v-text-field v-model="requestMessage" solo
                                                                :disabled="proof.status == 'Approved' || messageLoading"
                                                                :error-messages="errors" :error="errors.length !== 0"
                                                                :hide-details="errors.length <= 0"
                                                                placeholder="Write a message or a request here..."
                                                                @keypress.enter="requestChanges"
                                                                class="elevation-0 sendmsg"></v-text-field>
                                                        </validation-provider>

                                                        <div class="d-flex">
                                                            <!-- Upload Attachment -->
                                                            <!-- <div>
                                                                <v-btn @click="$refs.file.click()" outlined fab small
                                                                    :disabled="proof.status != 'Pending'" color="primary"
                                                                    class="ml-3 elevation-0">
                                                                    <img src="@/assets/images/svg/clip.svg"
                                                                        class="img-fluid">
                                                                </v-btn>
                                                                <input type="file" ref="file" @change="updateLogo"
                                                                    style="display: none" accept="image/*" />
                                                            </div> -->
                                                            <div class="d-flex align-center">
                                                                <div class="relative attachimg mr-2">
                                                                    <v-hover v-slot="{ hover }">
                                                                        <v-card :elevation="hover ? 0 : 0"
                                                                            :class="{ 'on-hover': hover }">
                                                                            <v-img :src="url"
                                                                                class="rounded-md chatpreview-img">
                                                                                <v-btn :class="{ 'show-btns': hover }"
                                                                                    @click="removeImage"
                                                                                    color="transparent" icon>
                                                                                    <v-icon>mdi-close</v-icon>
                                                                                </v-btn>
                                                                            </v-img>
                                                                        </v-card>
                                                                    </v-hover>
                                                                    <!-- <div class="close-icon">X</div> -->
                                                                </div>

                                                                <v-btn outlined fab small color="primary" class="pa-0"
                                                                    :disabled="proof.status == 'Approved' || messageLoading">
                                                                    <label for="chatimage" class="chatfile">
                                                                        <img src="@/assets/images/svg/clip.svg"
                                                                            class="img-fluid">
                                                                    </label>
                                                                </v-btn>
                                                                <v-file-input id="chatimage" v-model="chatimagefile"
                                                                    @change="updateLogo" accept="image/*"
                                                                    class="d-none"></v-file-input>
                                                            </div>

                                                            <!-- Send button -->
                                                            <v-btn fab small color="primary" @click="requestChanges"
                                                                :disabled="proof.status == 'Approved'"
                                                                :loading="messageLoading" class="ml-3 elevation-0">
                                                                <img src="@/assets/images/svg/paper-plane.svg"
                                                                    class="img-fluid">
                                                            </v-btn>
                                                        </div>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </validation-observer>
                                </v-container>
                            </v-footer>
                            <!-- Type message area END -->
                        </v-col>
                        <v-divider vertical style="height:100vh;"></v-divider>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
        <!-- View Proof Dialog END -->
    </v-app>
</template>

<script>
import Loader from "@/components/Loader"
export default {
    name: 'OrderDetails',
    components: {
        Loader
    },
    data() {
        return {
            loading: true,
            messageLoading: false,
            order: {},
            productIds: [],
            products: [],
            allLogoPrice: 0,
            artWorks: null,
            product_id: null,
            viewartwork: false,
            viewproof: false,
            proof: {},
            selectedProduct: {},
            msg: null,
            send: null,
            requestMessage: null,
            requestedArtWork: null,
            chatimagefile: null,
            messages: []
        }
    },

    mounted() { },
    computed: {
        url() {
            if (!this.chatimagefile) return;
            return URL.createObjectURL(this.chatimagefile);
        }
    },
    methods: {
        getOrder() {
            let data = {
                order_id: this.$route.params.order_id
            }
            this.loading = true
            this.$store.dispatch('GET_ORDER_DETAIL', data).then(
                response => {
                    this.order = response.order
                    this.createProductsArray()
                    this.messages = []
                    this.loading = false
                },
                error => {
                    this.loading = false
                    throw error
                }
            )
        },

        createProductsArray() {
            this.order.variants.forEach(variant => {
                let product = this.products.find(prod => {
                    return prod.product_id === variant.product_id
                });
                if (product) {
                    this.products.find(prod => {
                        return prod.product_id === variant.product_id
                    }).variants.push(variant)
                } else {
                    this.products.push({
                        product_id: variant.product_id,
                        name: variant.name,
                        selected_color: variant.selected_color,
                        product_quantity: variant.product_quantity,
                        number_of_logos: variant.number_of_logos,
                        imprint_color_price: variant.imprint_color_price,
                        images: variant.images,
                        variants: [variant]
                    })
                }
            });

            this.products.forEach(prod => {
                this.allLogoPrice = Number(this.allLogoPrice) + prod.imprint_color_price
            })
        },

        getCreatedDate(created_at) {
            let date = new Date(created_at)
            let time = date.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
            })
            return date.toLocaleString('default', { month: 'short' }) + " " + date.getDate() + ", " + date.getFullYear() + " " + time
        },

        getNumberOfProducts(order) {
            return this.$helper.functions.getNumberOfProducts(order)
        },

        getOrderPrice(order) {
            return Number(order.details?.total_price).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })
        },

        getProductPrice(product) {
            let price = product.variants.reduce(function (sum, current) {
                return Number(sum) + (Number(current.price) * Number(current.quantity));
            }, 0);



            return Number(price).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })
        },

        getAllOrderBasePrice() {
            let basePrice = Number(this.order.transaction?.amount) - Number(this.allLogoPrice)
            return basePrice.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })
        },

        checkArtWork() {
            if (this.order.details?.primary_logo || this.order.details?.secondary_logo) {
                return true
            } else {
                return false
            }
        },

        checkProof(product) {
            if (product.variants[0].art_works && product.variants[0].art_works.find(proof => proof.status != 'replaced')) {
                return true
            } else {
                return false
            }
        },

        proofApproved() {
            if (this.selectedProduct.variants[0]?.art_works && this.selectedProduct.variants[0]?.art_works.find(proof => proof.status == 'Approved')) {
                return true
            } else {
                return false
            }
        },

        viewArtWork(product) {
            if (product.variants[0]?.custom_art_works) {
                let customArtWork = product.variants[0]?.custom_art_works.find(artWork => artWork.status != 'Replaced')
                this.artWorks = customArtWork.image
            } else {
                this.artWorks = this.order.details?.primary_logo
            }
            this.selectedProduct = product
            this.viewartwork = !this.viewartwork
            this.product_id = product.product_id
        },

        viewProof(product) {
            if (product.variants[0].art_works) {
                this.proof = product.variants[0].art_works.find(proof => proof.status != 'replaced')
                this.selectedProduct = product
                // Get Messages
                let productDetails = this.order.details.product_details.find(prod => prod.product_id == product.product_id)
                this.messages = productDetails.messages
                // End Get Messages
                this.viewproof = !this.viewproof
            }
        },

        removeImage() {
            this.requestedArtWork = null
            this.chatimagefile = null
        },

        getProofImage(product) {
            if (product.variants[0].art_works) {
                let artWork = product.variants[0].art_works.find(proof => proof.status != 'replaced')
                if (artWork) {
                    return artWork.image
                } else {
                    return '-'
                }

            } else {
                return '-'
            }
        },

        approveArtWork() {
            this.loading = true
            let payload = {
                art_work: this.proof,
                order_id: this.order.id
            }
            this.$store.dispatch('APPROVE_ART_WORK', payload).then(
                response => {
                    this.$store.commit("showAlert", {
                        text: response.message,
                        successStatus: true,
                    });
                    this.products = []
                    this.order = response.order
                    this.createProductsArray()
                    this.viewproof = !this.viewproof
                    this.proof = {}
                    this.loading = false
                },
                error => {
                    this.loading = false
                    this.$store.commit("showAlert", {
                        text: error.data.message,
                        successStatus: false,
                        alertColor: 'red darken-4'
                    });
                    // if (error.data.redirect) {
                    //   this.$router.push({ name: 'Home' })
                    // }
                    throw error
                }
            )
        },

        uploadArtWork(event) {
            let fileType = event.target.files[0]['type'];
            let allowedTypes = ['application/postscript', 'image/svg+xml', 'image/png', 'image/jpeg']
            if (allowedTypes.includes(fileType)) {
                this.loading = true
                let formData = new FormData();
                formData.append('art_work', event.target.files[0])
                formData.append('order_id', this.order.id)
                formData.append('product_id', this.product_id)
                this.$store.dispatch('UPLOAD_PRODUCT_ART_WORK', formData).then(
                    response => {
                        this.$store.commit("showAlert", {
                            text: response.message,
                            successStatus: true,
                        });
                        this.products = []
                        this.order = response.order
                        this.createProductsArray()
                        this.viewartwork = false
                        this.proof = {}
                        this.product_id = null
                        this.loading = false
                    },
                    error => {
                        this.loading = false
                        this.$store.commit("showAlert", {
                            text: error.data.message,
                            successStatus: false,
                            alertColor: 'red darken-4'
                        });
                        // if (error.data.redirect) {
                        //   this.$router.push({ name: 'Home' })
                        // }
                        throw error
                    }
                )
            } else {
                this.$store.commit("showAlert", {
                    text: 'Invalid File Type',
                    successStatus: false,
                });
            }
        },

        updateLogo(event) {
            this.requestedArtWork = this.chatimagefile

            // let fileType = event.target.files[0]['type'];
            // let allowedTypes = ['application/postscript', 'image/svg+xml', 'image/png', 'image/jpeg']
            // if (allowedTypes.includes(fileType)) {
            //     this.requestedArtWork = event.target.files[0]
            // } else {
            //     this.$store.commit("showAlert", {
            //         text: 'Invalid File Type',
            //         successStatus: false,
            //     });
            // }

        },

        requestChanges() {
            this.$refs.observer.validate().then(success => {
                if (!success) {
                    return
                }
                this.messageLoading = true
                // let payload = {
                //     art_work: this.proof,
                //     order_id: this.order.id,
                //     request_message: this.requestMessage
                // }

                let formData = new FormData();
                formData.append('art_work', JSON.stringify(this.proof))
                formData.append('order_id', this.order.id)
                formData.append('request_message', this.requestMessage)
                formData.append('requested_art_work', this.requestedArtWork)
                formData.append('product_id', this.selectedProduct.product_id)
                formData.append('sended_by', 'Customer')

                this.$store.dispatch('SUBMIT_ARTWORK_CHANGES', formData).then(
                    response => {
                        // this.loading = false
                        // console.log(response)
                        this.$store.commit("showAlert", {
                            text: response.message,
                            successStatus: true,
                        });
                        console.log('response.sended_message', response.sended_message)
                        this.messages.push(response.sended_message)
                        this.products = []
                        this.order = response.order
                        this.createProductsArray()
                        // this.viewproof = !this.viewproof
                        // this.proof = {}
                        this.requestedArtWork = null
                        this.requestMessage = null
                        this.messageLoading = false
                    },
                    error => {
                        this.messageLoading = false
                        this.$store.commit("showAlert", {
                            text: error.data.message,
                            successStatus: false,
                            alertColor: 'red darken-4'
                        });
                        // if (error.data.redirect) {
                        //   this.$router.push({ name: 'Home' })
                        // }
                        throw error
                    }
                )
            });
        }
    },
    created() {
        this.getOrder()
    }
}
</script>

<style scoped>
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr>td:last-child,
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
    padding: 8px;
    vertical-align: top;
    font-size: 13px;
    height: auto;
    border-bottom: 1px solid rgba(0, 0, 0, .12);
}

.proofimage {
    border: 1px solid rgba(7, 26, 82, 0.16);
    border-radius: 8px;
}

.artworkimg {
    width: 350px;
    height: 350px;
    object-fit: contain;
    margin: 0 auto;
    overflow: hidden;
}

.artworkimg img {
    object-fit: contain;
    height: 100%;
}

.chat-window {
    max-height: calc(100vh - 110px);
    overflow-y: auto;
}

.chevron-right::before {
    content: "\F040A";
    font-family: "Material Design Icons";
    position: absolute;
    right: -9px;
    bottom: 0;
    color: #cfd8dc;
}

.chevron-left::before {
    content: "\F0141";
    font-family: "Material Design Icons";
    position: absolute;
    left: -11px;
    bottom: 0;
    color: #071a52;
}

.chevron-left::after {
    content: '';
    height: 6px;
    width: 4px;
    position: absolute;
    background: #fff;
    left: -1px;
    bottom: 9px;
}

.chatfile {
    height: 40px;
    width: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.sent-image {
    height: 200px;
    width: 200px;
    border-radius: 3px;
    object-fit: contain;
    margin-bottom: 4px;

}
</style>
