import axios from "axios";

import {
    CHECKOUT,
    CHECKOUT_SUCCESS,
    ADD_CUSTOMER,
    ADD_CUSTOMER_SUCCESS,
    PAYLATER_ORDER,
    CHARGE_PAYMENT,
    CALCULATE_TAX
} from "@/store/actions/stripe";

function initialState() {
    return {
        stripe: {}
    };
}

const state = initialState()


const getters = {
};

const actions = {
    // { commit },
    [CHECKOUT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios.post('/stripe-checkout', payload)
                .then((response) => {
                    if (response.status == 200) {
                        let stripe = response.data.boxes
                        commit("CHECKOUT_SUCCESS", { stripe });
                        resolve(response.data);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    [PAYLATER_ORDER]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios.post('/pay-later', payload)
                .then((response) => {
                    if (response.status == 200) {
                        resolve(response.data);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    [CHARGE_PAYMENT]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios.post('/charge-payment', payload)
                .then((response) => {
                    if (response.status == 200) {
                        resolve(response.data);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    [ADD_CUSTOMER]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios.post('/add-customer', payload)
                .then((response) => {
                    if (response.status == 200) {
                        resolve(response.data);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    [CALCULATE_TAX]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios.post('/calculate-tax', payload)
                .then((response) => {
                    // if (response.status == 200) {
                        resolve(response.data);
                    // }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
}

const mutations = {
    [CHECKOUT_SUCCESS]: (state, params) => {
        state.stripe = params.stripe;
    },

    // [PRESET_BOX_ERROR]: () => {
    // },
};

export default {
    state,
    getters,
    actions,
    mutations,
};