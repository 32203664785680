<template>
    <v-app>
        <v-container fluid :style="{height: '67vh'}">
            <h1 :style="{paddingTop: '14%'}">Page Not Found</h1>
        </v-container>
    </v-app>
</template>
  
<script>
export default {
    name: 'NotFound',
    components: {},
    mounted() { },

    data() {
        return {}
    },
}
</script>
  
<style scoped>
.action-buttons {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}

.active .v-sheet--outlined {
    border: 1px solid #071A52;
}

@media (max-width: 600px) {
    .action-buttons {
        position: relative;
    }
}
</style>
  