import axios from "axios";

import {
    GET_PRODUCT_LIST,
    PRODUCT_SUCCESS,
    PACKAGE_SUCCESS,
    PRODUCT_ERROR
} from "@/store/actions/products";

function initialState() {
    return {
        products: [],
        packages: []
    };
}

const state = initialState()


const getters = {
    products: (state) => state.products,
    packages: (state) => state.packages,
};

const actions = {
    [GET_PRODUCT_LIST]: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            axios.post('/products', params)
                .then((response) => {
                    if (response.status == 200) {
                        let products = response.data.products
                        let packages = response.data.packages
                        // commit("PRODUCT_SUCCESS", { products });
                        // commit("PACKAGE_SUCCESS", { packages });
                        resolve(response.data);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
}

const mutations = {
    [PRODUCT_SUCCESS]: (state, params) => {
        state.products = params.products;
    },

    [PACKAGE_SUCCESS]: (state, params) => {
        state.packages = params.packages;
    },

    [PRODUCT_ERROR]: () => {
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};