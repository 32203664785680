<template>
  <v-app>
    <!-- Pick an option-->
    <PickOption />

  </v-app>
</template>

<script>
  import PickOption      from "./components/PickOption"

  export default {
    name: 'ProductOnboarding',
    components: {
      PickOption,
    },
    mounted() {},
    computed: {},
    data() {
      return {

      }
    },
    methods: {}
  }
</script>

<style scoped>

</style>
