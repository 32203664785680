<template>
    <v-app>
        <v-container fluid class="px-0">
            <template v-if="loading">
                <Loader />
            </template>
            <template v-if="!loading">
                <v-row>
                    <v-col cols="12">
                        <!-- Title -->
                        <div class="d-flex mt-4">
                            <v-btn @click="$router.go(-1)" class="mx-2" fab x-small depressed>
                                <v-icon dark>mdi-chevron-left</v-icon>
                            </v-btn>
                            <div class="d-flex align-center mb-1 flex-wrap">
                                <h1 class="text-h6 font-weight-bold primary--text d-inline-block mr-1">Add contact group</h1>
                            </div>
                        </div>
                    </v-col>
                </v-row>

                <v-form>
                    <v-row>
                        <!-- Upload photo -->
                        <v-col cols="12" sm="4" md="3" lg="2" class="text-sm-left">
                            <v-file-input v-model="image" id="profilepic" class="d-none" />
                            <label for="profilepic" class="contactpic relative mx-auto mx-sm-0 ml-lg-3">
                                <div class="placeholder">
                                    <img src="@/assets/images/svg/users.svg" class="img-fluid">
                                    <div class="text-body-2 font-weight-extramedium primary--text mt-2">Upload photo</div>
                                </div>
                                <v-img :src="url" contain class="img-fluid"></v-img>
                            </label>
                        </v-col>

                        <!-- Form -->
                        <v-col cols="12" sm="8" md="9" lg="10">
                            <v-row>
                                <!-- Group name -->
                                <v-col cols="12" sm="6">
                                    <v-subheader class="px-0 text--primary font-weight-bold">Group name *</v-subheader>
                                    <validation-provider rules="required" name="Group name">
                                        <v-text-field placeholder="New Hires" hide-details outlined dense required></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <!-- Shirt size -->
                                <v-col cols="12" sm="6">
                                    <v-subheader class="px-0 text--primary font-weight-bold">Category</v-subheader>
                                    <validation-provider rules="required" name="Category">
                                        <v-select v-model="groupcategory" :items="Categorylist" item-text="categories" hide-details outlined dense required></v-select>
                                    </validation-provider>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-divider class="mt-6"></v-divider>
                    <!-- When contact groups is empty START -->
                    <div v-if="false">
                        <v-row>
                            <v-col>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">Contact</th>
                                                <th class="text-left">Company</th>
                                                <th class="text-left">Title</th>
                                                <th class="text-left">Email</th>
                                                <th class="text-left" style="width: 200px;">Shirt Size</th>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr style="&:hover: none;">
                                                <td colspan="5">
                                                    <v-btn depressed class="text-none my-3 d-block w-100 el primary--text font-weight-bold btn-outline">
                                                        <v-icon class="text-body-2 mr-1">mdi-plus-circle-outline</v-icon>
                                                        Add Contact
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </thead>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                        <v-row style="height: calc(100vh - 400px);" class="align-center">
                            <v-col cols="12" class="text-center">
                                <v-img :src="require('@/assets/images/svg/notes-card.svg')" contain width="48px" class="mx-auto"></v-img>
                                <div class="text-h6 font-weight-bold primary--text mb-1">No contact groups yet.</div>
                                <div class="grey--text font-weight-extramedium">You haven’t added contact groups yet.</div>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- When contact groups is empty START -->

                    <!-- Show contacts list when data available START -->
                    <div v-else>
                        <v-row>
                            <v-col>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">Contact</th>
                                                <th class="text-left">Company</th>
                                                <th class="text-left">Title</th>
                                                <th class="text-left">Email</th>
                                                <th class="text-left" style="width: 200px;">Shirt Size</th>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr style="&:hover: none;">
                                                <td colspan="5">
                                                    <v-btn depressed class="text-none my-3 d-block w-100 el primary--text font-weight-bold btn-outline">
                                                        <v-icon class="text-body-2 mr-1">mdi-plus-circle-outline</v-icon>
                                                        Add Contact
                                                    </v-btn>
                                                    <v-divider></v-divider>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <!-- Contact avatar & Name-->
                                                <td class="text-left">
                                                    <div>
                                                        <v-avatar color="primary" size="48" class="mr-3">
                                                            <span class="text-uppercase white--text font-weight-bold">KM</span>
                                                        </v-avatar>
                                                        <span>Karen M.</span>
                                                    </div>
                                                </td>

                                                <!-- Company -->
                                                <td class="text-left">Alphalete</td>

                                                <!-- Title -->
                                                <td class="text-left">Jr. Designer</td>

                                                <!-- Email -->
                                                <td class="text-left">
                                                    <a href="mailto: karen@alphalete.com" class="text-decoration-none primary--text font-weight-extramedium">
                                                        karen@alphalete.com
                                                    </a>
                                                </td>

                                                <!-- Shirt Size -->
                                                <td class="text-left">S</td>
                                            </tr>

                                            <tr>
                                                <!-- Contact avatar & Name-->
                                                <td class="text-left">
                                                    <div>
                                                        <v-avatar color="primary" size="48" class="mr-3">
                                                            <img src="https://randomuser.me/api/portraits/men/81.jpg" alt="Frank K. Chill">
                                                        </v-avatar>
                                                        <span>Frank K. Chill</span>
                                                    </div>
                                                </td>

                                                <!-- Company -->
                                                <td class="text-left">Alphalete</td>

                                                <!-- Title -->
                                                <td class="text-left">Co-ordinator</td>

                                                <!-- Email -->
                                                <td class="text-left">
                                                    <a href="mailto: frank@alphalete.com" class="text-decoration-none primary--text font-weight-extramedium">
                                                        frank@alphalete.com
                                                    </a>
                                                </td>

                                                <!-- Shirt Size -->
                                                <td class="text-left">LG</td>
                                            </tr>

                                            <tr>
                                                <!-- Contact avatar & Name-->
                                                <td class="text-left">
                                                    <div>
                                                        <v-avatar color="primary" size="48" class="mr-3">
                                                            <span class="text-uppercase white--text font-weight-bold">CN</span>
                                                        </v-avatar>
                                                        <span>Cynthia D. Nero</span>
                                                    </div>
                                                </td>

                                                <!-- Company -->
                                                <td class="text-left">Alphalete</td>

                                                <!-- Title -->
                                                <td class="text-left">Co-ordinator</td>

                                                <!-- Email -->
                                                <td class="text-left">
                                                    <a href="mailto: cynthia@alphalete.com" class="text-decoration-none primary--text font-weight-extramedium">
                                                        cynthia@alphalete.com
                                                    </a>
                                                </td>

                                                <!-- Shirt Size -->
                                                <td class="text-left">S</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- Show contacts list when data available END -->

                    <v-row>
                        <v-col cols="12" class="mt-10 text-sm-right">
                            <v-divider></v-divider>
                            <div class="mt-4">
                                <v-btn depressed class="text-none rounded-sm mx-1 py-6 font-weight-bold grey--text">Delete contact</v-btn>
                                <v-btn color="primary" class="text-none rounded-sm mx-1 py-6 font-weight-bold">Save contact</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-form>
            </template>
        </v-container>
    </v-app>
</template>

<script>
    import Loader from "@/components/Loader"
    // import NwImg from "@/components/NwImg"
    export default {
        name: 'AddContactGroup',
        components: {
            Loader,
            // NwImg
        },
        data() {
            return {
                loading: false,
                image: null,
                groupcategory: {
                    categories: 'Employees'
                },
                Categorylist: [{
                    categories: 'Employees'
                }, {
                    categories: 'Clients'
                }, {
                    categories: 'Team mates'
                }]
            }
        },

        mounted() {},

        computed: {
            url() {
                if (!this.image) return;
                return URL.createObjectURL(this.image);
            }
        },
        methods: {},
        created() {}
    }
</script>

<style scoped>
    .v-card.v-card--link:hover {
        transform: scale(1.004) !important;
    }

    .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child,
    .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr>td:not(.v-data-table__mobile-row) {
        padding: 8px;
        vertical-align: top;
        font-size: 13px;
        height: auto;
        /* border-bottom: 1px solid rgba(0, 0, 0, .12); */
    }

    .v-subheader {
        height: auto;
        margin-bottom: 10px;
    }
</style>
