import VueRouter from 'vue-router'
import Home from '@/views/Home'
import ProductOnboarding from '@/views/ProductOnboarding'
import PresetBoxes from '@/views/PresetBoxes'
import Cart from '@/views/Cart'
import PaymentOptions from '@/views/PaymentOptions'
import ProductCatalog from '@/views/ProductCatalog'
import LoginPage from '@/views/LoginPage'
import ForgotPassword from '@/views/ForgotPassword'
import SignUpPage from '@/views/SignUpPage'
import VerifyAccount from '@/views/SignUpPage/VerifyAccount'
import SetPassword from '@/views/SetPassword'
import NewPasswordSet from '@/views/NewPasswordSet'
import ThankYou from '@/views/ThankYou'
import OrderQuote from '@/views/ThankYou/OrderQuote.vue'
import CartEmpty from '@/views/CartEmpty'
import EmailVerified from '@/views/EmailVerified'
import AccountCreated from '@/views/AccountCreated'
import ProofAction from '@/views/ProofAction'
import UploadArtwork from '@/views/UploadArtwork'
import Payment from '@/views/Payment'
import NotFound from '@/views/NotFound'
// import DashBoard from '@/views/CustomerDashboard/DashBoard'
import TermsOfUse from '@/views/TermsOfUse'
import PrivacyPolicy from '@/views/PrivacyPolicy'
import CustomerDashboard from '@/views/CustomerDashboard'
import Dashboard from '@/views/CustomerDashboard/Dashboard'
import Quotes from '@/views/CustomerDashboard/Quotes'
import Orders from '@/views/CustomerDashboard/Orders'
import Shipment from '@/views/CustomerDashboard/Shipment'
import ShipmentDetails from '@/views/CustomerDashboard/Shipment/ShipmentDetails'
import ContactsList from '@/views/CustomerDashboard/ContactsList'
import AddNewContact from '@/views/CustomerDashboard/ContactsList/AddNewContact'
import ContactGroups from '@/views/CustomerDashboard/ContactGroups'
import AddContactGroup from '@/views/CustomerDashboard/ContactGroups/AddContactGroup'
import Inventory from '@/views/CustomerDashboard/Inventory'
import OrderDetails from '@/views/CustomerDashboard/Orders/OrderDetails'
import store from '@/store/index';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
    }
  },
  {
    path: '/product-on-boarding',
    name: 'ProductOnboarding',
    component: ProductOnboarding,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
    }
  },
  {
    path: '/preset-boxes',
    name: 'PresetBoxes',
    component: PresetBoxes,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
    }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
    }
  },
  {
    path: '/payment-options',
    name: 'PaymentOptions',
    component: PaymentOptions,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
    }
  },
  {
    path: '/product-catalog',
    name: 'ProductCatalog',
    component: ProductCatalog,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
    }
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
      hideNavbar: true,
      hideFooter: true,
      is_auth_page: true
    }
  },
  {
    path: '/sign-up',
    name: 'SignUpPage',
    component: SignUpPage,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
      hideNavbar: true,
      hideFooter: true,
      is_auth_page: true
    }
  },
  {
    path: '/verify-account/:token',
    name: 'EmailVerified',
    component: EmailVerified,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
      is_auth_page: true
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
      hideNavbar: true,
      hideFooter: true,
      is_auth_page: true
    }
  },
  {
    path: '/reset-password/:token',
    name: 'SetPassword',
    component: SetPassword,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
      hideNavbar: true,
      hideFooter: true,
      is_auth_page: true
    }
  },
  {
    path: '/password-set',
    name: 'NewPasswordSet',
    component: NewPasswordSet,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
      hideNavbar: true,
      hideFooter: true,
      is_auth_page: true
    }
  },
  {
    path: '/thank-You',
    name: 'ThankYou',
    component: ThankYou,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
    }
  },
  {
    path: '/order-quote',
    name: 'OrderQuote',
    component: OrderQuote,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
    }
  },
  {
    path: '/cart-empty',
    name: 'CartEmpty',
    component: CartEmpty,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
    }
  },
  // {
  //   path: '/dashboard',
  //   name: 'DashBoard',
  //   component: DashBoard,
  //   meta: {
  //     public: true, // public => can access those pages without login
  //     guest: true, // guest => can only access those as a guest (like login page)
  //   }
  // },
  {
    path: '/proof-action/:order_id',
    name: 'ProofAction',
    component: ProofAction,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
    }
  },
  {
    path: '/upload-artwork/:order_id',
    name: 'UploadArtwork',
    component: UploadArtwork,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
    }
  },
  {
    path: '/payment/:order_id',
    name: 'Payment',
    component: Payment,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
    }
  },
  // {
  //   path: '/email-verified',
  //   name: 'EmailVerified',
  //   component: EmailVerified,
  //   meta: {
  //     public: true, // public => can access those pages without login
  //     guest: true, // guest => can only access those as a guest (like login page)
  //   }
  // },
  {
    path: '/account-created',
    name: 'AccountCreated',
    component: AccountCreated,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
    }
  },
  {
    path: '/not-found',
    name: 'NotFound',
    component: NotFound,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
    }
  },
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    component: TermsOfUse,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
    }
  },
  {
    path: '/customer',
    name: 'Customer',
    component: CustomerDashboard,
    meta: {
      hideNavbar: true,
      hideFooter: false,
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          hideNavbar: true,
        }
      },
      {
        path: 'quotes',
        name: 'Quotes',
        component: Quotes,
        meta: {
          hideNavbar: true,
        }
      },
      {
        path: 'orders',
        name: 'Orders',
        component: Orders,
        meta: {
          hideNavbar: true,
        }
      },
      {
        path: 'shipment',
        name: 'Shipment',
        component: Shipment,
        meta: {
          hideNavbar: true,
        }
      },
      {
        path: 'inventory',
        name: 'Inventory',
        component: Inventory,
        meta: {
          hideNavbar: true,
        }
      },
      {
        path: 'order-detail/:order_id',
        name: 'OrderDetails',
        component: OrderDetails,
        meta: {
          hideNavbar: true,
        }
      },
      {
        path: 'shipment-details',
        name: 'ShipmentDetails',
        component: ShipmentDetails,
        meta: {
          hideNavbar: true,
        }
      },
      {
        path: 'contacts',
        name: 'ContactsList',
        component: ContactsList,
        meta: {
          hideNavbar: true,
        }
      },
      {
        path: 'new-contact',
        name: 'AddNewContact',
        component: AddNewContact,
        meta: {
          hideNavbar: true,
        }
      },
      {
        path: 'contact-groups',
        name: 'ContactGroups',
        component: ContactGroups,
        meta: {
          hideNavbar: true,
        }
      },
      {
        path: 'add-contact-group',
        name: 'AddContactGroup',
        component: AddContactGroup,
        meta: {
          hideNavbar: true,
        }
      },
    ]
  },
]


const router = new VueRouter({
  mode: "history",
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    //https://router.vuejs.org/guide/advanced/scroll-behavior.html
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 }
    }
  }
});

// Auth guard
router.beforeEach((to, from, next) => {
  const authRequired = !to.matched.some(record => record.meta.public)
  const loggedIn = store.getters.isLoggedIn
  if (to.name == null) {
    next({
      path: '/not-found',
    })
    return
  }
  if (to.matched.some(record => record.meta.is_auth_page) && loggedIn) {
    next({
      path: '/customer/dashboard',
    })
  }
  if (to.matched.some(record => record.meta.anyone)) {
    next()
    return
  }
  if (authRequired && !loggedIn) {
    next({
      path: '/',
      query: { redirect: to.fullPath }
    })
  }
  // else if (!authRequired && loggedIn) {
  //   next('/')
  //   return
  // }
  next()
})

export default router
