<template>
  <v-app>
    <!-- Hero section-->
    <HeroSection />

    <!-- A swag management-->
    <SwagManagement />

    <!-- create, pack and ship your swag-->
    <CreateYourSwag />

    <!-- Gifting your peers should be the easiest thing on your mind -->
    <GiftingYourPeers />

    <!-- What’s a swagbox?-->
    <WhatSwagbox />

    <!-- There are a lot more reasons to build your swagbox with us -->
    <BuildYourSwagbox />

    <!-- Ready to get started? -->
    <GetStarted />

  </v-app>
</template>

<script>
  import HeroSection      from "./components/HeroSection"
  import SwagManagement   from "./components/SwagManagement"
  import CreateYourSwag   from "./components/CreateYourSwag"
  import GiftingYourPeers from "./components/GiftingYourPeers"
  import WhatSwagbox      from "./components/WhatSwagbox"
  import BuildYourSwagbox from "./components/BuildYourSwagbox"
  import GetStarted       from "./components/GetStarted"

  export default {
    name: 'Home-Page',
    components: {
      HeroSection,
      SwagManagement,
      CreateYourSwag,
      GiftingYourPeers,
      WhatSwagbox,
      BuildYourSwagbox,
      GetStarted
    },
    mounted() {},
    computed: {},
    data() {
      return {

      }
    },
    methods: {}
  }
</script>

<style scoped>

</style>
